import { iGetMagnaReportForRatingProcess } from 'services/magna-report-helper';

export interface iRPPrivateMagnaReport {
  isInit: boolean;
  isLoading: boolean;
  isOpen: boolean;
  series: iGetMagnaReportForRatingProcess[];
}

export enum iRPPrivateMagnaReportActionTypes {
  RP_PRIVATE_MAGNA_REPORT_MERGE = 'RP_PRIVATE_MAGNA_REPORT_MERGE',
  // saga
  RP_PRIVATE_MAGNA_REPORT_INIT = 'RP_PRIVATE_MAGNA_REPORT_INIT',
  RP_PRIVATE_MAGNA_REPORT_SAVE = 'RP_PRIVATE_MAGNA_REPORT_SAVE',
}

export interface iRPPrivateMagnaReportInitAction {
  type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_INIT;
}

export interface iRPPrivateMagnaReportMergeAction {
  type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_MERGE;
  payload: Partial<iRPPrivateMagnaReport>;
}

export interface iRPPrivateMagnaReportSaveAction {
  type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_SAVE;
  payload: {
    downloadData?: { leadAnalystID: string; secondaryAnalystID: string };
    handleClose?: () => void;
    data: Partial<iRPPrivateMagnaReport['series']>[];
  };
}
