import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { IRoute } from 'AurionCR/components/router';
import CreditRating from 'components/svg-icons/CreditRating';
import Issuers from 'components/svg-icons/Issuers';
import OfficeAdministration from 'components/svg-icons/OfficeAdministration';
import { lazy } from 'react';
import { ePERMISSIONS } from 'services/user-position';

interface IRouteApp extends IRoute {
  permission?: ePERMISSIONS | string;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardOutlinedIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/DashboardReports')),
  },
  {
    path: '/issuers',
    title: 'issuers',
    icon: Issuers,
    permission: ePERMISSIONS.isAllowToViewClients,
    routes: [
      {
        path: '/issuers',
        title: 'issuers',
        permission: ePERMISSIONS.isAllowToViewClients,
        component: lazy(() => import(/* webpackChunkName: 'Issuers' */ 'pages/Issuers/Issuers')),
      },
      {
        path: '/contacts',
        title: 'contacts',
        permission: ePERMISSIONS.isAllowToViewContacts,
        component: lazy(() => import(/* webpackChunkName: 'Contact' */ 'pages/Issuers/Contact')),
      },
      {
        path: '/controlling-groups',
        title: 'controlling-groups',
        permission: ePERMISSIONS.isAllowToViewControllingGroups,
        component: lazy(
          () => import(/* webpackChunkName: 'ControllingGroup' */ 'pages/Issuers/ControllingGroup'),
        ),
      },
      {
        path: '/sectors',
        title: 'sectors',
        permission: ePERMISSIONS.isAllowToViewSectors,
        component: lazy(() => import(/* webpackChunkName: 'Sector' */ 'pages/Issuers/Sector')),
      },
      {
        path: '/departments',
        title: 'departments',
        permission: ePERMISSIONS.isAllowToViewDepartments,
        component: lazy(
          () => import(/* webpackChunkName: 'Department' */ 'pages/Issuers/Department'),
        ),
      },
      {
        path: '/companies-for-magna-report',
        title: 'companies-for-magna-report',
        permission: ePERMISSIONS.isAllowToEditCompany,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CompaniesForMagnaReport' */ 'pages/Issuers/CompaniesForMagnaReport'
            ),
        ),
      },
    ],
  },
  {
    path: '/methodologies',
    title: 'methodologies',
    icon: LayersTwoToneIcon,
    permission: ePERMISSIONS.isAllowToViewMethodologies,
    routes: [
      {
        path: '/methodologies',
        title: 'methodologies',
        permission: ePERMISSIONS.isAllowToViewMethodologies,
        component: lazy(
          () => import(/* webpackChunkName: 'Methodologies' */ 'pages/Methodology/Methodologies'),
        ),
      },
      {
        path: '/professional-documents',
        title: 'professional-documents',
        permission: ePERMISSIONS.isAllowToViewProfessionalDocuments,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'MethodologyProffessionalDocument' */ 'pages/Methodology/MethodologyProffessionalDocument'
            ),
        ),
      },
    ],
  },
  {
    path: '/professional-processes',
    title: 'professional-processes',
    icon: CreditRating,
    permission: ePERMISSIONS.isAllowToViewRatingProcesses,
    routes: [
      {
        path: '/rating-processes',
        title: 'rating-processes',
        permission: ePERMISSIONS.isAllowToViewRatingProcesses,
        component: lazy(
          () => import(/* webpackChunkName: 'RatingProcess' */ 'pages/CreditRatings/RatingProcess'),
        ),
      },
      {
        path: '/quarterly-monitoring',
        title: 'quarterly-monitoring',
        permission: ePERMISSIONS.isAllowToViewQuarterlyMonitoring,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'QuarterlyMonitoring' */ 'pages/CreditRatings/QuarterlyMonitoring'
            ),
        ),
      },
      {
        path: '/reports',
        title: 'reports',
        permission: ePERMISSIONS.isAllowToViewReports,
        component: lazy(
          () => import(/* webpackChunkName: 'RatingReports' */ 'pages/CreditRatings/RatingReports'),
        ),
      },
      {
        path: '/series-list',
        title: 'series-list',
        permission: ePERMISSIONS.isAllowToViewSeries,
        component: lazy(
          () => import(/* webpackChunkName: 'RatedSeries' */ 'pages/CreditRatings/RatedSeries'),
        ),
      },
      {
        path: '/series-rating-list',
        title: 'series-rating-list',
        permission: ePERMISSIONS.isAllowToViewSeries,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'SeriesRatingList' */ 'pages/CreditRatings/SeriesRatingList'
            ),
        ),
      },
    ],
  },
  {
    path: '/commercial-processes',
    title: 'commercial-processes',
    icon: WorkTwoToneIcon,
    permission: ePERMISSIONS.isAllowToViewComercialProcesses,
    routes: [
      {
        path: '/orders',
        title: 'orders',
        permission: ePERMISSIONS.isAllowToViewOrders,
        component: lazy(() => import(/* webpackChunkName: 'Orders' */ 'pages/Commercial/Order')),
      },
      {
        path: '/products',
        title: 'products',
        permission: ePERMISSIONS.isAllowToViewProducts,
        component: lazy(
          () => import(/* webpackChunkName: 'RatingProducts' */ 'pages/Commercial/RatingProducts'),
        ),
      },
      {
        path: '/rating-process-order',
        title: 'rating-process-order',
        permission: ePERMISSIONS.isAllowToViewOrders,
        component: lazy(
          () => import(/* webpackChunkName: 'RatingProcess' */ 'pages/Commercial/RatingProcess'),
        ),
      },
      {
        path: '/raised-capital-orders',
        title: 'raised-capital-orders',
        permission: ePERMISSIONS.isAllowToViewPaymentTransactions,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'LinkRatingProcessToAnOrder' */ 'pages/Commercial/LinkRatingProcessToAnOrder'
            ),
        ),
      },
      {
        path: '/payments',
        title: 'payments',
        permission: ePERMISSIONS.isAllowToViewPaymentTransactions,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'InvoiceMonitoring' */ 'pages/Commercial/InvoiceMonitoring'
            ),
        ),
      },
      {
        path: '/client-initiate-ratings',
        title: 'client-initiate-ratings',
        permission: ePERMISSIONS.isAllowToViewOrders,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'PartyRequestedTheRatings' */ 'pages/Commercial/PartyRequestedTheRatings'
            ),
        ),
      },
      {
        path: '/client-pay-ratings',
        title: 'client-pay-ratings',
        permission: ePERMISSIONS.isAllowToViewOrders,
        component: lazy(
          () =>
            import(
              /* wbpackChunkName: 'PartyThatPaidForTheRatings' */ 'pages/Commercial/PartyThatPaidForTheRatings'
            ),
        ),
      },
    ],
  },
  {
    path: '/system-reports',
    title: 'system-reports',
    icon: PollTwoToneIcon,
    routes: [
      {
        path: '/compliance-officer-reports',
        title: 'compliance-officer-reports',
        permission: ePERMISSIONS.isAllowToViewComplianceReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'ComplianceOfficerReport' */ 'pages/SystemReport/ComplianceOfficerReport'
            ),
        ),
      },
      {
        path: '/issuer-list-reports',
        title: 'issuer-list-reports',
        permission: ePERMISSIONS.isAllowToViewCompanyReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'IssuersListReport' */ 'pages/SystemReport/IssuersListReport'
            ),
        ),
      },
      {
        path: '/series-for-magna',
        title: 'series-for-magna',
        permission: ePERMISSIONS.isAllowToViewFinanceReports,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'SeriesForMagna' */ 'pages/SystemReport/SeriesForMagna'),
        ),
      },
      {
        path: '/rating-process-reports',
        title: 'rating-process-reports',
        permission: ePERMISSIONS.isAllowToViewCommitteeReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingProcessReport' */ 'pages/SystemReport/RatingProcessReport'
            ),
        ),
      },
      {
        path: '/raising-capital-reports',
        title: 'raising-capital-reports',
        permission: ePERMISSIONS.isAllowToViewRaisingCapitalReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RaisingCapitalReport' */ 'pages/SystemReport/RaisingCapitalReport'
            ),
        ),
      },
      {
        path: '/committee-reports',
        title: 'committee-reports',
        permission: ePERMISSIONS.isAllowToViewCommitteeReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommitteesReport' */ 'pages/SystemReport/CommitteesReport'
            ),
        ),
      },
      {
        path: '/preliminary-reports',
        title: 'preliminary-reports',
        permission: ePERMISSIONS.isAllowToViewPreliminaryRatingReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'PreliminaryReport' */ 'pages/SystemReport/PreliminaryReport'
            ),
        ),
      },
      {
        path: '/training-reports',
        title: 'training-reports',
        permission: ePERMISSIONS.isAllowToViewTrainingReports,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'TrainingReport' */ 'pages/SystemReport/TrainingReport'),
        ),
      },
      {
        path: '/monitoring-reports',
        title: 'monitoring-reports',
        permission: ePERMISSIONS.isAllowToViewMonitoringReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'MonitoringReport' */ 'pages/SystemReport/MonitoringReport'
            ),
        ),
      },
      {
        path: '/commercial-reports',
        title: 'commercial-reports',
        permission: ePERMISSIONS.isAllowToViewFinanceReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommercialReport' */ 'pages/SystemReport/CommercialReport'
            ),
        ),
      },
      {
        path: '/order-reports',
        title: 'order-reports',
        permission: ePERMISSIONS.isAllowToViewFinanceReports,
        component: lazy(
          () => import(/* webpackChunkName: 'OrderReport' */ 'pages/SystemReport/OrderReport'),
        ),
      },
      {
        path: '/series-reports',
        title: 'series-reports',
        permission: ePERMISSIONS.isAllowToViewSeriesReports,
        component: lazy(
          () => import(/* webpackChunkName: 'SeriesReport' */ 'pages/SystemReport/SeriesReport'),
        ),
      },
      {
        path: '/credit-review-reports',
        title: 'credit-review-reports',
        // permission: ePERMISSIONS.isAllowToViewComplianceReports,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CreditReviewReports' */ 'pages/SystemReport/CreditReviewReports'
            ),
        ),
      },
    ],
  },
  {
    path: '/office-administration',
    title: 'office-administration',
    icon: OfficeAdministration,
    permission: ePERMISSIONS.isAllowToViewOfficeAdministrations,
    routes: [
      {
        path: '/employees',
        title: 'employees',
        permission: ePERMISSIONS.isAllowToViewEmployees,
        component: lazy(
          () => import(/* webpackChunkName: 'Employees' */ 'pages/OfficeAdministration/Employees'),
        ),
      },
      {
        path: '/trainings',
        title: 'trainings',
        permission: ePERMISSIONS.isAllowToViewTrainings,
        component: lazy(
          () => import(/* webpackChunkName: 'Training' */ 'pages/OfficeAdministration/Training'),
        ),
      },
      {
        path: '/website-publication',
        title: 'website-publication',
        permission: ePERMISSIONS.isAllowToViewWebSitePublication,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'WebSitePublication' */ 'pages/OfficeAdministration/WebSitePublication'
            ),
        ),
      },
    ],
  },
  {
    path: '/rating-performance',
    title: 'rating-performance',
    icon: DonutSmallTwoToneIcon,
    routes: [
      {
        path: '/rating-performance-import-and-export-files',
        title: 'rating-performance-import-and-export-files',
        permission: ePERMISSIONS.isAllowToViewRatingPerformance,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingPerformanceImportAndExportFiles' */ 'pages/RatingPerformance/RatingPerformanceImportAndExportFiles'
            ),
        ),
      },
      {
        path: '/update-rating-processes-for-rating-performance',
        title: 'update-rating-processes-for-rating-performance',
        permission: ePERMISSIONS.isAllowToViewRatingPerformance,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'UpdateRatingProcessesForRatingPerformance' */ 'pages/RatingPerformance/UpdateRatingProcessesForRatingPerformance'
            ),
        ),
      },
      {
        path: '/update-series-for-rating-performance',
        title: 'update-series-for-rating-performance',
        permission: ePERMISSIONS.isAllowToViewRatingPerformance,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'UpdateSeriesForRatingPerformance' */ 'pages/RatingPerformance/UpdateSeriesForRatingPerformance'
            ),
        ),
      },
      {
        path: '/rating-performance-report',
        title: 'rating-performance-report',
        permission: ePERMISSIONS.isAllowToViewRatingPerformance,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingPerformanceReport' */ 'pages/RatingPerformance/RatingPerformanceReport'
            ),
        ),
      },
      {
        path: '/rating-performance-history',
        title: 'rating-performance-history',
        permission: ePERMISSIONS.isAllowToViewRatingPerformanceDashboard,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingPerformanceHistory' */ 'pages/RatingPerformance/RatingPerformanceHistory'
            ),
        ),
      },
    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsOutlinedIcon,
    permission: ePERMISSIONS.isAllowToViewSettings,
    routes: [
      {
        path: '/system-users',
        title: 'system-users',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () => import(/* webpackChunkName: 'SystemUsers' */ 'pages/Settings/SystemUsers'),
        ),
      },
      {
        path: '/system-settings',
        title: 'system-settings',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ 'pages/ZTemp')),
      },
      {
        path: '/permissions',
        title: 'permissions',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () => import(/* webpackChunkName: 'Permissions' */ 'pages/Settings/Permissions'),
        ),
      },
      {
        path: '/labels',
        title: 'labels',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Settings/Labels')),
      },
      {
        path: '/languages',
        title: 'languages',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () => import(/* webpackChunkName: 'Languages' */ 'pages/Settings/Languages'),
        ),
      },
      {
        path: '/rating-scale-parameters',
        title: 'rating-scale-parameters',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirect-to-first-children' */ 'components/layout/redirect-to-first-children'
            ),
        ),
        routes: [
          {
            path: '/midroog-rating',
            title: 'midroog-rating',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MidroogRating'
                ),
            ),
          },
          {
            path: '/midroog-outlook',
            title: 'midroog-outlook',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MidroogOutlook'
                ),
            ),
          },
          {
            path: '/midroog-rating-symbol',
            title: 'midroog-rating-symbol',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MidroogRatingSymbol'
                ),
            ),
          },
          {
            path: '/moodys-outlook',
            title: 'moodys-outlook',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MoodysOutlook'
                ),
            ),
          },
          {
            path: '/moodys-rating',
            title: 'moodys-rating',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MoodysRating'
                ),
            ),
          },
          {
            path: '/maalot-outlook',
            title: 'maalot-outlook',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MaalotOutlook'
                ),
            ),
          },
          {
            path: '/maalot-rating',
            title: 'maalot-rating',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingScaleParameters' */ 'pages/Settings/RatingScaleParameters/MaalotRating'
                ),
            ),
          },
        ],
      },
      {
        path: '/quarterly-monitoring-parameters',
        title: 'quarterly-monitoring-parameters',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirect-to-first-children' */ 'components/layout/redirect-to-first-children'
            ),
        ),
        routes: [
          {
            path: '/different-than-forecast',
            title: 'different-than-forecast',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/DifferentThanForecast'
                ),
            ),
          },
          {
            path: '/financial-convention-type',
            title: 'financial-convention-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/FinancialConventionType'
                ),
            ),
          },
          {
            path: '/check-models-assumption',
            title: 'check-models-assumption',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/CheckModelsAssumption'
                ),
            ),
          },
          {
            path: '/linkage-basis-type',
            title: 'linkage-basis-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/LinkageBasisType'
                ),
            ),
          },
          {
            path: '/security-pads-full',
            title: 'security-pads-full',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/SecurityPadsFull'
                ),
            ),
          },
          {
            path: '/signed-officer-statement',
            title: 'signed-officer-statement',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/SignedOfficerStatement'
                ),
            ),
          },
          {
            path: '/financial-statement-trend-type',
            title: 'financial-statement-trend-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'QuarterlyMonitoringParameters' */ 'pages/Settings/QuarterlyMonitoringParameters/FinancialStatementTrendType'
                ),
            ),
          },
        ],
      },
      {
        path: '/rating-process-parameters',
        title: 'rating-process-parameters',
        // permission: ePERMISSIONS.isAllowToViewSettings,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'redirect-to-first-children' */ 'components/layout/redirect-to-first-children'
            ),
        ),
        routes: [
          {
            path: '/rating-process-type',
            title: 'rating-process-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessType'
                ),
            ),
          },
          {
            path: '/rating-process-type-group',
            title: 'rating-process-type-group',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessTypeGroup'
                ),
            ),
          },
          {
            path: '/rating-process-type-option',
            title: 'rating-process-type-option',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessTypeOption'
                ),
            ),
          },
          {
            path: '/rating-process-status',
            title: 'rating-process-status',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessStatus'
                ),
            ),
          },
          {
            path: '/rating-process-step-type',
            title: 'rating-process-step-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessStepType'
                ),
            ),
          },
          {
            path: '/publish-type',
            title: 'publish-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/PublishType'
                ),
            ),
          },
          {
            path: '/rating-and-tracking-message',
            title: 'rating-and-tracking-message',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingAndTrackingMessage'
                ),
            ),
          },
          {
            path: '/rating-initiative',
            title: 'rating-initiative',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingInitiative'
                ),
            ),
          },
          {
            path: '/rating-status',
            title: 'rating-status',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingStatus'
                ),
            ),
          },
          {
            path: '/rating-stopped-reason',
            title: 'rating-stopped-reason',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingStoppedReason'
                ),
            ),
          },
          {
            path: '/rating-subject',
            title: 'rating-subject',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingSubject'
                ),
            ),
          },
          {
            path: '/rating-tool',
            title: 'rating-tool',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingTool'
                ),
            ),
          },
          {
            path: '/base-committee-material',
            title: 'base-committee-material',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/BaseCommitteeMaterial'
                ),
            ),
          },
          {
            path: '/base-committee-resource',
            title: 'base-committee-resource',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/BaseCommitteeResource'
                ),
            ),
          },
          {
            path: '/base-committee-significant-change',
            title: 'base-committee-significant-change',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/BaseCommitteeSignificantChange'
                ),
            ),
          },
          {
            path: '/base-report-checkup',
            title: 'base-report-checkup',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/BaseReportCheckup'
                ),
            ),
          },
          {
            path: '/client-decision',
            title: 'client-decision',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/ClientDecision'
                ),
            ),
          },
          {
            path: '/committee-manner',
            title: 'committee-manner',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/CommitteeManner'
                ),
            ),
          },
          {
            path: '/committee-position',
            title: 'committee-position',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/CommitteePosition'
                ),
            ),
          },
          {
            path: '/committee-significant-change-type',
            title: 'committee-significant-change-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/CommitteeSignificantChangeType'
                ),
            ),
          },
          {
            path: '/committee-type',
            title: 'committee-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/CommitteeType'
                ),
            ),
          },
          {
            path: '/monitor-decision-type',
            title: 'monitor-decision-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/MonitorDecisionType'
                ),
            ),
          },
          {
            path: '/monitor-status',
            title: 'monitor-status',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/MonitorStatus'
                ),
            ),
          },
          {
            path: '/publication-approval-type',
            title: 'publication-approval-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/PublicationApprovalType'
                ),
            ),
          },
          {
            path: '/report-checkup-type',
            title: 'report-checkup-type',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/ReportCheckupType'
                ),
            ),
          },
          {
            path: '/report-publication-option',
            title: 'report-publication-option',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/ReportPublicationOption'
                ),
            ),
          },
          {
            path: '/rating-process-step-update-status',
            title: 'rating-process-step-update-status',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'RatingProcessParameters' */ 'pages/Settings/RatingProcessParameters/RatingProcessStepUpdateStatus'
                ),
            ),
          },
          {
            path: '/documentation-statuses',
            title: 'documentation-statuses',
            component: lazy(
              () =>
                import(
                  /* webpackChunkName: 'DocumentationStatuses' */ 'pages/Settings/RatingProcessParameters/DocumentationStatuses'
                ),
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
];

export default routes;
