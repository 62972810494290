import { apiStatic, apiStaticCanceled, requestError } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import axios from 'axios';
import { format, isValid } from 'date-fns';
import { uniqBy } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API_DEPARTMENT } from 'services/department';
import { API_QUARTERLY_MONITORING } from 'services/quarterly-monitoring';
import {
  API_QUARTERLY_MONITORING_PERIOD,
  QuarterlyMonitoringPeriod,
} from 'services/quarterly-monitoring-period';
import {
  DepartmentItem,
  iQMS,
  iStepAnalyticsTasks,
  QMActionTypes,
  QMAddNewAction,
  QMState,
  QMStepAnalyticsTasksQMSaveAction,
  QMStepMeetingMergeSaveAction,
} from './@type';
import { getQuarterTitle, QMFormDefaultValues, QMPrepareData } from './helper';
import {
  QMFiltersMerge,
  QMMerge,
  QMSourceMerge,
  QMStepAnalyticsTasksMerge,
  QMStepAnalyticsTasksQMMerge,
  QMStepAnalyticsTasksReset,
  QMStepFinishMerge,
  QMStepMeetingMerge,
  stepAnalyticsTasksState,
  stepFinishState,
  stepMeetingState,
} from './module';
import { onUpdateHtmlField } from 'utils/html-upload-helpers';

const QMSPSelected =
  'id,isCompleted,meetingSummary,yieldsFilePath,isAnalystsStepCompleted,headOfFieldSignatureDate,ceoSignatureDate';
const QMSelectedMain = () => Object.keys(QMFormDefaultValues()).join();
const QMSelectedAddition = () =>
  [
    'ratedEntityID',
    'ratedEntity.name as ratedEntityName',
    'financialStatementTrendType.title as financialStatementTrendTypeTitle',
    'ratedEntity.longTermMidroogRating.title as ratedEntityLongTermMidroogRatingTitle',
    'ratedEntity.midroogOutlook.title as ratedEntityMidroogOutlookTitle',
    'ratedEntity.RatingProcesses.Where(x => x.IsCompleted&&(x.RatingProcessTypeID==8||x.RatingProcessTypeID==5)).OrderByDescending(x => x.CreatedDate).FirstOrDefault().RatingProcessSteps.Where(x => x.IsCompleted &&x.RatingProcessStepTypeID==5).FirstOrDefault().RatingProcessStepPublications.FirstOrDefault().HeadOfFieldSignatureDate as ratedEntityLastMonitoringRatingProcessDate',
    '((DateTime.Now.Date-convert.ToDateTime(ratedEntity.RatingProcesses.Where(x =>x.IsCompleted&&(x.RatingProcessTypeID==8||x.RatingProcessTypeID==5)).OrderByDescending(x =>x.CreatedDate).FirstOrDefault().RatingProcessSteps.Where(x => x.IsCompleted &&x.RatingProcessStepTypeID==5).FirstOrDefault().RatingProcessStepPublications.FirstOrDefault().HeadOfFieldSignatureDate).Date).TotalDays>365) as ratedEntityLastMonitoringRatingProcessDateExpired',
    'isIgnored',
    'isCompleted',
    'isMark',
  ].join();

// get departments
function* getDepartments() {
  try {
    const {
      data: { value: departments },
    }: { data: { value: DepartmentItem[] } } = yield call(apiStaticCanceled, {
      url: `${API_DEPARTMENT.GET_ALL_DYNAMIC}?select=id,title&filter=isActive==true%26%26isProfessional==true&orderBy=title`,
      _cancelID: `${API_DEPARTMENT.API}_QM`,
    });
    yield put(QMSourceMerge({ departments }));
    yield put(QMFiltersMerge({ department: departments[0] }));
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

// get periods
function* getPeriods() {
  const {
    filters: { department },
  }: QMState = yield select((state) => state.QM);
  if (department) {
    try {
      const {
        data: { value },
      }: { data: { value: any[] } } = yield call(apiStaticCanceled, {
        url: `${API_QUARTERLY_MONITORING_PERIOD.GET_ALL_DYNAMIC}?select=monitoringDate&Filter=departmentID==${department.id}&OrderBy=monitoringDate desc`,
        _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_QM`,
      });
      const periods = uniqBy(value, 'monitoringDate').reduce((acc, item) => {
        if (item?.monitoringDate) {
          const date = new Date(item.monitoringDate);
          if (isValid(date)) {
            acc.push({ id: getQuarterTitle(date), date });
          }
        }
        return acc;
      }, []);
      yield put(QMSourceMerge({ periods }));
    } catch (e) {
      if (!axios.isCancel(e)) {
        yield put(notifyRequestResult(requestError(e), 'error'));
      }
    }
  }
}

// INIT
function* init() {
  const { init, loading }: QMState = yield select((state) => state.QM);
  if (!init && !loading) {
    yield put(QMMerge({ loading: true }));
    // get sources
    yield call(getDepartments);
    yield call(getPeriods);
    // set init period
    const {
      sources: { periods },
    }: QMState = yield select((state) => state.QM);
    yield put(QMFiltersMerge({ period: periods[0] }));
    // load first step
    yield call(getDataStepAnalyticsTasks);
  }
}

function* sync() {
  const {
    stepAnalyticsTasks: { QMPeriod },
  }: QMState = yield select((state) => state.QM);
  if (QMPeriod?.id) {
    yield put(QMMerge({ loading: true }));
    try {
      yield call(apiStaticCanceled, {
        url: API_QUARTERLY_MONITORING_PERIOD.SyncQuarterlyMonitoring({
          id: QMPeriod?.id as number,
        }),
        _cancelID: `${API_DEPARTMENT.API}_SYNC_QM`,
      });
      yield put(QMMerge({ loading: false }));
      yield call(getDataStepAnalyticsTasks);
    } catch (e) {
      yield put(QMMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* filterDepartment() {
  // loading
  yield put(QMStepAnalyticsTasksMerge({ loading: true }));
  // get period sources
  yield call(getPeriods);
  const {
    filters: { period },
    sources: { periods },
  }: QMState = yield select((state) => state.QM);
  // check exist current period
  const current = periods.find(({ id }) => id === period?.id);
  yield put(QMFiltersMerge({ period: current || periods[0] }));
  // load
  yield call(getDataStepAnalyticsTasks, true);
}

function* addNew({ payload }: QMAddNewAction) {
  const {
    sources: { departments },
  }: QMState = yield select((state) => state.QM);
  const { duplicate, monitoringDate, ...data } = payload;
  const period = {
    id: getQuarterTitle(monitoringDate),
    date: monitoringDate,
  };
  const department = departments.find((item) => item.id === payload.departmentID);
  if (department) {
    yield put(QMMerge({ loading: true }));
    try {
      yield call(
        apiStatic.post,
        API_QUARTERLY_MONITORING_PERIOD.InitQuarterlyMonitoring(duplicate),
        { ...data, monitoringDate: `${format(monitoringDate, 'yyyy-MM-dd')}T00:00:00` },
      );
      yield put(QMFiltersMerge({ period, department }));
      yield put(QMMerge({ openAddNew: false }));
      yield call(filterDepartment);
    } catch (e) {
      yield put(QMMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* checkCompleteStepAnalytics() {
  const {
    stepAnalyticsTasks: { QMS, QMSList, QMPeriod },
  }: QMState = yield select((state) => state.QM);
  const complete = !QMSList.find((id) => !QMS[id].data.isCompleted && !QMS[id].data.isIgnored);
  if (QMSList.length) {
    yield put(QMStepAnalyticsTasksMerge({ complete }));
    if (QMPeriod?.isAnalystsStepCompleted !== complete) {
      try {
        const id = QMPeriod?.id as number;
        yield call(apiStaticCanceled, {
          method: 'PATCH',
          url: API_QUARTERLY_MONITORING_PERIOD.PATCH({ id }),
          _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_PATCH_ASC_QM`,
          data: { isAnalystsStepCompleted: complete, id },
        });
        yield put(
          QMStepAnalyticsTasksMerge({
            QMPeriod: { ...QMPeriod, isAnalystsStepCompleted: complete },
          }),
        );
      } catch (_) {}
    }
  }
}

function* getDataStepAnalyticsTasksRequests(filter: string): any {
  const [QMSMain, QMSAdditions] = yield all([
    call(apiStaticCanceled, {
      url: API_QUARTERLY_MONITORING.GET_ALL_DYNAMIC,
      _cancelID: `${API_QUARTERLY_MONITORING.API}_QMS_MAIN`,
      params: {
        select: QMSelectedMain(),
        filter,
        count: true,
      },
    }),
    call(apiStaticCanceled, {
      url: API_QUARTERLY_MONITORING.GET_ALL_DYNAMIC,
      _cancelID: `${API_QUARTERLY_MONITORING.API}_QMS_ADDITIONS`,
      params: {
        select: QMSelectedAddition(),
        filter,
        count: true,
      },
    }),
  ]);
  const QMS = QMSMain.data.value.map((item: any, i: number) => ({
    ...item,
    ...QMSAdditions?.data?.value[i],
  }));
  return QMS;
}

function* getDataStepAnalyticsTasks(ignoreLoading = false) {
  const {
    filters: { period, department },
    stepAnalyticsTasks: { loading },
  }: QMState = yield select((state) => state.QM);
  if (!loading || ignoreLoading) {
    yield put(QMStepAnalyticsTasksMerge({ loading: true }));
    try {
      const {
        data: { value: QMP },
      }: { data: { value: Partial<QuarterlyMonitoringPeriod>[] } } = yield call(apiStaticCanceled, {
        url: API_QUARTERLY_MONITORING_PERIOD.GET_ALL_DYNAMIC,
        _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_QM`,
        params: {
          select: QMSPSelected,
          filter: `departmentID==${department?.id} && monitoringDate.Date=="${format(
            period?.date as Date,
            'yyyy-MM-dd',
          )}"`,
          take: 1,
        },
      });
      if (QMP[0]) {
        const QMPeriod = QMP[0];
        const QMS: iQMS['data'][] = yield call(
          getDataStepAnalyticsTasksRequests,
          `quarterlyMonitoringPeriodID==${QMPeriod?.id}`,
        );
        yield put(
          QMMerge({
            stepAnalyticsTasks: stepAnalyticsTasksState({
              QMPeriod,
              complete: QMPeriod.isCompleted,
              selected: QMS[0]?.id || 0,
              ...QMS.reduce(
                (
                  acc: { QMS: iStepAnalyticsTasks['QMS']; QMSList: iStepAnalyticsTasks['QMSList'] },
                  data,
                ) => {
                  acc.QMSList.push(data.id);
                  acc.QMS[data.id] = { data: QMPrepareData(data), loading: false, id: data.id };
                  return acc;
                },
                { QMSList: [], QMS: {} },
              ),
            }),
            stepMeeting: stepMeetingState({ complete: QMPeriod.isCompleted }),
            stepFinish: stepFinishState({ complete: QMPeriod.isCompleted }),
            step: 'stepAnalyticsTasks',
          }),
        );

        yield call(checkCompleteStepAnalytics);
      } else {
        yield put(QMStepAnalyticsTasksReset());
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        yield put(QMStepAnalyticsTasksReset());
        yield put(notifyRequestResult(requestError(e), 'error'));
      }
    }
  }
  yield put(QMMerge({ init: true, loading: false }));
}

function* saveStepAnalyticsQM({ payload }: QMStepAnalyticsTasksQMSaveAction) {
  const { id, data: Data } = payload;
  const {
    stepAnalyticsTasks: { QMS },
  }: QMState = yield select((state) => state.QM);
  const QM = QMS[id];
  if (QM) {
    yield put(QMStepAnalyticsTasksQMMerge({ id, loading: true }));
    try {
      let { data, mixins } = parseMixins(Data as iQMS['data']);
      // @ts-ignore
      data = yield call(saveMixins, data, mixins);
      // @ts-ignore
      const htmlFieldsData = yield onUpdateHtmlField({
        formData: Data,
        field: ['remarks'],
      });
      yield call(apiStaticCanceled, {
        method: 'PATCH',
        url: API_QUARTERLY_MONITORING.PATCH({ id }),
        _cancelID: `${API_QUARTERLY_MONITORING.API}_PATCH_QM`,
        data: { ...data, remarks: htmlFieldsData.remarks },
      });
      const QMS: iQMS['data'][] = yield call(getDataStepAnalyticsTasksRequests, `id==${id}`);
      if (QMS[0]) {
        yield put(QMStepAnalyticsTasksQMMerge({ id, loading: false, data: QMPrepareData(QMS[0]) }));
        yield call(checkCompleteStepAnalytics);
      } else {
        yield put(notifyRequestResult('error', 'error'));
        yield put(QMStepAnalyticsTasksQMMerge({ id, loading: false }));
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        yield put(notifyRequestResult(requestError(e), 'error'));
        yield put(QMStepAnalyticsTasksQMMerge({ id, loading: false }));
      }
    }
  }
}

function* saveStepMeeting({ payload }: QMStepMeetingMergeSaveAction) {
  yield put(QMStepMeetingMerge({ loading: true }));
  try {
    let { data, mixins } = parseMixins(payload);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    // @ts-ignore
    const htmlFieldsData = yield onUpdateHtmlField({
      formData: payload,
      field: ['remarks'],
    });
    yield call(apiStaticCanceled, {
      method: 'PATCH',
      url: API_QUARTERLY_MONITORING_PERIOD.PATCH(data),
      _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_PATCH_QM`,
      data: { ...data, remarks: htmlFieldsData.remarks },
    });
    const {
      data: { value: QMP },
    }: { data: { value: Partial<QuarterlyMonitoringPeriod>[] } } = yield call(apiStaticCanceled, {
      url: API_QUARTERLY_MONITORING_PERIOD.GET_ALL_DYNAMIC,
      _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_QM`,
      params: {
        select: QMSPSelected,
        filter: `id==${data.id}`,
        take: 1,
      },
    });
    yield put(QMStepAnalyticsTasksMerge({ QMPeriod: QMP[0] }));
    yield put(QMStepMeetingMerge({ loading: false, complete: true }));
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(QMStepMeetingMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* saveStepFinish({ payload: data }: QMStepMeetingMergeSaveAction) {
  yield put(QMStepFinishMerge({ loading: true }));
  try {
    yield call(apiStaticCanceled, {
      method: 'PATCH',
      url: API_QUARTERLY_MONITORING_PERIOD.PATCH(data as any),
      _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_PATCH_QM`,
      data,
    });
    const { data: value }: { data: Partial<QuarterlyMonitoringPeriod> } = yield call(
      apiStaticCanceled,
      {
        url: API_QUARTERLY_MONITORING_PERIOD.CompleteQuarterlyMonitoring(data as any),
        _cancelID: `${API_QUARTERLY_MONITORING_PERIOD.API}_COMPLETE_QM`,
      },
    );
    yield put(QMStepAnalyticsTasksMerge({ QMPeriod: value }));
    yield put(QMStepFinishMerge({ loading: false, complete: true }));
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(QMStepFinishMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

// EXPORTS
export const QMSagas = [
  takeLatest(QMActionTypes.QM_INIT, init),
  takeLatest(QMActionTypes.QM_SYNC, sync),
  takeLatest(QMActionTypes.QM_ADD_NEW, addNew),
  takeLatest(QMActionTypes.QM_FILTER_DEPARTMENT, filterDepartment),
  takeLatest(QMActionTypes.QM_STEP_ANALYTICS_TASKS_GET_DATA, getDataStepAnalyticsTasks),
  takeLatest(QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_SAVE, saveStepAnalyticsQM),
  takeLatest(QMActionTypes.QM_STEP_MEETING_SAVE, saveStepMeeting),
  takeLatest(QMActionTypes.QM_STEP_FINISH_SAVE, saveStepFinish),
];
