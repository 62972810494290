import { apiStatic, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { pathStepCommittee } from 'components/rating-process-preview/step-committee';
import { pathStepDone } from 'components/rating-process-preview/step-done';
import { pathStepForecast } from 'components/rating-process-preview/step-forecast';
import { pathStepInformationRequest } from 'components/rating-process-preview/step-information-request';
import { pathStepPublication } from 'components/rating-process-preview/step-publication';
import { pathStepReport } from 'components/rating-process-preview/step-report';
import { format } from 'date-fns';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  API_RATING_PROCESS_DOCUMENTATION,
  eDocumentType,
} from 'services/rating-process-documentation';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { iRPState, iRPStepDoneActionTypes, iRPStepDoneFinishAction } from '../@type';
import { RPMainUpdateStep, RPMergeMain, RPStepDoneMerge } from '../helpers';
import { pdfData } from './pdf';
import { API_RATING_PROCESS } from 'services/rating-process';
import { prepareRequest } from 'components/helpers';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';

function* stepData({ path, step }: any): any {
  if (step.isCompleted) {
    const {
      data: { value },
    } = yield call(apiStatic.get, String(path));
    return {
      step,
      value: value[0],
    };
  } else {
    return {
      step,
      value: null,
    };
  }
}

function* getData() {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.id) {
    try {
      const data: any[] = yield all(
        main.ratingProcessSteps
          .map((step) => {
            switch (step.ratingProcessStepTypeID) {
              case 1:
                return { step, path: pathStepInformationRequest(main.id) };
              case 2:
                return { step, path: pathStepForecast(step.id) };
              case 3:
                return { step, path: pathStepCommittee(step.id) };
              case 4:
                return { step, path: pathStepReport(step.id) };
              case 5:
                return { step, path: pathStepPublication(step.id) };
              case 1000:
                return { step, path: pathStepDone(main.id) };
              default:
                return undefined;
            }
          })
          .filter((value) => value)
          .map((value) => call(stepData, value)),
      );
      yield put(RPStepDoneMerge({ isInit: true, isLoading: false, data }));
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* init() {
  const {
    stepDone: { isInit, isLoading },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit && !isLoading) {
    yield put(RPStepDoneMerge({ isLoading: true }));
    yield call(getData);
  }
}

export const fileToBase64 = (file: Blob): any => {
  return new Promise<string>((res, rej) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (!e.target) {
        return rej('error-file-type');
      }
      return res(String(e.target.result));
    };
    fileReader.onerror = rej;
    fileReader.readAsDataURL(file);
  });
};
export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};
export const bufferToBlobPdf = (buffer: Buffer): any => {
  return new Blob([buffer], { type: 'application/pdf' });
};

function* finish({ payload }: iRPStepDoneFinishAction) {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  yield put(RPStepDoneMerge({ isLoading: true }));
  try {
    yield call(() => saveForm({ payload, type: iRPStepDoneActionTypes.RP_STEP_DONE_FINISH }));
    // :TODO RP CREATE PDF
    const html: string = yield call(pdfData);
    const { data }: { data: any } = yield call(
      apiStatic.post,
      `PdfGeneratorHelper/GetPDF`,
      {
        ratedEntityName: main?.ratedEntityName,
        ratingProcessTypeTitle: main?.ratingProcessTypeTitle,
        html,
      },
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      },
    );
    // @ts-ignore
    const blob = yield call(bufferToBlobPdf, data);
    // @ts-ignore
    const b64 = yield call(fileToBase64, blob);
    const title = `${main?.ratedEntityName || ''} ${main?.ratingProcessTypeTitle || ''} | ${format(
      new Date(),
      'yyyy MM',
    )}`;
    const {
      data: { filePath },
    } = yield call(apiStatic.post, 'MediaUpload/UploadFileToCloud', {
      fileName: `${title}.pdf`,
      fileStreamString: base64ToFileStream(b64),
      filePath: 'Documents',
    });
    yield call(apiStatic.post, API_RATING_PROCESS_DOCUMENTATION.POST, {
      title,
      filePath,
      ratingProcessID: Number(main?.id),
      documentType: eDocumentType.Auto,
    });
    // window.open(URL.createObjectURL(blob));
    // :TODO RP COMPLETE
    if (main?.ratingProcessStatusID === 4) {
      yield call(
        apiStatic.get,
        API_RATING_PROCESS_HELPER.FINISH_RATING_PROCESS_FOR_UPDATE(Number(main?.id)),
      );
    } else {
      yield call(apiStatic.get, API_RATING_PROCESS_HELPER.FINISH_RATING_PROCESS(Number(main?.id)));
    }
    yield put(RPMainUpdateStep({ stepIndex: currentStep, isCompleted: true, isStepEdit: false }));
    yield put(RPStepDoneMerge({ isLoading: false }));
    yield put(RPMergeMain({ isCompleted: true, isRPCompleted: true, ratingProcessStatusID: 3 }));
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
    yield put(RPStepDoneMerge({ isLoading: false }));
  }
}

function* saveForm({ payload }: iRPStepDoneFinishAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  try {
    if (main?.id) {
      let { data, mixins } = parseMixins(payload);
      // @ts-ignore
      data = yield call(saveMixins, data, mixins);
      yield call(apiStatic.patch, API_RATING_PROCESS.PATCH({ id: main.id }), prepareRequest(data));
    }
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* submitPreviewDoneForm({ payload }: iRPStepDoneFinishAction) {
  yield put(RPStepDoneMerge({ isLoading: true }));
  try {
    yield call(() => saveForm({ payload, type: iRPStepDoneActionTypes.RP_STEP_DONE_FINISH }));
    yield call(getData);
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  yield put(RPStepDoneMerge({ isLoading: false }));
}

export default [
  takeLatest(iRPStepDoneActionTypes.RP_STEP_DONE_INIT, init),
  takeLatest(iRPStepDoneActionTypes.RP_STEP_DONE_FINISH, finish),
  takeLatest(iRPStepDoneActionTypes.RP_STEP_SUBMIT_FORM, submitPreviewDoneForm),
];
