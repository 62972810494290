import { memo } from 'react';
import { Typography } from '@mui/material';
import { tFormWrapper } from './@type';
import { FormHeaderControls } from './form-header-controls';
import { FormFooterControls } from './form-footer-controls';
import style from './index.module.scss';
import clsx from 'clsx';

export const FormWrapper = memo(
  ({ title = ' ', children, subHeader, classes, ...props }: tFormWrapper) => {
    return (
      <div className={clsx(style.formWrapper, classes?.formWrapper)}>
        <div className={clsx(style.header, classes?.header)}>
          <Typography variant="h2" className={clsx(style.title, classes?.title)} color={'inherit'}>
            {title}
          </Typography>
          <FormHeaderControls {...props} />
        </div>
        <div className={clsx(style.subHeader, classes?.subHeader)}>{subHeader}</div>
        <div className={clsx(style.content, classes?.content)}>{children}</div>
        <FormFooterControls {...props} />
      </div>
    );
  },
);

export * from './@type';
export * from './form-header-controls';
export * from './form-footer-controls';
export * from './form-tabs';

export default FormWrapper;
