import { iGetMagnaReportForRatingProcess } from 'services/magna-report-helper';

export interface iRPMagnaReport {
  isInit: boolean;
  isLoading: boolean;
  isOpen: boolean;
  series: iGetMagnaReportForRatingProcess[];
}

export enum iRPMagnaReportActionTypes {
  RP_MAGNA_REPORT_MERGE = 'RP_MAGNA_REPORT_MERGE',
  // saga
  RP_MAGNA_REPORT_INIT = 'RP_MAGNA_REPORT_INIT',
  RP_MAGNA_REPORT_SAVE = 'RP_MAGNA_REPORT_SAVE',
}

export interface iRPMagnaReportInitAction {
  type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_INIT;
}

export interface iRPMagnaReportMergeAction {
  type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_MERGE;
  payload: Partial<iRPMagnaReport>;
}

export interface iRPMagnaReportSaveAction {
  type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_SAVE;
  payload: {
    downloadData?: { leadAnalystID: string; secondaryAnalystID: string };
    handleClose?: () => void;
    data: Partial<iRPMagnaReport['series']>[];
  };
}
