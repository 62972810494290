import {
  iRPStepType3ActionTypes,
  iRPStepType3MSInitAction,
  iRPStepType3MSMergeAction,
  iRPStepType3MSMergeItemAction,
  iRPStepType3MSMergeItemDataAction,
  iRPStepType3MSSaveItemAction,
} from '../../@type';

export const iRPStepType3MSMerge = (
  payload: iRPStepType3MSMergeAction['payload'],
): iRPStepType3MSMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE, payload };
};
export const iRPStepType3MSMergeItem = (
  payload: iRPStepType3MSMergeItemAction['payload'],
): iRPStepType3MSMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE_ITEM, payload };
};
export const iRPStepType3MSMergeItemData = (
  payload: iRPStepType3MSMergeItemDataAction['payload'],
): iRPStepType3MSMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE_ITEM_DATA, payload };
};
// saga
export const iRPStepType3MSInit = (
  payload: iRPStepType3MSInitAction['payload'],
): iRPStepType3MSInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_INIT, payload };
};

export const iRPStepType3MSSaveItem = (
  payload: iRPStepType3MSSaveItemAction['payload'],
): iRPStepType3MSSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_SAVE_ITEM, payload };
};
