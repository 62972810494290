const API = 'MagnaReportHelper';

export const API_MAGNA_REPORT_HELPER = {
  API,
  ExportMagnaReportForRatingProcess: ({ ratingProcessID }: { ratingProcessID: number }) =>
    `${API}/ExportMagnaReportForRatingProcess/${ratingProcessID}`,
  GetExportMagnaReportForRatingProcessFileName: ({
    ratingProcessID,
  }: {
    ratingProcessID: number;
  }) => `${API}/GetExportMagnaReportForRatingProcessFileName/${ratingProcessID}`,
  ExportMagnaReportForReport: ({
    fromDate,
    toDate,
  }: {
    fromDate: string | Date;
    toDate: string | Date;
  }) => `${API}/ExportMagnaReportForReport/${fromDate}/${toDate}`,
  GetExportMagnaReportForReportFileName: ({
    fromDate,
    toDate,
  }: {
    fromDate: string | Date;
    toDate: string | Date;
  }) => `${API}/GetExportMagnaReportForReportFileName/${fromDate}/${toDate}`,
  AttachToRatingProcess: ({
    seriesDetailID,
    ratingProcessID,
  }: {
    seriesDetailID: number;
    ratingProcessID: number;
  }) => `${API}/AttachToRatingProcess/${seriesDetailID}/${ratingProcessID}`,
  GetMagnaReportForRatingProcess: ({ ratingProcessID }: { ratingProcessID: number }) =>
    `${API}/GetMagnaReportForRatingProcess/${ratingProcessID}`,
  ExportMagnaReportForRatingProcessFull: `${API}/ExportMagnaReportForRatingProcessFull`,
};

export class ModelExportMagnaReportForRatingProcessFull {
  leadAnalystID: string = '';
  secondaryAnalystID: string = '';
}

export interface iExportMagnaReportForRatingProcessFull
  extends ModelExportMagnaReportForRatingProcessFull {}

export interface iGetMagnaReportForRatingProcess {
  seriesID: number;
  seriesDetailID: number;
  seriesTitle: string | null;
  symbol: string | null;
  ratingFirstPublicationDate: string | Date | null;
  ratingLastUpdateDate: string | Date | null;
  reportInMagna: boolean;
  companyForMagnaReportID: number | null;
  companyForMagnaReportName: string;
  nullable: true;
  ratingInitiativeID: number | null;
  ratingInitiativeDetails: string | null;
  ratingSubjectID: number | null;
  ratingSubjectDetails: string | null;
  ratingAndTrackingMessageID: number | null;
  ratingAndTrackingMessageDetails: string | null;
  ratingStoppedReasonID: number | null;
  ratingStoppedReasonDetails: string | null;
  ratingID: number | null;
  ratingTitle: string | null;
  outlookID: number | null;
  outlookTitle: string | null;
  ratingSymbolID: number | null;
  ratingSymbolTitle: string | null;
}
