import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { pick } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  API_MAGNA_REPORT_HELPER,
  iExportMagnaReportForRatingProcessFull,
  iGetMagnaReportForRatingProcess,
} from 'services/magna-report-helper';
import { API_SERIES, tSeries } from 'services/series';
import { API_SERIES_DETAIL, tSeriesDetail } from 'services/series-detail';
import { iRPMagnaReportActionTypes, iRPMagnaReportSaveAction, iRPState } from '../@type';
import { RPMagnaReportMerge } from '../helpers';
import { handleError } from './main';

function* getData() {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.id) {
    try {
      const { data }: { data: iGetMagnaReportForRatingProcess[] } = yield call(
        apiStatic.get,
        API_MAGNA_REPORT_HELPER.GetMagnaReportForRatingProcess({ ratingProcessID: main.id }),
      );
      yield put(
        RPMagnaReportMerge({
          isInit: true,
          isLoading: false,
          series: data.map((item) =>
            parseResponseDate(item, ['ratingFirstPublicationDate', 'ratingLastUpdateDate']),
          ),
        }),
      );
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* init() {
  const {
    magnaReport: { isInit, isLoading },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit && !isLoading) {
    yield put(RPMagnaReportMerge({ isLoading: true }));
    yield call(getData);
    yield put(RPMagnaReportMerge({ isLoading: false }));
  }
}

function* saveSeries(Data: Partial<tSeries>): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  if (mixins.length) {
    data = yield call(saveMixins, data, mixins);
  }
  yield call(apiStatic.patch, API_SERIES.PATCH(data), prepareRequest(data));
  return 'ok';
}

function* saveSeriesDetails(Data: Partial<tSeriesDetail>): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  if (mixins.length) {
    data = yield call(saveMixins, data, mixins);
  }
  yield call(apiStatic.patch, API_SERIES_DETAIL.PATCH(data), prepareRequest(data));
  return 'ok';
}

function* saveData(data: any): any {
  yield all([
    call(saveSeries, {
      id: data.seriesID,
      ...pick(data, [
        'reportInMagna',
        'companyForMagnaReportID',
        'ratingFirstPublicationDate',
        'ratingLastUpdateDate',
        'ratingSubjectID',
        'ratingSubjectDetails',
        'ratingInitiativeID',
        'ratingInitiativeDetails',
        'symbol',
      ]),
    }),
    call(saveSeriesDetails, {
      id: data.seriesDetailID,
      ...pick(data, [
        'ratingAndTrackingMessageID',
        'ratingAndTrackingMessageDetails',
        'ratingStoppedReasonID',
        'ratingStoppedReasonDetails',
      ]),
    }),
  ]);
}

function* downloadTXT(data: iExportMagnaReportForRatingProcessFull, handleClose?: () => void) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.id) {
    try {
      const [{ data: blob }, { data: name }] = yield all([
        call(
          apiStatic.post,
          API_MAGNA_REPORT_HELPER.ExportMagnaReportForRatingProcessFull,
          {
            ratingProcessID: main.id,
            leadAnalystID: data.leadAnalystID,
            secondaryAnalystID: data.secondaryAnalystID,
          },
          {
            responseType: 'blob',
          },
        ),
        call(
          apiStatic.get,
          API_MAGNA_REPORT_HELPER.GetExportMagnaReportForRatingProcessFileName({
            ratingProcessID: main.id,
          }),
        ),
      ]);
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: 'text/plain;charset=UTF-8',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name); //or any other extension
      document.body.appendChild(link);
      link.click();
      // remove
      setTimeout(() => {
        if (link.parentNode) link.parentNode.removeChild(link);
      }, 100);
      handleClose && handleClose();
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* save({ payload: { downloadData, handleClose, data } }: iRPMagnaReportSaveAction) {
  yield put(RPMagnaReportMerge({ isLoading: true }));
  try {
    yield all(data.map((item) => call(saveData, item)));
    if (downloadData) yield call(() => downloadTXT(downloadData, handleClose));
    yield put(notifyRequestResult(requestSuccess('')));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(RPMagnaReportMerge({ isLoading: false /*, isOpen: false*/ }));
  yield call(getData);
}

export default [
  takeLatest(iRPMagnaReportActionTypes.RP_MAGNA_REPORT_INIT, init),
  takeLatest(iRPMagnaReportActionTypes.RP_MAGNA_REPORT_SAVE, save),
];
