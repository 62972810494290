import { tRatingProcessStepPublication } from 'services/rating-process-step-publication';
import { ReportCheckupType } from 'services/report-checkup-type';

const API = 'RatingProcessStepPublicationCheckup';

export const API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepPublicationCheckup, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepPublicationCheckup, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepPublicationCheckup, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepPublicationCheckup = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;
  title: string | null;
  titleEng: string | null;
  ratingProcessStepPublicationID: number;
  ratingProcessStepPublication?: tRatingProcessStepPublication;
  reportCheckupTypeID: number | null;
  reportCheckupType?: ReportCheckupType | null;
  headOfFieldApproval: boolean;
  analystApproval: boolean;
  remarks: string | null;
};
