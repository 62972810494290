export enum iRPStepType3ActionTypes {
  RP_STEP_TYPE_3_MERGE = 'RP_STEP_TYPE_3_MERGE',
  RP_STEP_TYPE_3_MERGE_LIST = 'RP_STEP_TYPE_3_MERGE_LIST',
  RP_STEP_TYPE_3_MERGE_LIST_DATA = 'RP_STEP_TYPE_3_MERGE_LIST_DATA',
  RP_STEP_TYPE_3_ADD_LIST_ITEM = 'RP_STEP_TYPE_3_ADD_LIST_ITEM',
  RP_STEP_TYPE_3_DELETE_LIST_ITEM = 'RP_STEP_TYPE_3_DELETE_LIST_ITEM',
  // saga
  RP_STEP_TYPE_3_INIT = 'RP_STEP_TYPE_3_INIT',
  RP_STEP_TYPE_3_SAVE = 'RP_STEP_TYPE_3_SAVE',
  RP_STEP_TYPE_3_ADD_NEW = 'RP_STEP_TYPE_3_ADD_NEW',
  RP_STEP_TYPE_3_DELETE = 'RP_STEP_TYPE_3_DELETE',
  RP_STEP_TYPE_3_APPROVE = 'RP_STEP_TYPE_3_APPROVE',
  RP_STEP_TYPE_3_REJECT = 'RP_STEP_TYPE_3_REJECT',
  // validate
  RP_STEP_TYPE_3_INIT_COMMITTEE = 'RP_STEP_TYPE_3_INIT_COMMITTEE',
  RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS_VOTES = 'RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS_VOTES',
  RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS = 'RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS',

  // SignificantChange
  RP_STEP_TYPE_3_SC_MERGE = 'RP_STEP_TYPE_3_SC_MERGE',
  RP_STEP_TYPE_3_SC_MERGE_ITEM = 'RP_STEP_TYPE_3_SC_MERGE_ITEM',
  RP_STEP_TYPE_3_SC_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_SC_MERGE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_SC_INIT = 'RP_STEP_TYPE_3_SC_INIT',
  RP_STEP_TYPE_3_SC_SAVE_ITEM = 'RP_STEP_TYPE_3_SC_SAVE_ITEM',

  // Materials
  RP_STEP_TYPE_3_MS_MERGE = 'RP_STEP_TYPE_3_MS_MERGE',
  RP_STEP_TYPE_3_MS_MERGE_ITEM = 'RP_STEP_TYPE_3_MS_MERGE_ITEM',
  RP_STEP_TYPE_3_MS_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_MS_MERGE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_MS_INIT = 'RP_STEP_TYPE_3_MS_INIT',
  RP_STEP_TYPE_3_MS_SAVE_ITEM = 'RP_STEP_TYPE_3_MS_SAVE_ITEM',

  // Resources
  RP_STEP_TYPE_3_RS_MERGE = 'RP_STEP_TYPE_3_RS_MERGE',
  RP_STEP_TYPE_3_RS_MERGE_ITEM = 'RP_STEP_TYPE_3_RS_MERGE_ITEM',
  RP_STEP_TYPE_3_RS_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_RS_MERGE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_RS_INIT = 'RP_STEP_TYPE_3_RS_INIT',
  RP_STEP_TYPE_3_RS_SAVE_ITEM = 'RP_STEP_TYPE_3_RS_SAVE_ITEM',

  // Members
  RP_STEP_TYPE_3_USER_MERGE = 'RP_STEP_TYPE_3_USER_MERGE',
  RP_STEP_TYPE_3_USER_MERGE_ITEM = 'RP_STEP_TYPE_3_USER_MERGE_ITEM',
  RP_STEP_TYPE_3_USER_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_USER_MERGE_ITEM_DATA',
  RP_STEP_TYPE_3_USER_DELETE_ITEM_DATA = 'RP_STEP_TYPE_3_USER_DELETE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_USER_INIT = 'RP_STEP_TYPE_3_USER_INIT',
  RP_STEP_TYPE_3_USER_REFRESH = 'RP_STEP_TYPE_3_USER_REFRESH',
  RP_STEP_TYPE_3_USER_SAVE_ITEM = 'RP_STEP_TYPE_3_USER_SAVE_ITEM',
  RP_STEP_TYPE_3_USER_DELETE_ITEM = 'RP_STEP_TYPE_3_USER_DELETE_ITEM',

  // Members votes
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM_DATA',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM_DATA = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_INIT = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_INIT',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_REFRESH = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_REFRESH',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_SAVE_ITEM = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_SAVE_ITEM',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM',
  RP_STEP_TYPE_3_USER_RAT_ENT_VOT_IMPORT = 'RP_STEP_TYPE_3_USER_RAT_ENT_VOT_IMPORT',

  // series rating
  RP_STEP_TYPE_3_SERIES_RATING_MERGE = 'RP_STEP_TYPE_3_SERIES_RATING_MERGE',
  RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM = 'RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM',
  RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM_DATA = 'RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM_DATA',
  RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM_DATA = 'RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM_DATA',
  // saga
  RP_STEP_TYPE_3_SERIES_RATING_INIT = 'RP_STEP_TYPE_3_SERIES_RATING_INIT',
  RP_STEP_TYPE_3_SERIES_RATING_REFRESH = 'RP_STEP_TYPE_3_SERIES_RATING_REFRESH',
  RP_STEP_TYPE_3_SERIES_RATING_SAVE_ITEM = 'RP_STEP_TYPE_3_SERIES_RATING_SAVE_ITEM',
  RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM = 'RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM',
  RP_STEP_TYPE_3_SERIES_RATING_QUICK_SET = 'RP_STEP_TYPE_3_SERIES_RATING_QUICK_SET',
  RP_STEP_TYPE_3_SERIES_RATING_NEW_ITEM = 'RP_STEP_TYPE_3_SERIES_RATING_NEW_ITEM',
  RP_STEP_TYPE_3_SERIES_RATING_ADD_EXIST_ITEM = 'RP_STEP_TYPE_3_SERIES_RATING_ADD_EXIST_ITEM',

  // user series voting
  RP_STEP_TYPE_3_USER_SERIES_VOTING_MERGE = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_MERGE',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_MERGE = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_MERGE',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_MERGE = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_MERGE',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_DATA_MERGE = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_DATA_MERGE',
  // saga
  RP_STEP_TYPE_3_USER_SERIES_VOTING_INIT = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_INIT',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_REFRESH = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_REFRESH',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_SAVE_ITEM = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_SAVE_ITEM',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_QUICK_SET = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_QUICK_SET',
  RP_STEP_TYPE_3_USER_SERIES_VOTING_IMPORT_ITEMS = 'RP_STEP_TYPE_3_USER_SERIES_VOTING_IMPORT_ITEMS',
}

export * from './main';
export * from './significant-change';
export * from './materials';
export * from './resources';
export * from './user';
export * from './user-rates-entity-voting';
export * from './series-rating';
export * from './user-series-voting';
