import { useI18n } from 'AurionCR/components';
import { DialogHeading } from 'components/dialog-title';
import { memo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, LinearProgress } from '@mui/material';

export const StepDetailsDialog = memo(
  ({
    title,
    loading,
    onCloseDialog,
    children,
  }: {
    title: string;
    loading: boolean;
    onCloseDialog: () => void;
    children: any;
  }) => {
    const { t } = useI18n();
    //render
    return (
      <Dialog open={true} onClose={onCloseDialog} fullWidth maxWidth="sm">
        <DialogHeading onClose={onCloseDialog} title={title} />
        {loading && <LinearProgress />}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <div className="form-edit-controls">
            <div className="left-side" />
            <div className="right-side">
              <Button
                className="btn-cancel"
                onClick={onCloseDialog}
                color="secondary"
                size={'small'}
                disabled={loading}
              >
                {t('close')}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  },
);

export default StepDetailsDialog;
