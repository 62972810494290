import React, { memo, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getQuarter, format, addYears, subMilliseconds, addMonths, Month, Quarter } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Button, Popper, Tab, Tabs, ClickAwayListener, InputAdornment } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useI18n } from 'AurionCR/components';
import Select from 'AurionCR/components/form/select';
import { Input } from 'AurionCR/components/form/input';
import { DateRangePicker } from 'AurionCR/components/form/date/date-range-piker';
import style from './index.module.scss';
import clsx from 'clsx';

const getDatesByYear = (year: number) => {
  const from_ = new Date(year, 0, 1);
  return [from_, subMilliseconds(addYears(from_, 1), 1)];
};
const getDatesByQuarter = (year: number, quarter: number) => {
  const from_ = new Date(year, quarter * 3 - 3, 1);
  return [from_, subMilliseconds(addMonths(from_, 3), 1)];
};
const getDatesByMonth = (year: number, month: number) => {
  const from_ = new Date(year, month - 1, 1);
  return [from_, subMilliseconds(addMonths(from_, 1), 1)];
};

export const DataMultipleContent = memo(
  ({
    value,
    onChange,
    disableClearable,
  }: {
    value: any[];
    onChange: any;
    disableClearable: boolean;
  }) => {
    const { t } = useI18n();
    const [tab, setTab] = React.useState(0);
    // sources
    const [year, setYear] = useState(new Date().getFullYear());
    const [quarter, setQuarter] = useState(1);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [dates, setDates] = useState<any[]>([]);
    const DSYear = useMemo(
      () => [...Array(10)].map((_: { id: number }, i) => ({ id: year - i })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    const DSQuarter = useMemo(
      () =>
        [...Array(4)].map((_: { title: string; id: number }, i) => ({
          title: enUS.localize?.quarter((i + 1) as Quarter, { width: 'abbreviated' }),
          id: i + 1,
        })),
      [],
    );
    const DSMonth = useMemo(
      () =>
        [...Array(12)].map((_: { title: string; id: number }, i) => ({
          title: enUS.localize?.month(i as Month, { width: 'abbreviated' }),
          id: i + 1,
        })),
      [],
    );

    const onChangeTab = useCallback(
      (_: SyntheticEvent, value: any) => {
        setTab(value);
        window.dispatchEvent(new Event('resize'));
      },
      [setTab],
    );

    const onChangeProvider = useCallback(
      (dates: Date[]) => {
        onChange(dates);
      },
      [onChange],
    );
    const onApply = useCallback(() => {
      switch (tab) {
        case 0:
          if (dates.length < 2) {
            onChange([]);
          } else {
            onChangeProvider([...dates]);
          }
          break;
        case 1:
          onChangeProvider(getDatesByYear(year));
          break;
        case 2:
          onChangeProvider(getDatesByQuarter(year, quarter));
          break;
        case 3:
          onChangeProvider(getDatesByMonth(year, month));
          break;
      }
    }, [tab, year, quarter, month, dates, onChangeProvider]);

    const onChangeYear = useCallback(
      (e: any) => {
        setYear(e.target.value);
        setDates(getDatesByYear(e.target.value));
      },
      [setYear, setDates],
    );
    const onChangeQuarter = useCallback(
      (e: any) => {
        const value_ = e.target.value;
        setQuarter(value_);
        setMonth(value_ * 3 - 2);
        setDates(getDatesByQuarter(year, value_));
      },
      [year, setQuarter, setMonth, setDates],
    );
    const onChangeMonth = useCallback(
      (e: any) => {
        const value_ = e.target.value;
        setMonth(value_);
        setQuarter(getQuarter(new Date(year, value_ - 1, 1)));
        setDates(getDatesByMonth(year, value_));
      },
      [year, setMonth, setQuarter, setDates],
    );
    const onChangeDates = useCallback(
      (value: Date[]) => {
        setDates([...value]);
        setYear(value[0].getFullYear());
        setQuarter(getQuarter(value[0]));
        setMonth(value[0].getMonth() + 1);
      },
      [setYear, setQuarter, setMonth, setDates],
    );

    const parseValue = useCallback(
      (value: Date[] | undefined[]) => {
        // const from = convertDateUtcToLocal(value[0]) || new Date();
        // const to = convertDateUtcToLocal(value[1]);
        const from = value[0] || new Date();
        const to = value[1];

        setYear(from.getFullYear());
        setQuarter(getQuarter(from));
        setMonth(from.getMonth() + 1);
        setDates(from && to ? [from, to] : []);
      },
      [setYear, setQuarter, setMonth, setDates],
    );

    // init
    useEffect(() => {
      parseValue(value);
    }, [value, parseValue]);

    return (
      <div className={clsx(style.root)}>
        <Tabs
          value={tab}
          indicatorColor={'primary'}
          textColor={'primary'}
          onChange={onChangeTab}
          className={style.tabs}
        >
          <Tab label={t('from-to')} className={style.tab} />
          <Tab label={t('yearly')} className={style.tab} />
          <Tab label={t('quarterly')} className={style.tab} />
          <Tab label={t('monthly')} className={style.tab} />
        </Tabs>
        <div>
          {tab === 0 && <DateRangePicker value={dates} onChange={onChangeDates} />}
          {tab !== 0 && (
            <div className={style.content}>
              <Select
                settings={{ label: 'year', source: DSYear, option: { label: 'id', value: 'id' } }}
                onChange={onChangeYear}
                value={year}
                disableClearable
                autoFocus
              />
              {tab === 2 && (
                <Select
                  settings={{ label: 'quarter', source: DSQuarter }}
                  onChange={onChangeQuarter}
                  value={quarter}
                  disableClearable
                />
              )}
              {tab === 3 && (
                <Select
                  settings={{ label: 'month', source: DSMonth }}
                  onChange={onChangeMonth}
                  value={month}
                  disableClearable
                />
              )}
            </div>
          )}
        </div>
        <div className={style.controls}>
          {!disableClearable && (
            <Button onClick={() => onChange([])} color="primary" size="small">
              {t('clear')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onApply}
            className={style.apply}
          >
            {t('apply')}
          </Button>
        </div>
      </div>
    );
  },
);

export const DateMultiple = memo(
  ({ value, onChange, onCustomClickAway, disableClearable, ...rest }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    const [open, setOpen] = useState(false);

    const onOpen = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        setAnchorEl(event.currentTarget);
        setOpen(true);
      },
      [setAnchorEl, setOpen],
    );
    const onClear = useCallback(() => {
      !disableClearable && onChange([]);
    }, [onChange, disableClearable]);
    const onClose = useCallback(() => {
      setOpen(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAnchorEl, setOpen]);

    const onClickAway = useCallback(() => {
      onClose();
      onCustomClickAway && onCustomClickAway();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    const title = useMemo(() => {
      onClose();
      if (value[0] && value[1]) {
        // const from: any = convertDateUtcToLocal(value[0]);
        // const to: any = convertDateUtcToLocal(value[1]);
        // return `${format(from, 'yyyy.MM.dd')} - ${format(to, 'yyyy.MM.dd')}`;
        return `${format(value[0], 'yyyy.MM.dd')} - ${format(value[1], 'yyyy.MM.dd')}`;
      } else {
        return '';
      }
    }, [value, onClose]);

    return (
      <>
        <Input
          {...rest}
          className={'data-range_input'}
          value={title}
          onClick={onOpen}
          onClear={onClear}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
        />
        <Popper
          open={open}
          anchorEl={anchorEl}
          className="date-multiple_tooltip"
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <div>
              <DataMultipleContent
                value={value}
                onChange={onChange}
                disableClearable={disableClearable}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      </>
    );
  },
);
