import { apiStatic, getRandomString, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { API_RATING_PROCESS_STEP } from 'services/rating-process-step';
import {
  API_RATING_PROCESS_STEP_REPORT,
  tRatingProcessStepReport,
} from 'services/rating-process-step-report';
import { API_REPORT } from 'services/report';
import {
  iRPState,
  iRPStepType4ActionTypes,
  iRPStepType4AdditionCommitteeAction,
  iRPStepType4AddNewAction,
  iRPStepType4ApproveAction,
  iRPStepType4Data,
  iRPStepType4DeleteAction,
  iRPStepType4RejectAction,
  iRPStepType4SaveAction,
  iRPStepType4SaveReportAction,
} from '../@type';
import {
  iRPStepType4DeleteListItem,
  iRPStepType4Merge,
  iRPStepType4MergeList,
  iRPStepType4MergeListData,
  RPMainUpdateStep,
  RPMerge,
} from '../helpers';
import { calcCurrentStep, handleError, stepApprove, stepReject } from '../saga';
import { refresh as refreshCommittee } from '../saga/step-type-3/main';
import { onUpdateHtmlField } from 'utils/html-upload-helpers';

export const HTML_FIELDS_STEP_4 = ['remarks'];

function* getData(stepID?: number) {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  try {
    const {
      data: { value },
    }: { data: { value: tRatingProcessStepReport[] } } = yield call(
      apiStatic.get,
      API_RATING_PROCESS_STEP_REPORT.GET_ALL_DYNAMIC,
      {
        params: {
          select: [
            'id',
            'reportID',
            '(report==null)?null:new{report.id,report.filePath,report.remarks} as report',
            'draftRequestDate',
            'issuerContactName',
            'isIssuerContactSeniorOfficer',
            'draftResposeDate',
            'isClientNotResponse',
            'remarks',
            'teamLeaderSignatureDate',
            'teamLeaderUserID',
          ].join(),
          filter: `ratingProcessStepID==${stepID || main?.ratingProcessSteps[currentStep]?.id}`,
        },
      },
    );
    yield put(
      iRPStepType4Merge({
        loading: false,
        init: true,
        list: value.map((item, index) => ({
          id: item.id,
          isOpen: index === value.length - 1,
          isEdit: index === value.length - 1,
          data: {
            ...parseResponseDate(item, [
              'teamLeaderSignatureDate',
              'draftRequestDate',
              'draftResposeDate',
            ]),
            issuerContactName: item.issuerContactName || '',
          },
        })),
      }),
    );
  } catch (e) {
    yield put(iRPStepType4Merge({ loading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* init() {
  const {
    stepType4: { init, loading },
  }: iRPState = yield select((state) => state.RP);
  if (!init && !loading) {
    yield put(iRPStepType4Merge({ loading: true }));
    yield call(getData);
    yield put(iRPStepType4Merge({ loading: false }));
  }
}

function* saveData({
  clientUpdateDate,
  ...Data
}: Partial<iRPStepType4Data['data']> & { clientUpdateDate?: string | Date }): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  data = yield call(saveMixins, data, mixins);
  const htmlFieldsData = yield onUpdateHtmlField({
    formData: Data,
    field: HTML_FIELDS_STEP_4,
  });

  const updatedData = data;
  HTML_FIELDS_STEP_4.forEach((item) => {
    updatedData[item] = htmlFieldsData[item];
  });

  yield call(
    apiStatic.patch,
    API_RATING_PROCESS_STEP_REPORT.PATCH(data),
    prepareRequest(updatedData),
  );
  yield call(saveStepData, { clientUpdateDate });
  return updatedData;
}

function* saveStepData(data: { clientUpdateDate?: string | Date }) {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  yield call(
    apiStatic.patch,
    API_RATING_PROCESS_STEP.PATCH({ id: Number(main?.ratingProcessSteps[currentStep].id) }),
    prepareRequest(data),
  );
  RPMainUpdateStep({ stepIndex: currentStep, ...data });
}

function* save({ payload: { reportIndex, triggerClose, ...Data } }: iRPStepType4SaveAction) {
  yield put(RPMerge({ loading: true }));
  try {
    // @ts-ignore
    const data = yield call(saveData, Data);
    yield put(notifyRequestResult(requestSuccess('')));
    if (!triggerClose) {
      yield put(iRPStepType4MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    } else {
      yield put(RPMerge({ triggerClose }));
    }
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* addNew({ payload }: iRPStepType4AddNewAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.id) {
    yield put(RPMerge({ loading: true }));
    yield call(saveData, payload);
    yield call(apiStatic.get, API_RATING_PROCESS_HELPER.ADD_NEW_DRAFT_REPORT(main.id, 5));
    yield call(getData);
    yield put(RPMerge({ loading: false }));
  }
}

function* deleteItem({ payload: { reportIndex } }: iRPStepType4DeleteAction) {
  const {
    stepType4: { list },
  }: iRPState = yield select((state) => state.RP);
  try {
    yield put(RPMerge({ loading: true }));
    yield call(
      apiStatic.delete,
      API_RATING_PROCESS_STEP_REPORT.DELETE({ id: list[reportIndex].id }),
    );
    yield put(iRPStepType4DeleteListItem({ reportIndex }));
    yield put(iRPStepType4MergeList({ reportIndex: list.length - 2, isOpen: true, isEdit: true }));
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* reject({ payload }: iRPStepType4RejectAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveData, payload);
    yield put(iRPStepType4MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(stepReject);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* approve({ payload }: iRPStepType4ApproveAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveData, payload);
    yield put(iRPStepType4MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(getData);
    yield call(stepApprove);
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* saveReport({ payload }: iRPStepType4SaveReportAction) {
  const {
    stepType4: { isEditReportIndex },
  }: iRPState = yield select((state) => state.RP);
  if (isEditReportIndex !== null) {
    const {
      main,
      stepType4: {
        list: {
          [isEditReportIndex]: {
            data: { id, reportID },
          },
        },
      },
    }: iRPState = yield select((state) => state.RP);
    yield put(RPMerge({ loading: true }));
    try {
      let { data, mixins } = parseMixins({
        ...payload,
        ratedEntityID: main?.ratedEntityID,
      });
      let updatedData = data;
      // @ts-ignore
      const htmlFieldsData = yield onUpdateHtmlField({
        formData: {
          ...payload,
          ratedEntityID: main?.ratedEntityID,
        },
        field: HTML_FIELDS_STEP_4,
      });

      HTML_FIELDS_STEP_4.forEach((item) => {
        updatedData[item] = htmlFieldsData[item];
      });
      // - create report
      if (!reportID) {
        const {
          data: { id: responseID },
        } = yield call(
          apiStatic.post,
          API_REPORT.POST,
          prepareRequest({ ...updatedData, title: `report-from-rating-process #${main?.id}` }),
        );
        updatedData.id = responseID;
      } else {
        updatedData.id = reportID;
      }
      // - patch report
      // @ts-ignore
      updatedData = yield call(saveMixins, updatedData, mixins);
      yield call(apiStatic.patch, API_REPORT.PATCH(updatedData), prepareRequest(updatedData));
      yield put(
        iRPStepType4MergeListData({
          reportIndex: isEditReportIndex,
          reportID: updatedData?.id,
          report: updatedData,
        }),
      );
      // - patch RatingProcessStepReport
      if (!reportID) {
        yield call(
          apiStatic.patch,
          API_RATING_PROCESS_STEP_REPORT.PATCH({ id }),
          prepareRequest({ reportID: updatedData.id }),
        );
      }
      yield put(iRPStepType4Merge({ isEditReportIndex: null }));
    } catch (e) {
      yield call(handleError, e);
    }
    yield put(RPMerge({ loading: false }));
  }
}

function* additionCommittee({ payload }: iRPStepType4AdditionCommitteeAction) {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  if (main) {
    const committeeStepIndex = main.ratingProcessSteps.findIndex(
      ({ ratingProcessStepTypeID }) => ratingProcessStepTypeID === 3,
    );
    if (committeeStepIndex !== -1) {
      try {
        yield put(RPMerge({ loading: true }));
        yield call(saveData, payload);
        // create committee
        yield call(apiStatic.get, API_RATING_PROCESS_HELPER.ADD_NEW_COMMITTEE(Number(main?.id)));
        // create report
        yield call(apiStatic.get, API_RATING_PROCESS_HELPER.ADD_NEW_DRAFT_REPORT(main.id, 4));
        // patch committee step
        yield call(
          apiStatic.patch,
          API_RATING_PROCESS_STEP.PATCH({ id: main.ratingProcessSteps[committeeStepIndex].id }),
          {
            isCompleted: false,
          },
        );
        // get committee
        yield call(refreshCommittee, main.ratingProcessSteps[committeeStepIndex].id);
        // set is steps compete
        yield put(
          RPMainUpdateStep({
            stepIndex: currentStep,
            isCompleted: false,
            isStepEdit: false,
          }),
        );
        yield put(
          RPMainUpdateStep({
            stepIndex: committeeStepIndex,
            isCompleted: false,
            isStepEdit: true,
          }),
        );
        yield call(calcCurrentStep);
        // get reports
        yield call(getData, main.ratingProcessSteps[currentStep].id);
      } catch (e) {
        yield call(handleError, e);
      }
      yield put(RPMerge({ loading: false }));
    }
  }
}

export default [
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_INIT, init),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE, save),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_NEW, addNew),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE, deleteItem),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_REJECT, reject),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_APPROVE, approve),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE_REPORT, saveReport),
  takeLatest(iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADDITION_COMMITTEE, additionCommittee),
];
