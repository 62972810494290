import update from 'immutability-helper';
import {
  DashboardAction,
  DashboardActionTypes,
  DashboardFilterSetPeriodAction,
  DashboardFiltersMergeAction,
  DashboardGetDepartmentsAction,
  DashboardSourcesMergeAction,
  DashboardState,
} from './@type';
import { format, startOfMonth, startOfYear, subYears } from 'date-fns';
import { TIME_FORMAT_DATE } from 'configs/const';
import { DASHBOARD_FILTERS_TAB } from '../filters';

export const DASHBOARD_PERIOD_FILTER = {
  [DASHBOARD_FILTERS_TAB.BEGIN_YEAR]: {
    fromDate: format(startOfYear(new Date()), TIME_FORMAT_DATE),
    toDate: format(new Date(), TIME_FORMAT_DATE),
  },
  [DASHBOARD_FILTERS_TAB.ALL_MONTHS]: {
    fromDate: format(subYears(new Date(), 1), TIME_FORMAT_DATE),
    toDate: format(new Date(), TIME_FORMAT_DATE),
  },
};

export const initialState = (): DashboardState => ({
  filters: {
    period: DASHBOARD_PERIOD_FILTER[0],
    customDate: [
      format(startOfMonth(new Date()), TIME_FORMAT_DATE),
      format(new Date(), TIME_FORMAT_DATE),
    ],
    departmentID: null,
    activeTabIndex: 0,
  },
  sources: {
    departments: [],
  },
});

export const dashboard = (state = initialState(), action: DashboardAction): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.DASHBOARD_FILTERS_MERGE: {
      return update(state, { filters: { $merge: action.payload } });
    }

    case DashboardActionTypes.DASHBOARD_FILTER_SET_PERIOD: {
      return update(state, {
        filters: {
          $merge: {
            period: {
              fromDate: format(new Date(state.filters.customDate[0]), TIME_FORMAT_DATE),
              toDate: format(new Date(state.filters.customDate[1]), TIME_FORMAT_DATE),
            },
            activeTabIndex: 2,
          },
        },
      });
    }

    case DashboardActionTypes.DASHBOARD_SOURCES_MERGE: {
      return update(state, { sources: { $merge: action.payload } });
    }

    default:
      return state;
  }
};

// SAGA helpers
export const DashboardGetDepartments = (): DashboardGetDepartmentsAction => {
  return { type: DashboardActionTypes.DASHBOARD_SET_DEPARTMENTS };
};

// REDUX helpers
export const DashboardFiltersMerge = (
  payload: DashboardFiltersMergeAction['payload'],
): DashboardFiltersMergeAction => {
  return { type: DashboardActionTypes.DASHBOARD_FILTERS_MERGE, payload };
};
export const DashboardSourceMerge = (
  payload: DashboardSourcesMergeAction['payload'],
): DashboardSourcesMergeAction => {
  return { type: DashboardActionTypes.DASHBOARD_SOURCES_MERGE, payload };
};
export const DashboardFilterSetPeriod = (): DashboardFilterSetPeriodAction => {
  return { type: DashboardActionTypes.DASHBOARD_FILTER_SET_PERIOD };
};
