import { iRPState } from 'pages/CreditRatings/RatingProcess/components/editor/store/@type/index';
import { tRatingProcessStepPublication } from 'services/rating-process-step-publication';
import { tRatingProcessStepPublicationCheckup } from 'services/rating-process-step-publication-checkup';

export interface iRPStepType5Checkup {
  id: number;
  isLoading: boolean;
  data: tRatingProcessStepPublicationCheckup;
}

export interface iRPStepType5 {
  init: boolean;
  loading: boolean;
  isSubmit: boolean;
  isEditReport: boolean;
  data: Partial<tRatingProcessStepPublication>;
  checkups: iRPStepType5Checkup[];
}

export enum iRPStepType5ActionTypes {
  RP_STEP_TYPE_5_MERGE = 'RP_STEP_TYPE_5_MERGE',
  RP_STEP_TYPE_5_MERGE_DATA = 'RP_STEP_TYPE_5_MERGE_DATA',
  RP_STEP_TYPE_5_MERGE_CHECKUP = 'RP_STEP_TYPE_5_MERGE_CHECKUP',
  RP_STEP_TYPE_5_MERGE_CHECKUP_DATA = 'RP_STEP_TYPE_5_MERGE_CHECKUP_DATA',
  // saga
  RP_STEP_TYPE_5_INIT = 'RP_STEP_TYPE_5_INIT',
  RP_STEP_TYPE_5_SAVE = 'RP_STEP_TYPE_5_SAVE',
  RP_STEP_TYPE_5_APPROVE = 'RP_STEP_TYPE_5_APPROVE',
  RP_STEP_TYPE_5_REJECT = 'RP_STEP_TYPE_5_REJECT',
  RP_STEP_TYPE_5_SAVE_CHECKUP = 'RP_STEP_TYPE_5_SAVE_CHECKUP',
  RP_STEP_TYPE_5_VALIDATION = 'RP_STEP_TYPE_5_VALIDATION',
  RP_STEP_TYPE_5_SAVE_REPORT = 'RP_STEP_TYPE_5_SAVE_REPORT',
}

export interface iRPStepType5InitAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_INIT;
}

export interface iRPStepType5MergeAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE;
  payload: Partial<iRPStepType5>;
}

export interface iRPStepType5MergeDataAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_DATA;
  payload: Partial<iRPStepType5['data']>;
}

export interface iRPStepType5MergeCheckupAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP;
  payload: Partial<iRPStepType5Checkup> & { index: number };
}

export interface iRPStepType5MergeCheckupDataAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP_DATA;
  payload: Partial<iRPStepType5Checkup['data']> & { index: number };
}

export interface iRPStepType5SaveCheckupAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_CHECKUP;
  payload: Partial<iRPStepType5Checkup['data']> & { index: number };
}

export interface iRPStepType5SaveAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE;
  payload: Partial<iRPStepType5['data']> & { triggerClose?: iRPState['triggerClose'] };
}

export interface iRPStepType5ApproveAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_APPROVE;
  payload: Partial<iRPStepType5['data']>;
}

export interface iRPStepType5RejectAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_REJECT;
  payload: Partial<iRPStepType5['data']>;
}

export interface iRPStepType5ValidationAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_VALIDATION;
  payload?: (value: boolean) => void;
}

export interface iRPStepType5SaveReportAction {
  type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_REPORT;
  payload: Partial<iRPStepType5['data']['report']>;
}
