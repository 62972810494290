import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { apiStatic, api, Loading } from 'AurionCR/components';

interface Config {
  api: string;
}

interface Props {
  children: ReactNode;
}

export const AppConfigProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const getConfig = useCallback(async () => {
    if (!import.meta.env.VITE_API_SERVER) {
      const result = await fetch('./config.json');
      const config: Config = await result.json();
      apiStatic.defaults.baseURL = config.api;
      api.defaults.baseURL = config.api;
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getConfig().catch((e) => console.error(e, 'load config'));
  }, [getConfig]);

  if (isLoading) return <Loading active={true} />;
  return <>{children}</>;
};
