import { apiStatic, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { getLabelsPath, labelsKey } from 'components/language';
import { format, isValid } from 'date-fns';
import { all, call, put, select } from 'redux-saga/effects';
import { iRPState } from '../../@type';
import { PageTitle } from './page-title';
import { stepType1 } from './step-type-1';
import { stepType2 } from './step-type-2';
import { stepType3 } from './step-type-3';
import { stepType4 } from './step-type-4';
import { stepType5 } from './step-type-5';
import { validURL } from "utils/validation";

export const dateHelper = (date: any, dateFormat = 'dd/MM/yyyy') => {
  return date ? (isValid(new Date(date)) ? format(new Date(date), dateFormat) : '') : '';
};

export const linkHelper = (link: any, title: string) => {
  return link ? `[<a href='${link}'>${title}</a>]` : '';
};

export const getHtml = (path: string) => {
  return new Promise((resolve, reject) => {
    if (path && validURL(path)) {
      apiStatic
        .get(`MediaUpload/GetHtmlFromCloud?fileName=${path}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    } else {
      resolve('');
    }
  });
};

export const translate = (labels: any, key?: string) => {
  if (key) {
    return labels[key]?.title || key;
  } else {
    return '';
  }
};
export type tTranslate = (key?: string) => string;

export function* pdfData() {
  const { main }: iRPState = yield select((state) => state.RP);
  // @ts-ignore
  const { sources, language }: iRPState = yield select((state) => state);
  const labels = sources[labelsKey]?.[getLabelsPath(language.languageID)]?.map || {};
  const t = (key?: string) => translate(labels, key);

  // @ts-ignore
  const pageTitle: any = yield call(PageTitle, t);
  let pdfData = [pageTitle];

  if (main?.id) {
    try {
      const pages: any[] = yield all(
        main.ratingProcessSteps
          .filter(({ ratingProcessStepTypeID }) => ratingProcessStepTypeID !== 1000)
          .map((step) => {
            switch (step.ratingProcessStepTypeID) {
              case 1:
                return call(stepType1, t, step);
              case 2:
                return call(stepType2, t, step);
              case 3:
                return call(stepType3, t, step);
              case 4:
                return call(stepType4, t, step);
              case 5:
                return call(stepType5, t, step);
              default:
                return undefined;
            }
          }),
      );
      pdfData = [...pdfData, ...pages];
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }

  return `<html dir='rtl'><head>${psdStyle}</head><body>${pdfData.join('')}</body></html>`;
}

export const psdStyle = `
<style>
  * {
    font: 14px Arial, Helvetica, sans-serif;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 0 15px;
  }
  .full-width {
    width: 100%;
  }
  h1.pageTitle {
    font-size: 20px;
    background: #0028a0;
    color: #fff;
    padding: 5px 10px;
    margin: 0 0 15px;
  }
  h2 {
    color: #0028a0;
    font-size: 22px;
    padding: 0 0 15px;
    margin: 0;
  }
  h3 {
    color: #0028a0;
    font-size: 18px;
    padding: 0 0 15px;
    margin: 0;
  }
  h4 {
    color: #0028a0;
    font-size: 14px;
    padding: 0 0 10px;
    margin: 0;
  }
  p {
    padding: 0 0 10px;
  }
  .tablePreview td,
  .tablePreview th {
    padding: 7px 10px;
  }
  .tablePreview th {
    white-space: nowrap;
  }
  .tablePreview tbody th {
    text-align: right;
    vertical-align: baseline;
  }
  .tableStandard {
    border: 1px solid #ccc;
    border-bottom: none;
    border-left: none;
  }
  .tableStandard th {
    font-weight: 700;
  }
  .tableStandard td, .tableStandard th {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 7px 10px;
  }
  .tableTdCenter td,
  .tableTdCenter th {
    text-align: center;
  }
  .html p,
  .html h1,
  .html h2,
  .html h3,
  .html h4,
  .html h5,
  .html h6 {
    padding: 0 0 10px;
    margin: 0;
  }
  div.html {
    padding: 0 0 15px;
  }
</style>
`;
