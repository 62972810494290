import { memo } from 'react';
import { useI18n, CopyPathToClipboard } from 'AurionCR/components';
import { Button } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { tControls } from './@type';
import style from './index.module.scss';

export const FormHeaderControls = memo(
  ({
    state = 'save',
    disabled = false,
    showBtnCopy = true,
    ButtonCopyName = 'copy',
    ButtonCreateAndContinueName = 'create-and-continue',
    ButtonCancelName = 'cancel',
    onCancel,
    onSubmit,
    onSubmitAndContinue,
  }: Partial<tControls>) => {
    const { t } = useI18n();
    // render
    return (
      <div className={style.headerControls}>
        {state === 'save' && showBtnCopy && (
          <CopyPathToClipboard text={window.location.href}>
            <Button color={'primary'} size={'small'} startIcon={<LinkIcon />} disabled={disabled}>
              {t(ButtonCopyName)}
            </Button>
          </CopyPathToClipboard>
        )}
        {onCancel && (
          <Button
            color={'primary'}
            size={'small'}
            startIcon={<CloseIcon />}
            disabled={disabled}
            onClick={onCancel}
          >
            {t(ButtonCancelName)}
          </Button>
        )}
        {state === 'create' && onSubmitAndContinue && (
          <Button
            color={'primary'}
            size={'small'}
            startIcon={<DoneIcon />}
            disabled={disabled}
            onClick={onSubmit}
          >
            {t(ButtonCreateAndContinueName)}
          </Button>
        )}
        {onSubmit && (
          <Button
            color={'primary'}
            size={'small'}
            startIcon={<DoneIcon />}
            disabled={disabled}
            onClick={onSubmit}
          >
            {t(state)}
          </Button>
        )}
      </div>
    );
  },
);

export default FormHeaderControls;
