import { AnyAction } from 'redux-saga';

export interface PeriodItem {
  fromDate: string;
  toDate: string;
}

export interface DepartmentItem {
  id: number;
  title: string;
}

export enum DashboardActionTypes {
  DASHBOARD_FILTERS_MERGE = 'DASHBOARD_FILTERS_MERGE',
  DASHBOARD_FILTER_SET_PERIOD = 'DASHBOARD_FILTER_SET_PERIOD',
  DASHBOARD_SOURCES_MERGE = 'DASHBOARD_SOURCES_MERGE',
  DASHBOARD_SET_DEPARTMENTS = 'DASHBOARD_SET_DEPARTMENTS',
}

export interface DashboardGetDepartmentsAction extends AnyAction {
  type: DashboardActionTypes.DASHBOARD_SET_DEPARTMENTS;
}

export interface DashboardFiltersMergeAction {
  type: DashboardActionTypes.DASHBOARD_FILTERS_MERGE;
  payload: Partial<DashboardState['filters']>;
}

export interface DashboardFilterSetPeriodAction {
  type: DashboardActionTypes.DASHBOARD_FILTER_SET_PERIOD;
}

export interface DashboardSourcesMergeAction {
  type: DashboardActionTypes.DASHBOARD_SOURCES_MERGE;
  payload: Partial<DashboardState['sources']>;
}

export interface DashboardState {
  filters: {
    period: PeriodItem;
    customDate: string[];
    departmentID: number | null;
    activeTabIndex: number;
  };
  sources: {
    departments: DepartmentItem[];
  };
}

export type DashboardAction =
  | DashboardFiltersMergeAction
  | DashboardFilterSetPeriodAction
  | DashboardSourcesMergeAction;
