import { Box } from '@mui/material';
import { memo } from 'react';
import { useI18n } from '../../../i18';
import style from './index.module.scss';

const InactiveLabel = memo(() => {
  const { t } = useI18n();
  return <Box className={style.root}>{t('inactive')}</Box>;
});

export default InactiveLabel;
