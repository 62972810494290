import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';

const API = 'RatingProcessStepCommitteeMaterial';

export const API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeMaterial, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeMaterial, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeMaterial, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommitteeMaterial = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  title: string | null;
  titleEng: string | null;
  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  isInclude: boolean;
  remarks: string | null;
};