import { ButtonBase } from '@mui/material';
import { apiStatic, Loading, requestError, useAuth, useI18n, useRouter } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import axios from 'axios';
import clsx from 'clsx';
import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { API_METHODOLOGY } from 'services/methodology';
import { useAppDispatch } from 'store';
import style from './index.module.scss';

export const Methodologies = memo(
  ({ search, onClose }: { search: string; onClose: () => void }) => {
    const { t } = useI18n();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const router = useRouter();
    // state
    const [isAllowed] = useState(auth.permission('isAllowToViewMethodologies'));
    const [data, setData] = useState<{ id: number; title: string }[]>([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // handlers
    const onClick = useCallback(
      (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, id: number) => {
        e.stopPropagation();
        e.preventDefault();
        router.push(`/methodologies/methodologies?methodologies=${id}`);
        onClose();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClose],
    );
    useEffect(() => {
      const CancelToken = axios.CancelToken;
      let cancel: any;
      // 'search'
      if (isAllowed) {
        setIsLoading(true);
        apiStatic
          .get(`${API_METHODOLOGY.GET_ALL_DYNAMIC}`, {
            params: {
              select: 'id,title',
              filter: `isActive==true${search ? `&&title.ToLower().contains("${search}")` : ''}`,
              orderBy: 'title',
              take: 25,
              count: true,
            },
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          })
          .then(({ data }) => {
            setData(data?.value || []);
            setTotal(data?.count || 0);
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              dispatch(notifyRequestResult(requestError(e, e.message), 'error'));
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      return () => {
        if (cancel) cancel();
      };
    }, [isAllowed, setData, setTotal, setIsLoading, dispatch, search]);
    // render
    return isAllowed ? (
      <div className={style.result}>
        <div className={style.title}>
          <div>{t('methodologies')}</div>
          <div className={style.pagination}>
            <strong>{data.length}</strong> {t('of')} <strong>{total}</strong>
          </div>
        </div>
        <div className={'native-scroll scroll-size-s'}>
          <div className={clsx('native-scroll__helper')}>
            {data.map(({ id, title }) => (
              <ButtonBase key={id} onClick={(e) => onClick(e, id)} className={style.btn}>
                {title}
              </ButtonBase>
            ))}
          </div>
          {isLoading && <Loading />}
        </div>
      </div>
    ) : null;
  },
);
