export interface iRPStepDone {
  isInit: boolean;
  isLoading: boolean;
  data: any;
}

export interface iRPStepDoneForm {
  isInit: boolean;
  isLoading: boolean;
  data: any;
}

export enum iRPStepDoneActionTypes {
  RP_STEP_DONE_MERGE = 'RP_STEP_DONE_MERGE',
  // saga
  RP_STEP_DONE_INIT = 'RP_STEP_DONE_INIT',
  RP_STEP_DONE_FINISH = 'RP_STEP_DONE_FINISH',
  RP_STEP_SUBMIT_FORM = 'RP_STEP_SUBMIT_FORM',
}

export enum iRPStepDoneFormActionTypes {
  RP_STEP_DONE_FORM_MERGE = 'RP_STEP_DONE_FORM_MERGE',
  // saga
  RP_STEP_DONE_FORM_INIT = 'RP_STEP_DONE_FORM_INIT',
}

export interface IRPStepDoneForm {
  monitorVotingSheetFilePath: string | { value: string };
  monitorFinancialAnalysisFilePath: string | { value: string };
  monitorOtherFilePath: string | { value: string };
  monitorDescription: string;
  monitorUserID: string;
  monitorSignatureDate: string;
  monitorApproval1DocumentationStatusID: string;
  monitorApproval2DocumentationStatusID: string;
  monitorApproval3DocumentationStatusID: string;
  monitorApproval4DocumentationStatusID: string;
  ratingPerformanceCategoryID?: string;
}

export interface iRPStepDoneInitAction {
  type: iRPStepDoneActionTypes.RP_STEP_DONE_INIT;
}

export interface iRPStepDoneFormInitAction {
  type: iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_INIT;
}

export interface iRPStepDoneFinishAction {
  type: iRPStepDoneActionTypes.RP_STEP_DONE_FINISH;
  payload: IRPStepDoneForm;
}

export interface iRPStepSubmitFormAction {
  type: iRPStepDoneActionTypes.RP_STEP_SUBMIT_FORM;
  payload: IRPStepDoneForm;
}

export interface iRPStepDoneMergeAction {
  type: iRPStepDoneActionTypes.RP_STEP_DONE_MERGE;
  payload: Partial<iRPStepDone>;
}

export interface iRPStepDoneFormMergeAction {
  type: iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_MERGE;
  payload: Partial<iRPStepDoneForm>;
}
