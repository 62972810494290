import { IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { checkEs6AndRun, DialogAlert, useI18n } from 'AurionCR/components';
import {
  ErrorFileUploader,
  ErrorFileUploaderTypes,
  FileUploaderProps,
  FileUploaderRenderProps,
  Input,
} from 'AurionCR/components/formV2';
import { useOpen } from 'AurionCR/components/hooks';
import { mimeTypes } from 'file-type';
import filetype from 'magic-bytes.js';
import mime from 'mime-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

export const InputUploader: React.FC<FileUploaderRenderProps & { validationError: any }> = ({
  value,
  open,
  remove,
  error,
  renderProps = {},
  fileName,
  validationError,
}) => {
  const { label, disabled } = renderProps;
  const { isOpen, handleClose, handleOpen } = useOpen();
  useEffect(() => {
    if (error) {
      handleOpen();
    }
  }, [error, handleOpen]);

  return (
    <>
      <Input
        value={fileName}
        label={label}
        error={validationError || error?.message}
        onClear={remove}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton
              color="primary"
              component="span"
              size="small"
              disabled={disabled}
              onClick={open}
            >
              <CloudUploadIcon />
            </IconButton>
          ),
        }}
      />
      {/*eslint-disable-next-line react-hooks/exhaustive-deps*/}
      {value && fileName === value && (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      )}
      {isOpen && <DialogAlert message={error?.message} onClose={handleClose} />}
    </>
  );
};

export const FileUploader: React.FC<
  FileUploaderProps & { fileNamePrefixTemplate?: string; error?: any }
> = ({
  name,
  value,
  fileAccept = '.pdf,.doc,.docx,.xls,.xlsx,.htm,.html,application/msword,text/html,application/pdf,application/excel',
  apiSet = 'MediaUpload/UploadFileToCloud',
  apiRemove = `MediaUpload/RemoveFileFromCloud?fileName=\${data}`,
  filePath = 'Documents', // custom folder in cloud
  onChange,
  fileMaxSize,
  render = InputUploader,
  renderProps,
  className = 'FormControllerStyled',
  fileNamePrefixTemplate = `\${data.id}`, //add to file name some field from form data -> create uniq names in cloud
  error: validationError,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<any>({ FileName: '', Remove: null });

  const value_ = useMemo(
    () => (typeof value === 'string' ? value : state.FileName),
    [value, state.FileName],
  );

  const accept = useMemo<{ input?: string; types: null | string[] }>(() => {
    if (fileAccept) {
      const types_ = fileAccept
        .replace(/\s/g, '')
        .split(',')
        .reduce((result: any, item: any) => {
          const type_ = mime.lookup(item);
          if (type_) {
            result.push(type_);
            result.push(item);
          }
          return result;
        }, []);
      if (types_.length)
        return {
          input: types_.join(','),
          types: types_,
        };
    }
    return {
      input: undefined,
      types: null,
    };
  }, [fileAccept]);

  const { t } = useI18n();
  const onChangeData = useCallback(
    (base64 = '', fileName = '') => {
      let value: any = '';
      const { Remove, FileName } = state;
      const remove =
        !FileName && value_
          ? () => ({ method: 'put', url: checkEs6AndRun(apiRemove, value_) })
          : Remove;

      if (remove || base64) {
        value = {
          mixin_: {
            requests: [],
            updateModel: 'filePath',
            name,
          },
          value: fileName,
        };
        if (remove) {
          value.mixin_.requests.push(remove);
        }
        if (base64) {
          value.mixin_.requests.push((data: any) => ({
            method: 'post',
            url: apiSet,
            data: {
              fileName: `${checkEs6AndRun(fileNamePrefixTemplate, data)}_${fileName}`,
              fileStreamString: base64.split(',')[1],
              filePath,
            },
          }));
        }
      }
      setState({ FileName: fileName, remove });
      if (!value?.mixin_?.requests.length) {
        onChange('');
      } else {
        onChange({ target: { value } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state, setState, name, onChange, fileNamePrefixTemplate, filePath, apiRemove, apiSet, value_],
  );

  const [error, setError] = useState<ErrorFileUploader | null>(null);
  const errorController = useCallback(
    (type: ErrorFileUploaderTypes | null) => {
      if (type !== null && ref.current) {
        ref.current.value = '';
      }
      switch (type) {
        case null:
          setError(null);
          break;
        case 'ERROR_LOAD':
          setError(new ErrorFileUploader(type, t('file-error-load')));
          break;
        case 'INCORRECT_TYPE':
          setError(new ErrorFileUploader(type, t('file-incorrect-type')));
          break;
        case 'MAX_FILE_SIZE':
          setError(new ErrorFileUploader(type, `${t('file-max-size')} < ${fileMaxSize}Mb`));
          break;
        default:
          setError(new ErrorFileUploader('UNKNOWN', t('file-incorrect-type')));
      }
    },
    [t, fileMaxSize, ref],
  );

  const onLoadFile = useCallback(
    (file_: File) => {
      errorController(null);
      let fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        if (e) {
          onChangeData(e.target.result, file_.name);
          if (ref.current) ref.current.value = '';
        } else {
          errorController('ERROR_LOAD');
        }
      };
      fileReader.readAsDataURL(file_);
    },
    [errorController, onChangeData, ref],
  );

  const onChangeFile = useCallback(
    async (e: any) => {
      errorController(null);
      const file_ = e.target.files[0];
      if (file_) {
        // 1. fast check to file type
        if (
          accept.types &&
          file_.type &&
          !accept.types.some((type) => type === file_.type.toLowerCase())
        ) {
          errorController('INCORRECT_TYPE');
          // 2. check to file size
        } else if (fileMaxSize && file_.size > fileMaxSize * 1000000) {
          errorController('MAX_FILE_SIZE');
        } else if (accept.types && mimeTypes.has(file_.type)) {
          // 3. slow test to file type
          try {
            const result = filetype(new Uint8Array(await file_.arrayBuffer()));
            if (
              accept.types?.some((type) => result.some((guessedFile) => guessedFile.mime === type))
            ) {
              onLoadFile(file_);
            } else {
              errorController('INCORRECT_TYPE');
            }
          } catch (_) {
            errorController('INCORRECT_TYPE');
          }
        } else {
          onLoadFile(file_);
        }
      }
    },
    [fileMaxSize, accept, onLoadFile, errorController],
  );

  // render methods
  const open = useCallback(() => {
    ref.current?.click();
  }, []);
  const remove = useCallback(() => {
    onChangeData();
  }, [onChangeData]);
  const renderValue = useMemo(() => {
    return typeof value === 'string' ? value : value?.base64 || null;
  }, [value]);

  const classNames = clsx(
    'file-uploader',
    value && `file-uploader_active`,
    className,
    state.FileName && 'file',
  );

  return (
    <div className={classNames}>
      <input
        ref={ref}
        onChange={onChangeFile}
        accept={accept.input}
        type="file"
        tabIndex={-1}
        style={{ display: 'none' }}
      />
      {render({
        open,
        remove,
        value: renderValue,
        fileName: value_,
        renderProps,
        error,
        validationError,
      })}
    </div>
  );
};
