import { main } from './main';
import { SC } from './significant-change';
import { MS } from './materials';
import { RS } from './resources';
import { User } from './user';
import { UserRatingEntityVoting } from './user-rates-entity-voting';
import { SeriesRating } from './series-rating';
import { UserSeriesVoting } from './user-series-voting';

export default [
  ...main,
  ...SC,
  ...MS,
  ...RS,
  ...User,
  ...UserRatingEntityVoting,
  ...SeriesRating,
  ...UserSeriesVoting,
];
