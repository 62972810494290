import {
  iRPStepType4ActionTypes,
  iRPStepType4AdditionCommitteeAction,
  iRPStepType4AddListItemAction,
  iRPStepType4AddNewAction,
  iRPStepType4ApproveAction,
  iRPStepType4DeleteAction,
  iRPStepType4DeleteListItemAction,
  iRPStepType4InitAction,
  iRPStepType4MergeAction,
  iRPStepType4MergeListAction,
  iRPStepType4MergeListDataAction,
  iRPStepType4RejectAction,
  iRPStepType4SaveAction,
  iRPStepType4SaveReportAction,
} from '../@type';

export const iRPStepType4Init = (): iRPStepType4InitAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_INIT };
};

export const iRPStepType4Merge = (
  payload: iRPStepType4MergeAction['payload'],
): iRPStepType4MergeAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE, payload };
};

export const iRPStepType4MergeList = (
  payload: iRPStepType4MergeListAction['payload'],
): iRPStepType4MergeListAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST, payload };
};

export const iRPStepType4MergeListData = (
  payload: iRPStepType4MergeListDataAction['payload'],
): iRPStepType4MergeListDataAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST_DATA, payload };
};

export const iRPStepType4Save = (
  payload: iRPStepType4SaveAction['payload'],
): iRPStepType4SaveAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE, payload };
};

export const iRPStepType4AddNew = (
  payload: iRPStepType4AddNewAction['payload'],
): iRPStepType4AddNewAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_NEW, payload };
};

export const iRPStepType4AddListItem = (
  payload: iRPStepType4AddListItemAction['payload'],
): iRPStepType4AddListItemAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_LIST_ITEM, payload };
};

export const iRPStepType4DeleteListItem = (
  payload: iRPStepType4DeleteListItemAction['payload'],
): iRPStepType4DeleteListItemAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE_LIST_ITEM, payload };
};

export const iRPStepType4Delete = (
  payload: iRPStepType4DeleteAction['payload'],
): iRPStepType4DeleteAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE, payload };
};

export const iRPStepType4Approve = (
  payload: iRPStepType4ApproveAction['payload'],
): iRPStepType4ApproveAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_APPROVE, payload };
};

export const iRPStepType4Reject = (
  payload: iRPStepType4RejectAction['payload'],
): iRPStepType4RejectAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_REJECT, payload };
};

export const iRPStepType4SaveReport = (
  payload: iRPStepType4SaveReportAction['payload'],
): iRPStepType4SaveReportAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE_REPORT, payload };
};

export const iRPStepType4AdditionCommittee = (
  payload: iRPStepType4AdditionCommitteeAction['payload'],
): iRPStepType4AdditionCommitteeAction => {
  return { type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADDITION_COMMITTEE, payload };
};
