const API = 'Department';

export const API_DEPARTMENT = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tDepartment, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tDepartment, 'id'>) => `${API}/Delete/${id}`,
};

export type tDepartment = {
  id: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  title?: string;
  titleEng?: string;
  isProfessional?: boolean;
};
