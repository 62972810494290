import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  API_RATING_PROCESS_STEP_PUBLICATION,
  tRatingProcessStepPublication,
} from 'services/rating-process-step-publication';
import {
  API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP,
  tRatingProcessStepPublicationCheckup,
} from 'services/rating-process-step-publication-checkup';
import { API_REPORT } from 'services/report';
import {
  iRPState,
  iRPStepType5,
  iRPStepType5ActionTypes,
  iRPStepType5ApproveAction,
  iRPStepType5RejectAction,
  iRPStepType5SaveAction,
  iRPStepType5SaveCheckupAction,
  iRPStepType5SaveReportAction,
  iRPStepType5ValidationAction,
} from '../@type';
import {
  iRPStepType5Merge,
  iRPStepType5MergeCheckup,
  iRPStepType5MergeCheckupData,
  iRPStepType5MergeData,
  RPMerge,
  RPPrivateMagnaReportMerge,
} from '../helpers';
import { handleError, stepApprove, stepReject } from '../saga';
import { onUpdateHtmlField } from 'utils/html-upload-helpers';

export const HTML_FIELDS_STEP_5 = ['remarks'];

function* getData() {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  try {
    const {
      data: {
        // eslint-disable-next-line no-useless-computed-key
        value: { [0]: data },
      },
    }: { data: { value: tRatingProcessStepPublication[] } } = yield call(
      apiStatic.get,
      API_RATING_PROCESS_STEP_PUBLICATION.GET_ALL_DYNAMIC,
      {
        params: {
          select: [
            'id',
            // cols - top
            'teamLeaderUserID',
            'headOfFieldUserID',
            'analystUserID',
            // cols - bottom
            'teamLeaderSignatureDate',
            'headOfFieldSignatureDate',
            'analystSignatureDate',
            // footer
            'reportPublicationOptionID',

            'magnaPublicationDate',
            'publicationApprovalTypeID',
            'reportID',
            '(report==null)?null:new{report.id,report.filePath,report.remarks} as report',
            'confirmationFilePath',

            'remarks',
          ].join(),
          take: 1,
          filter: `ratingProcessStepID==${main?.ratingProcessSteps[currentStep].id}`,
        },
      },
    );
    const {
      data: { value: checkups },
    }: { data: { value: tRatingProcessStepPublicationCheckup[] } } = yield call(
      apiStatic.get,
      API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP.GET_ALL_DYNAMIC,
      {
        params: {
          select: [
            'id',
            'title',
            'reportCheckupTypeID',
            'headOfFieldApproval',
            'analystApproval',
            'remarks',
          ].join(),
          orderBy: 'rank asc',
          filter: `ratingProcessStepPublicationID==${data?.id}`,
        },
      },
    );
    yield put(
      iRPStepType5Merge({
        loading: false,
        init: true,
        data: parseResponseDate(data, [
          'teamLeaderSignatureDate',
          'headOfFieldSignatureDate',
          'analystSignatureDate',
          'magnaPublicationDate',
        ]),
        checkups: checkups.map((item) => ({
          id: item.id,
          isLoading: false,
          data: item,
        })),
      }),
    );
  } catch (e) {
    yield put(iRPStepType5Merge({ loading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* init() {
  const {
    stepType5: { init, loading },
  }: iRPState = yield select((state) => state.RP);
  if (!init && !loading) {
    yield put(iRPStepType5Merge({ loading: true }));
    yield call(getData);
    yield put(iRPStepType5Merge({ loading: false }));
  }
}

function* saveData(Data: Partial<iRPStepType5['data']>): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  data = yield call(saveMixins, data, mixins);
  const htmlFieldsData = yield onUpdateHtmlField({
    formData: Data,
    field: HTML_FIELDS_STEP_5,
  });

  const updatedData = data;
  HTML_FIELDS_STEP_5.forEach((item) => {
    updatedData[item] = htmlFieldsData[item];
  });

  yield call(
    apiStatic.patch,
    API_RATING_PROCESS_STEP_PUBLICATION.PATCH(data),
    prepareRequest(updatedData),
  );
  return updatedData;
}

function* save({ payload: { triggerClose, ...Data } }: iRPStepType5SaveAction) {
  yield put(RPMerge({ loading: true }));
  try {
    // @ts-ignore
    const data = yield call(saveData, Data);
    yield put(notifyRequestResult(requestSuccess('')));
    if (!triggerClose) {
      yield put(iRPStepType5MergeData(data));
    } else {
      yield put(RPMerge({ triggerClose }));
    }
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* saveCheckup({ payload: { index, ...Data } }: iRPStepType5SaveCheckupAction) {
  const {
    stepType5: {
      checkups: {
        [index]: {
          data: { id },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType5MergeCheckup({ index, isLoading: true }));
  try {
    let { data, mixins } = parseMixins(Data);
    if (mixins.length) {
      // @ts-ignore
      data = yield call(saveMixins, data, mixins);
    }
    yield call(
      apiStatic.patch,
      API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP.PATCH({ id }),
      prepareRequest(data),
    );
    yield put(iRPStepType5MergeCheckupData({ index, ...data }));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(iRPStepType5MergeCheckup({ index, isLoading: false }));
}

function* reject({ payload }: iRPStepType5RejectAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveData, payload);
    yield put(iRPStepType5MergeData(data));
    yield call(stepReject);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* approve({ payload }: iRPStepType5ApproveAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveData, payload);
    yield put(iRPStepType5MergeData(data));
    if (data.reportPublicationOptionID === 3) {
      yield put(RPPrivateMagnaReportMerge({ isOpen: true }));
    } else {
      yield call(stepApprove);
    }
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* validation({ payload }: iRPStepType5ValidationAction) {
  const {
    stepType5: { checkups },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType5Merge({ isSubmit: true }));
  if (payload) {
    payload(
      !checkups.some(
        ({ data: { reportCheckupTypeID, headOfFieldApproval } }) =>
          !reportCheckupTypeID || !headOfFieldApproval,
      ),
    );
  }
}

function* saveReport({ payload }: iRPStepType5SaveReportAction) {
  const {
    main,
    stepType5: {
      data: { id, reportID, report },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(RPMerge({ loading: true }));
  try {
    let { data, mixins } = parseMixins({
      ...payload,
      ratedEntityID: main?.ratedEntityID,
    });
    // - create report
    if (!reportID) {
      const {
        data: { id: responseID },
      } = yield call(
        apiStatic.post,
        API_REPORT.POST,
        prepareRequest({ ...data, title: `report-from-rating-process #${main?.id}` }),
      );
      data.id = responseID;
    } else {
      data.id = reportID;
    }
    // - patch report
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    yield call(apiStatic.patch, API_REPORT.PATCH(data), prepareRequest(data));
    yield put(
      iRPStepType5MergeData({ reportID: data?.id, report: { ...(report || {}), ...data } }),
    );
    // - patch RatingProcessStepReport
    if (!reportID) {
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_PUBLICATION.PATCH({ id: Number(id) }),
        prepareRequest({ reportID: data.id }),
      );
    }
    yield put(iRPStepType5Merge({ isEditReport: false }));
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(RPMerge({ loading: false }));
}

export default [
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_INIT, init),
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE, save),
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_REJECT, reject),
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_APPROVE, approve),
  takeEvery(iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_CHECKUP, saveCheckup),
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_VALIDATION, validation),
  takeLatest(iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_REPORT, saveReport),
];
