import { memo, ReactElement, useEffect, useMemo } from 'react';
import { API_LABELS, API_LANGUAGES } from 'services/labels';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getKey, I18nContext } from 'AurionCR/components';
import source from 'AurionCR/components/source';
import { setLanguage } from 'AurionCR/store/modules/language';
import { LANGUAGE_LOCAL_STORE_NAME } from 'components/language/index';

export const getLabelsPath = (languageID: number) =>
  `${API_LABELS.DEFAULT_REQUEST}&filter=languageID=="${languageID}"`;
export const labelsKey = getKey(API_LABELS.DEFAULT_REQUEST);

export const I18NContextProvider = memo(({ children }: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const default_ = useMemo(() => ({ loading: true, data: [], map: {} }), []);
  const languages = source(API_LANGUAGES.DEFAULT_REQUEST, 'id', true);
  const language = useSelector((state: any) => state.language);
  const path = useMemo(() => {
    return language?.languageID ? getLabelsPath(Number(language.languageID)) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language?.languageID]);
  // labels
  const labels = useSelector((state: any) => {
    if (path) {
      const state_ = state.sources[labelsKey];
      return state_ ? state_[path] || default_ : default_;
    } else {
      return default_;
    }
  }, shallowEqual);
  // update labels
  useEffect(() => {
    if (path)
      dispatch({ type: 'SOURCE_INIT', key: labelsKey, path, fieldID: 'labelKey', mapping: true });
    return () => {
      if (path) dispatch({ type: 'SOURCE_MOUNT_UNMOUNT', key: labelsKey, path, methodType: -1 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (languages?.data?.length === 0) return;
    const currentLangID =
      localStorage.getItem(LANGUAGE_LOCAL_STORE_NAME(user?.userID)) ||
      localStorage.getItem(LANGUAGE_LOCAL_STORE_NAME()) ||
      language?.languageID;
    if (String(currentLangID) !== String(language?.languageID) || !language?.languageID) {
      const lang =
        languages.data.find((item: any) => String(item.id) === currentLangID) || languages.data[0];
      localStorage.setItem(LANGUAGE_LOCAL_STORE_NAME(user?.userID), lang.id);
      dispatch(setLanguage(lang.id, lang, lang?.direction));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, languages?.data, language?.languageID, dispatch]);

  return <I18nContext.Provider value={{ ...labels }}>{children}</I18nContext.Provider>;
});

export default I18NContextProvider;
