import { FC, memo, useMemo, useState } from 'react';
import {
  DateView,
  TimeView,
  MobileDatePicker,
  MobileDateTimePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
  PickersActionBarAction,
} from '@mui/x-date-pickers';
import Calendar from '@mui/icons-material/Event';
import { isValid } from 'date-fns';
import CustomDatePickerToolBar from './components/app-date-picker-toolbar';
import { useI18n } from '../../../i18';
import { CustomDatePickerHeader } from '../components/custom-date-picker-header.tsx';
import CustomDateTimePickerToolBar from './components/app-time-date-picker-toolbar';
import style from './index.module.scss';
import { useOpen } from '../../../hooks';

type ITextFieldColor = 'error' | 'warning' | 'primary' | 'secondary' | 'info' | 'success';

interface IAppDatePicker {
  label?: string;
  value?: any;
  error?: any | { message: string };
  format?: string;
  showTodayButton?: boolean;
  ampm?: boolean;
  helperText?: string;
  onChange: (e: object, value?: any) => void;
}

export const DatePicker: FC<IAppDatePicker> = memo(
  ({
    value,
    error,
    format = 'dd/MM/yyyy HH:mm',
    showTodayButton = true,
    ampm = false,
    label,
    helperText,
    onChange,
    ...props
  }) => {
    const { t } = useI18n();
    const [dateView, setDateView] = useState<DateView>('day');
    const [dateTimeView, setTimeDateView] = useState<DateView | TimeView>('day');
    const { isOpen, handleOpen, handleClose } = useOpen();

    const value_ = useMemo(() => {
      if (value) {
        if (typeof value === 'string' && isValid(new Date(value))) {
          return new Date(value);
        } else if (value instanceof Date) {
          return value;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }, [value]);

    const isTimeAvailable = useMemo(() => {
      return /[hHm]/g.test(format);
    }, [format]);

    const desktopOptions = {
      open: false,
      label: label,
      value: value_,
      format: format,
      onOpen: handleOpen,
      onChange: (value: Date | null) => onChange({ e: { target: { value: value } } }, value),
    };

    const desktopSlotProps = {
      textField: {
        error: !!error,
        color: (error ? 'warning' : 'primary') as ITextFieldColor,
        helperText: error && error.message ? t(error.message) || '' : helperText,
        fullWidth: true,
        InputProps: {
          endAdornment: <Calendar color={'disabled'} onClick={handleOpen} />,
        },
      },
    };

    const mobileOptions = {
      open: isOpen,
      label: label,
      value: value_,
      format: format,
      onChange: (value: Date | null) => onChange({ e: { target: { value: value } } }, value),
      onClose: handleClose,
    };

    const mobileSlotProps = {
      mobilePaper: {
        className: style.paper,
      },
      textField: {
        sx: {
          display: 'none',
        },
      },
      actionBar: {
        actions: ['today', 'cancel', 'accept'] as PickersActionBarAction[],
      },
    };

    const localeText = {
      cancelButtonLabel: t('cancel'),
      todayButtonLabel: t('today'),
      okButtonLabel: t('ok'),
    };

    return (
      <div className="date-field-wrapper">
        {isTimeAvailable && (
          <>
            <DesktopDateTimePicker {...props} {...desktopOptions} slotProps={desktopSlotProps} />
            {isOpen && (
              <MobileDateTimePicker
                {...props}
                {...mobileOptions}
                ampm={ampm}
                view={dateTimeView}
                views={['year', 'day', 'hours', 'minutes']}
                onViewChange={(view) => setTimeDateView(view)}
                slots={{
                  toolbar: (props) => (
                    <CustomDateTimePickerToolBar {...props} setOpenView={setTimeDateView} />
                  ),
                  calendarHeader: CustomDatePickerHeader,
                }}
                slotProps={mobileSlotProps}
                localeText={localeText}
              />
            )}
          </>
        )}
        {!isTimeAvailable && (
          <>
            <DesktopDatePicker {...props} {...desktopOptions} slotProps={desktopSlotProps} />
            {isOpen && (
              <MobileDatePicker
                {...props}
                {...mobileOptions}
                view={dateView}
                onViewChange={(view) => setDateView(view)}
                slots={{
                  toolbar: (props) => (
                    <CustomDatePickerToolBar {...props} setOpenView={setDateView} />
                  ),
                  calendarHeader: CustomDatePickerHeader,
                }}
                slotProps={mobileSlotProps}
                localeText={localeText}
              />
            )}
          </>
        )}
      </div>
    );
  },
);
