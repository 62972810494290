import { IDataGridColumn } from 'components/data-grid/model';
import { ModuleExportColumn } from 'components/hooks/export-excel/models';
import { tSourceDefault } from 'components/@types';
import { isValid } from 'date-fns';

export const FORMAT_DATE = 'dd/MM/yyyy';
export const FORMAT_DATE_TIME = `${FORMAT_DATE} HH:mm`;
export const HTML_STORE_URL = 'https://storewiz';
export const calcFilterFullName = ({
  firstName = 'firstName',
  lastName = 'lastName',
  value,
}: {
  firstName?: string;
  lastName?: string;
  value: string;
}) => {
  return `(${firstName}.replace(" ", String.Empty) + ${lastName}.replace(" ", String.Empty)).contains("${value.replace(
    / /gi,
    '',
  )}")`;
};
export const calcSearchMember = <Model>({ value }: { name: keyof Model; value: string }) => {
  const fields = [
    'fullName',
    'email',
    'companyName',
    'position',
    'stripeProductPrice',
    'stripeKey',
  ] as const;

  return value
    ? `(${fields
        .map((field) => {
          switch (field) {
            case 'fullName':
              return calcFilterFullName({ value });
            default:
              return `${field}.contains("${value}")`;
          }
        })
        .join(' || ')})`
    : undefined;
};

const getColumnField = (fieldGridColumn: string): string => {
  const arr = fieldGridColumn.split(' ');
  return arr[arr.length - 1];
};
export const gidColumnsToExcel = (columns: IDataGridColumn[]): ModuleExportColumn[] => {
  return columns.map((column) => {
    return {
      title: column.title,
      field: getColumnField(column.field),
      // @ts-ignore
      type: column.type,
      // @ts-ignore
      valueTemplate: column.valueTemplate,
    };
  });
};

export const prepareRequest = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === '' ? null : data[key];
    return acc;
  }, data);
};

export const addZero = (value: number) => {
  return value > 9 ? value.toString() : '0' + value;
};

export const createConstFromEnum = (
  Enum: any[],
  useValue?: boolean,
  number?: boolean,
): tSourceDefault[] => {
  return Enum.slice(0, Enum.length / (useValue ? 1 : 2)).map(([id, title]) => ({
    id: number ? Number(id) : id,
    title,
  }));
};

export const StringToLabelKey = (value: string) => {
  return value
    ? value.replace(/[A-Z]/g, (str: string, i) => (i > 0 ? '-' : '') + str.toLowerCase())
    : value;
};

export const parseResponseDate = <T>(data: any, keys: string[]): T => {
  Object.keys(data).forEach((key) => {
    if (keys.includes(key)) {
      const date_ = data[key] ? new Date(data[key]) : null;
      data[key] = date_ && isValid(date_) ? date_ : null;
    }
  });
  return data;
};

export const REPORT_FILE_TYPE =
  '.svg,.txt,.tiff,.jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.htm,.html,.msg,.eml,.xlsm';

export enum ORDER_BY {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = '',
}

export interface ISetOrder {
  field: string;
  order: ORDER_BY;
}

export const changeOrder = (
  orderName: string,
  sortOrder: ISetOrder,
  setSortOrder: (value: ISetOrder) => void,
) => {
  if (orderName !== sortOrder.field) {
    return setSortOrder({
      field: orderName,
      order: ORDER_BY.DESC,
    });
  }
  let updatedOrder = ORDER_BY.DEFAULT;

  if (sortOrder.order === ORDER_BY.DEFAULT) {
    updatedOrder = ORDER_BY.DESC;
  } else if (sortOrder.order === ORDER_BY.DESC) {
    updatedOrder = ORDER_BY.ASC;
  }

  setSortOrder({
    field: updatedOrder ? orderName : '',
    order: updatedOrder,
  });
};

export const tabsProps = (index: number) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

export const translateLabels = (t: (value: string) => string, labels: string[] | string) => {
  if (Array.isArray(labels)) {
    return labels.map((item) => t(item)).join(' ');
  }

  return labels ? t(labels) : '';
};
