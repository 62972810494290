import { MaalotOutlook } from 'services/maalot-outlook';
import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { MoodysRating } from 'services/moodys-rating';
import { RatingAndTrackingMessage } from 'services/rating-and-tracking-message';
import { RatingStoppedReason } from 'services/rating-stopped-reason';
import { tSeries } from 'services/series';

const API = 'SeriesDetail';

export const API_SERIES_DETAIL = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tSeriesDetail, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tSeriesDetail, 'id'>) => `${API}/Delete/${id}`,
};

export type tSeriesDetail = {
  id: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  expirationDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  longTermMidroogRatingID?: number;
  shortTermMidroogRatingID?: number;
  baselineCreditAssesmentRatingID?: number;
  bankLawerTier2CapitalRatingID?: number;
  bankUpperTier2CapitalRatingID?: number;
  bankTier1CapitalRatingID?: number;
  bankCocosRatingID?: number;
  insuranceFinancialStrengthRatingID?: number;
  insuranceTier2CapitalRatingID?: number;
  insuranceLawerTier2CapitalRatingID?: number;
  insuranceComplexTier2CapitalRatingID?: number;
  insuranceComplexTier3CapitalRatingID?: number;
  midroogOutlookID?: number;
  midroogRatingSymbolID?: number;
  moodysRatingID?: number;
  moodysOutlookID?: number;
  maalotRatingID?: number;
  maalotOutlookID?: number;
  provisional?: boolean;
  longTermMidroogRating?: MidroogRating;
  shortTermMidroogRating?: MidroogRating;
  baselineCreditAssesmentRating?: MidroogRating;
  bankLawerTier2CapitalRating?: MidroogRating;
  bankUpperTier2CapitalRating?: MidroogRating;
  bankTier1CapitalRating?: MidroogRating;
  bankCocosRating?: MidroogRating;
  insuranceFinancialStrengthRating?: MidroogRating;
  insuranceTier2CapitalRating?: MidroogRating;
  insuranceLawerTier2CapitalRating?: MidroogRating;
  insuranceComplexTier2CapitalRating?: MidroogRating;
  insuranceComplexTier3CapitalRating?: MidroogRating;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbol?: MidroogRatingSymbol;
  moodysRating?: MoodysRating;
  maalotOutlook?: MaalotOutlook;
  seriesID?: number;
  ratingProcessID?: number;
  performanceMidroogRatingID?: number;
  performanceMidroogOutlookID?: number;
  ratingAndTrackingMessageID?: number;
  ratingStoppedReasonID?: number;
  ratingDate?: string | Date;
  initSize?: number;
  outstandingDebtNominal?: number;
  outstandingDebtNis?: number;
  remarks?: string;
  ratingAndTrackingMessageDetails?: string;
  ratingStoppedReasonDetails?: string;
  isInProgress?: boolean;
  series?: tSeries;
  performanceMidroogRating?: MidroogRating;
  performanceMidroogOutlook?: MidroogOutlook;
  ratingAndTrackingMessage?: RatingAndTrackingMessage;
  ratingStoppedReason?: RatingStoppedReason;
  reportIssueDate?: string;
  performanceTitleEng?: string;
  instrumentDescription?: string;
  instrumentSeniority?: string;
  instrumentType?: string;
};
