import { useAuth } from 'AurionCR/components';
import { RPStepUpdate } from 'pages/CreditRatings/RatingProcess/components/editor/store';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ePERMISSIONS } from 'services/user-position';
import { useAppDispatch, useAppSelector } from 'store';

export const useStepHooks = () => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  // state
  const [isAllowToEditRatingProcess] = useState(
    auth.permission(ePERMISSIONS.isAllowToViewRatingProcesses),
  );
  const { currentStep } = useAppSelector((state) => state.RP);
  const step = useAppSelector((state) => state.RP.main?.ratingProcessSteps[currentStep]);
  const refFormStepUpdate = useRef<any>(null);
  const isShowUpdateButtons = useMemo(
    () => Boolean(step?.update && step.update.ratingProcessStepUpdateStatusID === 1),
    [step?.update],
  );
  const isShowUpdateForm = useMemo(
    () => Boolean(step?.update && step.update.ratingProcessStepUpdateStatusID === 2),
    [step?.update],
  );
  const isPreviewMain = useMemo(
    () => !step?.isStepEdit || isShowUpdateButtons,
    [step, isShowUpdateButtons],
  );
  const onApproveWrapper = useCallback(
    (callback: () => void) => {
      if (refFormStepUpdate?.current?.getValues) {
        dispatch(
          RPStepUpdate({
            ...refFormStepUpdate.current.getValues(),
            isCompleted: true,
            setLoading: true,
            headOfFieldSignatureDate: new Date(),
            callback,
          }),
        );
      } else {
        callback();
      }
    },
    [dispatch, refFormStepUpdate],
  );
  return {
    currentStep,
    step,
    isPreviewMain,
    refFormStepUpdate,
    isShowUpdateButtons,
    isShowUpdateForm,
    onApproveWrapper,
    isAllowToEditRatingProcess,
  };
};
