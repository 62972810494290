import {
  iRPStepType3ActionTypes,
  iRPStepType3UserDeleteItemAction,
  iRPStepType3UserDeleteItemDataAction,
  iRPStepType3UserInitAction,
  iRPStepType3UserMergeAction,
  iRPStepType3UserMergeItemAction,
  iRPStepType3UserMergeItemDataAction,
  iRPStepType3UserRefreshAction,
  iRPStepType3UserSaveItemAction,
} from '../../@type';

export const iRPStepType3UserMerge = (
  payload: iRPStepType3UserMergeAction['payload'],
): iRPStepType3UserMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE, payload };
};
export const iRPStepType3UserMergeItem = (
  payload: iRPStepType3UserMergeItemAction['payload'],
): iRPStepType3UserMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE_ITEM, payload };
};
export const iRPStepType3UserMergeItemData = (
  payload: iRPStepType3UserMergeItemDataAction['payload'],
): iRPStepType3UserMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE_ITEM_DATA, payload };
};
export const iRPStepType3UserDeleteItemData = (
  payload: iRPStepType3UserDeleteItemDataAction['payload'],
): iRPStepType3UserDeleteItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_DELETE_ITEM_DATA, payload };
};
// saga
export const iRPStepType3UserInit = (
  payload: iRPStepType3UserInitAction['payload'],
): iRPStepType3UserInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_INIT, payload };
};
export const iRPStepType3UserRefresh = (
  payload: iRPStepType3UserRefreshAction['payload'],
): iRPStepType3UserRefreshAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_REFRESH, payload };
};
export const iRPStepType3UserSaveItem = (
  payload: iRPStepType3UserSaveItemAction['payload'],
): iRPStepType3UserSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SAVE_ITEM, payload };
};
export const iRPStepType3UserDeleteItem = (
  payload: iRPStepType3UserDeleteItemAction['payload'],
): iRPStepType3UserDeleteItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_DELETE_ITEM, payload };
};
