import { memo } from 'react';
import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from '@mui/material';

export interface CheckboxProps extends Omit<MaterialCheckboxProps, 'value' | 'checked' | 'onBlur'> {
  value?: boolean;
  onBlur?: () => void;
}

export const Checkbox = memo(({ value, onChange, onBlur, ...rest }: CheckboxProps) => {
  return <MaterialCheckbox checked={value} onChange={onChange} onBlur={onBlur} {...rest} />;
});
