import { apiStatic } from 'AurionCR/components';
import { pathStepInformationRequest } from 'components/rating-process-preview/step-information-request';
import { call, select } from 'redux-saga/effects';
import { iRPMainDataSteps, iRPState } from '../../@type';
import { dateHelper, tTranslate } from './index';

export function* stepType1(t: tTranslate, step: iRPMainDataSteps): any {
  const { main }: iRPState = yield select((state) => state.RP);
  const {
    data: {
      // eslint-disable-next-line no-useless-computed-key
      value: { [0]: stepData },
    },
  } = yield call(apiStatic.get, pathStepInformationRequest(Number(main?.id)));
  // parse data
  stepData.discussionDate = dateHelper(stepData?.discussionDate);
  stepData.requestMaterialsDate = dateHelper(stepData?.requestMaterialsDate);
  stepData.responseMaterialsDate = dateHelper(stepData?.responseMaterialsDate);
  // create html
  return `<div style='page-break-before: always;'>
    <h1 class='pageTitle'>${step.ratingProcessStepTypeTitle}</h1>
    <table class='tablePreview'>
      <tbody>
      <tr>
        <th>${t('discussion-date')}</th>
        <td>${stepData.discussionDate}</td>    
      </tr>
       <tr>
        <th>${t('request-materials-date')}</th>
        <td>${stepData.requestMaterialsDate}</td>    
      </tr>
       <tr>
        <th>${t('response-materials-date')}</th>
        <td>${stepData.responseMaterialsDate}</td>    
      </tr>
       <tr>
        <th>${t('methodology')}</th>
        <td>${stepData?.primaryMethodologyTitle || ''}</td>    
      </tr>
       <tr>
        <th>${t('sub-methodology')}</th>
        <td>${stepData?.secondaryMethodologyTitle || ''}</td>    
      </tr>
       <tr>
        <th>${t('head-of-field')}</th>
        <td>${stepData?.headOfFieldName || ''}</td>    
      </tr>
       <tr>
        <th>${t('team-leader')}</th>
        <td>${stepData?.teamLeaderName || ''}</td>    
      </tr>
       <tr>
        <th>${t('additional-analyst')}</th>
        <td>${stepData?.analystName || ''}</td>    
      </tr>
      <tr>
        <th>${t('more-members')}</th>
        <td>${stepData?.members || ''}</td>    
      </tr>
      </tbody>
    </table>
  </div>`;
}
