import { Company } from 'services/company';
import { MaalotOutlook } from 'services/maalot-outlook';
import { MaalotRating } from 'services/maalot-rating';
import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { MoodysOutlook } from 'services/moodys-outlook';
import { MoodysRating } from 'services/moodys-rating';

const API = 'Report';

export const API_REPORT = {
  API,
  GET: ({ id }: Pick<tReport, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tReport, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tReport, 'id'>) => `${API}/Delete/${id}`,
};

export type tReport = {
  id: number | string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  longTermMidroogRatingID?: string;
  shortTermMidroogRatingID?: string;
  baselineCreditAssesmentRatingID?: string;
  bankLawerTier2CapitalRatingID?: string;
  bankUpperTier2CapitalRatingID?: string;
  bankTier1CapitalRatingID?: string;
  bankCocosRatingID?: string;
  insuranceFinancialStrengthRatingID?: string;
  insuranceTier2CapitalRatingID?: string;
  insuranceLawerTier2CapitalRatingID?: string;
  insuranceComplexTier2CapitalRatingID?: string;
  insuranceComplexTier3CapitalRatingID?: string;
  midroogOutlookID?: string;
  midroogRatingSymbolID?: string;
  moodysRatingID?: string;
  moodysOutlookID?: string;
  maalotRatingID?: string;
  maalotOutlookID?: string;
  provisional?: boolean;
  longTermMidroogRating?: MidroogRating;
  shortTermMidroogRating?: MidroogRating;
  baselineCreditAssesmentRating?: MidroogRating;
  bankLawerTier2CapitalRating?: MidroogRating;
  bankUpperTier2CapitalRating?: MidroogRating;
  bankTier1CapitalRating?: MidroogRating;
  bankCocosRating?: MidroogRating;
  insuranceFinancialStrengthRating?: MidroogRating;
  insuranceTier2CapitalRating?: MidroogRating;
  insuranceLawerTier2CapitalRating?: MidroogRating;
  insuranceComplexTier2CapitalRating?: MidroogRating;
  insuranceComplexTier3CapitalRating?: MidroogRating;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbol?: MidroogRatingSymbol;
  moodysRating?: MoodysRating;
  moodysOutlook?: MoodysOutlook;
  maalotRating?: MaalotRating;
  maalotOutlook?: MaalotOutlook;
  ratedEntityID?: string;
  title?: string;
  titleEng?: string;
  reportDate?: string | Date;
  linkToFile?: string;
  filePath?: string;
  remarks?: string;
  isDraft?: boolean;
  ratedEntity?: Company;
  ReportSeries?: [];
};
