import { CommitteeSignificantChangeType } from 'services/committee-significant-change-type';
import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';

const API = 'RatingProcessStepCommitteeSignificantChange';

export const API_RATING_PROCESS_STEP_COMMITTEE_SIGNIFICANT_CHANGE = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeSignificantChange, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeSignificantChange, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeSignificantChange, 'id'>) =>
    `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommitteeSignificantChange = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  title: string | null;
  titleEng: string | null;
  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  committeeSignificantChangeTypeID: number;
  committeeSignificantChangeType?: CommitteeSignificantChangeType;
  remarks: string | null;
};
