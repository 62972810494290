import { apiStaticCanceled, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { DashboardActionTypes, DepartmentItem } from './@type';
import { DashboardFiltersMerge, DashboardSourceMerge } from './module';
import { API_DASHBOARD_REPORTS, DASHBOARD_TYPE } from 'services/dashboard-reports';

// get departments
function* getDepartments() {
  try {
    const { data }: { data: DepartmentItem[] } = yield call(apiStaticCanceled, {
      url: API_DASHBOARD_REPORTS.GET_DEPARTMENTS_FOR_PROFESSIONAL,
      _cancelID: `${API_DASHBOARD_REPORTS.API}_Dashboard`,
    });
    const user: { dashboardType: number; departmentID: number } = yield select(
      (state) => state.auth.user,
    );
    if (user.dashboardType === DASHBOARD_TYPE.PROFESSIONAL) {
      yield put(DashboardFiltersMerge({ departmentID: user.departmentID }));
    }
    yield put(DashboardSourceMerge({ departments: data }));
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

// EXPORTS
export const dashboardSagas = [
  takeLatest(DashboardActionTypes.DASHBOARD_SET_DEPARTMENTS, getDepartments),
];
