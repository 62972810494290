import * as yup from 'yup';
import { ServiceMediaUploads } from 'services/media-uploads';

const schema = yup.string().url();

interface ICheckHtmlValue {
  formData: any;
  initData?: any;
  field: string;
}

const checkHtmlValue = async ({ formData, initData, field }: ICheckHtmlValue) => {
  const deletedPathValue: string = (initData && initData[field]) || formData[field]?.initValue;
  if (deletedPathValue && schema.isValidSync(deletedPathValue)) {
    await ServiceMediaUploads.remove({ filePath: deletedPathValue });
  }

  let filePathValue = null;
  const uploadedValue =
    typeof formData[field] === 'string' ? formData[field] : formData[field]?.currentValue;

  if (uploadedValue) {
    const {
      data: { filePath },
    } = await ServiceMediaUploads.uploadHtml({
      value: uploadedValue,
      name: field,
    });

    filePathValue = filePath;
  }

  return filePathValue;
};

interface IUpdateHtmlField {
  formData: any;
  initData?: any;
  field: string | string[];
}

export const onUpdateHtmlField = async ({ formData, initData, field }: IUpdateHtmlField) => {
  const isArray = Array.isArray(field);
  if (isArray) {
    const updatedFields: { [key: string]: string | null } = {};
    await Promise.all(
      field.map(async (item) => {
        if (!formData.hasOwnProperty(item)) {
          return;
        }
        if (
          formData[item]?.currentValue &&
          formData[item]?.currentValue === formData[item]?.initValue
        ) {
          updatedFields[item] = formData[item]?.currentValue;
          return;
        }

        updatedFields[item] = await checkHtmlValue({ formData, initData, field: item });
      }),
    );

    return {
      ...formData,
      ...updatedFields,
    };
  } else {
    if (!formData.hasOwnProperty(field)) {
      return { ...formData };
    }

    const updatedFieldValue = await checkHtmlValue({ formData, initData, field });
    return { ...formData, [field]: updatedFieldValue };
  }
};

interface IUpdateHtmlFieldRequest<T> {
  submit: (value: T) => any;
  formData: any;
  initData?: any;
  field: string;
}

export const onUpdateHtmlFieldRequest = async <T,>({
  submit,
  formData,
  initData,
  field,
}: IUpdateHtmlFieldRequest<T>) => {
  const updatedFormData = await onUpdateHtmlField({ formData, initData, field });
  return await submit(updatedFormData);
};
interface IUpdateHtmlFormValues {
  initValues: any;
  fields: any;
  formValues: any;
}
export const updateHtmlFormValues = ({ initValues, fields, formValues }: IUpdateHtmlFormValues) => {
  const updatedFields: { [key: string]: { initValue: string; currentValue: string } } = {};
  fields?.forEach((field: string) => {
    updatedFields[field] = {
      initValue: initValues ? initValues[field] : '',
      currentValue: formValues[field],
    };
  });

  return updatedFields;
};
