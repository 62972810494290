import {
  iRPStepType2ActionTypes,
  iRPStepType2AddListItemAction,
  iRPStepType2AddNewAction,
  iRPStepType2ApproveAction,
  iRPStepType2DeleteAction,
  iRPStepType2DeleteListItemAction,
  iRPStepType2InitAction,
  iRPStepType2MergeAction,
  iRPStepType2MergeListAction,
  iRPStepType2MergeListDataAction,
  iRPStepType2RejectAction,
  iRPStepType2SaveAction,
} from '../@type';

export const RPStepType2Init = (): iRPStepType2InitAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_INIT };
};

export const RPStepType2Merge = (
  payload: iRPStepType2MergeAction['payload'],
): iRPStepType2MergeAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE, payload };
};

export const iRPStepType2MergeList = (
  payload: iRPStepType2MergeListAction['payload'],
): iRPStepType2MergeListAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST, payload };
};

export const iRPStepType2MergeListData = (
  payload: iRPStepType2MergeListDataAction['payload'],
): iRPStepType2MergeListDataAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST_DATA, payload };
};

export const iRPStepType2Save = (
  payload: iRPStepType2SaveAction['payload'],
): iRPStepType2SaveAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_SAVE, payload };
};

export const iRPStepType2AddNew = (
  payload: iRPStepType2AddNewAction['payload'],
): iRPStepType2AddNewAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_NEW, payload };
};

export const iRPStepType2AddListItem = (
  payload: iRPStepType2AddListItemAction['payload'],
): iRPStepType2AddListItemAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_LIST_ITEM, payload };
};

export const iRPStepType2DeleteListItem = (
  payload: iRPStepType2DeleteListItemAction['payload'],
): iRPStepType2DeleteListItemAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE_LIST_ITEM, payload };
};

export const iRPStepType2Delete = (
  payload: iRPStepType2DeleteAction['payload'],
): iRPStepType2DeleteAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE, payload };
};

export const iRPStepType2Approve = (
  payload: iRPStepType2ApproveAction['payload'],
): iRPStepType2ApproveAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_APPROVE, payload };
};

export const iRPStepType2Reject = (
  payload: iRPStepType2RejectAction['payload'],
): iRPStepType2RejectAction => {
  return { type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_REJECT, payload };
};
