import { iRPStepType3Data } from '../../@type';

export const defaultRPStepType3Data = (): iRPStepType3Data => ({
  id: 0,
  index: 0,
  isOpen: false,
  isEdit: false,
  isSubmit: false,

  data: null,
  user: {
    isLoading: false,
    isInit: false,
    isEdit: null,
    isDelete: null,
    isInValid: false,
    list: [],
  },
  significantChange: {
    isLoading: false,
    isInit: false,
    list: [],
  },
  materials: {
    isLoading: false,
    isInit: false,
    list: [],
  },
  resources: {
    isLoading: false,
    isInit: false,
    list: [],
  },
  userRatEntVot: {
    isLoading: false,
    isInit: false,
    isDelete: null,
    isEdit: null,
    isInValid: false,
    list: [],
  },
  seriesRating: {
    isLoading: false,
    isInit: false,
    isDelete: null,
    isEdit: null,
    openNewDialogForm: null,
    isInValid: false,
    list: [],
  },
  userSeriesVoting: {
    isLoading: false,
    isInit: false,
    isInValid: false,
    map: {},
    users: [],
  },

  isOpenPreliminary: false,
  isOpenMaterials: false,
  isOpenResources: false,
  isOpenRatingRational: false,
  isOpenSignificantChanges: false,
  isOpenRestrictions: false,
  isOpenOtherIssuers: false,
  isOpenMembersVotes: false,
  isOpenComplianceReporting: false,
});

export * from './main';
export * from './significant-change';
export * from './materials';
export * from './resources';
export * from './user';
export * from './user-rates-entity-voting';
export * from './series-rating';
export * from './user-series-voting';
