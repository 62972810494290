import { apiStatic, getRandomString, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { uniq } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import {
  API_RATING_PROCESS_STEP_COMMITTEE,
  tRatingProcessStepCommittee,
} from 'services/rating-process-step-committee';
import {
  iRPState,
  iRPStepType2Data,
  iRPStepType2DeleteAction,
  iRPStepType3ActionTypes,
  iRPStepType3AddNewAction,
  iRPStepType3ApproveAction,
  iRPStepType3InitCommitteeAction,
  iRPStepType3RejectAction,
  iRPStepType3SaveAction,
  iRPStepType3ValidateCommitteeMembersAction,
  iRPStepType3ValidateCommitteeMembersVotesAction,
} from '../../@type';
import {
  defaultRPStepType3Data,
  iRPStepType3UserMerge,
  RPMerge,
  RPStepType3DeleteListItem,
  RPStepType3Merge,
  RPStepType3MergeList,
  RPStepType3MergeListData,
} from '../../helpers';
import { handleError, stepApprove, stepReject } from '../main';
import { init as initSeriesRating } from './series-rating';
import { init as initUser } from './user';
import { init as initUserRatesEntityVoting } from './user-rates-entity-voting';
import { init as initUserSeriesVoting } from './user-series-voting';
import { onUpdateHtmlField } from 'utils/html-upload-helpers';

export const HTML_FIELDS_STEP_3 = [
  'remarks',
  'ratingDecisionRemarks',
  'restrictions',
  'discussionIssueses',
  'changeRatingMethodRemarks',
  'ratingChangeImpactRemarks',
  'materialsReservations',
];

const getMainData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE.GET_ALL_DYNAMIC, {
    params: {
      select: [
        'id',
        // main
        'committeeDate',
        'lastVotingDate',
        // 'committeeTypeID',
        'committeeMannerID',
        'examinedFinancialStatementDate',
        'isAdditionalPublications',
        'isAdditionalMaterialsLate',
        'remarks',
        // rating rational
        'ratingDecisionRemarks',
        // restrictions
        'restrictions',
        // other issues
        'discussionIssueses',
        // member voting - top
        'isMaterialsUpdated',
        'isMaterialsVolumeEnough',
        'isMaterialsReliable',
        'materialsReservations',
        // member voting - middle - left
        'longTermMidroogRatingID',
        'shortTermMidroogRatingID',
        'baselineCreditAssesmentRatingID',
        // member voting - middle - center
        'midroogOutlookID',
        'midroogRatingSymbolID',
        // member voting - middle - right
        'longTermCapitalApproved',
        'longTermCapital',
        'shortTermCapitalApproved',
        'shortTermCapital',
        // member voting - bottom - bank-ratings
        // 'bankTier1CapitalRatingID',
        // 'bankLawerTier2CapitalRatingID',
        // 'bankUpperTier2CapitalRatingID',
        // 'bankCocosRatingID',
        // member voting - bottom - insurance-ratings
        // 'insuranceFinancialStrengthRatingID',
        // 'insuranceTier2CapitalRatingID',
        // 'insuranceLawerTier2CapitalRatingID',
        // 'insuranceComplexTier2CapitalRatingID',
        // 'insuranceComplexTier3CapitalRatingID',
        // compliance reporting
        'isChangeRatingMethod',
        'changeRatingMethodRemarks',
        'isRatingChangeImpact',
        'ratingChangeImpactRemarks',
      ].join(),
      filter: `ratingProcessStepID==${id}`,
    },
  });
};

function* saveCommitData(Data: Partial<iRPStepType2Data>): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  data = yield call(saveMixins, data, mixins);
  const htmlFieldsData = yield onUpdateHtmlField({
    formData: Data,
    field: [...HTML_FIELDS_STEP_3, 'conflictOfInterestsInfo'],
  });

  const updatedData = data;
  [...HTML_FIELDS_STEP_3, 'conflictOfInterestsInfo'].forEach((item) => {
    updatedData[item] = htmlFieldsData[item];
  });

  yield call(
    apiStatic.patch,
    API_RATING_PROCESS_STEP_COMMITTEE.PATCH(data),
    prepareRequest(updatedData),
  );

  return updatedData;
}

export function* refresh(stepID: number) {
  try {
    const {
      data: { value },
    }: { data: { value: tRatingProcessStepCommittee[] } } = yield call(getMainData, stepID);
    // set data
    yield put(
      RPStepType3Merge({
        loading: false,
        init: true,
        list: value.map((item, index) => ({
          ...defaultRPStepType3Data(),
          id: item.id,
          index,
          isOpen: index === value.length - 1,
          isEdit: index === value.length - 1,
          data: parseResponseDate(
            {
              ...item,
              longTermCapital: item.longTermCapital ?? undefined,
              longTermCapitalApproved: item.longTermCapitalApproved ?? undefined,
              shortTermCapital: item.shortTermCapital ?? undefined,
              shortTermCapitalApproved: item.shortTermCapitalApproved ?? undefined,
            },
            ['committeeDate', 'lastVotingDate', 'examinedFinancialStatementDate'],
          ),
        })),
      }),
    );
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* getCommittees() {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  yield put(RPStepType3Merge({ loading: true }));
  yield call(refresh, Number(main?.ratingProcessSteps[currentStep].id));
  yield put(RPStepType3Merge({ loading: false }));
}

function* init() {
  const {
    stepType3: { init, loading },
  }: iRPState = yield select((state) => state.RP);
  if (!init && !loading) yield call(getCommittees);
}

function* save({ payload: { triggerClose, ...Data } }: iRPStepType3SaveAction) {
  yield put(RPMerge({ loading: true }));
  try {
    // @ts-ignore
    const data = yield call(saveCommitData, Data);
    yield put(notifyRequestResult(requestSuccess('')));
    if (!triggerClose) {
      yield put(RPStepType3MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    } else {
      yield put(RPMerge({ triggerClose }));
    }
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* addNew({ payload }: iRPStepType3AddNewAction) {
  const {
    // stepType3: { list },
    main,
  }: iRPState = yield select((state) => state.RP);
  yield put(RPMerge({ loading: true }));
  try {
    // @ts-ignore
    // const data = yield call(saveCommitData, payload);
    // @ts-ignore
    yield call(saveCommitData, payload);
    yield call(apiStatic.get, API_RATING_PROCESS_HELPER.ADD_NEW_COMMITTEE(Number(main?.id)));
    yield call(getCommittees);
    // yield put(RPStepType3MergeList({ id: data.id, isOpen: false, isEdit: false }));
    // yield put(RPStepType3MergeListData(data));
    // yield put(
    //   RPStepType3AddListItem({
    //     id: newReview.id,
    //     index: list.length,
    //     isOpen: true,
    //     isEdit: true,
    //     data: {
    //       ...newReview,
    //       reviewDate:
    //         newReview.reviewDate && isValid(new Date(newReview.reviewDate))
    //           ? new Date(newReview.reviewDate)
    //           : null,
    //     },
    //   }),
    // );
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* deleteItem({ payload: { id } }: iRPStepType2DeleteAction) {
  const {
    stepType3: { list },
  }: iRPState = yield select((state) => state.RP);
  try {
    yield put(RPMerge({ loading: true }));
    yield call(apiStatic.delete, API_RATING_PROCESS_STEP_COMMITTEE.DELETE({ id }));
    yield put(RPStepType3DeleteListItem({ id }));
    yield put(RPStepType3MergeList({ id: list[list.length - 2].id, isOpen: true, isEdit: true }));
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* reject({ payload }: iRPStepType3RejectAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveCommitData, payload);
    yield put(RPStepType3MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(stepReject);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* approve({ payload }: iRPStepType3ApproveAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveCommitData, payload);
    yield put(RPStepType3MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(stepApprove);
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* initCommittee({ payload }: iRPStepType3InitCommitteeAction) {
  yield put(RPMerge({ loading: true }));
  try {
    yield call(initUser, {
      payload,
      type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_INIT,
    });
    yield all([
      call(initUserRatesEntityVoting, {
        payload,
        type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_INIT,
      }),
      call(initSeriesRating, {
        payload,
        type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_INIT,
      }),
      call(initUserSeriesVoting, {
        payload,
        type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_INIT,
      }),
    ]);
  } catch (e) {
    yield call(handleError, e);
  }
  yield put(RPMerge({ loading: false }));
}

function* validateCommitteeMembers({
  payload: { committeeIndex, callback },
}: iRPStepType3ValidateCommitteeMembersAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          user: { list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(RPStepType3MergeList({ id, isSubmit: true }));
  const invalid = list.some(
    ({ data: { isConflictOfInterests, committeePositionID } }) =>
      !isConflictOfInterests || !committeePositionID,
  );
  const userIDs = list.map(({ data: { userID } }) => userID);
  const isDuplicates = uniq(userIDs).length !== userIDs.length;
  if (isDuplicates) {
    yield put(iRPStepType3UserMerge({ committeeIndex, isInValid: true }));
    yield put(notifyRequestResult('members-duplicate', 'error'));
  } else {
    yield put(iRPStepType3UserMerge({ committeeIndex, isInValid: userIDs.length === 0 }));
  }
  if (callback) {
    callback(userIDs.length > 0 && !invalid && !isDuplicates);
  }
}

function* validateCommitteeMembersVotes({
  payload: { committeeIndex, callback },
}: iRPStepType3ValidateCommitteeMembersVotesAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          userRatEntVot: { isInValid: isInValidUserRatEntVot, list: listUserRatEntVot },
          seriesRating: { isInValid: isInValidSeriesRating, list: listSeriesRating },
          // userSeriesVoting: { isInValid: isInValidUserSeriesVoting, map: mapUserSeriesVoting },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(RPStepType3MergeList({ id, isSubmit: true }));
  let valid = false;
  const validEmpty = !isInValidUserRatEntVot && !isInValidSeriesRating; //&& !isInValidUserSeriesVoting;
  if (validEmpty) {
    const invalidIndexUserRatEntVot = listUserRatEntVot.some(
      ({ data: { longTermMidroogRatingID, midroogOutlookID } }) =>
        !longTermMidroogRatingID || !midroogOutlookID,
    );
    if (!invalidIndexUserRatEntVot) {
      const invalidIndexSeriesRating = listSeriesRating.some(
        ({ data: { midroogRatingID, midroogOutlookID } }) => !midroogRatingID || !midroogOutlookID,
      );
      // if (!invalidIndexSeriesRating) {
      //   valid = !Object.keys(mapUserSeriesVoting).some((key) => {
      //     const { users } = mapUserSeriesVoting[Number(key)];
      //     return Object.keys(users).some((userID) => {
      //       const user = users[userID];
      //       if (user) {
      //         return !user?.data?.midroogRatingID || !user?.data?.midroogOutlookID;
      //       } else {
      //         return false;
      //       }
      //     });
      //   });
      // }
      valid = !invalidIndexSeriesRating;
    }
  }
  if (callback) callback(valid);
}

export const main = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_INIT, init),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SAVE, save),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_ADD_NEW, addNew),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_DELETE, deleteItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_REJECT, reject),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_APPROVE, approve),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_INIT_COMMITTEE, initCommittee),
  takeLatest(
    iRPStepType3ActionTypes.RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS,
    validateCommitteeMembers,
  ),
  takeLatest(
    iRPStepType3ActionTypes.RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS_VOTES,
    validateCommitteeMembersVotes,
  ),
];
