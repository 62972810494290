import { CommitteeManner } from 'services/committee-manner';
import { CommitteeType } from 'services/committee-type';
import { MaalotOutlook } from 'services/maalot-outlook';
import { MaalotRating } from 'services/maalot-rating';
import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { MoodysOutlook } from 'services/moodys-outlook';
import { MoodysRating } from 'services/moodys-rating';
import { tRatingProcessStep } from 'services/rating-process-step';

const API = 'RatingProcessStepCommittee';

export const API_RATING_PROCESS_STEP_COMMITTEE = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommittee, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommittee, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommittee, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommittee = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  longTermMidroogRatingID: number | null;
  longTermMidroogRating?: MidroogRating;
  shortTermMidroogRatingID: number | null;
  shortTermMidroogRating?: MidroogRating;
  baselineCreditAssesmentRatingID: number | null;
  baselineCreditAssesmentRating?: MidroogRating;
  bankLawerTier2CapitalRatingID: number | null;
  bankLawerTier2CapitalRating?: MidroogRating;
  bankUpperTier2CapitalRatingID: number | null;
  bankUpperTier2CapitalRating?: MidroogRating;
  bankTier1CapitalRatingID: number | null;
  bankTier1CapitalRating?: MidroogRating;
  bankCocosRatingID: number | null;
  bankCocosRating?: MidroogRating;
  insuranceFinancialStrengthRatingID: number | null;
  insuranceFinancialStrengthRating?: MidroogRating;
  insuranceTier2CapitalRatingID: number | null;
  insuranceTier2CapitalRating?: MidroogRating;
  insuranceLawerTier2CapitalRatingID: number | null;
  insuranceLawerTier2CapitalRating?: MidroogRating;
  insuranceComplexTier2CapitalRatingID: number | null;
  insuranceComplexTier2CapitalRating?: MidroogRating;
  insuranceComplexTier3CapitalRatingID: number | null;
  insuranceComplexTier3CapitalRating?: MidroogRating;
  midroogOutlookID: number | null;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbolID: number | null;
  midroogRatingSymbol?: MidroogRatingSymbol;
  moodysRatingID: number | null;
  moodysRating?: MoodysRating;
  moodysOutlookID: number | null;
  moodysOutlook?: MoodysOutlook;
  maalotRatingID: number | null;
  maalotRating?: MaalotRating;
  maalotOutlookID: number | null;
  maalotOutlook?: MaalotOutlook;
  provisional: boolean;
  ratingProcessStepID: number;
  ratingProcessStep?: tRatingProcessStep;
  committeeMannerID: number;
  committeeManner?: CommitteeManner;
  committeeTypeID: number;
  committeeType?: CommitteeType;
  lastVotingDate: string | Date | null;
  committeeDate: string | Date;
  restrictions: string | null;
  discussionIssueses: string | null;
  remarks: string | null;
  performanceMidroogRatingID: number | null;
  performanceMidroogRating?: MidroogRating;
  performanceMidroogOutlookID: number | null;
  performanceMidroogOutlook?: MidroogRating;
  examinedFinancialStatementDate: string | Date | null;
  isAdditionalPublications: boolean;
  isAdditionalMaterialsLate: boolean;
  additionalMaterialsLateRemarks: string | null;
  isMaterialsUpdated: boolean;
  isMaterialsVolumeEnough: boolean;
  isMaterialsReliable: boolean;
  materialsReservations: string | null;
  isChangeRatingMethod: boolean;
  changeRatingMethodRemarks: string | null;
  isRatingChangeImpact: boolean;
  ratingChangeImpactRemarks: string | null;
  shortTermCapital: number | null;
  longTermCapitalApproved: number | null;
  shortTermCapitalApproved: number | null;
  longTermCapital: number | null;
  ratingDecisionRemarks: string | null;
};
