import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS } from 'services/rating-process';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { API_RATING_PROCESS_STEP } from 'services/rating-process-step';
import { API_RATING_PROCESS_STEP_COMMITTEE } from 'services/rating-process-step-committee';
import { API_RATING_PROCESS_STEP_UPDATE } from 'services/rating-process-step-update';
import {
  iRPActionTypes,
  iRPBackToStepIndexAction,
  iRPInitAction,
  iRPMainData,
  iRPMainDataSteps,
  iRPSaveMainAction,
  iRPState,
  iRPStepRejectAction,
  iRPStepUpdateAction,
  iRPUpdateRatingProcess,
} from '../@type';
import {
  RPInit,
  RPMainPushStepLog,
  RPMainUpdateStep,
  RPMainUpdateStepLog,
  RPMerge,
  RPStepUpdate,
} from '../helpers';

export const RPStepType0Select = [
  'discussionDate',
  'requestMaterialsDate',
  'responseMaterialsDate',
  'methodologyID',
  'subMethodologyID',
  'headOfFieldUserID',
  'teamLeaderUserID',
  'analystUserID',
];

export function* calcCurrentStep() {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main) {
    if (main.isRPCompleted) {
      yield put(RPMerge({ currentStep: main.ratingProcessSteps.length - 1 }));
    } else {
      const index = main.ratingProcessSteps.findIndex(({ isStepEdit }) => isStepEdit);
      yield put(RPMerge({ currentStep: index !== -1 ? index : 0 }));
    }
  }
}

export function* handleError(e: any) {
  yield put(RPMerge({ loading: false }));
  yield put(notifyRequestResult(requestError(e), 'error'));
}

export function* init({ payload: { id, anyway } }: iRPInitAction) {
  const { init, loading }: iRPState = yield select((state) => state.RP);
  if ((!init && !loading) || anyway) {
    yield put(RPMerge({ loading: true }));
    try {
      // main
      const {
        data: { value },
      } = yield call(apiStatic.get, API_RATING_PROCESS.GET_ALL_DYNAMIC, {
        params: {
          select: [
            'id',
            'orderID',
            'ratedEntityID',
            'ratedEntity.name as ratedEntityName',
            'isCompleted',
            'ratingProcessTypeID',
            'ratingProcessType.title as ratingProcessTypeTitle',
            'headOfField.fullName as headOfFieldFullName',
            'ratingProcessStatusID',
            `ratingProcessSteps
              .OrderBy(o=>o.ratingProcessStepTypeID)
              .Select(s=>new {${[
                's.id',
                's.ratingProcessID',
                's.ratingProcessStepTypeID',
                's.ratingProcessStepType.title as ratingProcessStepTypeTitle',
                's.headOfFieldSignatureDate',
                's.clientDecision.title as clientDecisionTitle',
                's.isCompleted',
                's.clientUpdateDate',
              ].join()}}).toList() as ratingProcessSteps`.replace(/  +|\r\n|\n|\r/gm, ''),
            ...RPStepType0Select,
          ].join(),
          filter: `id==${id}`,
        },
      });
      const main = value[0];
      // logs
      const {
        data: { value: logData },
      } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_UPDATE.GET_ALL_DYNAMIC, {
        params: {
          select: [
            'id',
            'ratingProcessStepID',
            'headOfFieldSignatureDate',
            'ratingProcessStepUpdateStatusID',
            'updateDescription',
            // 'clientDecisionID',
            'isCompleted',
          ].join(),
          filter: main.ratingProcessSteps
            .map(({ id }: { id: number }) => `ratingProcessStepID == ${id}`)
            .join(' || '),
          orderBy: 'headOfFieldSignatureDate',
        },
      });
      const stepsLog = logData.reduce((acc: any, item: any) => {
        const { ratingProcessStepID } = item;
        if (!acc[ratingProcessStepID]) acc[ratingProcessStepID] = [];
        acc[ratingProcessStepID].push(item);
        return acc;
      }, {});
      // parse all
      let isEdit = false;
      main.isRPCompleted = [2, 3].includes(main.ratingProcessStatusID);
      main.ratingProcessSteps = [
        ...main.ratingProcessSteps.map((item: iRPMainDataSteps, index: number) => {
          const log = stepsLog[item.id] || [];
          const update = log.length && !log[0]?.isCompleted ? log.shift() : null;
          return {
            ...parseResponseDate<iRPMainDataSteps>(item, [
              'headOfFieldSignatureDate',
              'clientUpdateDate',
            ]),
            index,
            isStepEdit:
              !isEdit && !main.isRPCompleted && !item.isCompleted ? (isEdit = true) : false,
            log,
            update,
          };
        }),
        {
          index: main?.ratingProcessSteps?.length || 0,
          id: 1000,
          ratingProcessID: main.id,
          ratingProcessStepTypeID: 1000,
          ratingProcessStepTypeTitle: 'done',
          headOfFieldSignatureDate: main.completeRatingProcessDate || '',
          isCompleted: main.isRPCompleted,
          isStepEdit: !isEdit && !main.isRPCompleted,
          log: [],
          update: null,
        },
      ];
      yield put(RPMerge({ main }));
      yield call(calcCurrentStep);
      yield put(RPMerge({ loading: false, init: true }));
    } catch (e) {
      yield put(RPMerge({ loading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* RPSave(Data: Partial<iRPMainData>) {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  data = yield call(saveMixins, data, mixins);
  yield call(apiStatic.patch, API_RATING_PROCESS.PATCH(data), prepareRequest(data));
}

export function* stepApprove() {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  if (main) {
    try {
      yield call(apiStatic.post, API_RATING_PROCESS_HELPER.APPROVE_RATING_PROCESS_STEP, {
        ratingProcessID: main.id,
        ratingProcessStepID: main.ratingProcessSteps[currentStep].id,
        clientDecisionID: 1,
        headOfFieldSignatureDate: new Date(),
      });
      yield put(
        RPMainUpdateStep({
          stepIndex: currentStep,
          isCompleted: true,
          isStepEdit: false,
        }),
      );
      if (main.ratingProcessSteps[currentStep + 1]) {
        yield put(
          RPMainUpdateStep({
            stepIndex: currentStep + 1,
            isStepEdit: true,
          }),
        );
      }
      yield call(calcCurrentStep);
    } catch (e) {
      yield call(handleError, e);
    }
  }
}

export function* stepReject() {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  if (main) {
    try {
      yield call(apiStatic.post, API_RATING_PROCESS_HELPER.REJECT_RATING_PROCESS, {
        ratingProcessID: main.id,
        stepID: main.ratingProcessSteps[currentStep].id,
        completeRatingProcessDate: new Date(),
      });
      yield put(RPMerge({ triggerClose: 'close-and-refresh-grid' }));
    } catch (e) {
      yield call(handleError, e);
    }
  }
}

function* saveMain({ payload: { triggerClose, ...Data } }: iRPSaveMainAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main) {
    yield put(RPMerge({ loading: true }));
    try {
      yield call(RPSave, { id: main.id, ...Data });
      yield put(notifyRequestResult(requestSuccess('')));
      if (!triggerClose) {
        yield put(RPInit({ id: main.id, anyway: true }));
      } else {
        yield put(RPMerge({ triggerClose }));
        yield put(RPMerge({ loading: false }));
      }
    } catch (e) {
      yield call(handleError, e);
    }
  }
}

function* updateRatingProcess({ payload: { ratingProcessTypeID } }: iRPUpdateRatingProcess) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main) {
    yield put(RPMerge({ loading: true }));
    try {
      yield call(
        apiStatic.get,
        API_RATING_PROCESS_HELPER.UPDATE_RATING_PROCESS_TYPE(main.id, ratingProcessTypeID),
      );
      yield put(notifyRequestResult(requestSuccess('')));
      yield put(RPInit({ id: main.id, anyway: true }));
    } catch (e) {
      yield call(handleError, e);
    }
  }
}

function* stepType1Reject({ payload }: iRPStepRejectAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main) {
    yield put(RPMerge({ loading: true }));
    yield call(RPSave, { id: main.id, ...payload });
    yield call(stepReject);
  }
}

export function* stepType1Approve({ payload }: iRPStepRejectAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main) {
    yield put(RPMerge({ loading: true }));
    yield call(RPSave, { id: main.id, ...payload });
    yield call(apiStatic.get, API_RATING_PROCESS_HELPER.INIT_RATING_PROCESS(main.id));
    yield call(stepApprove);
    yield put(RPInit({ id: main.id, anyway: true }));
    yield put(RPMerge({ loading: false }));
  }
}

function* displaySeries() {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.ratingProcessSteps) {
    const committee = main.ratingProcessSteps.find(
      ({ ratingProcessStepTypeID }) => ratingProcessStepTypeID === 3,
    );
    if (committee?.id) {
      yield put(RPMerge({ loading: true }));
      try {
        const {
          data: { value },
        } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_COMMITTEE.GET_ALL_DYNAMIC, {
          params: {
            select: 'id',
            filter: `ratingProcessStepID==${committee.id}`,
          },
        });
        if (value.length) {
          yield put(RPMerge({ displaySeriesBy: value[value.length - 1]?.id || null }));
        }
      } catch (e) {
        yield call(handleError, e);
      }
    }
    yield put(RPMerge({ loading: false }));
  }
}

export function* stepUpdate({ payload: { callback, setLoading, ...rest } }: iRPStepUpdateAction) {
  const { currentStep, main }: iRPState = yield select((state) => state.RP);
  if (main?.ratingProcessSteps[currentStep]?.update?.id !== undefined) {
    try {
      if (setLoading) yield put(RPMerge({ loading: true }));
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_UPDATE.PATCH({
          // @ts-ignore
          id: Number(main.ratingProcessSteps[currentStep].update.id),
        }),
        rest,
      );

      if (rest.isCompleted) {
        yield put(
          // @ts-ignore
          RPMainPushStepLog({
            ...main.ratingProcessSteps[currentStep].update,
            ...rest,
            stepIndex: currentStep,
          }),
        );
        yield put(RPMainUpdateStep({ update: null, stepIndex: currentStep }));
      } else {
        yield put(RPMainUpdateStepLog({ ...rest, stepIndex: currentStep }));
      }
      if (callback) callback();
    } catch (e) {
      yield call(handleError, e);
    }
    if (setLoading) yield put(RPMerge({ loading: false }));
  }
}

export function* stepUpdateIgnore() {
  yield put(RPMerge({ loading: true }));
  yield put(
    RPStepUpdate({
      headOfFieldSignatureDate: new Date(),
      isCompleted: true,
    }),
  );
  yield call(stepApprove);
  yield put(RPMerge({ loading: false }));
}

export function* setIsEditRPStep({
  step,
  newCurrentIndex,
}: {
  step: iRPMainDataSteps;
  newCurrentIndex: number;
}): any {
  if (step.isCompleted) {
    yield call(apiStatic.patch, API_RATING_PROCESS_STEP.PATCH({ id: step.id }), {
      id: step.id,
      isCompleted: false,
    });
  }
  yield put(
    RPMainUpdateStep({
      stepIndex: step.index,
      isCompleted: false,
      isStepEdit: step.index === newCurrentIndex,
    }),
  );
}

export function* backToStepIndex({ payload: { stepIndex } }: iRPBackToStepIndexAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.ratingProcessSteps) {
    yield put(RPMerge({ loading: true }));
    try {
      for (let i = main.ratingProcessSteps.length - 1; i > stepIndex - 1; i--) {
        yield call(setIsEditRPStep, {
          step: main.ratingProcessSteps[i],
          newCurrentIndex: stepIndex,
        });
      }
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
    yield call(calcCurrentStep);
    yield put(RPMerge({ loading: false }));
  }
}

export default [
  takeLatest(iRPActionTypes.RP_INIT, init),
  takeLatest(iRPActionTypes.RP_SAVE_MAIN, saveMain),
  takeLatest(iRPActionTypes.RP_UPDATE_RATING_PROCESS, updateRatingProcess),
  takeLatest(iRPActionTypes.RP_STEP_REJECT, stepType1Reject),
  takeLatest(iRPActionTypes.RP_STEP_APPROVE, stepType1Approve),
  takeLatest(iRPActionTypes.RP_MAIN_DISPLAY_SERIES, displaySeries),
  takeLatest(iRPActionTypes.RP_STEP_UPDATE, stepUpdate),
  takeLatest(iRPActionTypes.RP_STEP_UPDATE_IGNORE, stepUpdateIgnore),
  takeLatest(iRPActionTypes.RP_BACK_TO_STEP_INDEX, backToStepIndex),
];
