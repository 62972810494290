import { format, isValid, max, min, toDate as fnsToDate } from 'date-fns';
import { TIME_FORMAT_DATE } from '../configs/const';

export type DateValue = Date | string | number | null | undefined;

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};
export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};

export const dateFormat = (
  value: DateValue,
  _format: Parameters<typeof format>[1] = TIME_FORMAT_DATE,
  options?: Parameters<typeof format>[2],
) => {
  if (isDateLike(value)) {
    return format(convertToDate(value), _format, options);
  }
  return '--';
};
