import { apiStatic, requestError } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  API_RATING_PROCESS_STEP_COMMITTEE_RESOURCE,
  tRatingProcessStepCommitteeResource,
} from 'services/rating-process-step-committee-resource';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3RSInitAction,
  iRPStepType3RSSaveItemAction,
} from '../../@type';
import {
  iRPStepType3RSMerge,
  iRPStepType3RSMergeItem,
  iRPStepType3RSMergeItemData,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_RESOURCE.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: ['id', 'title', 'ratingProcessStepCommitteeID', 'remarks', 'isInclude'].join(),
    },
  });
};

function* init({ payload: { committeeIndex } }: iRPStepType3RSInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          resources: { isInit, isLoading },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit && !isLoading) {
    yield put(iRPStepType3RSMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: tRatingProcessStepCommitteeResource[] } } = yield call(getData, id);
      // set data
      yield put(
        iRPStepType3RSMerge({
          committeeIndex,
          isLoading: false,
          isInit: true,
          list: value.map((item, index) => ({
            index,
            isLoading: false,
            data: item,
          })),
        }),
      );
    } catch (e) {
      yield put(iRPStepType3RSMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* saveItem({
  payload: { committeeIndex, itemIndex, ...newData },
}: iRPStepType3RSSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          resources: {
            list: {
              [itemIndex]: { data: prevData },
            },
          },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3RSMergeItem({ committeeIndex, itemIndex, isLoading: true }));
  try {
    let { data, mixins } = parseMixins(newData);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    yield call(
      apiStatic.patch,
      API_RATING_PROCESS_STEP_COMMITTEE_RESOURCE.PATCH(prevData),
      prepareRequest(data),
    );
    yield put(iRPStepType3RSMergeItemData({ committeeIndex, itemIndex, ...data }));
  } catch (e) {
    yield put(iRPStepType3RSMergeItemData({ committeeIndex, itemIndex, ...prevData }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  yield put(iRPStepType3RSMergeItem({ committeeIndex, itemIndex, isLoading: false }));
}

export const RS = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_INIT, init),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_SAVE_ITEM, saveItem),
];
