import { useI18n, useSource } from 'AurionCR/components';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS_STEP_COMMITTEE } from 'services/rating-process-step-committee';
import { iRatingProcessStepPreview, iStepDetail } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { StepDetailsList } from './step-details-list';
import { useData } from './useData';
import { IUserDetailsOption, makeUserDetailsField } from 'components/hooks/use-user-detail-field';
import { API_USER_DETAIL } from 'services/user-detail';
import { Tooltip } from '@mui/material';

export const pathStepCommittee = (id: number) => {
  return `
      ${API_RATING_PROCESS_STEP_COMMITTEE.GET_ALL_DYNAMIC}
      ?select=${[
        'committeeDate',
        'lastVotingDate',
        'committeeType.title as committeeTypeTitle',
        'string.join(",",ratingProcessStepCommitteeUsers.Select(s=>s.user.fullName)) as members',
        'longTermMidroogRating.title as rating',
        'midroogOutlook.title as outlook',
        'longTermCapitalApproved',
        'longTermCapital',
        'ratingDecisionRemarks',
      ].join()}
    &filter=ratingProcessStepID==${id}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepCommittee = (
  stepData: any,
  t: (label: string) => string,
  userDetailsOptions: IUserDetailsOption[],
): iStepDetail[] | null => {
  if (stepData) {
    const updatedMembersName = makeUserDetailsField(stepData?.members, userDetailsOptions, t, true);

    const committeeDate = stepData?.committeeDate
      ? new Date(stepData?.committeeDate)
      : stepData?.committeeDate;
    const lastVotingDate = stepData?.lastVotingDate ? new Date(stepData?.lastVotingDate) : null;
    return [
      {
        label: t('committee-date'),
        value: committeeDate && isValid(committeeDate) ? format(committeeDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('last-voting-date'),
        value:
          lastVotingDate && isValid(lastVotingDate) ? format(lastVotingDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('committee-type'),
        value: stepData?.committeeTypeTitle || '',
      },
      {
        label: t('members'),
        value: updatedMembersName,
      },
      {
        label: t('rating'),
        value: stepData?.rating || '',
      },
      {
        label: t('outlook'),
        value: stepData?.outlook || '',
      },
      {
        label: t('long-term-capital-approved'),
        value:
          stepData?.longTermCapitalApproved !== undefined ? stepData?.longTermCapitalApproved : '',
      },
      {
        label: t('long-term-capital'),
        value: stepData?.longTermCapital !== undefined ? stepData?.longTermCapital : '',
      },
      {
        label: t('rating-decisions'),
        value: stepData?.ratingDecisionRemarks || '',
        type: 'html',
      },
    ];
  } else {
    return null;
  }
};

export const StepCommittee = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const [path] = useState(pathStepCommittee(data.id));
  const userDetailSource = useSource<IUserDetailsOption>(
    `${API_USER_DETAIL.GET_ALL_DYNAMIC}?Select=fullName,isActive`,
  );
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<iStepDetail[] | null>(
    () => prepareDataStepCommittee(stepData, t, userDetailSource.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData, labels],
  );
  const title = useMemo(() => data?.ratingProcessStepTypeTitle || '', [data]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<StepDetailsList data={prepareData} loading={loading} title={title} />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton data={data} date={date} title={title} />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <StepDetailsList data={prepareData} loading={loading} />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton data={data} date={date} title={title} disabled={true} />
    </div>
  );
});
