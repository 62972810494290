import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { sortBy } from 'lodash';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING } from 'services/rating-process-step-committee-user-series-voting';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3UserSeriesVoting,
  iRPStepType3UserSeriesVotingImportItemsAction,
  iRPStepType3UserSeriesVotingInitAction,
  iRPStepType3UserSeriesVotingItemUser,
  iRPStepType3UserSeriesVotingQuickSetAction,
  iRPStepType3UserSeriesVotingRefreshAction,
  iRPStepType3UserSeriesVotingSaveItemAction,
} from '../../@type';
import {
  iRPStepType3UserSeriesVotingItemUserDataMerge,
  iRPStepType3UserSeriesVotingItemUserMerge,
  iRPStepType3UserSeriesVotingMerge,
  iRPStepType3UserSeriesVotingRefresh,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: [
        'id',
        'ratingProcessStepCommitteeID',
        'seriesID',
        'series.title as seriesTitle',
        'userID',
        'user.fullName as fullName',

        'midroogRatingID',
        'midroogOutlookID',
      ].join(),
    },
  });
};

export function* refresh({
  payload: { committeeIndex },
}: iRPStepType3UserSeriesVotingRefreshAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          userSeriesVoting: { isLoading },
          user: { list: usersList },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isLoading) {
    yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: iRPStepType3UserSeriesVotingItemUser['data'][] } } = yield call(
        getData,
        id,
      );
      // rank matrix
      const rankMap: { [key: string]: number } = usersList.reduce((acc, item, index) => {
        if (item.data.userID) {
          // @ts-ignore
          acc[String(item.data.userID)] = index;
        }
        return acc;
      }, {});
      // parse and create matrix
      let result = value.reduce(
        (acc: iRPStepType3UserSeriesVoting & { committeeIndex: number }, item) => {
          if (!acc.map[item.seriesID]) {
            acc.map[item.seriesID] = {
              seriesID: item.seriesID,
              seriesTitle: item.seriesTitle,
              users: {},
            };
          }
          return acc;
        },
        {
          isLoading: false,
          isInit: true,
          committeeIndex,
          map: {},
          users: [],
          isInValid: value.length === 0,
        },
      );
      const seriesIDs = Object.keys(result.map).map((key) => Number(key));
      // fill matrix
      value.forEach((item) => {
        if (item.userID) {
          // create blank
          if (result.map[seriesIDs[0]].users[item.userID] === undefined) {
            result.users.push({
              userID: item.userID,
              fullName: item.fullName,
              rank:
                rankMap[String(item.userID)] !== undefined ? rankMap[String(item.userID)] : 10000,
            });
            seriesIDs.forEach((key) => {
              result.map[key].users[item.userID as string] = null;
            });
          }
          // fill place
          result.map[item.seriesID].users[item.userID] = { isLoading: false, data: item };
        }
      });
      result.users = sortBy(result.users, ['rank']);
      yield put(iRPStepType3UserSeriesVotingMerge(result));
    } catch (e) {
      yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

export function* init({ payload: { committeeIndex } }: iRPStepType3UserSeriesVotingInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          userSeriesVoting: { isInit },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit) {
    yield call(refresh, {
      payload: { committeeIndex },
      type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_REFRESH,
    });
  }
}

function* saveItem({
  payload: { committeeIndex, userID, seriesID, ...formData },
}: iRPStepType3UserSeriesVotingSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          userSeriesVoting: { map },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const current = map[seriesID]?.users[userID];
  if (current) {
    yield put(
      iRPStepType3UserSeriesVotingItemUserMerge({
        committeeIndex,
        seriesID,
        userID,
        isLoading: true,
      }),
    );
    try {
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING.PATCH({ id: current.data.id }),
        prepareRequest(formData),
      );
      yield put(
        iRPStepType3UserSeriesVotingItemUserDataMerge({
          committeeIndex,
          seriesID,
          userID,
          ...formData,
        }),
      );
      yield put(notifyRequestResult(requestSuccess('')));
    } catch (e) {
      yield put(
        iRPStepType3UserSeriesVotingItemUserDataMerge({
          committeeIndex,
          ...current.data,
          seriesID,
          userID,
        }),
      );
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
    yield put(
      iRPStepType3UserSeriesVotingItemUserMerge({
        committeeIndex,
        seriesID,
        userID,
        isLoading: false,
      }),
    );
  }
}

function* quickSet({
  payload: { committeeIndex, userID, midroogRatingID, midroogOutlookID },
}: iRPStepType3UserSeriesVotingQuickSetAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: { id },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: true }));
  try {
    if (userID) {
      yield call(
        apiStatic.get,
        API_RATING_PROCESS_HELPER.APPlY_USER_VOTING_FOR_COMMITTEE_SERIES(
          id,
          userID,
          midroogRatingID,
          midroogOutlookID,
        ),
      );
    } else {
      yield call(
        apiStatic.get,
        API_RATING_PROCESS_HELPER.APPlY_ALL_USERS_VOTING_FOR_COMMITTEE_SERIES(
          id,
          midroogRatingID,
          midroogOutlookID,
        ),
      );
    }
    yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestSuccess('')));
    yield put(iRPStepType3UserSeriesVotingRefresh({ committeeIndex }));
  } catch (e) {
    yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* importItems({
  payload: { committeeIndex },
}: iRPStepType3UserSeriesVotingImportItemsAction) {
  const {
    main,
    stepType3: {
      list: {
        [committeeIndex]: { id },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: true }));
  try {
    yield call(
      apiStatic.get,
      API_RATING_PROCESS_HELPER.IMPORT_USERS_FOR_COMMITTEE_SERIES_VOTING(
        Number(main?.ratedEntityID),
        id,
      ),
    );
    yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestSuccess('')));
    yield put(iRPStepType3UserSeriesVotingRefresh({ committeeIndex }));
  } catch (e) {
    yield put(iRPStepType3UserSeriesVotingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

export const UserSeriesVoting = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_INIT, init),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_REFRESH, refresh),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_SAVE_ITEM, saveItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_QUICK_SET, quickSet),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_IMPORT_ITEMS, importItems),
];
