import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill={'#0028a0'}>
      <path opacity="0.3" d="M16 11H11V16H16V11Z" />
      <path opacity="0.3" d="M9 11H4V16H9V11Z" />
      <path opacity="0.3" d="M16 4H4V9H16V4Z" />
      <path d="M20 6V20H6V22H20C21.1 22 22 21.1 22 20V6H20Z" />
      <path d="M18 16V4C18 2.9 17.1 2 16 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H16C17.1 18 18 17.1 18 16ZM4 4H16V9H4V4ZM9 16H4V11H9V16ZM11 11H16V16H11V11Z" />
    </g>
  </svg>,
  'OfficeAdministration',
);
