import { Loading, useI18n } from 'AurionCR/components';
import clsx from 'clsx';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS } from 'services/rating-process';
import { iRatingProcessStepPreview } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { useData } from './useData';
import { StepDonePreviewForm } from './step-done-preview-form';
import { Tooltip } from '@mui/material';

export interface prepareData {
  date: string;
  documents: {
    title: string;
    filePath: string;
  }[];
  monitorVotingSheetFilePath: string;
  monitorFinancialAnalysisFilePath: string;
  monitorOtherFilePath: string;
  monitorDescription: string;
  monitorUser: string;
  monitorUserID: string;
  monitorSignatureDate: string;
  monitorApproval1DocumentationStatusTitle: string;
  monitorApproval2DocumentationStatusTitle: string;
  monitorApproval3DocumentationStatusTitle: string;
  monitorApproval4DocumentationStatusTitle: string;
  monitorApproval1DocumentationStatusID: string;
  monitorApproval2DocumentationStatusID: string;
  monitorApproval3DocumentationStatusID: string;
  monitorApproval4DocumentationStatusID: string;
}

export const pathStepDone = (id: number) => {
  return `
      ${API_RATING_PROCESS.GET_ALL_DYNAMIC}
      ?select=${[
        'completeRatingProcessDate',
        'ratingProcessDocumentations.Select(s=>new {s.title,s.filePath}) as documents',
        'monitorVotingSheetFilePath',
        'monitorFinancialAnalysisFilePath',
        'monitorOtherFilePath',
        'monitorDescription',
        'monitorUser.fullName as monitorUser',
        'monitorUserID',
        'monitorSignatureDate',
        'monitorApproval1DocumentationStatus.title as monitorApproval1DocumentationStatusTitle',
        'monitorApproval2DocumentationStatus.title as monitorApproval2DocumentationStatusTitle',
        'monitorApproval3DocumentationStatus.title as monitorApproval3DocumentationStatusTitle',
        'monitorApproval4DocumentationStatus.title as monitorApproval4DocumentationStatusTitle',
        'monitorApproval1DocumentationStatusID',
        'monitorApproval2DocumentationStatusID',
        'monitorApproval3DocumentationStatusID',
        'monitorApproval4DocumentationStatusID',
      ].join()}
    &filter=id==${id}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepDone = (stepData: any): prepareData | null => {
  const completeRatingProcessDate = stepData?.completeRatingProcessDate
    ? new Date(stepData?.completeRatingProcessDate)
    : null;
  const completeMonitorSignatureDate = stepData?.monitorSignatureDate
    ? new Date(stepData?.monitorSignatureDate)
    : null;

  if (stepData) {
    return {
      date:
        completeRatingProcessDate && isValid(completeRatingProcessDate)
          ? format(completeRatingProcessDate, 'dd/MM/yyyy')
          : '',
      documents: stepData.documents,
      monitorVotingSheetFilePath: stepData.monitorVotingSheetFilePath,
      monitorFinancialAnalysisFilePath: stepData.monitorFinancialAnalysisFilePath,
      monitorOtherFilePath: stepData.monitorOtherFilePath,
      monitorDescription: stepData.monitorDescription,
      monitorUser: stepData.monitorUser,
      monitorUserID: stepData.monitorUserID,
      monitorSignatureDate:
        completeMonitorSignatureDate && isValid(completeMonitorSignatureDate)
          ? format(completeMonitorSignatureDate, 'dd/MM/yyyy HH:mm')
          : '',
      monitorApproval1DocumentationStatusTitle: stepData.monitorApproval1DocumentationStatusTitle,
      monitorApproval2DocumentationStatusTitle: stepData.monitorApproval2DocumentationStatusTitle,
      monitorApproval3DocumentationStatusTitle: stepData.monitorApproval3DocumentationStatusTitle,
      monitorApproval4DocumentationStatusTitle: stepData.monitorApproval4DocumentationStatusTitle,
      monitorApproval1DocumentationStatusID: stepData.monitorApproval1DocumentationStatusID,
      monitorApproval2DocumentationStatusID: stepData.monitorApproval2DocumentationStatusID,
      monitorApproval3DocumentationStatusID: stepData.monitorApproval3DocumentationStatusID,
      monitorApproval4DocumentationStatusID: stepData.monitorApproval4DocumentationStatusID,
    };
  } else {
    return null;
  }
};

export const Details = memo(
  ({
    data,
    loading,
    title,
    isPreview,
  }: {
    data: prepareData | null;
    loading: boolean;
    title?: string;
    isPreview?: boolean;
  }) => {
    const { t } = useI18n();
    //render
    const monitorApprovalDocumentationStatus =
      data?.monitorApproval1DocumentationStatusID ||
      data?.monitorApproval2DocumentationStatusID ||
      data?.monitorApproval3DocumentationStatusID ||
      data?.monitorApproval4DocumentationStatusID;
    return (
      <div className={clsx(style.stepDetailsList, loading && style.loading)}>
        {title && <div className={style.title}>{t(title)}</div>}
        {data && (
          <table width={'100%'}>
            <tbody>
              <>
                {(data?.monitorSignatureDate && monitorApprovalDocumentationStatus) || isPreview ? (
                  <tr>
                    <tr>
                      <th>{t('monitor-voting-sheet-file')}</th>
                      <td>
                        {data.monitorVotingSheetFilePath && (
                          <>
                            [
                            <a
                              href={data.monitorVotingSheetFilePath}
                              target={'_blank'}
                              rel="noopener noreferrer"
                            >
                              {t('download')}
                            </a>
                            ]
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('monitor-financial-analysis-file')}</th>
                      <td>
                        {data.monitorFinancialAnalysisFilePath && (
                          <>
                            [
                            <a
                              href={data.monitorFinancialAnalysisFilePath}
                              target={'_blank'}
                              rel="noopener noreferrer"
                            >
                              {t('download')}
                            </a>
                            ]
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('monitor-other-file-path')}</th>
                      <td>
                        {data.monitorOtherFilePath && (
                          <>
                            [
                            <a
                              href={data.monitorOtherFilePath}
                              target={'_blank'}
                              rel="noopener noreferrer"
                            >
                              {t('download')}
                            </a>
                            ]
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('monitor-description')}</th>
                      <td>{data?.monitorDescription}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-user')}</th>
                      <td>{data?.monitorUser}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-signature-date')}</th>
                      <td>{data?.monitorSignatureDate}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-approval1-documentation-status-title')}</th>
                      <td>{data?.monitorApproval1DocumentationStatusTitle}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-approval2-documentation-status-title')}</th>
                      <td>{data?.monitorApproval2DocumentationStatusTitle}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-approval3-documentation-status-title')}</th>
                      <td>{data?.monitorApproval3DocumentationStatusTitle}</td>
                    </tr>
                    <tr>
                      <th>{t('monitor-approval4-documentation-status-title')}</th>
                      <td>{data?.monitorApproval4DocumentationStatusTitle}</td>
                    </tr>
                  </tr>
                ) : (
                  <StepDonePreviewForm data={data} loading={loading} />
                )}
              </>
              <tr>
                <tr>
                  <th>{t('complete-rating-process-date')}</th>
                  <td>{data?.date}</td>
                </tr>
                <tr>
                  <th>{t('documents')}</th>
                  <td>
                    <ul className={style.noTire}>
                      {data.documents.map(({ title, filePath }, index) => (
                        <li key={index}>
                          [
                          <a href={filePath} target={'_blank'} rel="noopener noreferrer">
                            {title || t('download')}
                          </a>
                          ]
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tr>
            </tbody>
          </table>
        )}
        {loading && <Loading size={30} />}
      </div>
    );
  },
);

export const StepDone = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const [path] = useState(pathStepDone(data.ratingProcessID));
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<prepareData | null>(
    () => prepareDataStepDone(stepData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const title = useMemo(() => t('done'), [labels]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<Details data={prepareData} loading={loading} title={title} isPreview />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton
            data={data}
            date={date}
            title={title}
            titleDate={'complete-rating-process-date'}
          />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <Details data={prepareData} loading={loading} title={title} isPreview />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton
        data={data}
        date={date}
        title={title}
        titleDate={'complete-rating-process-date'}
        disabled={true}
      />
    </div>
  );
});
