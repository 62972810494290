import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { setLanguage } from 'AurionCR/store/modules/language';
import style from 'components/language/language-switcher/index.module.scss';
import clsx from 'clsx';
import { useI18n } from 'AurionCR/components';
import { useSourcesLanguages } from 'components/hooks';
import { LANGUAGE_LOCAL_STORE_NAME } from '../index.ts';

interface Props {
  theme?: 'dark' | 'light';
}

export const LanguageSwitcher: React.FC<Props> = memo(({ theme = 'dark' }) => {
  const dispatch = useDispatch();
  const { t } = useI18n();
  // state
  const languageID = useSelector((state: any) => state.language.languageID);
  const user = useSelector((state: any) => state.auth.user);
  const { data: languages } = useSourcesLanguages();
  // render
  return (
    <div className={clsx(style.wrap, style[theme])}>
      {languages.map((item) => (
        <Button
          key={item.id}
          variant={item.id === languageID ? 'contained' : 'text'}
          size={'small'}
          color={item.id === languageID ? 'secondary' : 'primary'}
          disabled={item.id === languageID}
          onClick={() => {
            localStorage.setItem(LANGUAGE_LOCAL_STORE_NAME(user?.userID), item.id);
            dispatch(setLanguage(item.id, item, item.direction));
          }}
          classes={{
            root: style.button,
          }}
        >
          {t(item.title)}
        </Button>
      ))}
    </div>
  );
});

export default LanguageSwitcher;
