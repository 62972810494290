import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  LOGIN: createTypedRoute<{ redirect?: string } | void>('/login'),
  HOME: createTypedRoute<{ redirect?: string } | void>('/'),

  // ISSUERS
  ISSUERS: {
    ISSUERS: createTypedRoute<{ redirect?: string } | void>('/issuers/issuers'),
    CONTACTS: createTypedRoute<{ redirect?: string } | void>('/issuers/contacts'),
    CONTROLLING_GROUPS: createTypedRoute<{ redirect?: string } | void>(
      '/issuers/controlling-groups',
    ),
    SECTORS: createTypedRoute<{ redirect?: string } | void>('/issuers/sectors'),
    DEPARTMENTS: createTypedRoute<{ redirect?: string } | void>('/issuers/departments'),
    COMPANIES_FOR_MAGNA_REPORT: createTypedRoute<{ redirect?: string } | void>(
      '/issuers/companies-for-magna-report',
    ),
  },

  // METHODOLOGY
  METHODOLOGY: {
    METHODOLOGIES: createTypedRoute<{ redirect?: string } | void>('/methodologies/methodologies'),
    PROFESSIONAL_DOCUMENTS: createTypedRoute<{ redirect?: string } | void>(
      '/methodologies/professional-documents',
    ),
  },

  // PROFESSIONAL PROCESSES
  PROFESSIONAL_PROCESSES: {
    RATING_PROCESS: createTypedRoute<{ redirect?: string } | void>(
      '/professional-processes/rating-processes',
    ),
    QUARTERLY_MONITORING: createTypedRoute<{ redirect?: string } | void>(
      '/professional-processes/quarterly-monitoring',
    ),
    REPORTS: createTypedRoute<{ redirect?: string } | void>('/professional-processes/reports'),
    SERIES_LIST: createTypedRoute<{ redirect?: string } | void>(
      '/professional-processes/series-list',
    ),
    SERIES_RATING_LIST: createTypedRoute<{ redirect?: string } | void>(
      '/professional-processes/series-rating-list',
    ),
  },

  //SETTINGS
  SETTINGS: {
    SYSTEM_USERS: createTypedRoute<{ redirect?: string } | void>('/settings/system-users'),
    SYSTEM_SETTINGS: createTypedRoute<{ redirect?: string } | void>('/settings/system-settings'),
    PERMISSIONS: createTypedRoute<{ redirect?: string } | void>('/settings/permissions'),
    LABELS: createTypedRoute<{ redirect?: string } | void>('/settings/labels'),
    LANGUAGES: createTypedRoute<{ redirect?: string } | void>('/settings/languages'),
    RATING_SCALE_PARAMETERS: {
      MIDROOG_RATING: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/midroog-rating',
      ),
      MIDROOG_OUTLOOK: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/midroog-outlook',
      ),
      MIDROOG_RATING_SYMBOL: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/midroog-rating-symbol',
      ),
      MOODYS_OUTLOOK: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/moodys-outlook',
      ),
      MOODYS_RATING: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/moodys-rating',
      ),
      MAALOT_OUTLOOK: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/maalot-outlook',
      ),
      MAALOT_RATING: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-scale-parameters/maalot-rating',
      ),
    },
    QUARTERLY_MONITORING_PARAMETERS: {
      DIFFERENT_THAN_FORECAST: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/different-than-forecast',
      ),
      FINANCIAL_CONVENTION_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/financial-convention-type',
      ),
      CHECK_MODELS_ASSUMPTION: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/check-models-assumption',
      ),
      LINKAGE_BASIS_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/linkage-basis-type',
      ),
      SECURITY_PUDS_FULL: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/security-pads-full',
      ),
      SIGNED_OFFICER_STATEMENT: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/signed-officer-statement',
      ),
      FINANCIAL_STATEMENT_TREND_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/quarterly-monitoring-parameters/financial-statement-trend-type',
      ),
    },
    RATING_PROCESS_PARAMETERS: {
      RATING_PROCESS_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-type',
      ),
      RATING_PROCESS_TYPE_GROUP: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-type-group',
      ),
      RATING_PROCESS_TYPE_OPTION: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-type-option',
      ),
      RATING_PROCESS_STATUS: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-status',
      ),
      RATING_PROCESS_STEP_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-step-type',
      ),
      PUBLISH_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/publish-type',
      ),
      RATING_AND_TRACKING_MESSAGE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-and-tracking-message',
      ),
      RATING_INITIATIVE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-initiative',
      ),
      RATING_STATUS: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-status',
      ),
      RATING_STOPPED_REASON: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-stopped-reason',
      ),
      RATING_SUBJECT: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-subject',
      ),
      RATING_TOOL: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-tool',
      ),
      BASE_COMMITTEE_MATERIAL: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/base-committee-material',
      ),
      BASE_COMMITTEE_RESOURCE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/base-committee-resource',
      ),
      BASE_COMMITTEE_SIGNIFICANT_CHANGE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/base-committee-significant-change',
      ),
      BASE_REPORT_CHECKUP: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/base-report-checkup',
      ),
      CLIENT_DECISION: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/client-decision',
      ),
      COMMITTEE_MANNER: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/committee-manner',
      ),
      COMMITTEE_POSITION: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/committee-position',
      ),
      COMMITTEE_SIGNIFICANT_CHANGE_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/committee-significant-change-type',
      ),
      COMMITTEE_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/committee-type',
      ),
      MONITOR_DECISION_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/monitor-decision-type',
      ),
      MONITOR_STATUS: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/monitor-status',
      ),
      PUBLICATION_APPROVAL_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/publication-approval-type',
      ),
      REPORT_CHECKUP_TYPE: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/report-checkup-type',
      ),
      REPORT_PUBLICATION_OPTION: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/report-publication-option',
      ),
      RATING_PROCESS_STEP_UPDATE_STATUS: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/rating-process-step-update-status',
      ),
      DOCUMENTATION_STATUSES: createTypedRoute<{ redirect?: string } | void>(
        '/settings/rating-process-parameters/documentation-statuses',
      ),
    },
  },

  //RATING PERFORMANCE
  RATING_PERFORMANCE: {
    IMPORT_AND_EXPORT_FILES: createTypedRoute<{ redirect?: string } | void>(
      '/rating-performance/rating-performance-import-and-export-files',
    ),
    UPDATE_RATING_PROCESS_FOR_RATING_PERFORMANCE: createTypedRoute<{ redirect?: string } | void>(
      '/rating-performance/update-rating-processes-for-rating-performance',
    ),
    UPDATE_SERIES_FOR_RATING_PERFORMANCE: createTypedRoute<{
      redirect?: string;
    } | void>('/rating-performance/update-series-for-rating-performance'),
    REPORT: createTypedRoute<{
      redirect?: string;
    } | void>('/rating-performance/rating-performance-report'),
    HISTORY: createTypedRoute<{
      redirect?: string;
    } | void>('/rating-performance/rating-performance-history'),
  },

  //OFFICE ADMINISTRATION
  OFFICE_ADMINISTRATION: {
    EMPLOYEES: createTypedRoute<{
      redirect?: string;
    } | void>('/office-administration/employees'),
    TRAININGS: createTypedRoute<{
      redirect?: string;
    } | void>('/office-administration/trainings'),
    WEBSITE_PUBLICATION: createTypedRoute<{
      redirect?: string;
    } | void>('/office-administration/website-publication'),
  },

  //SYSTEM REPORTS
  SYSTEM_REPORTS: {
    CREDIT_REVIEW_REPORTS: createTypedRoute<{
      redirect?: string;
    } | void>('/system-reports/credit-review-reports'),
    SERIES_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/series-reports',
    ),
    ORDER_REPORTS: createTypedRoute<{ redirect?: string } | void>('/system-reports/order-reports'),
    COMMERCIAL_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/commercial-reports',
    ),
    MONITORING_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/monitoring-reports',
    ),
    TRAINING_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/training-reports',
    ),
    PRELIMINARY_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/preliminary-reports',
    ),
    COMMITTEE_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/committee-reports',
    ),
    RAISING_CAPITAL_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/raising-capital-reports',
    ),
    RATING_PROCESS_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/rating-process-reports',
    ),
    SERIES_FOR_MAGNA: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/series-for-magna',
    ),
    ISSUE_LIST_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/issuer-list-reports',
    ),
    COMPANY_OFFICER_REPORTS: createTypedRoute<{ redirect?: string } | void>(
      '/system-reports/compliance-officer-reports',
    ),
  },

  // COMMERCIAL PROCESSES
  COMMERCIAL_PROCESSES: {
    ORDERS: createTypedRoute<{ redirect?: string } | void>('/commercial-processes/orders'),
    PRODUCTS: createTypedRoute<{ redirect?: string } | void>('/commercial-processes/products'),
    RATING_PROCESS_ORDER: createTypedRoute<{ redirect?: string } | void>(
      '/commercial-processes/rating-process-order',
    ),
    RAISED_CAPITAL_ORDER: createTypedRoute<{ redirect?: string } | void>(
      '/commercial-processes/raised-capital-orders',
    ),
    PAYMENTS: createTypedRoute<{ redirect?: string } | void>('/commercial-processes/payments'),
    CLIENT_INITIAL_RATINGS: createTypedRoute<{ redirect?: string } | void>(
      '/commercial-processes/client-initiate-ratings',
    ),
    CLIENT_PAY_RATINGS: createTypedRoute<{ redirect?: string } | void>(
      '/commercial-processes/client-pay-ratings',
    ),
  },
};
