import { tRatingProcessStep } from 'services/rating-process-step';
import { tReport } from 'services/report';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessStepReport';

export const API_RATING_PROCESS_STEP_REPORT = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepReport, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepReport, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepReport, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepReport = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  ratingProcessStepID: number | null;
  ratingProcessStep?: tRatingProcessStep;
  reportID: number | null;
  report?: tReport;
  teamLeaderUserID: string | null;
  teamLeader?: tUserDetail;
  analystUserID: string | null;
  analyst?: tUserDetail;
  teamLeaderSignatureDate: string | Date;
  analystSignatureDate: string | Date;
  draftRequestDate: string | Date;
  draftResposeDate: string | Date;
  issuerContactName: string | null;
  isIssuerContactSeniorOfficer: boolean;
  issuerContactCertificate: string | null;
  shortTermCapital: number | null;
  shortTermCapitalRaised: number | null;
  longTermCapital: number | null;
  longTermCapitalRaised: number | null;
  remarks: string;
  isClientNotResponse: boolean;
};
