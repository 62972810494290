const _API = 'Label';

export const API_LABELS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({ id }: Pick<Label, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Label, 'id'>) => `${_API}/Delete/${id}`,
  DEFAULT_REQUEST: `${_API}/GetAllDynamic?Select=labelKey,title`,
};

export class Label {
  id!: number;
  title: string = '';
  labelKey: string = '';
  longDescription: string = '';
  languageID!: string;
  labelSourceID!: string;
  isActive: boolean = true;
}
export interface ILabel extends Label {
  language: Language;
}

const API = 'Language';

export const API_LANGUAGES = {
  API: API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<Language, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Language, 'id'>) => `${API}/Delete/${id}`,
  DEFAULT_REQUEST: `${API}/GetAllDynamic?Select=id,title,direction&Filter=isActive==true&OrderBy=rank`,
};

export enum DIRECTIONS {
  LTR = 1,
  RTL = 2,
}

export class Language {
  id!: string;
  title = '';
  direction: DIRECTIONS = DIRECTIONS.LTR;
  culture = '';
  icon = '';
  currency = '';
  isActive: boolean = false;
  rank = 0;
}
