import { tRatingProcessStepReport } from 'services/rating-process-step-report';
import { iRPState } from '../@type';

export interface iRPStepType4Data {
  id: number;
  isOpen: boolean;
  isEdit: boolean;
  data: tRatingProcessStepReport & { triggerRefreshMixins?: string };
}

export interface iRPStepType4 {
  init: boolean;
  loading: boolean;
  isEditReportIndex: number | null;
  list: iRPStepType4Data[];
}

export enum iRPStepType4ActionTypes {
  RP_STEP_TYPE_4_MERGE = 'RP_STEP_TYPE_4_MERGE',
  RP_STEP_TYPE_4_MERGE_LIST = 'RP_STEP_TYPE_4_MERGE_LIST',
  RP_STEP_TYPE_4_MERGE_LIST_DATA = 'RP_STEP_TYPE_4_MERGE_LIST_DATA',
  RP_STEP_TYPE_4_ADD_LIST_ITEM = 'RP_STEP_TYPE_4_ADD_LIST_ITEM',
  RP_STEP_TYPE_4_DELETE_LIST_ITEM = 'RP_STEP_TYPE_4_DELETE_LIST_ITEM',
  // saga
  RP_STEP_TYPE_4_INIT = 'RP_STEP_TYPE_4_INIT',
  RP_STEP_TYPE_4_SAVE = 'RP_STEP_TYPE_4_SAVE',
  RP_STEP_TYPE_4_ADD_NEW = 'RP_STEP_TYPE_4_ADD_NEW',
  RP_STEP_TYPE_4_DELETE = 'RP_STEP_TYPE_4_DELETE',
  RP_STEP_TYPE_4_APPROVE = 'RP_STEP_TYPE_4_APPROVE',
  RP_STEP_TYPE_4_REJECT = 'RP_STEP_TYPE_4_REJECT',
  RP_STEP_TYPE_4_SAVE_REPORT = 'RP_STEP_TYPE_4_SAVE_REPORT',
  RP_STEP_TYPE_4_ADDITION_COMMITTEE = 'RP_STEP_TYPE_4_ADDITION_COMMITTEE',
}

export interface iRPStepType4InitAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_INIT;
}

export interface iRPStepType4MergeAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE;
  payload: Partial<iRPStepType4>;
}

export interface iRPStepType4MergeListAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST;
  payload: Partial<iRPStepType4Data> & { reportIndex: number };
}

export interface iRPStepType4MergeListDataAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST_DATA;
  payload: Partial<iRPStepType4Data['data']> & { reportIndex: number };
}

export interface iRPStepType4SaveAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE;
  payload: Partial<iRPStepType4Data['data']> & {
    reportIndex: number;
    triggerClose?: iRPState['triggerClose'];
    clientUpdateDate?: string | Date;
  };
}

export interface iRPStepType4AddNewAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_NEW;
  payload: Partial<iRPStepType4Data['data']> & { clientUpdateDate?: string | Date };
}

export interface iRPStepType4AddListItemAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_LIST_ITEM;
  payload: iRPStepType4Data;
}

export interface iRPStepType4DeleteListItemAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE_LIST_ITEM;
  payload: { reportIndex: number };
}

export interface iRPStepType4DeleteAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE;
  payload: { reportIndex: number };
}

export interface iRPStepType4ApproveAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_APPROVE;
  payload: Partial<iRPStepType4Data['data']> & {
    reportIndex: number;
    clientUpdateDate?: string | Date;
  };
}

export interface iRPStepType4RejectAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_REJECT;
  payload: Partial<iRPStepType4Data['data']> & {
    reportIndex: number;
    clientUpdateDate?: string | Date;
  };
}

export interface iRPStepType4SaveReportAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_SAVE_REPORT;
  payload: Partial<iRPStepType4Data['data']['report']>;
}

export interface iRPStepType4AdditionCommitteeAction {
  type: iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADDITION_COMMITTEE;
  payload: Partial<iRPStepType4Data['data']> & { clientUpdateDate?: string | Date };
}
