const API = 'UserPosition';

export const API_USER_POSITION = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  PATCH: ({ id }: Pick<tUserPosition, 'id'>) => `${API}/Patch/${id}`,
  POST: `${API}/Create`,
  DELETE: `${API}/Delete`,
};

export enum eUserPositionPosition {
  ceo = 1,
  chairman,
  headOfField,
  marketingDirector,
  marketing,
  chiefCreditOfficer,
  complianceOfficer,
  cfo,
  cto,
  teamLeader,
  director,
  externalDirector,
  secretary,
  iterenalAuditory,
  analyst,
  guest,
  analystLeader,
  accountsManager,
  analystTechnicalLeader,
  researchAssistant,
  analystReaserchAssistant,
}

export enum ePERMISSIONS {
  isAllowToAddRatingProcess = 'isAllowToAddRatingProcess',
  isAllowToViewSettings = 'isAllowToViewSettings',
  isAllowToViewOrders = 'isAllowToViewOrders',
  isAllowToViewRatingProcesses = 'isAllowToViewRatingProcesses',
  isAllowToViewMethodologies = 'isAllowToViewMethodologies',
  isAllowToViewReports = 'isAllowToViewReports',
  isAllowToViewSeries = 'isAllowToViewSeries',
  isAllowToViewTrainings = 'isAllowToViewTrainings',
  isAllowToViewEmployees = 'isAllowToViewEmployees',
  // isAllowToViewSuppliers = 'isAllowToViewSuppliers',
  // isAllowToViewTasks = 'isAllowToViewTasks',
  isAllowToViewDepartments = 'isAllowToViewDepartments',
  isAllowToViewContacts = 'isAllowToViewContacts',
  isAllowToViewControllingGroups = 'isAllowToViewControllingGroups',
  isAllowToViewSectors = 'isAllowToViewSectors',
  isAllowToViewProfessionalDocuments = 'isAllowToViewProfessionalDocuments',
  // isAllowToViewMethodologyProtocols = 'isAllowToViewMethodologyProtocols',
  // isAllowToViewMethodologyMemorandums = 'isAllowToViewMethodologyMemorandums',
  isAllowToViewProducts = 'isAllowToViewProducts',
  isAllowToViewPaymentTransactions = 'isAllowToViewPaymentTransactions',
  isAllowToViewComercialProcesses = 'isAllowToViewComercialProcesses',
  isAllowToViewOfficeAdministrations = 'isAllowToViewOfficeAdministrations',
  // isAllowToViewBoardMeetings = 'isAllowToViewBoardMeetings',
  // isAllowToViewInventories = 'isAllowToViewInventories',
  isAllowToViewClients = 'isAllowToViewClients',
  isAllowToViewWebSitePublication = 'isAllowToViewWebSitePublication',
  isAllowToViewQuarterlyMonitoring = 'isAllowToViewQuarterlyMonitoring',
  isAllowToEditMethodologies = 'isAllowToEditMethodologies',
  isAllowToDeleteRatingProcess = 'isAllowToDeleteRatingProcess',
  isAllowToViewComplianceReports = 'isAllowToViewComplianceReports',
  isAllowToViewCompanyReports = 'isAllowToViewCompanyReports',
  // isAllowToViewSupportTicket = 'isAllowToViewSupportTicket',
  // isAllowToEditSupportTicket = 'isAllowToEditSupportTicket',
  // isAllowToViewSupportTicketBilling = 'isAllowToViewSupportTicketBilling',
  isAllowToEditReports = 'isAllowToEditReports',
  isAllowToEditRatingPerformance = 'isAllowToEditRatingPerformance',
  isAllowToViewRatingPerformance = 'isAllowToViewRatingPerformance',
  isAllowToEditCompany = 'isAllowToEditCompany',
  isAllowToEditSeries = 'isAllowToEditSeries',
  isAllowToEditSeriesDetail = 'isAllowToEditSeriesDetail',
  isAllowToViewCommitteeReports = 'isAllowToViewCommitteeReports',
  isAllowToViewSeriesReports = 'isAllowToViewSeriesReports',
  isAllowToViewPreliminaryRatingReports = 'isAllowToViewPreliminaryRatingReports',
  isAllowToViewRaisingCapitalReports = 'isAllowToViewRaisingCapitalReports',
  isAllowToViewTrainingReports = 'isAllowToViewTrainingReports',
  isAllowToViewMonitoringReports = 'isAllowToViewMonitoringReports',
  isAllowToViewFinanceReports = 'isAllowToViewFinanceReports',
  isAllowToEditCompanyCommercial = 'isAllowToEditCompanyCommercial',
  isAllowToEditRatingProcess = 'isAllowToEditRatingProcess',
  isAllowToViewRatingPerformanceDashboard = 'isAllowToViewRatingPerformanceDashboard',
}

export type tUserPermissions = { [key in keyof typeof ePERMISSIONS]?: boolean };

export type tUserPosition = {
  id: string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  title?: string;
  titleEng?: string;
  position?: eUserPositionPosition;
  votingOrder?: number;
} & tUserPermissions;
