import { HTMLAttributes } from 'react';
import { Box } from '@mui/material';
import InactiveLabel from '../../inactive-label';
import { IUserDetailsItem } from '../../types';

const renderUserDetailsOption = (
  props: HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  item: IUserDetailsItem,
) => (
  <Box
    {...props}
    key={item.id}
    sx={{
      display: 'flex',
      justifyContent: 'space-between !important',
      width: '100%',
      paddingRight: '2.8rem',
    }}
  >
    <Box>
      {item.title || item.fullName} {item.userPositionTitle ? `(${item.userPositionTitle})` : ''}
    </Box>
    {!item.isActive && <InactiveLabel />}
  </Box>
);

export default renderUserDetailsOption;
