import { IUserDetailsItem } from '../types';

type IProps = IUserDetailsItem & {
  t: (key: string) => any;
};
const getUserDetailsOptionLabel = (item: IProps) => {
  return [item.title, item.userPositionTitle && `(${item.userPositionTitle})`, !item.isActive && `[${item.t('inactive')}]`]
    .filter(Boolean)
    .join(' ');
};

export default getUserDetailsOptionLabel;
