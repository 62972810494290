import { FC, ReactNode } from 'react';
import { LayoutDesktopNav } from './navigations';

import style from './index.module.scss';
import { useSelector } from 'react-redux';

const FIELD_IMAGE_PATH = 'picture';

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  // states
  const user = useSelector((state) => (state as any).auth.user);
  // render
  return (
    <div className={style.wrap}>
      {user && <LayoutDesktopNav user={user} fieldImagePath={FIELD_IMAGE_PATH} />}
      <main className={style.content}>{children}</main>
    </div>
  );
};

export default Layout;

export * from './content-wrapper';
export * from './form-wrapper';
export * from './navigations';
