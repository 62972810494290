import { apiStatic } from 'AurionCR/components';
import { call } from 'redux-saga/effects';
import { API_RATING_PROCESS_STEP_PUBLICATION } from 'services/rating-process-step-publication';
import { API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP } from 'services/rating-process-step-publication-checkup';
import { API_USER_DETAIL } from 'services/user-detail';
import { iRPMainDataSteps } from '../../@type';
import { dateHelper, getHtml, linkHelper, tTranslate } from './index';

export function* stepType5(t: tTranslate, step: iRPMainDataSteps): any {
  const {
    data: {
      // eslint-disable-next-line no-useless-computed-key
      value: { [0]: data },
    },
  }: { data: { value: any[] } } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_PUBLICATION.GET_ALL_DYNAMIC,
    {
      params: {
        select: [
          'id',
          // cols - top
          'teamLeaderUserID',
          'headOfFieldUserID',
          'analystUserID',
          // cols - bottom
          'teamLeaderSignatureDate',
          'headOfFieldSignatureDate',
          'analystSignatureDate',
          // footer
          'reportPublicationOption.title as reportPublicationOptionTitle',

          'magnaPublicationDate',
          'publicationApprovalType.title as publicationApprovalTypeTitle',
          'report.filePath as reportFilePath',
          'confirmationFilePath',

          'remarks',
        ].join(),
        take: 1,
        filter: `ratingProcessStepID==${step.id}`,
      },
    },
  );
  const {
    data: { value: checkups },
  }: { data: { value: any[] } } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_PUBLICATION_CHECKUP.GET_ALL_DYNAMIC,
    {
      params: {
        select: [
          'title',
          'reportCheckupType.title as reportCheckupTypeTitle',
          'headOfFieldApproval',
          'analystApproval',
          'remarks',
        ].join(),
        orderBy: 'rank asc',
        filter: `ratingProcessStepPublicationID==${data?.id}`,
      },
    },
  );
  const {
    data: { value: usersList },
  } = yield call(apiStatic.get, `${API_USER_DETAIL.GET_ALL_DYNAMIC}?Select=userID,fullName`);
  const users = usersList.reduce((acc: any, item: any) => {
    acc[item.userID] = item.fullName;
    return acc;
  }, {});

  // parse data
  data.teamLeaderUserFullName = users[data.teamLeaderUserID] || '';
  data.headOfFieldUserFullName = users[data.headOfFieldUserID] || '';
  data.analystUserFullName = users[data.analystUserID] || '';

  data.teamLeaderSignatureDate = dateHelper(data.teamLeaderSignatureDate, 'yyyy.MM.dd hh:mm');
  data.headOfFieldSignatureDate = dateHelper(data.headOfFieldSignatureDate, 'yyyy.MM.dd hh:mm');
  data.analystSignatureDate = dateHelper(data.analystSignatureDate, 'yyyy.MM.dd hh:mm');
  data.magnaPublicationDate = dateHelper(data.magnaPublicationDate, 'yyyy.MM.dd hh:mm');

  data.remarks = yield call(getHtml, data.remarks);

  data.reportFilePath = linkHelper(data.reportFilePath, t('download'));
  data.confirmationFilePath = linkHelper(data.confirmationFilePath, t('download'));

  for (let i = 0; i < checkups.length; i++) {
    checkups[i].remarks = yield call(getHtml, checkups[i].remarks);
  }
  // create html
  return `<div style='page-break-before: always;'>
    <h1 class='pageTitle'>${step.ratingProcessStepTypeTitle}</h1>
    <table class='tableStandard tableTdCenter full-width'>
      <thead>
        <tr>
          <th>${t('analyst')}</th>
          <th>${t('head-of-field')}</th>
          <th>${t('additional-analyst')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${data.teamLeaderUserFullName}</td>
          <td>${data.headOfFieldUserFullName}</td>
          <td>${data.analystUserFullName}</td>    
        </tr>
        <tr>
          <td>${data.teamLeaderSignatureDate}</td>
          <td>${data.headOfFieldSignatureDate}</td>
          <td>${data.analystSignatureDate}</td>    
        </tr>
      </tbody>
    </table>
    <table class='tablePreview'>
      <tbody>
        <tr>
          <th>${t('publication')}</th>
          <td>${data.reportPublicationOptionTitle || ''}</td>
        </tr>
        <tr>
          <th>${t('final-report-publication-date')}</th>
          <td>${data.magnaPublicationDate}</td>
        </tr>
        <tr>
          <th>${t('publication-approval-type')}</th>
          <td>${data.publicationApprovalTypeTitle || ''}</td>
        </tr>
        <tr>
          <th>${t('confirmation-file')}</th>
          <td>${data.confirmationFilePath}</td>
        </tr>
        <tr>
          <th>${t('report')}</th>
          <td>${data.reportFilePath}</td>
        </tr>
        <tr>
          <th>${t('remarks')}</th>
          <td class='html'>${data.remarks}</td>
        </tr>
      </tbody>
    </table>
    <h3>${t('checkups')}</h3>
    <ol>
    ${checkups
      .map(
        (item: any) => `<li>
          <table class='tablePreview'>
            <tbody>
              <tr>
                <th>${t('title')}</th>
                <td>${item.title || ''}</td>    
              </tr>
              <tr>
                <th>${t('report-checkup-type')}</th>
                <td>${item.reportCheckupTypeTitle || ''}</td>    
              </tr>
              <tr>
                <th>${t('head-of-field-approval')}</th>
                <td>${item.headOfFieldApproval ? '&#10003;' : '&#9744;'}</td>    
              </tr>
              <tr>
                <th>${t('analyst-approval')}</th>
                <td>${item.analystApproval ? '&#10003;' : '&#9744;'}</td>    
              </tr>
              <tr>
                <th>${t('remarks')}</th>
                <td class='html'>${item.remarks}</td>    
              </tr>
            </tbody>
          </table>
        </li>`,
      )
      .join('')}
    </ol>
  </div>`;
}
