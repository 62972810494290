import { RatingSubject } from 'services/rating-subject';
import { RatingStatus } from 'services/rating-status';
import { PublishType } from 'services/publish-type/models';
import { tCompanyForMagnaReport } from 'services/company-for-magna-report';
import { RatingTool } from 'services/rating-tool';
import { RatingInitiative } from 'services/rating-initiative';
import { Company } from 'services/company';
const API = 'Series';

export const API_SERIES = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tSeries, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tSeries, 'id'>) => `${API}/Delete/${id}`,
};

export type tSeries = {
  id: string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  ratingToolID?: number;
  publishTypeID?: number;
  ratingStatusID?: number;
  ratedEntityID?: number;
  ratingInitiativeID?: number;
  ratingSubjectID?: number;
  companyForMagnaReportID?: number;
  title?: string;
  titleEng?: string;
  titleExchange?: string;
  perfomanceTitleHeb?: string;
  perfomanceTitleEng?: string;
  symbol?: string;
  issueDate?: string | Date;
  reportInMagna?: boolean;
  expirationDate?: string | Date;
  remarks?: string;
  immediateRepaymentGrounds?: string;
  ratingSubjectDetails?: string;
  ratingInitiativeDetails?: string;
  ratingFirstPublicationDate?: string | Date;
  ratingLastUpdateDate?: string | Date;
  isDraft?: boolean;
  includeInRatingPerformance?: boolean;
  includeInMagnaReportForPublic?: boolean;
  includeInMagnaReportForPrivate?: boolean;
  companyForMagnaReport?: tCompanyForMagnaReport;
  ratingTool?: RatingTool;
  publishType?: PublishType;
  ratingStatus?: RatingStatus;
  ratingInitiative?: RatingInitiative;
  ratingSubject?: RatingSubject;
  ratedEntity?: Company;
  clientInitiateRating?: [];
  clientPayRating?: [];
  performanceTitleEng?: string;
  instrumentDescription?: string;
  instrumentSeniority?: string;
  instrumentType?: string;
};
