import { apiStatic } from 'AurionCR/components';
import { pathStepReport } from 'components/rating-process-preview/step-report';
import { call } from 'redux-saga/effects';
import { iRPMainDataSteps } from '../../@type';
import { dateHelper, getHtml, linkHelper, tTranslate } from './index';

export function* stepType4(t: tTranslate, step: iRPMainDataSteps): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, pathStepReport(step.id));
  // parse data
  for (let i = 0; i < value.length; i++) {
    value[i].draftReport = linkHelper(value[i].draftReport, t('download'));

    value[i].reportDate = dateHelper(value[i].reportDate);
    value[i].draftRequestDate = dateHelper(value[i].draftRequestDate);
    value[i].draftResposeDate = dateHelper(value[i].draftResposeDate);
    value[i].teamLeaderSignatureDate = dateHelper(value[i].teamLeaderSignatureDate);

    value[i].remarks = yield call(getHtml, value[i].remarks);
  }
  // create html
  return `<div style='page-break-before: always;'>
    <h1 class='pageTitle'>${step.ratingProcessStepTypeTitle}</h1>
    <ol>
      ${value
        .map(
          (item: any) => `<li>
          <table class='tablePreview'>
            <tbody>
              <tr>
                <th>${t('draft-report')}</th>
                <td>${item.draftReport || ''}</td>    
              </tr>
              <tr>
                <th>${t('report-date')}</th>
                <td>${item.reportDate}</td>    
              </tr>
              <tr>
                <th>${t('draft-request-date')}</th>
                <td>${item.draftRequestDate}</td>    
              </tr>
              <tr>
                <th>${t('draft-response-date')}</th>
                <td>${item.draftResposeDate}</td>    
              </tr>
              <tr>
                <th>${t('team-leader')}</th>
                <td>${item.teamLeader || ''}</td>    
              </tr>
              <tr>
                <th>${t('team-leader-signature-date')}</th>
                <td>${item.teamLeaderSignatureDate}</td>    
              </tr>
              <tr>
                <th>${t('issuer-contact-name')}</th>
                <td>${item.issuerContactName || ''}</td>    
              </tr>
              <tr>
                <th>${t('issuer-contact-senior-officer')}</th>
                <td>${item.isIssuerContactSeniorOfficer ? 'yes' : 'no'}</td>    
              </tr>
              <tr>
                <th>${t('the-client-decision')}</th>
                <td>${item.clientDecision || ''}</td>    
              </tr>
              <tr>
                <th>${t('remarks')}</th>
                <td class='html'>${item.remarks}</td>    
              </tr>
            </tbody>
          </table>
        </li>`,
        )
        .join('')}
    </ol>
  </div>`;
}
