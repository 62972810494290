import { PublicationApprovalType } from 'services/publication-approval-type';
import { tRatingProcessStep } from 'services/rating-process-step';
import { tReport } from 'services/report';
import { ReportPublicationOption } from 'services/report-publication-option';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessStepPublication';

export const API_RATING_PROCESS_STEP_PUBLICATION = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepPublication, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: { id: number }) => `${API}/Patch/${id}`,
  DELETE: ({ id }: { id: number }) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepPublication = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;
  ratingProcessStepID: number;
  ratingProcessStep?: tRatingProcessStep;
  reportID: number | null;
  report?: tReport | null;
  headOfFieldUserID: string | null;
  headOfFieldUser?: tUserDetail | null;
  teamLeaderUserID: string | null;
  teamLeaderUser?: tUserDetail | null;
  analystUserID: string | null;
  analystUser?: tUserDetail | null;
  reportPublicationOptionID: number | null;
  reportPublicationOption?: ReportPublicationOption | null;
  publicationApprovalTypeID: number | null;
  publicationApprovalType?: PublicationApprovalType | null;
  headOfFieldSignatureDate: string | Date | null;
  analystSignatureDate: string | Date | null;
  teamLeaderSignatureDate: string | Date | null;
  magnaPublicationDate: string | Date | null;
  websitePublicationDate: string | Date | null;
  confirmationFilePath: string | null;
  remarks: string | null;
};
