import { Loading, useI18n } from 'AurionCR/components';
import clsx from 'clsx';
import { HtmlPreview } from 'components/html-preview';
import { memo } from 'react';
import { iStepDetail } from './@type';
import style from './index.module.scss';

export const StepDetailsList = memo(
  ({ data, loading, title }: { data: iStepDetail[] | null; loading: boolean; title?: string }) => {
    const { t } = useI18n();
    //render
    return (
      <div className={clsx(style.stepDetailsList, loading && style.loading)}>
        {title && <div className={style.title}>{title}</div>}
        {data && (
          <table>
            <tbody>
              {data.map(({ label, value, type }) => (
                <tr key={label}>
                  <th>{label}</th>
                  <td>
                    {value ? (
                      <>
                        {type === 'link' ? (
                          <>
                            [
                            <a href={value} target={'_blank'} rel="noopener noreferrer">
                              {t('download')}
                            </a>
                            ]
                          </>
                        ) : type === 'html' ? (
                          <HtmlPreview value={value} config={{ height: '15rem' }} />
                        ) : (
                          value
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {loading && <Loading size={30} />}
      </div>
    );
  },
);
