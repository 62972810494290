import { getQuarter, isValid } from 'date-fns';
import { QuarterlyMonitoring } from 'services/quarterly-monitoring';

export const getQuarterTitle = (date: Date) => `Q${getQuarter(date)} ${date.getFullYear()}`;

export const QMFormDefaultValues = (): Partial<QuarterlyMonitoring> => ({
  id: undefined,
  // header
  ratedEntityMark: undefined,
  analystUserID: undefined,

  // form
  financialStatementTrendTypeID: undefined,
  financialStatementTrendTypeMark: false,
  financialStatementTrendTypeDescription: undefined,

  financialConventionTypeID: undefined,
  financialConventionTypeMark: false,
  financialConventionTypeDescription: undefined,

  immediateRepayment: '',
  immediateRepaymentMark: false,
  immediateRepaymentDescription: undefined,

  bdiLastCheckResult: '',
  bdiLastCheckResultMark: false,
  bdiLastCheckResultDescription: undefined,

  bdiCheckDate: undefined,
  bdiCheckDateMark: false,
  bdiCheckDateDescription: undefined,

  bondRepayment: 0,
  bondRepaymentMark: false,
  bondRepaymentDescription: undefined,

  cashBalance: 0,
  cashBalanceMark: false,
  cashBalanceDescription: undefined,

  isCPARemark: undefined,
  isCPARemarkMark: false,
  isCPARemarkDescription: undefined,

  isImmediateRepaymentGroundsExamined: undefined,
  isImmediateRepaymentGroundsExaminedMark: false,
  isImmediateRepaymentGroundsExaminedDescription: undefined,

  isSeriesExaminedForQuarterlyMonitoring: undefined,
  isSeriesExaminedForQuarterlyMonitoringMark: false,
  isSeriesExaminedForQuarterlyMonitoringDescription: undefined,

  isNeedRatingReview: undefined,
  isNeedRatingReviewMark: false,
  isNeedRatingReviewDescription: undefined,

  remarks: undefined,

  trusteeName: '',
  trusteeNameMark: false,
  trusteeNameDescription: undefined,

  trusteeComments: '',
  trusteeCommentsMark: false,
  trusteeCommentsDescription: undefined,

  substantiveIssues: '',
  substantiveIssuesMark: false,
  substantiveIssuesDescription: undefined,

  lastFinancialReport: undefined,
  lastFinancialReportMark: false,
  lastFinancialReportDescription: undefined,

  signedOfficerStatementID: undefined,
  signedOfficerStatementMark: false,
  signedOfficerStatementDescription: undefined,

  securityPadsFullID: undefined,
  securityPadsFullMark: false,
  securityPadsFullDescription: undefined,

  differentThanForecastID: undefined,
  differentThanForecastMark: false,
  differentThanForecastDescription: undefined,

  checkModelsAssumptionID: undefined,
  checkModelsAssumptionMark: false,
  checkModelsAssumptionDescription: undefined,
});
export const QMPrepareData = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === null) data[key] = undefined;
    if (['bdiCheckDate', 'lastFinancialReport'].includes(key)) {
      data[key] = isValid(new Date(data[key])) ? new Date(data[key]) : undefined;
    }
  });
  return data;
};
