import {
  iRPStepType3ActionTypes,
  iRPStepType3UserSeriesVotingImportItemsAction,
  iRPStepType3UserSeriesVotingInitAction,
  iRPStepType3UserSeriesVotingItemMergeAction,
  iRPStepType3UserSeriesVotingItemUserDataMergeAction,
  iRPStepType3UserSeriesVotingItemUserMergeAction,
  iRPStepType3UserSeriesVotingMergeAction,
  iRPStepType3UserSeriesVotingQuickSetAction,
  iRPStepType3UserSeriesVotingRefreshAction,
  iRPStepType3UserSeriesVotingSaveItemAction,
} from '../../@type';

export const iRPStepType3UserSeriesVotingMerge = (
  payload: iRPStepType3UserSeriesVotingMergeAction['payload'],
): iRPStepType3UserSeriesVotingMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_MERGE, payload };
};
export const iRPStepType3UserSeriesVotingItemMerge = (
  payload: iRPStepType3UserSeriesVotingItemMergeAction['payload'],
): iRPStepType3UserSeriesVotingItemMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_MERGE, payload };
};
export const iRPStepType3UserSeriesVotingItemUserMerge = (
  payload: iRPStepType3UserSeriesVotingItemUserMergeAction['payload'],
): iRPStepType3UserSeriesVotingItemUserMergeAction => {
  return {
    type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_MERGE,
    payload,
  };
};
export const iRPStepType3UserSeriesVotingItemUserDataMerge = (
  payload: iRPStepType3UserSeriesVotingItemUserDataMergeAction['payload'],
): iRPStepType3UserSeriesVotingItemUserDataMergeAction => {
  return {
    type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_DATA_MERGE,
    payload,
  };
};

// saga
export const iRPStepType3UserSeriesVotingInit = (
  payload: iRPStepType3UserSeriesVotingInitAction['payload'],
): iRPStepType3UserSeriesVotingInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_INIT, payload };
};
export const iRPStepType3UserSeriesVotingRefresh = (
  payload: iRPStepType3UserSeriesVotingRefreshAction['payload'],
): iRPStepType3UserSeriesVotingRefreshAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_REFRESH, payload };
};
export const iRPStepType3UserSeriesVotingSaveItem = (
  payload: iRPStepType3UserSeriesVotingSaveItemAction['payload'],
): iRPStepType3UserSeriesVotingSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_SAVE_ITEM, payload };
};
export const iRPStepType3UserSeriesVotingQuickSet = (
  payload: iRPStepType3UserSeriesVotingQuickSetAction['payload'],
): iRPStepType3UserSeriesVotingQuickSetAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_QUICK_SET, payload };
};
export const iRPStepType3UserSeriesVotingImportItems = (
  payload: iRPStepType3UserSeriesVotingImportItemsAction['payload'],
): iRPStepType3UserSeriesVotingImportItemsAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_IMPORT_ITEMS, payload };
};
