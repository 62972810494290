import { tDepartment } from 'services/department';

const API = 'QuarterlyMonitoringPeriod';

export const API_QUARTERLY_MONITORING_PERIOD = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<QuarterlyMonitoringPeriod, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<QuarterlyMonitoringPeriod, 'id'>) => `${API}/Delete/${id}`,
  SyncQuarterlyMonitoring: ({ id }: Pick<QuarterlyMonitoringPeriod, 'id'>) =>
    `${API}/SyncQuarterlyMonitoring/${id}`,
  CompleteQuarterlyMonitoring: ({ id }: Pick<QuarterlyMonitoringPeriod, 'id'>) =>
    `${API}/CompleteQuarterlyMonitoring/${id}`,
  InitQuarterlyMonitoring: (duplicate: boolean) => `${API}/InitQuarterlyMonitoring/${duplicate}`,
};

export type QuarterlyMonitoringPeriod = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date;
  externalSystemKey: string;
  isActive: boolean;

  departmentID: number;
  monitoringDate: string | Date;
  headOfFieldSignatureDate: string | Date;
  ceoSignatureDate: string | Date;
  meetingSummary: string;
  yieldsFilePath: string;
  isAnalystsStepCompleted: boolean;
  isCompleted: boolean;
  department: tDepartment;

  quarterlyMonitoringPeriodUsers?: [];
};
