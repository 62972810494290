import { API_MEDIA_UPLOADS, MediaUpload } from './models';
import { apiStatic, getRandomString } from 'AurionCR/components';

export * from './models';

export interface ValueHtmlContentUploader {
  name: string;
  value: string;
}

class Service {
  async uploadHtml(data: ValueHtmlContentUploader) {
    const { name, value } = data;
    const fileName = [getRandomString(20), name].filter(Boolean).join('__') + '_htmlContent.html';
    return apiStatic.post<Required<MediaUpload>>(API_MEDIA_UPLOADS.UPLOAD_HTML, {
      fileName: fileName,
      filePath: 'HtmlParts',
      htmlContent: value,
    });
  }
  async remove(data: Pick<Required<MediaUpload>, 'filePath'>) {
    try {
      await apiStatic.put(API_MEDIA_UPLOADS.REMOVE_FILE(data));
    } catch (e) {
      console.warn('Error to remove file');
    }
  }
  async getHtml(data: string) {
    try {
      return await apiStatic.get(API_MEDIA_UPLOADS.GET_HTML(data));
    } catch (e) {
      console.warn('Error to get html file');
    }
  }
}

export const ServiceMediaUploads = new Service();
