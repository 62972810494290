import { tUserPosition } from 'services/user-position';
import { tDepartment } from 'services/department';

const API = 'UserDetail';

export const API_USER_DETAIL = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ userID }: Pick<tUserDetail, 'userID'>) => `${API}/Patch/${userID}`,
  DELETE: ({ userID }: Pick<tUserDetail, 'userID'>) => `${API}/Delete/${userID}`,
};

export type tUserDetail = {
  userID: string;
  startDate?: string | Date;
  email?: string;
  mobilePhone?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  nameEng?: string;
  phone?: string;
  isActive?: boolean;
  isSystemUser?: boolean;
  generatePasswordEndDate?: string | Date;
  departmentID?: string;
  positionID?: string;
  education?: string;
  experience?: string;
  picture?: string;
  conflictOfInterestsInfo?: string;
  isConflictOfInterests?: boolean;
  userPosition?: tUserPosition;
  department?: tDepartment;
  userTrainig?: [];
};
