import React, { memo, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { IRoute, useRouter } from 'AurionCR/components';
import { checkActive } from '../main-nav';
import style from './index.module.scss';
import { AppScrollbars } from 'components/app-scrollbar';

const MenuItem = memo(({ path, title }: IRoute) => {
  const router = useRouter();
  const active = useMemo(() => {
    return checkActive(router.pathname, path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, path]);

  const onClick = useCallback(() => {
    router.push(path);
  }, [path, router]);

  return (
    <ListItemButton onClick={onClick} className={clsx(style.link, active && style.active)}>
      <ListItemText className={style.title} color="primary" primary={title} />
    </ListItemButton>
  );
});

export const TabsNav = memo(({ routes }: { routes: IRoute[] }) => {
  return (
    <AppScrollbars
      className={clsx('r-scroll-bar', style.tabsNav)}
      autoHide={false}
      autoHideTimeout={0}
      autoHideDuration={0}
    >
      <List component="nav" disablePadding>
        {routes.map((item) => (
          <MenuItem key={item.path} {...item} />
        ))}
      </List>
    </AppScrollbars>
  );
});

export const InnerMainNav = memo(({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  // state
  const { map }: { map: { [key: string]: IRoute } } = useSelector((state) => (state as any).routes);
  const parent = useMemo(() => {
    const route = router.pathname.split('/');
    if (route.length > 3) {
      return map[route.slice(0, -1).join('/')] || null;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, router.pathname]);

  return parent ? (
    <div className={style.wrapper}>
      <TabsNav routes={parent.routes || []} />
      <div className={style.content}>{children}</div>
    </div>
  ) : (
    <>{children}</>
  );
});

export default InnerMainNav;
