import { useI18n, useSource } from 'AurionCR/components';
import { API_LANGUAGES, DIRECTIONS, Language } from 'services/labels';
import { useMemo } from 'react';
import { tSourceDefault } from 'components/@types';

export const useEnumSource = ({
  value,
  translate,
}: {
  value: tSourceDefault[];
  translate?: boolean;
}) => {
  const { t, labels } = useI18n();
  const { data, map } = useMemo<{
    data: tSourceDefault[];
    map: { [id: tSourceDefault['id']]: tSourceDefault };
  }>(
    () => {
      return value.reduce(
        (acc: any, { id, title }) => {
          acc.data.push((acc.map[id] = { id, title: translate ? t(title) : title }));
          return acc;
        },
        { data: [], map: {} },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [labels, value, translate],
  );
  return { data, map };
};

export const useSourcesLanguages = () => {
  return useSource<Language>(API_LANGUAGES.DEFAULT_REQUEST, 'id', true);
};
export type tSourceDirection = { id: DIRECTIONS; title: string };

export const useSourceDirection = () => {
  const { t } = useI18n();
  const { data, map } = useMemo(() => {
    const data: tSourceDirection[] = [];
    const map: { [key in DIRECTIONS]?: tSourceDirection } = {};
    data.push((map[DIRECTIONS.LTR] = { id: DIRECTIONS.LTR, title: t('ltr') }));
    data.push((map[DIRECTIONS.RTL] = { id: DIRECTIONS.RTL, title: t('rtl') }));
    return { data, map };
  }, [t]);
  return { data, map };
};
