import { useCallback, useEffect, useState } from 'react';
import { ServiceMediaUploads } from 'services/media-uploads';
import * as yup from 'yup';
import { useMountedRef } from './use-mounted-ref';

const schema = yup.string().url();
interface Props {
  value: string | undefined | null;
  name?: string;
  innerValue?: string;
  isGridTemplate?: boolean;
}
export const useLoadHtml = ({ value, name, innerValue, isGridTemplate }: Props) => {
  const [html, setHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const mountedRef = useMountedRef();
  const onLoad = useCallback(
    async (url: string) => {
      setIsLoading(true);
      const response = await ServiceMediaUploads.getHtml(url);

      if (!mountedRef.current) return;

      if (response?.data) {
        setHtml(response?.data);
      }
      setIsLoading(false);
    },
    [mountedRef],
  );

  const onSaveToCloud = useCallback(
    async (value: string) => {
      if (name) {
        const {
          data: { filePath },
        } = await ServiceMediaUploads.uploadHtml({ value, name });
        onLoad(filePath);
      }
    },
    [name, onLoad],
  );

  useEffect(() => {
    if (isGridTemplate && typeof value === 'string' && !schema.isValidSync(value)) {
      setHtml(value);
    } else if (innerValue === undefined && value) {
      if (schema.isValidSync(value)) {
        onLoad(value);
      } else {
        onSaveToCloud(value);
      }
    }
  }, [value, onLoad, onSaveToCloud, isGridTemplate, innerValue]);

  return { html, isLoading };
};
