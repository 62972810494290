import { apiStatic } from 'AurionCR/components';
import { sortBy } from 'lodash';
import { all, call } from 'redux-saga/effects';
import { API_RATING_PROCESS_STEP_COMMITTEE } from 'services/rating-process-step-committee';
import { API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL } from 'services/rating-process-step-committee-material';
import { API_RATING_PROCESS_STEP_COMMITTEE_RESOURCE } from 'services/rating-process-step-committee-resource';
import { API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING } from 'services/rating-process-step-committee-series-rating';
import { API_RATING_PROCESS_STEP_COMMITTEE_SIGNIFICANT_CHANGE } from 'services/rating-process-step-committee-significant-change';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER } from 'services/rating-process-step-committee-user';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING } from 'services/rating-process-step-committee-user-rates-entity-voting';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING } from 'services/rating-process-step-committee-user-series-voting';
import { iRPMainDataSteps } from '../../@type';
import { dateHelper, getHtml, tTranslate } from './index';

function* getMaterials(main: any): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${main.id}`,
      select: ['title', 'remarks', 'isInclude'].join(),
    },
  });
  for (let i = 0; i < value.length; i++) {
    value[i].remarks = yield call(getHtml, value[i].remarks);
  }
  main.materials = value;
}

function* getResources(main: any): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_COMMITTEE_RESOURCE.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${main.id}`,
      select: ['title', 'remarks', 'isInclude'].join(),
    },
  });
  for (let i = 0; i < value.length; i++) {
    value[i].remarks = yield call(getHtml, value[i].remarks);
  }
  main.resources = value;
}

function* getSignificantChange(main: any): any {
  const {
    data: { value },
  } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_COMMITTEE_SIGNIFICANT_CHANGE.GET_ALL_DYNAMIC,
    {
      params: {
        filter: `ratingProcessStepCommitteeID==${main.id}`,
        select: [
          'title',
          'committeeSignificantChangeType.title as committeeSignificantChangeTypeTitle',
          'remarks',
          'isActive',
        ].join(),
      },
    },
  );
  for (let i = 0; i < value.length; i++) {
    value[i].remarks = yield call(getHtml, value[i].remarks);
  }
  main.significantChanges = value;
}

function* getUsers(main: any): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_COMMITTEE_USER.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${main.id}`,
      select: [
        'userID',
        'user.fullName as userFullName',
        'committeePosition.title as committeePositionTitle',
        'votingOrder',
        'isAllowedToVote',
        'isConflictOfInterests.toString() as isConflictOfInterests',
      ].join(),
      orderBy: 'votingOrder',
    },
  });
  main.users = value;
}

function* getUserRatEntVot(main: any, map: any): any {
  const {
    data: { value },
  } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING.GET_ALL_DYNAMIC,
    {
      params: {
        filter: `ratingProcessStepCommitteeID==${main.id}`,
        select: [
          'userID',
          'user.fullName as userFullName',
          'committeePosition.title as committeePositionTitle',
          'longTermMidroogRating.title as longTermMidroogRatingTitle',
          'midroogOutlook.title as midroogOutlookTitle',
        ].join(),
      },
    },
  );
  main.userRatEntVot = sortBy(
    value.map((item: any) => {
      item.rank = map[String(item.userID)] !== undefined ? map[String(item.userID)] : 10000;
      return item;
    }),
    ['rank'],
  );
}

function* getSeriesRating(main: any): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${main.id}`,
      select: [
        'series.title as seriesTitle',
        'midroogRating.title as midroogRatingTitle',
        'midroogOutlook.title as midroogOutlookTitle',
        'midroogRatingSymbol.title as midroogRatingSymbol',
        'provisional',
      ].join(),
    },
  });
  main.seriesRating = value;
}

function* getUserSeriesVoting(main: any, map: any): any {
  const {
    data: { value },
  } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING.GET_ALL_DYNAMIC,
    {
      params: {
        filter: `ratingProcessStepCommitteeID==${main.id}`,
        select: [
          'seriesID',
          'userID',
          'user.fullName as userFullName',
          'series.title as seriesTitle',
          'midroogRating.title as midroogRatingTitle',
          'midroogOutlook.title as midroogOutlookTitle',
        ].join(),
      },
    },
  );
  main.userSeriesVoting = sortBy(
    value.map((item: any) => {
      item.rank = map[String(item.userID)] !== undefined ? map[String(item.userID)] : 10000;
      return item;
    }),
    ['rank', 'seriesID'],
  );
}

export function* stepType3(t: tTranslate, step: iRPMainDataSteps): any {
  const {
    data: { value },
  }: { data: { value: any[] } } = yield call(
    apiStatic.get,
    API_RATING_PROCESS_STEP_COMMITTEE.GET_ALL_DYNAMIC,
    {
      params: {
        select: [
          'id',
          // main
          'committeeDate',
          'lastVotingDate',
          'committeeManner.title as committeeMannerTitle',
          'examinedFinancialStatementDate',
          'isAdditionalPublications',
          'isAdditionalMaterialsLate',
          'remarks',
          // rating rational
          'ratingDecisionRemarks',
          // restrictions
          'restrictions',
          // other issues
          'discussionIssueses',
          // member voting - top
          'isMaterialsUpdated',
          'isMaterialsVolumeEnough',
          'isMaterialsReliable',
          'materialsReservations',
          // member voting - middle - left
          'longTermMidroogRating.title as longTermMidroogRatingTitle',
          'shortTermMidroogRating.title as shortTermMidroogRatingTitle',
          'baselineCreditAssesmentRating.title as baselineCreditAssesmentRatingTitle',
          // member voting - middle - center
          'midroogOutlook.title as midroogOutlookTitle',
          'midroogRatingSymbol.title as midroogRatingSymbolTitle',
          // member voting - middle - right
          'longTermCapitalApproved',
          'longTermCapital',
          'shortTermCapitalApproved',
          'shortTermCapital',
          // compliance reporting
          'isChangeRatingMethod',
          'changeRatingMethodRemarks',
          'isRatingChangeImpact',
          'ratingChangeImpactRemarks',
        ].join(),
        filter: `ratingProcessStepID==${step.id}`,
      },
    },
  );
  for (let i = 0; i < value.length; i++) {
    yield all([
      call(getMaterials, value[i]),
      call(getResources, value[i]),
      call(getSignificantChange, value[i]),
      call(getUsers, value[i]),
    ]);

    const rankMap: { [key: string]: number } = value[i].users.reduce(
      (acc: any, item: any, index: number) => {
        if (item.userID) {
          // @ts-ignore
          acc[String(item.userID)] = index;
        }
        return acc;
      },
      {},
    );

    yield all([
      call(getUserRatEntVot, value[i], rankMap),
      call(getUserSeriesVoting, value[i], rankMap),
      call(getSeriesRating, value[i]),
    ]);

    value[i].committeeDate = dateHelper(value[i].committeeDate);
    value[i].lastVotingDate = dateHelper(value[i].lastVotingDate);
    value[i].examinedFinancialStatementDate = dateHelper(value[i].examinedFinancialStatementDate);

    value[i].remarks = yield call(getHtml, value[i].remarks);
    value[i].ratingDecisionRemarks = yield call(getHtml, value[i].ratingDecisionRemarks);
    value[i].restrictions = yield call(getHtml, value[i].restrictions);
    value[i].discussionIssueses = yield call(getHtml, value[i].discussionIssueses);
    value[i].materialsReservations = yield call(getHtml, value[i].materialsReservations);
    value[i].changeRatingMethodRemarks = yield call(getHtml, value[i].changeRatingMethodRemarks);
    value[i].ratingChangeImpactRemarks = yield call(getHtml, value[i].ratingChangeImpactRemarks);
  }
  // create html
  return `<div style='page-break-before: always;'>
    <h1 class='pageTitle'>${step.ratingProcessStepTypeTitle}</h1>
    <ol>
   ${value
     .map(
       (item: any) => `<li>
        <h3>${t('preliminary')}</h3>
        <table class='tablePreview'>
          <tbody>
            <tr>
              <th>${t('committee-date')}</th>
              <td>${item.committeeDate || ''}</td>    
            </tr>
            <tr>
              <th>${t('last-voting-date')}</th>
              <td>${item.lastVotingDate || ''}</td>    
            </tr>
            <tr>
              <th>${t('committee-manner')}</th>
              <td>${item.committeeMannerTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('examined-financial-statement-date')}</th>
              <td>${item.examinedFinancialStatementDate || ''}</td>    
            </tr>
            <tr>
              <th>${t('additional-publications')}</th>
              <td>${item.isAdditionalPublications ? '&#10003;' : '&#9744;'}</td>    
            </tr>
            <tr>
              <th>${t('additional-materials-late')}</th>
              <td>${item.isAdditionalMaterialsLate ? '&#10003;' : '&#9744;'}</td>    
            </tr>
            <tr>
              <th>${t('remarks')}</th>
              <td class='html'>${item.remarks || '-'}</td>    
            </tr>
          </tbody>
        </table>
        <h4>${t('members')}</h4>
        <table class='tableStandard tableTdCenter full-width'>
          <thead>
            <tr>
              <th>${t('name')}</th>
              <th>${t('committee-position')}</th>
              <th>${t('voting-order')}</th>
              <th>${t('is-allowed-to-vote')}</th>
              <th>${t('is-conflict-of-interests')}</th>
            </tr>
          </thead>
          <tbody>
          ${item.users
            .map(
              (user: any) => `<tr>
                <td>${user.userFullName}</td>
                <td>${user.committeePositionTitle}</td>
                <td>${user.votingOrder}</td>    
                <td>${user.isAllowedToVote ? '&#10003;' : '&#9744;'}</td>    
                <td>${t(user.isConflictOfInterests)}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h3>${t('committee-materials')}</h3>
        <table class='tablePreview full-width'>
          <tbody>
          ${item.materials
            .map(
              (material: any, i: number) => `<tr>
                <th>${i + 1}.</th>
                <th>${material.isInclude ? '&#10003;' : '&#9744;'}</th>
                <th>${material.title || ''}</th>    
                <td class='html'>${material.remarks || '-'}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h3>${t('committee-resources')}</h3>
        <table class='tablePreview full-width'>
          <tbody>
          ${item.resources
            .map(
              (resource: any, i: number) => `<tr>
                <th>${i + 1}.</th>
                <th>${resource.isInclude ? '&#10003;' : '&#9744;'}</th>
                <th>${resource.title || ''}</th>    
                <td class='html'>${resource.remarks || '-'}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h3>${t('rating-decisions')}</h3>
        <p>${item.ratingDecisionRemarks || '-'}</p>
        <h3>${t('significant-changes')}</h3>
        <table class='tablePreview full-width'>
          <tbody>
          ${item.significantChanges
            .map(
              (significantChange: any, i: number) => `<tr>
                <th>${i + 1}.</th>
                <th>${significantChange.isActive ? '&#10003;' : '&#9744;'}</th>
                <th>${significantChange.title || ''}</th>    
                <th>${significantChange.committeeSignificantChangeTypeTitle || ''}</th>    
                <td class='html'>${significantChange.remarks || '-'}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h3>${t('restrictions')}</h3>
        <div class='html'>${item.restrictions || '-'}</div>
        <h3>${t('other-issues')}</h3>
        <div class='html'>${item.discussionIssueses || '-'}</div>
        <h3>${t('committee-rated-entity-votings')}</h3>
        <h4>${t('materials-before-committee')}</h4>
        <table class='tablePreview'>
          <tbody>
            <tr>
              <th>${t('materials-updated')}</th>
              <td>${item.isMaterialsUpdated ? '&#10003;' : '&#9744;'}</td>    
            </tr>
            <tr>
              <th>${t('materials-volume-enough')}</th>
              <td>${item.isMaterialsVolumeEnough ? '&#10003;' : '&#9744;'}</td>    
            </tr>
            <tr>
              <th>${t('materials-reliable')}</th>
              <td>${item.isMaterialsReliable ? '&#10003;' : '&#9744;'}</td>    
            </tr>
            <tr>
              <th>${t('materials-reservations')}</th>
              <td>${item.materialsReservations || ''}</td>    
            </tr>
          </tbody>
        </table>
        <h4>${t('committee-rated-entity-votings')}</h4>
        <table class='tableStandard tableTdCenter full-width'>
          <thead>
            <tr>
              <th>${t('name')}</th>
              <th>${t('committee-position')}</th>
              <th>${t('long-term-rating')}</th>
              <th>${t('outlook')}</th>
            </tr>
          </thead>
          <tbody>
          ${item.userRatEntVot
            .map(
              (user: any) => `<tr>
                <td>${user.userFullName || ''}</td>
                <td>${user.committeePositionTitle || ''}</td>
                <td>${user.longTermMidroogRatingTitle || ''}</td>    
                <td>${t(user.midroogOutlookTitle || '')}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h4>${t('series-voting')}</h4>
        <table class='tableStandard tableTdCenter full-width'>
          <thead>
            <tr>
              <th>${t('series')}</th>
              <th>${t('midroog-rating')}</th>
              <th>${t('midroog-outlook')}</th>
              <th>${t('midroog-rating-symbol')}</th>
              <th>${t('provisional')}</th>
            </tr>
          </thead>
          <tbody>
          ${item.seriesRating
            .map(
              (rating: any) => `<tr>
                <td>${rating.seriesTitle || ''}</td>
                <td>${rating.midroogRatingTitle || ''}</td>
                <td>${rating.midroogOutlookTitle || ''}</td>    
                <td>${rating.midroogRatingSymbol || '-'}</td>    
                <td>${rating.provisional ? '&#10003;' : '&#9744;'}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h4>${t('analysts-voting-for-series')}</h4>
        <table class='tableStandard tableTdCenter full-width'>
          <thead>
            <tr>
              <th>${t('name')}</th>
              <th>${t('series')}</th>
              <th>${t('midroog-rating')}</th>
              <th>${t('midroog-outlook')}</th>
            </tr>
          </thead>
          <tbody>
          ${item.userSeriesVoting
            .map(
              (rating: any) => `<tr>
                <td>${rating.userFullName || ''}</td>
                <td>${rating.seriesTitle || ''}</td>
                <td>${rating.midroogRatingTitle || ''}</td>
                <td>${rating.midroogOutlookTitle || ''}</td>    
              </tr>`,
            )
            .join('')}
          </tbody>
        </table>
        <h4>${t('materials-before-committee')}</h4>
        <table class='tablePreview'>
          <tbody>
            <tr>
              <th>${t('long-term-midroog-rating')}</th>
              <td>${item.longTermMidroogRatingTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('short-term-midroog-rating')}</th>
              <td>${item.shortTermMidroogRatingTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('baseline-credit-assesment-rating')}</th>
              <td>${item.baselineCreditAssesmentRatingTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('midroog-outlook')}</th>
              <td>${item.midroogOutlookTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('midroog-rating-symbol')}</th>
              <td>${item.midroogRatingSymbolTitle || ''}</td>    
            </tr>
            <tr>
              <th>${t('long-term-capital-approved')}</th>
              <td>${item.longTermCapitalApproved || ''}</td>    
            </tr>
            <tr>
              <th>${t('long-term-capital')}</th>
              <td>${item.longTermCapital || ''}</td>    
            </tr>
            <tr>
              <th>${t('short-term-capital-approved')}</th>
              <td>${item.shortTermCapitalApproved || ''}</td>    
            </tr>
            <tr>
              <th>${t('short-term-capital')}</th>
              <td>${item.shortTermCapital || ''}</td>    
            </tr>
          </tbody>
        </table>
        <h3>${t('compliance-officer-issues')}</h3>
        <div class='html'>${item.isChangeRatingMethod ? '&#10003;' : '&#9744;'} ${t(
         'change-rating-method',
       )}</div>
        ${item.isChangeRatingMethod ? `<div>${item.changeRatingMethodRemarks}</div>` : '-'}
        <div  class='html'>${item.isRatingChangeImpact ? '&#10003;' : '&#9744;'} ${t(
         'rating-change-impact',
       )}</div>
        ${item.isRatingChangeImpact ? `<div>${item.ratingChangeImpactRemarks}</div>` : '-'}
      </li>`,
     )
     .join('')}
  </ol>
  </div>`;
}
