import {
  IRPStepDoneForm,
  iRPStepDoneActionTypes,
  iRPStepDoneFinishAction,
  iRPStepDoneInitAction,
  iRPStepDoneMergeAction,
  iRPStepSubmitFormAction,
} from '../@type';

export const RPStepDoneInit = (): iRPStepDoneInitAction => {
  return { type: iRPStepDoneActionTypes.RP_STEP_DONE_INIT };
};

export const RPStepDoneFinish = (payload: IRPStepDoneForm): iRPStepDoneFinishAction => {
  return { type: iRPStepDoneActionTypes.RP_STEP_DONE_FINISH, payload };
};

export const RPStepSubmitForm = (payload: IRPStepDoneForm): iRPStepSubmitFormAction => {
  return { type: iRPStepDoneActionTypes.RP_STEP_SUBMIT_FORM, payload };
};

export const RPStepDoneMerge = (
  payload: iRPStepDoneMergeAction['payload'],
): iRPStepDoneMergeAction => {
  return { type: iRPStepDoneActionTypes.RP_STEP_DONE_MERGE, payload };
};
