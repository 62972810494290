import update from 'immutability-helper';
import {
  iStepAnalyticsTasks,
  iStepFinish,
  iStepMeeting,
  QMAction,
  QMActionTypes,
  QMAddNewAction,
  QMFilterDepartmentAction,
  QMFiltersMergeAction,
  QMInitAction,
  QMMergeAction,
  QMResetAction,
  QMSourcesMergeAction,
  QMState,
  QMStepAnalyticsTasksGetDataAction,
  QMStepAnalyticsTasksMergeAction,
  QMStepAnalyticsTasksQMMergeAction,
  QMStepAnalyticsTasksQMSaveAction,
  QMStepAnalyticsTasksResetAction,
  QMStepFinishMergeAction,
  QMStepFinishMergeSaveAction,
  QMStepMeetingMergeAction,
  QMStepMeetingMergeSaveAction,
  QMSyncAction,
} from './@type';

export const stepAnalyticsTasksState = (
  state?: Partial<iStepAnalyticsTasks>,
): iStepAnalyticsTasks => ({
  loading: false,
  complete: false,
  QMPeriod: null,
  selected: 0,
  QMSList: [],
  QMS: {},
  ...(state || {}),
});

export const stepMeetingState = (state?: Partial<iStepMeeting>): iStepMeeting => ({
  loading: false,
  complete: false,
  ...(state || {}),
});
export const stepFinishState = (state?: Partial<iStepFinish>): iStepFinish => ({
  loading: false,
  complete: false,
  ...(state || {}),
});
export const initialState = (): QMState => ({
  // flags
  init: false,
  loading: false,
  openAddNew: false,
  step: 'stepAnalyticsTasks',
  // sources
  sources: {
    periods: [],
    departments: [],
  },
  // filters
  filters: {
    period: null,
    department: null,
    ratedEntityID: null,
    analystUserID: null,
  },
  // steps
  stepAnalyticsTasks: stepAnalyticsTasksState(),
  stepMeeting: stepMeetingState(),
  stepFinish: stepFinishState(),
});

export const QM = (state = initialState(), action: QMAction): QMState => {
  switch (action.type) {
    case QMActionTypes.QM_RESET: {
      return initialState();
    }
    case QMActionTypes.QM_MERGE: {
      return update(state, { $merge: action.payload });
    }
    // sources
    case QMActionTypes.QM_SOURCES_MERGE: {
      return update(state, { sources: { $merge: action.payload } });
    }
    // filters
    case QMActionTypes.QM_FILTERS_MERGE: {
      return update(state, { filters: { $merge: action.payload } });
    }
    // stepAnalyticsTasks
    case QMActionTypes.QM_STEP_ANALYTICS_TASKS_RESET: {
      return update(state, {
        stepAnalyticsTasks: { $set: stepAnalyticsTasksState(action.payload) },
      });
    }
    case QMActionTypes.QM_STEP_ANALYTICS_TASKS_MERGE: {
      return update(state, { stepAnalyticsTasks: { $merge: action.payload } });
    }
    case QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_MERGE: {
      const { id, data, ...rest } = action.payload;
      if (state.stepAnalyticsTasks.QMS[id]) {
        let state_ = update(state, { stepAnalyticsTasks: { QMS: { [id]: { $merge: rest } } } });
        if (data) {
          state_ = update(state_, {
            stepAnalyticsTasks: { QMS: { [id]: { data: { $merge: data } } } },
          });
        }
        return state_;
      } else {
        return state;
      }
    }
    // StepMeeting
    case QMActionTypes.QM_STEP_MEETING_MERGE: {
      return update(state, { stepMeeting: { $merge: action.payload } });
    }
    // StepMeeting
    case QMActionTypes.QM_STEP_FINISH_MERGE: {
      return update(state, { stepFinish: { $merge: action.payload } });
    }
    default:
      return state;
  }
};

// REDUX helpers
export const QMReset = (): QMResetAction => {
  return { type: QMActionTypes.QM_RESET };
};
export const QMMerge = (payload: QMMergeAction['payload']): QMMergeAction => {
  return { type: QMActionTypes.QM_MERGE, payload };
};
export const QMFiltersMerge = (payload: QMFiltersMergeAction['payload']): QMFiltersMergeAction => {
  return { type: QMActionTypes.QM_FILTERS_MERGE, payload };
};
export const QMSourceMerge = (payload: QMSourcesMergeAction['payload']): QMSourcesMergeAction => {
  return { type: QMActionTypes.QM_SOURCES_MERGE, payload };
};
// SAGA helpers
export const QMInit = (): QMInitAction => {
  return { type: QMActionTypes.QM_INIT };
};
export const QMSync = (): QMSyncAction => {
  return { type: QMActionTypes.QM_SYNC };
};
export const QMAddNew = (payload: QMAddNewAction['payload']): QMAddNewAction => {
  return { type: QMActionTypes.QM_ADD_NEW, payload };
};
export const QMFilterDepartment = (): QMFilterDepartmentAction => {
  return { type: QMActionTypes.QM_FILTER_DEPARTMENT };
};

// stepAnalyticsTasks
export const QMStepAnalyticsTasksReset = (
  payload?: QMStepAnalyticsTasksResetAction['payload'],
): QMStepAnalyticsTasksResetAction => {
  return { type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_RESET, payload };
};
export const QMStepAnalyticsTasksMerge = (
  payload: QMStepAnalyticsTasksMergeAction['payload'],
): QMStepAnalyticsTasksMergeAction => {
  return { type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_MERGE, payload };
};
export const QMStepAnalyticsTasksQMMerge = (
  payload: QMStepAnalyticsTasksQMMergeAction['payload'],
): QMStepAnalyticsTasksQMMergeAction => {
  return { type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_MERGE, payload };
};
// SAGA helpers
export const QMStepAnalyticsTasksGetData = (): QMStepAnalyticsTasksGetDataAction => {
  return { type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_GET_DATA };
};
export const QMStepAnalyticsTasksQMSave = (
  payload: QMStepAnalyticsTasksQMSaveAction['payload'],
): QMStepAnalyticsTasksQMSaveAction => {
  return { type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_SAVE, payload };
};

// StepMeeting
export const QMStepMeetingMerge = (
  payload: QMStepMeetingMergeAction['payload'],
): QMStepMeetingMergeAction => {
  return { type: QMActionTypes.QM_STEP_MEETING_MERGE, payload };
};
export const QMStepMeetingMergeSave = (
  payload: QMStepMeetingMergeSaveAction['payload'],
): QMStepMeetingMergeSaveAction => {
  return { type: QMActionTypes.QM_STEP_MEETING_SAVE, payload };
};

// StepFinish
export const QMStepFinishMerge = (
  payload: QMStepFinishMergeAction['payload'],
): QMStepFinishMergeAction => {
  return { type: QMActionTypes.QM_STEP_FINISH_MERGE, payload };
};
export const QMStepFinishMergeSave = (
  payload: QMStepFinishMergeSaveAction['payload'],
): QMStepFinishMergeSaveAction => {
  return { type: QMActionTypes.QM_STEP_FINISH_SAVE, payload };
};
