import { useI18n, useSource } from 'AurionCR/components';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS_STEP_REVIEW } from 'services/rating-process-step-review';
import { iRatingProcessStepPreview, iStepDetail } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { StepDetailsList } from './step-details-list';
import { useData } from './useData';
import { IUserDetailsOption, makeUserDetailsField } from 'components/hooks/use-user-detail-field';
import { API_USER_DETAIL } from 'services/user-detail';
import { Tooltip } from '@mui/material';

export const pathStepForecast = (id: number) => {
  return `
      ${API_RATING_PROCESS_STEP_REVIEW.GET_ALL_DYNAMIC}
      ?select=${[
        'reviewDate',
        'string.join(",",ratingProcessStepReviewUsers.Select(s=>s.user.fullName)) as members',
        'midroogRating.title as midroogRatingTitle',
        'minRangeMidroogRating.title as minRangeMidroogRatingTitle',
        'maxRangeMidroogRating.title as maxRangeMidroogRatingTitle',
        'review',
      ].join()}
        &filter=ratingProcessStepID==${id}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepForecast = (
  stepData: any,
  t: (label: string) => string,
  userDetailsOptions: IUserDetailsOption[],
): iStepDetail[] | null => {
  if (stepData) {
    const reviewDate = stepData?.reviewDate ? new Date(stepData?.reviewDate) : null;
    const updatedMembersName = makeUserDetailsField(stepData?.members, userDetailsOptions, t, true);
    return [
      {
        label: t('review-date'),
        value: reviewDate && isValid(reviewDate) ? format(reviewDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('members'),
        value: updatedMembersName,
      },
      {
        label: t('forecast-rating'),
        value: stepData?.midroogRatingTitle
          ? stepData?.midroogRatingTitle
          : `${stepData?.minRangeMidroogRatingTitle || '...'} - ${
              stepData?.maxRangeMidroogRatingTitle || '...'
            }`,
      },
      {
        label: t('review-remarks'),
        value: stepData?.review || '',
        type: 'html',
      },
    ];
  } else {
    return null;
  }
};

export const StepForecast = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const userDetailSource = useSource<IUserDetailsOption>(
    `${API_USER_DETAIL.GET_ALL_DYNAMIC}?Select=fullName,isActive`,
  );
  const [path] = useState(pathStepForecast(data.id));
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<iStepDetail[] | null>(
    () => prepareDataStepForecast(stepData, t, userDetailSource.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData, labels],
  );
  const title = useMemo(() => data?.ratingProcessStepTypeTitle || '', [data]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<StepDetailsList data={prepareData} loading={loading} title={title} />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton data={data} date={date} title={title} />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <StepDetailsList data={prepareData} loading={loading} />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton data={data} date={date} title={title} disabled={true} />
    </div>
  );
});
