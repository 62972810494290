import React, { useCallback, useEffect, useMemo } from 'react';
import { Drawer, IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { UserNav } from 'AurionCR/components/navigation/user';
import Logo from 'images/logo-login.svg';
import { useAuth, useI18n } from 'AurionCR/components';
import { debounce } from 'lodash';
import { AppUser } from 'services/account';
import { useOpen } from 'AurionCR/components/hooks';
import LanguageSwitcher from 'components/language/language-switcher';
import { MainNav } from '../main-nav';
import ExpandMore from '@mui/icons-material/ExpandMore';

import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  user: AppUser;
  fieldImagePath: keyof AppUser;
}

export const LayoutDesktopNav: React.FC<Props> = ({ user, fieldImagePath }) => {
  const { t } = useI18n();
  const { permission } = useAuth();
  const [open, setOpen] = React.useState(true);
  const { isOpen: hover, setIsOpen: setHover } = useOpen({ init: false });
  const onToggle = useCallback(
    (bool: boolean) => {
      setOpen(bool);
      localStorage.setItem(`menu_user-${user?.userID}`, JSON.stringify({ open: bool }));

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    [setOpen, user],
  );
  const userNav = useMemo(() => {
    if (user && permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [user, permission]);
  const onHover = useMemo(
    () =>
      debounce((value: boolean) => {
        setHover(value);
      }, 300),
    [setHover],
  );

  useEffect(() => {
    if (user?.userID) {
      const store_ = JSON.parse(localStorage.getItem(`menu_user-${user.userID}`) || '{}');
      setOpen(store_ && store_.open !== undefined ? store_.open : true);
    }
  }, [setOpen, user]);

  return (
    <>
      <Drawer
        className={clsx(open && style.opened, hover && style.hover)}
        variant={'permanent'}
        classes={{
          root: style.wrap,
          paper: style.paper,
        }}
      >
        <div className={style.header}>
          <div className={style.control}>
            <IconButton
              onClick={() => onToggle(!open)}
              color={'inherit'}
              style={{ fontSize: '2.4rem' }}
            >
              {open ? <MenuOpenIcon fontSize={'inherit'} /> : <MenuIcon fontSize={'inherit'} />}
            </IconButton>
          </div>
          <div className={style.logo}>
            <img src={Logo} alt="" className={style.logo} />
          </div>
          <div
            className={style.user}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
          >
            <UserNav
              menu={userNav}
              fieldImagePath={fieldImagePath}
              title={(data: AppUser) => (
                <>
                  <strong className={style.userName}>{data.fullName}</strong>
                  <span className={'position'}>{data.positionTitle}</span>
                </>
              )}
              endIcon={<ExpandMore />}
            />
          </div>
        </div>
        <div
          className={style.navigation}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
        >
          <MainNav collapsed={!open && !hover} accordion={true} />
        </div>
        <div className={style.footer}>
          <div className={style.language}>
            <LanguageSwitcher theme={'light'} />
          </div>
          <div className={style.copyright}>{t('copy-rights')}</div>
        </div>
      </Drawer>
    </>
  );
};
