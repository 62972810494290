import { DifferentThanForecast } from 'services/different-than-forecast';
import { Company } from 'services/company';
import { tUserDetail } from 'services/user-detail';
import { FinancialConventionType } from 'services/financial-convention-type';
import { LinkageBasisType } from 'services/linkage-basis-type';
import { SignedOfficerStatement } from 'services/signed-officer-statement';
import { SecurityPadsFull } from 'services/security-pads-full';
import { CheckModelsAssumption } from 'services/check-models-assumption';
import { QuarterlyMonitoringPeriod } from 'services/quarterly-monitoring-period';
import { FinancialStatementTrendType } from 'services/financial-statemen-thrend-type';

const API = 'QuarterlyMonitoring';

export const API_QUARTERLY_MONITORING = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<QuarterlyMonitoring, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<QuarterlyMonitoring, 'id'>) => `${API}/Delete/${id}`,
};

export type QuarterlyMonitoring = {
  id: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;

  quarterlyMonitoringPeriodID: number;
  ratedEntityID: number;
  analystUserID: string;
  financialStatementTrendTypeID: number;
  financialStatementTrendTypeMark: boolean;
  financialStatementTrendTypeDescription: string;
  financialConventionTypeID: number;
  financialConventionTypeMark: boolean;
  financialConventionTypeDescription: string;
  immediateRepayment: string;
  immediateRepaymentMark: boolean;
  immediateRepaymentDescription: string;
  bdiLastCheckResult: string;
  bdiLastCheckResultMark: boolean;
  bdiLastCheckResultDescription: string;
  bdiCheckDate: string | Date;
  bdiCheckDateMark: boolean;
  bdiCheckDateDescription: string;
  bondRepayment: number | null;
  bondRepaymentMark: boolean;
  bondRepaymentDescription: string;
  cashBalance: number;
  cashBalanceMark: boolean;
  cashBalanceDescription: string;
  isCPARemark: boolean;
  isCPARemarkMark: boolean;
  isCPARemarkDescription: string;
  isImmediateRepaymentGroundsExamined: boolean;
  isImmediateRepaymentGroundsExaminedMark: boolean;
  isImmediateRepaymentGroundsExaminedDescription: string;
  isSeriesExaminedForQuarterlyMonitoring: boolean;
  isSeriesExaminedForQuarterlyMonitoringMark: boolean;
  isSeriesExaminedForQuarterlyMonitoringDescription: string;
  isNeedRatingReview: boolean;
  isNeedRatingReviewMark: boolean;
  isNeedRatingReviewDescription: string;
  remarks: string;
  ratedEntityMark: boolean;
  trusteeName: string;
  trusteeNameMark: boolean;
  trusteeNameDescription: string;
  trusteeComments: string;
  trusteeCommentsMark: boolean;
  trusteeCommentsDescription: string;
  substantiveIssues: string;
  substantiveIssuesMark: boolean;
  substantiveIssuesDescription: string;
  lastFinancialReport: string | Date;
  lastFinancialReportMark: boolean;
  lastFinancialReportDescription: string;
  signedOfficerStatementID: number;
  signedOfficerStatementMark: boolean;
  signedOfficerStatementDescription: string;
  securityPadsFullID: number;
  securityPadsFullMark: boolean;
  securityPadsFullDescription: string;
  differentThanForecastID: number;
  differentThanForecastMark: boolean;
  differentThanForecastDescription: string;
  checkModelsAssumptionID: number;
  checkModelsAssumptionMark: boolean;
  checkModelsAssumptionDescription: string;
  isMonitoredInLastQuarter?: boolean;
  isIgnored: boolean;
  isCompleted: boolean;
  isMark: boolean;
  quarterlyMonitoringPeriod?: QuarterlyMonitoringPeriod;
  ratedEntity?: Company;
  analyst?: tUserDetail;
  financialStatementTrendType?: FinancialStatementTrendType;
  financialConventionType?: FinancialConventionType;
  linkageBasisType?: LinkageBasisType;
  signedOfficerStatement?: SignedOfficerStatement;
  securityPadsFull?: SecurityPadsFull;
  checkModelsAssumption?: CheckModelsAssumption;
  differentThanForecast?: DifferentThanForecast;
  updateByLastQuarterly?: string;
  updateByLastQuarterlyMark?: boolean;
  bdiLastCheckChange?: string;
  bdiLastCheckChangeMark?: boolean;
  mainSeriesName?: string;
  mainSeriesNameMark?: boolean;
  mainSeriesNumber?: string;
  mainSeriesNumberMark?: boolean;
  linkageBasisTypeID?: number;
  linkageBasisTypeMark?: boolean;
  durationBruto?: number;
  durationBrutoMark?: boolean;
  yieldBruto?: number;
  yieldBrutoMark?: boolean;
  yieldBrutoYear?: number;
  yieldBrutoYearMark?: boolean;
  stockYield?: number;
  stockYieldMark?: boolean;
};
