const API = 'DocumentationStatus';

export const API_DOCUMENTATION_STATUS = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tDocumentationStatus, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tDocumentationStatus, 'id'>) => `${API}/Delete/${id}`,
};

export type tDocumentationStatus = {
  id: string;
  title: string;
  titleEng: string;
  rank: number;
  isActive: boolean;
};
