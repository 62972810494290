import {
  iRPPrivateMagnaReportActionTypes,
  iRPPrivateMagnaReportInitAction,
  iRPPrivateMagnaReportMergeAction,
  iRPPrivateMagnaReportSaveAction,
} from '../@type';

export const RPPrivateMagnaReportInit = (): iRPPrivateMagnaReportInitAction => {
  return { type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_INIT };
};

export const RPPrivateMagnaReportMerge = (
  payload: iRPPrivateMagnaReportMergeAction['payload'],
): iRPPrivateMagnaReportMergeAction => {
  return { type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_MERGE, payload };
};

export const RPPrivateMagnaReportSave = (
  payload: iRPPrivateMagnaReportSaveAction['payload'],
): iRPPrivateMagnaReportSaveAction => {
  return { type: iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_SAVE, payload };
};
