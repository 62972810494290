import { useI18n } from 'AurionCR/components';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS_STEP_REPORT } from 'services/rating-process-step-report';
import { iRatingProcessStepPreview, iStepDetail } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { StepDetailsList } from './step-details-list';
import { useData } from './useData';
import { Tooltip } from '@mui/material';

export const pathStepReport = (id: number) => {
  return `
      ${API_RATING_PROCESS_STEP_REPORT.GET_ALL_DYNAMIC}
      ?select=${[
        'report!=null?report.filePath:null as draftReport',
        'report!=null?report.reportDate:null as reportDate',
        'draftRequestDate',
        'draftResposeDate',
        'teamLeader.fullName as teamLeader',
        'teamLeaderSignatureDate',
        'issuerContactName',
        'isIssuerContactSeniorOfficer',
        'ratingProcessStep.clientDecision.title as clientDecision',
        'remarks',
      ].join()}
    &filter=ratingProcessStepID==${id}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepReport = (
  stepData: any,
  t: (label: string) => string,
): iStepDetail[] | null => {
  if (stepData) {
    const reportDate = stepData?.reportDate ? new Date(stepData?.reportDate) : null;
    const draftRequestDate = stepData?.draftRequestDate
      ? new Date(stepData?.draftRequestDate)
      : null;
    const draftResposeDate = stepData?.draftResposeDate
      ? new Date(stepData?.draftResposeDate)
      : null;
    const teamLeaderSignatureDate = stepData?.teamLeaderSignatureDate
      ? new Date(stepData?.teamLeaderSignatureDate)
      : null;
    return [
      {
        label: t('draft-report'),
        value: stepData?.draftReport || '',
        type: 'link',
      },
      {
        label: t('report-date'),
        value: reportDate && isValid(reportDate) ? format(reportDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('draft-request-date'),
        value:
          draftRequestDate && isValid(draftRequestDate)
            ? format(draftRequestDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('draft-response-date'),
        value:
          draftResposeDate && isValid(draftResposeDate)
            ? format(draftResposeDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('team-leader'),
        value: stepData?.teamLeader || '',
      },
      {
        label: t('team-leader-signature-date'),
        value:
          teamLeaderSignatureDate && isValid(teamLeaderSignatureDate)
            ? format(teamLeaderSignatureDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('issuer-contact-name'),
        value: stepData?.issuerContactName || '',
      },
      {
        label: t('issuer-contact-senior-officer'),
        value:
          stepData?.isIssuerContactSeniorOfficer !== undefined
            ? t(String(stepData.isIssuerContactSeniorOfficer))
            : '',
      },
      {
        label: t('the-client-decision'),
        value: stepData?.clientDecision || '',
      },
      {
        label: t('remarks'),
        value: stepData?.remarks || '',
        type: 'html',
      },
    ];
  } else {
    return null;
  }
};

export const StepReport = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const [path] = useState(pathStepReport(data.id));
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<iStepDetail[] | null>(
    () => prepareDataStepReport(stepData, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData, labels],
  );
  const title = useMemo(() => data?.ratingProcessStepTypeTitle || '', [data]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<StepDetailsList data={prepareData} loading={loading} title={title} />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton data={data} date={date} title={title} />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <StepDetailsList data={prepareData} loading={loading} />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton data={data} date={date} title={title} disabled={true} />
    </div>
  );
});
