import {
  iRPStepDoneFormInitAction,
  iRPStepDoneFormActionTypes,
  iRPStepDoneFormMergeAction,
} from '../@type';

export const RPStepDoneFormInit = (): iRPStepDoneFormInitAction => {
  return { type: iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_INIT };
};

export const RPStepDoneFormMerge = (
  payload: iRPStepDoneFormMergeAction['payload'],
): iRPStepDoneFormMergeAction => {
  return { type: iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_MERGE, payload };
};
