import { useAuth } from './auth.tsx';

export interface IRoute {
  path: string;
  title: string;
  inMenu?: boolean;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  private?: undefined | boolean;
  permission?: undefined | string;
  inLayout?: boolean;
  component?: any;
  routes?: undefined | IRoute[];
}
//
import { ElementType, FC, Suspense, useMemo } from 'react';
import {
  BrowserRouter,
  Route,
  To,
  useLocation,
  useNavigate,
  Routes as RouterRoutes,
  Navigate,
} from 'react-router-dom';
import { useTypedRouteParams } from '../../modules/typed-routes';
import { useI18n } from './i18.tsx';
import { useDispatch } from 'react-redux';
import { initRoutes } from '../store/modules/routes.ts';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useTypedRouteParams<any>();
  return useMemo(
    () => {
      return {
        push: navigate,
        replace: (to: To) => navigate(to, { replace: true }),
        pathname: location.pathname,
        query: query,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  );
};

export const parseRoute = (
  t: any,
  route: IRoute,
  flat: IRoute[],
  map: any,
  parent?: IRoute | null,
) => {
  const permission_ = `${parent?.permission || ''}${route.permission && parent?.permission ? ',' : ''}${route.permission || ''}`;
  let result: IRoute = {
    inMenu: true,
    private: true,
    inLayout: true,
    ...route,
    permission: permission_,
    path: `${parent ? parent.path : ''}${route.path}`,
  };
  result.title = t(result.title);
  if (result.routes)
    result.routes = result.routes.map((value) => parseRoute(t, value, flat, map, result));
  if (result.component) flat.push(result);
  map[result.path] = result;
  return result;
};
export const parser = (config: IRoute[], page404: ElementType, t: any) => {
  let map: {} = {};
  let flat: IRoute[] = [];
  let tree: IRoute[] = [
    ...config,
    {
      path: '*',
      title: '404',
      inMenu: false,
      private: false,
      component: page404,
    },
  ].map((value) => parseRoute(t, value, flat, map, null));
  return { flat, tree, map };
};

export const LayoutWrapper: FC<any> = (props) => {
  const route = useRouter();
  if (props.layout && props.routes[route.pathname] && props.routes[route.pathname].inLayout) {
    return <props.layout>{props.children}</props.layout>;
  }
  return <>{props.children}</>;
};

interface IRoutePrivateWrapperProps {
  route: IRoute;
  loginPath: string;
  page404: ElementType;
}
export const RoutePrivateWrapper = (props: IRoutePrivateWrapperProps) => {
  const auth = useAuth();
  const { pathname } = useRouter();

  if (props.route.private) {
    if (!auth.init && auth.loading) {
      return <h1>Loading...</h1>;
    } else if (auth.error || !auth.user) {
      return (
        <Navigate
          to={{
            pathname: props.loginPath,
            search: `?redirect=${pathname.slice(1)}`,
          }}
          replace
        />
      );
    } else if (auth.permission(props.route.permission)) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <props.route.component {...props} />
        </Suspense>
      );
    } else {
      return <props.page404 />;
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <props.route.component {...props} />
    </Suspense>
  );
};

export const Routes: FC<{
  routes: IRoute[];
  layout: ElementType;
  page404: ElementType;
  basename?: string;
  loginPath?: string;
}> = ({ routes, layout, page404, basename = '/', loginPath = '/login' }) => {
  const { t } = useI18n();
  // parse props
  const { flat, tree, map } = parser(routes, page404, t);
  // set store
  const dispatch = useDispatch();
  // TODO: need check init state, now hack to waiting init components
  setTimeout(() => {
    dispatch(initRoutes(tree, flat, map, basename, loginPath));
  }, 100);

  // render
  return (
    // render routes
    <BrowserRouter basename={basename}>
      <RouterRoutes>
        {flat?.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <LayoutWrapper layout={layout} routes={map}>
                <RoutePrivateWrapper route={route} loginPath={loginPath} page404={page404} />
              </LayoutWrapper>
            }
          />
        ))}
      </RouterRoutes>
    </BrowserRouter>
  );
};

export default useRouter;
