import { tCategory } from 'services/category';
import { tCompanyType } from 'services/company-type';
import { tDepartment } from 'services/department';
import { MaalotOutlook } from 'services/maalot-outlook';
import { MaalotRating } from 'services/maalot-rating';
import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { MoodysOutlook } from 'services/moodys-outlook';
import { MoodysRating } from 'services/moodys-rating';
import { tRatingPerformanceCategory } from 'services/rating-perfomance-category';
import { tSector } from 'services/sector';
import { tUserDetail } from 'services/user-detail';

const API = 'Company';

export const API_COMPANY = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<Company, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Company, 'id'>) => `${API}/Delete/${id}`,
};

export type Company = {
  id?: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  provisional?: boolean;
  longTermMidroogRatingID?: number | null;
  shortTermMidroogRatingID?: number | null;
  baselineCreditAssesmentRatingID?: number | null;
  bankLawerTier2CapitalRatingID?: number | null;
  bankUpperTier2CapitalRatingID?: number | null;
  bankTier1CapitalRatingID?: number | null;
  bankCocosRatingID?: number | null;
  insuranceFinancialStrengthRatingID?: number | null;
  insuranceTier2CapitalRatingID?: number | null;
  insuranceLawerTier2CapitalRatingID?: number | null;
  insuranceComplexTier2CapitalRatingID?: number | null;
  insuranceComplexTier3CapitalRatingID?: number | null;
  midroogOutlookID?: number | null;
  midroogRatingSymbolID?: number | null;
  moodysRatingID?: number | null;
  moodysOutlookID?: number | null;
  maalotRatingID?: number | null;
  maalotOutlookID?: number | null;
  longTermMidroogRating?: MidroogRating;
  shortTermMidroogRating?: MidroogRating;
  baselineCreditAssesmentRating?: MidroogRating;
  bankLawerTier2CapitalRating?: MidroogRating;
  bankUpperTier2CapitalRating?: MidroogRating;
  bankTier1CapitalRating?: MidroogRating;
  bankCocosRating?: MidroogRating;
  insuranceFinancialStrengthRating?: MidroogRating;
  insuranceTier2CapitalRating?: MidroogRating;
  insuranceLawerTier2CapitalRating?: MidroogRating;
  insuranceComplexTier2CapitalRating?: MidroogRating;
  insuranceComplexTier3CapitalRating?: MidroogRating;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbol?: MidroogRatingSymbol;
  moodysRating?: MoodysRating;
  moodysOutlook?: MoodysOutlook;
  maalotRating?: MaalotRating;
  maalotOutlook?: MaalotOutlook;
  sectorID?: number | null;
  categoryID?: number | null;
  companyTypeID?: number | null;
  ratingPerformanceCategoryID?: number | null;
  departmentID?: number | null;
  teamLeaderUserID?: string;
  analystUserID?: string;
  performanceMidroogRatingID?: number | null;
  performanceMidroogOutlookID?: number | null;
  name?: string;
  nameEng?: string;
  performanceNameHeb?: string;
  performanceNameEng?: string;
  priorityKey?: string;
  companyNumber?: string;
  address?: string;
  phone?: string;
  fax?: string;
  externalFileSystemLink?: string;
  remarks?: string;
  monitoringPrice?: number;
  monitoringPeriod?: boolean;
  monitoringPriorityOrderNumber?: string;
  monitoringOrderDate?: string | Date;
  base?: string;
  monitoringPercentage?: number;
  isFinancialInstrument?: boolean;
  isPublic?: boolean;
  isPublication?: boolean;
  isRatedEntity?: boolean;
  isIssuer?: boolean;
  isInCommercialProcess?: boolean;
  isInInitRatingProcess?: boolean;
  category?: tCategory;
  sector?: tSector;
  companyType?: tCompanyType;
  ratingPerformanceCategory?: tRatingPerformanceCategory;
  department?: tDepartment;
  performanceMidroogRating?: MidroogRating;
  performanceMidroogOutlook?: MidroogOutlook;
  teamLeader?: tUserDetail;
  analyst?: tUserDetail;
  addClientInitiateRating?: boolean;
  addClientPayRating?: boolean;
  totalRatingProcesses?: number;
  lastMonitoringRatingProcessID?: number;
  lastMonitoringRatingProcessDate?: string | Date;
  lastRatingProcessID?: number;
  lastRatingProcessDate?: string | Date;
  lastMonitoringRatingProcessDateExpired?: boolean;

  controllingGroups?: [];
  userWithConflictOfInterests?: [];
};
