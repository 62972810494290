import { put, call, select, takeEvery, all, delay } from 'redux-saga/effects';
import {addRoot, addChildren, updateChildren} from "../modules/sources"
import {getKey} from "../../components/source";
import {api} from "../../components";

interface AnyObject {
  [key: string]: unknown;
}

export interface SourcesState {
  sources: AnyObject;
  [key: string]: unknown;
}

export interface ISourceChildren {
  loading?: boolean,
  data?: Array<any>,
  map?: object,
  latest?: number,
  update?: boolean,
  watchers?: number
  mapping?: boolean
  fieldID?: string,
}

const sources = (state: SourcesState) => state.sources;

function* init({key, path, fieldID, mapping}: any) {
  yield delay(Math.floor(Math.random() * 50));
  // check root
  // @ts-ignore
  let root = yield select(sources);
  if (!root[key]) yield put(addRoot(key));
  // check children
  // @ts-ignore
  root = yield select(sources);
  if (!root[key][path]) {
    yield put(addChildren(key, path, fieldID, mapping));
    yield call(update, {key, path})
  } else {
    yield call(mount_unmount, {key, path});
  }
}

function* mount_unmount({key, path, methodType = 1}: any) {
  // @ts-ignore
  const root = yield select(sources);
  if (root[key] && root[key][path]) {
    yield put(updateChildren(key, path, {watchers: root[key][path].watchers + methodType}));
    if (methodType === 1) yield call(update, {key, path})
  } else {
    console.error('error API store - mount_unmount', key, path)
  }
}

function* sources_update({path}: any) {
  const key = getKey(path);
  // @ts-ignore
  const root = yield select();
  if (root.sources[key]) {
    yield all(Object.keys(root.sources[key]).map(path => call(update_helper, {key, path})));
  }
}

function* update_helper({key, path}: any) {
  yield put(updateChildren(key, path, {update: true}));
  yield call(update, {key, path});
}

function* update({key, path}: any) {
  // @ts-ignore
  const root = yield select();
  try {
    let source: ISourceChildren = root.sources[key][path];
    if (!source.loading) {
      // check exist listeners
      if (!source.watchers) {
        yield put(updateChildren(key, path, {update: true}));
      } else if (source.update) {
        yield put(updateChildren(key, path, {loading: true}));
        // @ts-ignore
        let response = yield call(api.get, path);
        let {fieldID, mapping}: any = source;
        const data = response.data.value ? response.data.value : Array.isArray(response.data) ? response.data : [];
        yield put(updateChildren(key, path, {
          loading: false,
          latest: (new Date()).getTime(),
          update: false,
          data: data,
          map: !mapping ? {} : data.reduce((result: any, value: any) => {
            result[value[fieldID]] = value;
            return result;
          }, {})
        }));
      }
    } else {
      // source now updating
      // may be need create concurrent computing
    }
  } catch (e) {
    console.error('error API store', key, path)
  }
}

export default [
  takeEvery('SOURCE_INIT', init),
  takeEvery('SOURCES_UPDATE', sources_update),
  takeEvery('SOURCE_UPDATE', update),
  takeEvery('SOURCE_MOUNT_UNMOUNT', mount_unmount),
];
