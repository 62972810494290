import {
  iRPMagnaReportActionTypes,
  iRPMagnaReportInitAction,
  iRPMagnaReportMergeAction,
  iRPMagnaReportSaveAction,
} from '../@type';

export const RPMagnaReportInit = (): iRPMagnaReportInitAction => {
  return { type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_INIT };
};

export const RPMagnaReportMerge = (
  payload: iRPMagnaReportMergeAction['payload'],
): iRPMagnaReportMergeAction => {
  return { type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_MERGE, payload };
};

export const RPMagnaReportSave = (
  payload: iRPMagnaReportSaveAction['payload'],
): iRPMagnaReportSaveAction => {
  return { type: iRPMagnaReportActionTypes.RP_MAGNA_REPORT_SAVE, payload };
};
