import { memo } from 'react';
// @ts-ignore
import Cleave from 'cleave.js/dist/cleave-react';

export const CleaveTime = memo((props: any) => {
  const { inputRef, onBlur, value, onChange, ...rest } = props;
  // render
  return (
    <Cleave
      htmlRef={inputRef}
      options={{
        time: true,
        timePattern: ['h', 'm'],
      }}
      onChange={onChange}
      value={value}
      {...rest}
      onBlur={(e: any) => {
        const { value } = e.target;
        if (rest.onChange && value.length && value.length < 5) {
          let val = value.split(':');
          e.target.value = [val[0] + '00', `${val[1] || ''}00`]
            .map((item) => item.slice(0, 2))
            .join(':');
          rest.onChange(e);
        }
        if (onBlur) onBlur(e);
      }}
    />
  );
});
