import React, { ReactNode, useEffect, useRef } from 'react';
import { api, apiStatic } from 'AurionCR/components';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { prepareDateToSave } from './index';

const instances = [apiStatic, api, axios];
const patchDotNet5 = (conf: InternalAxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object.entries(prepareDateToSave(conf.data))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return { path, value };
      });
  }
  return conf;
};

interface IAxiosInterceptorPatch {
  children: ReactNode;
}

export const AxiosInterceptorPatch: React.FC<IAxiosInterceptorPatch> = ({ children }) => {
  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      instances.map((instance) => {
        return instance.interceptors.request.use(patchDotNet5);
      });
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  return <>{children}</>;
};
