import { ClientDecision } from 'services/client-decision';
import { tRatingProcess } from 'services/rating-process';
import { RatingProcessStepType } from 'services/rating-process-step-type';

const API = 'RatingProcessStep';

export const API_RATING_PROCESS_STEP = {
  API,
  GET: ({ id }: { id: number }) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: { id: number }) => `${API}/Patch/${id}`,
  DELETE: ({ id }: { id: number }) => `${API}/Delete/${id}`,
};

export type tRatingProcessStep = {
  id: number | string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  ratingProcessID?: number;
  ratingProcessStepTypeID?: number;
  headOfFieldSignatureCode?: string;
  headOfFieldSignatureDate?: string | Date;
  clientUpdateDate?: string | Date;
  clientDecisionID?: number;
  isCompleted?: boolean;
  ratingProcess?: tRatingProcess;
  ratingProcessStepType?: RatingProcessStepType;
  clientDecision?: ClientDecision;
};
