import { memo, useMemo } from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { SvgIcon } from '@mui/material';
import { getImageSrc } from './helpers';

export const Image = memo(
  ({
    src,
    border = false,
    contain = false,
  }: {
    src: string;
    border?: boolean;
    contain?: boolean;
  }) => {
    const src_ = useMemo(() => getImageSrc(src), [src]);
    const className = `c-image ${border ? 'border' : ''} ${contain ? 'contain' : ''}`;
    if (src_) {
      return <div className={className} style={{ backgroundImage: `url(${src_})` }} />;
    } else {
      return (
        <div className={`${className} no-image`}>
          <SvgIcon component={ImageSearchIcon} />
        </div>
      );
    }
  },
);

export default Image;
