import { createConstFromEnum } from 'components/helpers';
import { CommitteePosition } from 'services/committee-position';
import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessStepCommitteeUser';

export const API_RATING_PROCESS_STEP_COMMITTEE_USER = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeUser, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeUser, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeUser, 'id'>) => `${API}/Delete/${id}`,
};

export enum eIsConflictOfInterests {
  No = 'No',
  Yes = 'Yes',
  // None = 'None',
}

export const ConflictOfInterests = createConstFromEnum(
  Object.entries(eIsConflictOfInterests),
  true,
);

export type tRatingProcessStepCommitteeUser = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  userID?: string | null;
  user?: tUserDetail;
  committeePositionID: number;
  committeePosition?: CommitteePosition;
  votingOrder: number;
  isAllowedToVote: boolean;
  conflictOfInterestsInfo: string | null;
  isNotConflictOfInterests: boolean | null;
  isConflictOfInterests: eIsConflictOfInterests | string | number;
};
