import { QuarterlyMonitoring } from 'services/quarterly-monitoring';
import { QuarterlyMonitoringPeriod } from 'services/quarterly-monitoring-period';

export interface PeriodItem {
  id: string;
  date: string | Date;
}

export interface DepartmentItem {
  id: number;
  title: string;
}

export interface iQMS {
  data: QuarterlyMonitoring & {
    ratedEntityName: string;
    financialStatementTrendTypeTitle: string;
    ratedEntityLongTermMidroogRatingTitle: string;
    ratedEntityMidroogOutlookTitle: string;
    ratedEntityLastMonitoringRatingProcessDate: string | Date;
    ratedEntityLastMonitoringRatingProcessDateExpired: boolean;
  };
  id: QuarterlyMonitoring['id'];
  loading: boolean;
}

export interface iStepAnalyticsTasks {
  loading: boolean;
  complete: boolean;
  selected: number;
  QMPeriod: Partial<QuarterlyMonitoringPeriod> | null;
  QMSList: number[];
  QMS: { [id: number]: iQMS };
}

export interface iStepMeeting {
  loading: boolean;
  complete: boolean;
}

export interface iStepFinish {
  loading: boolean;
  complete: boolean;
}

export interface QMState {
  // flags
  init: boolean;
  loading: boolean;
  openAddNew: boolean;
  step: 'stepAnalyticsTasks' | 'stepMeeting' | 'stepFinish';
  // sources
  sources: {
    periods: PeriodItem[];
    departments: DepartmentItem[];
  };
  // filters
  filters: {
    period: PeriodItem | null;
    department: DepartmentItem | null;
    ratedEntityID: number | null;
    analystUserID: string | null;
  };
  // steps
  stepAnalyticsTasks: iStepAnalyticsTasks;
  stepMeeting: iStepMeeting;
  stepFinish: iStepFinish;
}

export enum QMActionTypes {
  // Main
  QM_RESET = 'QM_RESET',
  QM_MERGE = 'QM_MERGE',
  QM_SOURCES_MERGE = 'QM_SOURCES_MERGE',
  QM_FILTERS_MERGE = 'QM_FILTERS_MERGE',
  // #saga
  QM_INIT = 'QM_INIT',
  QM_SYNC = 'QM_SYNC',
  QM_ADD_NEW = 'QM_ADD_NEW',
  QM_FILTER_DEPARTMENT = 'QM_FILTER_DEPARTMENT',

  // StepAnalyticsTasks
  QM_STEP_ANALYTICS_TASKS_RESET = 'QM_STEP_ANALYTICS_TASKS_RESET',
  QM_STEP_ANALYTICS_TASKS_MERGE = 'QM_STEP_ANALYTICS_TASKS_MERGE',
  QM_STEP_ANALYTICS_TASKS_QM_MERGE = 'QM_STEP_ANALYTICS_TASKS_QM_MERGE',
  // #saga
  QM_STEP_ANALYTICS_TASKS_GET_DATA = 'QM_STEP_ANALYTICS_TASKS_GET_DATA',
  QM_STEP_ANALYTICS_TASKS_QM_SAVE = 'QM_STEP_ANALYTICS_TASKS_QM_SAVE',

  // StepMeeting
  QM_STEP_MEETING_MERGE = 'QM_STEP_MEETING_MERGE',
  // #saga
  QM_STEP_MEETING_SAVE = 'QM_STEP_MEETING_SAVE',

  // StepMeeting
  QM_STEP_FINISH_MERGE = 'QM_STEP_FINISH_MERGE',
  // #saga
  QM_STEP_FINISH_SAVE = 'QM_STEP_FINISH_SAVE',
}

export interface QMResetAction {
  type: QMActionTypes.QM_RESET;
}

export interface QMMergeAction {
  type: QMActionTypes.QM_MERGE;
  payload: Partial<QMState>;
}

export interface QMFiltersMergeAction {
  type: QMActionTypes.QM_FILTERS_MERGE;
  payload: Partial<QMState['filters']>;
}

export interface QMSourcesMergeAction {
  type: QMActionTypes.QM_SOURCES_MERGE;
  payload: Partial<QMState['sources']>;
}

export interface QMInitAction {
  type: QMActionTypes.QM_INIT;
}

export interface QMSyncAction {
  type: QMActionTypes.QM_SYNC;
}

export interface QMAddNewAction {
  type: QMActionTypes.QM_ADD_NEW;
  payload: any;
}

export interface QMFilterDepartmentAction {
  type: QMActionTypes.QM_FILTER_DEPARTMENT;
}

// StepAnalyticsTasks
export interface QMStepAnalyticsTasksResetAction {
  type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_RESET;
  payload?: Partial<iStepAnalyticsTasks>;
}

export interface QMStepAnalyticsTasksMergeAction {
  type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_MERGE;
  payload: Partial<iStepAnalyticsTasks>;
}

export interface QMStepAnalyticsTasksGetDataAction {
  type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_GET_DATA;
}

export interface QMStepAnalyticsTasksQMMergeAction {
  type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_MERGE;
  payload: Partial<iQMS> & { id: iQMS['id'] };
}

export interface QMStepAnalyticsTasksQMSaveAction {
  type: QMActionTypes.QM_STEP_ANALYTICS_TASKS_QM_SAVE;
  payload: Partial<iQMS> & { id: iQMS['id'] };
}

// StepMeeting
export interface QMStepMeetingMergeAction {
  type: QMActionTypes.QM_STEP_MEETING_MERGE;
  payload: Partial<iStepMeeting>;
}

export interface QMStepMeetingMergeSaveAction {
  type: QMActionTypes.QM_STEP_MEETING_SAVE;
  payload: Partial<QuarterlyMonitoringPeriod>;
}

// StepFinish
export interface QMStepFinishMergeAction {
  type: QMActionTypes.QM_STEP_FINISH_MERGE;
  payload: Partial<iStepFinish>;
}

export interface QMStepFinishMergeSaveAction {
  type: QMActionTypes.QM_STEP_FINISH_SAVE;
  payload: Partial<QuarterlyMonitoringPeriod>;
}

export type QMAction =
  | QMResetAction
  | QMMergeAction
  | QMFiltersMergeAction
  | QMSourcesMergeAction
  | QMInitAction
  | QMSyncAction
  | QMAddNewAction
  | QMFilterDepartmentAction
  // StepAnalyticsTasks
  | QMStepAnalyticsTasksResetAction
  | QMStepAnalyticsTasksMergeAction
  | QMStepAnalyticsTasksGetDataAction
  | QMStepAnalyticsTasksQMSaveAction
  | QMStepAnalyticsTasksQMMergeAction
  // StepMeeting
  | QMStepMeetingMergeAction
  | QMStepMeetingMergeSaveAction
  // StepFinish
  | QMStepFinishMergeAction
  | QMStepFinishMergeSaveAction;
