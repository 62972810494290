import { tRatingProcess } from 'services/rating-process';
import { tRatingProcessStepUpdate } from 'services/rating-process-step-update';
import { iRPState } from './index';

export enum iPRSteps {
  InformationRequest = 1,
  Forecast,
  Committee,
  Report,
  Publication,
  Done = 1000,
}

export interface iRatingProcessStepUpdate extends Partial<tRatingProcessStepUpdate> {
  id: number;
}

export interface iRPMainDataSteps {
  id: number;
  ratingProcessStepTypeID?: iPRSteps;
  ratingProcessStepTypeTitle?: string;
  clientDecisionTitle?: string;
  headOfFieldSignatureDate?: string | Date;
  isCompleted?: boolean;
  clientUpdateDate?: string | Date;
  // calculate
  index: number;
  isStepEdit: boolean;
  log: iRatingProcessStepUpdate[];
  update: iRatingProcessStepUpdate | null;
}

export interface iRPMainData extends tRatingProcess {
  ratingProcessSteps: iRPMainDataSteps[];
  isRPCompleted: boolean;
  ratingProcessStatusID: number;
  ratedEntityName: string;
  headOfFieldFullName: string;
  ratingProcessTypeTitle: string;
}

// ACTIONS
export enum iRPActionTypes {
  RP_RESET = 'RP_RESET',
  RP_MERGE = 'RP_MERGE',
  RP_MERGE_MAIN = 'RP_MERGE_MAIN',
  RP_MAIN_UPDATE_STEP = 'RP_MAIN_UPDATE_STEP',
  RP_MAIN_UPDATE_STEP_LOG = 'RP_MAIN_UPDATE_STEP_LOG',
  RP_MAIN_PUSH_STEP_LOG = 'RP_MAIN_PUSH_STEP_LOG',
  // saga
  RP_INIT = 'RP_INIT',
  RP_SAVE_MAIN = 'RP_SAVE_MAIN',
  RP_UPDATE_RATING_PROCESS = 'RP_UPDATE_RATING_PROCESS',
  RP_STEP_REJECT = 'RP_STEP_REJECT',
  RP_STEP_APPROVE = 'RP_STEP_APPROVE',
  RP_STEP_UPDATE = 'RP_STEP_UPDATE',
  RP_STEP_UPDATE_IGNORE = 'RP_STEP_UPDATE_IGNORE',
  RP_MAIN_DISPLAY_SERIES = 'RP_MAIN_DISPLAY_SERIES',
  RP_BACK_TO_STEP_INDEX = 'RP_BACK_TO_STEP_INDEX',
}

export interface iRPResetAction {
  type: iRPActionTypes.RP_RESET;
}

export interface iRPMergeAction {
  type: iRPActionTypes.RP_MERGE;
  payload: Partial<iRPState>;
}

export interface iRPMergeMainAction {
  type: iRPActionTypes.RP_MERGE_MAIN;
  payload: Partial<iRPMainData>;
}

export interface iRPInitAction {
  type: iRPActionTypes.RP_INIT;
  payload: { id: number; anyway?: boolean };
}

export interface iRPSaveMainAction {
  type: iRPActionTypes.RP_SAVE_MAIN;
  payload: Partial<iRPMainData> & { triggerClose?: iRPState['triggerClose'] };
}

export interface iRPUpdateRatingProcess {
  type: iRPActionTypes.RP_UPDATE_RATING_PROCESS;
  payload: { ratingProcessTypeID: number };
}

export interface iRPStepRejectAction {
  type: iRPActionTypes.RP_STEP_REJECT;
  payload: Partial<iRPMainData>;
}

export interface iRPStepApproveAction {
  type: iRPActionTypes.RP_STEP_APPROVE;
  payload: Partial<iRPMainData>;
}

export interface iRPMainUpdateStepAction {
  type: iRPActionTypes.RP_MAIN_UPDATE_STEP;
  payload: Partial<iRPMainDataSteps> & { stepIndex: number };
}

export interface iRPMainUpdateStepLogAction {
  type: iRPActionTypes.RP_MAIN_UPDATE_STEP_LOG;
  payload: Partial<iRatingProcessStepUpdate> & { stepIndex: number };
}

export interface iRPMainPushStepLogAction {
  type: iRPActionTypes.RP_MAIN_PUSH_STEP_LOG;
  payload: iRatingProcessStepUpdate & { stepIndex: number };
}

export interface iRPStepUpdateAction {
  type: iRPActionTypes.RP_STEP_UPDATE;
  payload: Partial<iRatingProcessStepUpdate> & { callback?: () => void; setLoading?: boolean };
}

export interface iRPStepUpdateIgnoreAction {
  type: iRPActionTypes.RP_STEP_UPDATE_IGNORE;
}

export interface iRPMainDisplaySeriesAction {
  type: iRPActionTypes.RP_MAIN_DISPLAY_SERIES;
}

export interface iRPBackToStepIndexAction {
  type: iRPActionTypes.RP_BACK_TO_STEP_INDEX;
  payload: { stepIndex: number };
}
