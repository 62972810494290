import { createContext, FC, memo, ReactNode, useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useI18n } from './i18';

export const Loading = memo(
  ({
    active = true,
    zIndex = 1000,
    background = 'rgba(255,255,255,.5)',
    size = 50,
  }: {
    active?: boolean;
    zIndex?: number;
    background?: string;
    size?: number;
  }) => {
    if (!active) return null;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex,
          background: background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={size} />
      </div>
    );
  },
);
const initState: { loading: boolean; setLoading: any } = { loading: false, setLoading: null };
export const LoadingOverlayContext = createContext(initState);
export const useLoadingOverlay = () => useContext(LoadingOverlayContext);

interface ILoadingOverlayProvider {
  children: ReactNode;
}

export const LoadingOverlayProvider: FC<ILoadingOverlayProvider> = ({ children }) => {
  const auth = useSelector((state) => (state as any).auth);
  const labels = useI18n();
  const [loading, setLoading] = useState(false);
  return (
    <LoadingOverlayContext.Provider value={{ loading, setLoading }}>
      {auth.init && children}
      <Loading active={loading || auth.loading || labels.loading} />
    </LoadingOverlayContext.Provider>
  );
};
