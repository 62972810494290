import React, { ReactNode, useCallback } from 'react';
import { AxiosInterceptorsToken } from './token';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { apiStatic, baseURL, getCookies, setCookies } from 'AurionCR/components';
import { AxiosInterceptorPatch } from './patch';

import { AxiosInterceptorsSources } from './sources';
import { AxiosInterceptorPost } from './post';
import { format, isValid } from 'date-fns';

const refresh = async (data: { token: string; refreshToken: string }) => {
  const response = await apiStatic.post('Account/RefreshToken', data);
  const {
    data: { token },
  } = response;
  return fetch(`${baseURL}Account/GetCurrentAppUser`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return response;
  });
};

export const updateTokenAll = ({ token = '', refreshToken = '', expires = '' } = {}) => {
  // update cookie
  setCookies(window.authCookieName_, token, expires);
  setCookies(window.authRefreshCookieName_, refreshToken, expires);
  setCookies(window.authDateCookieName_, expires, expires);
};

interface IAxiosInterceptors {
  children: ReactNode;
}

export const AxiosInterceptors: React.FC<IAxiosInterceptors> = ({ children }) => {
  const onRefresh = useCallback(async () => {
    const token = getCookies(window.authCookieName_);
    const refreshToken = getCookies(window.authRefreshCookieName_);
    const expires = getCookies(window.authDateCookieName_) as string;

    if (!token || !refreshToken) {
      throw new Error('token-not-exist');
    }

    const {
      data: { refreshToken: RefreshToken, token: Token },
    } = await refresh({ token, refreshToken });

    return { token: Token, refreshToken: RefreshToken, expires };
  }, []);

  const onError = useCallback(() => {
    if (window.location.pathname !== '/login') {
      window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <AxiosInterceptorPost>
      <AxiosInterceptorPatch>
        <AxiosInterceptorsToken>
          <AxiosInterceptorsRefreshToken
            onRefresh={onRefresh}
            onSuccess={updateTokenAll}
            onError={onError}
          >
            <AxiosInterceptorsSources>{children}</AxiosInterceptorsSources>
          </AxiosInterceptorsRefreshToken>
        </AxiosInterceptorsToken>
      </AxiosInterceptorPatch>
    </AxiosInterceptorPost>
  );
};

export const prepareDateToSave = <M extends { [x: string]: any | Date }>(data: M) => {
  let _data = { ...data };
  Object.keys(data).forEach((key: keyof M) => {
    // @ts-ignore
    _data[key] =
      // @ts-ignore
      data[key] && data[key] instanceof Date
        ? // @ts-ignore
          isValid(data[key])
          ? format(data[key], "yyyy-MM-dd'T'HH:mm:ss")
          : data[key]
        : data[key];
  });

  return _data;
};
