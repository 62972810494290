import update from 'immutability-helper';
import {
  iRPAction,
  iRPActionTypes,
  iRPMagnaReportActionTypes,
  iRPPrivateMagnaReportActionTypes,
  iRPState,
  iRPStepDoneActionTypes,
  iRPStepDoneFormActionTypes,
  iRPStepType2ActionTypes,
  iRPStepType3ActionTypes,
  iRPStepType4ActionTypes,
  iRPStepType5ActionTypes,
} from './@type';

export const initialState = (): iRPState => ({
  // flags
  init: false,
  loading: false,
  currentStep: 0,
  triggerClose: null,
  displaySeriesBy: null,
  // main | step 1
  main: null,
  // step2
  stepType2: {
    init: false,
    loading: false,
    list: [],
  },
  // step3
  stepType3: {
    init: false,
    loading: false,
    list: [],
  },
  // step4
  stepType4: {
    init: false,
    loading: false,
    isEditReportIndex: null,
    list: [],
  },
  // step5
  stepType5: {
    init: false,
    loading: false,
    isSubmit: false,
    isEditReport: false,
    data: {},
    checkups: [],
  },
  // step done
  stepDone: {
    isInit: false,
    isLoading: false,
    data: null,
  },
  stepDoneForm: {
    isInit: false,
    isLoading: false,
    data: null,
  },
  // magnaReport
  magnaReport: {
    isInit: false,
    isLoading: false,
    isOpen: false,
    series: [],
  },
  privateMagnaReport: {
    isInit: false,
    isLoading: false,
    isOpen: false,
    series: [],
  },
});

export const RP = (state = initialState(), action: iRPAction): iRPState => {
  switch (action.type) {
    case iRPActionTypes.RP_RESET: {
      return initialState();
    }
    case iRPActionTypes.RP_MERGE: {
      return update(state, { $merge: action.payload });
    }
    case iRPActionTypes.RP_MERGE_MAIN: {
      return update(state, { main: { $merge: action.payload } });
    }
    case iRPActionTypes.RP_MAIN_UPDATE_STEP: {
      const { stepIndex, ...rest } = action.payload;
      const step = state?.main?.ratingProcessSteps[stepIndex];
      if (step) {
        return update(state, { main: { ratingProcessSteps: { [stepIndex]: { $merge: rest } } } });
      } else {
        return state;
      }
    }
    case iRPActionTypes.RP_MAIN_UPDATE_STEP_LOG: {
      const { stepIndex, ...rest } = action.payload;
      const up = state?.main?.ratingProcessSteps[stepIndex]?.update;
      if (up) {
        return update(state, {
          main: { ratingProcessSteps: { [stepIndex]: { update: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPActionTypes.RP_MAIN_PUSH_STEP_LOG: {
      const { stepIndex, ...rest } = action.payload;
      const up = state?.main?.ratingProcessSteps[stepIndex]?.update;
      if (up) {
        return update(state, {
          main: { ratingProcessSteps: { [stepIndex]: { log: { $push: [rest] } } } },
        });
      } else {
        return state;
      }
    }

    // :TODO Step Type2 Actions
    case iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE: {
      return update(state, { stepType2: { $merge: action.payload } });
    }
    case iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_LIST_ITEM: {
      return update(state, { stepType2: { list: { $push: [action.payload] } } });
    }
    case iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST: {
      const { id, ...rest } = action.payload;
      const index = (state?.stepType2?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType2: { list: { [index]: { $merge: rest } } } });
      } else {
        return state;
      }
    }
    case iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST_DATA: {
      const { id, ...rest } = action.payload;
      const index = (state?.stepType2?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType2: { list: { [index]: { data: { $merge: rest } } } } });
      } else {
        return state;
      }
    }
    case iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE_LIST_ITEM: {
      const { id } = action.payload;
      const index = (state?.stepType2?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType2: { list: { $splice: [[index, 1]] } } });
      } else {
        return state;
      }
    }

    // :TODO Step Type3 Actions
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE: {
      return update(state, { stepType3: { $merge: action.payload } });
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_ADD_LIST_ITEM: {
      return update(state, { stepType3: { list: { $push: [action.payload] } } });
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE_LIST: {
      const { id, ...rest } = action.payload;
      const index = (state?.stepType3?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType3: { list: { [index]: { $merge: rest } } } });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE_LIST_DATA: {
      const { id, ...rest } = action.payload;
      const index = (state?.stepType3?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType3: { list: { [index]: { data: { $merge: rest } } } } });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_DELETE_LIST_ITEM: {
      const { id } = action.payload;
      const index = (state?.stepType3?.list || []).findIndex((item) => item.id === id);
      if (index !== -1) {
        return update(state, { stepType3: { list: { $splice: [[index, 1]] } } });
      } else {
        return state;
      }
    }
    // user
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.user) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { user: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.user?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { user: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.user?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                user: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_DELETE_ITEM_DATA: {
      const { committeeIndex, itemIndex } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.user?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                user: { list: { $splice: [[itemIndex, 1]] } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // userRatEntVot
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userRatEntVot) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { userRatEntVot: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userRatEntVot?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { userRatEntVot: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userRatEntVot?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                userRatEntVot: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM_DATA: {
      const { committeeIndex, itemIndex } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userRatEntVot?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                userRatEntVot: { list: { $splice: [[itemIndex, 1]] } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // userRatEntVot
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.seriesRating) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { seriesRating: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.seriesRating?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { seriesRating: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.seriesRating?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                seriesRating: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM_DATA: {
      const { committeeIndex, itemIndex } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.seriesRating?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                seriesRating: { list: { $splice: [[itemIndex, 1]] } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // userRatEntVot
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userSeriesVoting) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { userSeriesVoting: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_MERGE: {
      const { committeeIndex, seriesID, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userSeriesVoting?.map[seriesID]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { userSeriesVoting: { map: { [seriesID]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_MERGE: {
      const { committeeIndex, seriesID, userID, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.userSeriesVoting?.map[seriesID]?.users[userID]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                userSeriesVoting: {
                  map: { [seriesID]: { users: { [userID]: { $merge: rest } } } },
                },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SERIES_VOTING_ITEM_USER_DATA_MERGE: {
      const { committeeIndex, seriesID, userID, ...rest } = action.payload;
      if (
        state?.stepType3?.list[committeeIndex]?.userSeriesVoting?.map[seriesID]?.users[userID]?.data
      ) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                userSeriesVoting: {
                  map: { [seriesID]: { users: { [userID]: { data: { $merge: rest } } } } },
                },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // significant changes
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.significantChange) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { significantChange: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.significantChange?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { significantChange: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.significantChange?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                significantChange: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // materials
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.materials) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { materials: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.materials?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { materials: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.materials?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                materials: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }
    // resources
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE: {
      const { committeeIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.resources) {
        return update(state, {
          stepType3: { list: { [committeeIndex]: { resources: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE_ITEM: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.resources?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: { resources: { list: { [itemIndex]: { $merge: rest } } } },
            },
          },
        });
      } else {
        return state;
      }
    }
    case iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE_ITEM_DATA: {
      const { committeeIndex, itemIndex, ...rest } = action.payload;
      if (state?.stepType3?.list[committeeIndex]?.resources?.list[itemIndex]) {
        return update(state, {
          stepType3: {
            list: {
              [committeeIndex]: {
                resources: { list: { [itemIndex]: { data: { $merge: rest } } } },
              },
            },
          },
        });
      } else {
        return state;
      }
    }

    // :TODO Step Type4 Actions
    case iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE: {
      return update(state, { stepType4: { $merge: action.payload } });
    }
    case iRPStepType4ActionTypes.RP_STEP_TYPE_4_ADD_LIST_ITEM: {
      return update(state, { stepType4: { list: { $push: [action.payload] } } });
    }
    case iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST: {
      const { reportIndex, ...rest } = action.payload;
      if (state?.stepType4?.list[reportIndex]) {
        return update(state, { stepType4: { list: { [reportIndex]: { $merge: rest } } } });
      } else {
        return state;
      }
    }
    case iRPStepType4ActionTypes.RP_STEP_TYPE_4_MERGE_LIST_DATA: {
      const { reportIndex, ...rest } = action.payload;
      if (state?.stepType4?.list[reportIndex]) {
        return update(state, {
          stepType4: { list: { [reportIndex]: { data: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }
    case iRPStepType4ActionTypes.RP_STEP_TYPE_4_DELETE_LIST_ITEM: {
      const { reportIndex } = action.payload;
      if (state?.stepType4?.list[reportIndex]) {
        return update(state, { stepType4: { list: { $splice: [[reportIndex, 1]] } } });
      } else {
        return state;
      }
    }

    // :TODO Step Type5 Actions
    case iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE: {
      return update(state, { stepType5: { $merge: action.payload } });
    }
    case iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_DATA: {
      return update(state, { stepType5: { data: { $merge: action.payload } } });
    }
    case iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP: {
      const { index, ...rest } = action.payload;
      if (state?.stepType5?.checkups[index]) {
        return update(state, { stepType5: { checkups: { [index]: { $merge: rest } } } });
      } else {
        return state;
      }
    }
    case iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP_DATA: {
      const { index, ...rest } = action.payload;
      if (state?.stepType5?.checkups[index]) {
        return update(state, {
          stepType5: { checkups: { [index]: { data: { $merge: rest } } } },
        });
      } else {
        return state;
      }
    }

    // :TODO Step Done
    case iRPStepDoneActionTypes.RP_STEP_DONE_MERGE: {
      return update(state, { stepDone: { $merge: action.payload } });
    }

    case iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_MERGE: {
      return update(state, { stepDoneForm: { $merge: action.payload } });
    }

    // :TODO Magna Eeport
    case iRPMagnaReportActionTypes.RP_MAGNA_REPORT_MERGE: {
      return update(state, { magnaReport: { $merge: action.payload } });
    }

    case iRPPrivateMagnaReportActionTypes.RP_PRIVATE_MAGNA_REPORT_MERGE: {
      return update(state, { privateMagnaReport: { $merge: action.payload } });
    }
    default:
      return state;
  }
};
