import { tClientInitiateRating } from 'services/client-initiate-rating';
import { Company } from 'services/company';
import { tDepartment } from 'services/department';
import { tOrderSource } from 'services/order-source';
import { tOrderStatus } from 'services/order-status';

const API = 'Order';

export const API_ORDER = {
  API,
  GET: ({ id }: Pick<tOrder, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tOrder, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tOrder, 'id'>) => `${API}/Delete/${id}`,
};

export type tOrder = {
  id?: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;

  clientPayRatingID?: number | string;
  clientInitiateRatingID?: number | string;
  ratedEntityID?: number | string;
  orderStatusID?: number;
  orderStepID?: number;
  orderSourceID?: number;
  departmentID?: number | string;
  title?: string;
  orderDate?: string | Date;
  leadDate?: string | Date;
  priorityOrderID?: string;
  agreementFilePath?: string;
  remarks?: string;
  monitoringPrice?: number;
  capitalRaised?: number;
  capitalRaiseDate?: string | Date;
  issueCertificateFilePath?: string;
  structuredFinance?: boolean;
  internalUse?: boolean;
  isPrivate?: boolean;
  startMonitoring?: boolean;
  isFavorite?: boolean;
  isCompleted?: boolean;

  clientPayRating?: tClientInitiateRating;
  clientInitiateRating?: tClientInitiateRating;
  ratedEntity?: Company;
  orderStatus?: tOrderStatus;
  // orderStep?:	OrderStep;
  orderSource?: tOrderSource;
  department?: tDepartment;

  isTeamRequest?: boolean;
  hasRatingProcess?: boolean;
  hasDetails?: boolean;
  hasRaisedCapitalPaymentTransaction?: boolean;
  paymentAmount?: number;
  productTitle?: string;

  paymentTransactions?: [];
};
