const API = 'RatingProcessHelper';

export const API_RATING_PROCESS_HELPER = {
  API,
  CREATE_RATING_PROCESS: `${API}/CreateRatingProcess`,
  INIT_RATING_PROCESS: (ratingProcessID: number) =>
    `${API}/InitRatingProcess?ratingProcessID=${ratingProcessID}`,
  INIT_RATING_PROCESS_FOR_UPDATE: (ratingProcessID: number) =>
    `${API}/InitRatingProcessForUpdate?ratingProcessID=${ratingProcessID}`,
  REJECT_RATING_PROCESS: `${API}/RejectRatingProcess`,
  APPROVE_RATING_PROCESS_STEP: `${API}/ApproveRatingProcessStep`,
  ADD_NEW_REVIEW: (ratingProcessID: number) =>
    `${API}/AddNewReview?ratingProcessID=${ratingProcessID}`,
  ADD_NEW_DRAFT_REPORT: (ratingProcessID: number, decisionID: 5 | 4) =>
    `${API}/AddNewDraftReport?ratingProcessID=${ratingProcessID}&decisionID=${decisionID}`,
  ADD_NEW_COMMITTEE: (ratingProcessID: number) =>
    `${API}/AddNewCommittee?ratingProcessID=${ratingProcessID}`,
  IMPORT_USERS_FOR_COMMITTEE_RATED_ENTRY_VOTING: (ratingProcessStepCommitteeID: number) =>
    `${API}/ImportUsersForCommitteeRatedEntityVotings?ratingProcessStepCommitteeID=${ratingProcessStepCommitteeID}`,
  IMPORT_USERS_FOR_COMMITTEE_SERIES_VOTING: (
    ratedEntityID: number,
    ratingProcessStepCommitteeID: number,
  ) =>
    `${API}/ImportUsersForCommitteeSeriesVotings?ratedEntityID=${ratedEntityID}&ratingProcessStepCommitteeID=${ratingProcessStepCommitteeID}`,
  APPlY_VOTING_FOR_COMMITTEE_SERIES: (
    ratingProcessStepCommitteeID: number,
    midroogRatingID: number,
    midroogOutlookID: number,
  ) =>
    `${API}/ApplyVotingForCommitteeSeries?ratingProcessStepCommitteeID=${ratingProcessStepCommitteeID}&midroogRatingID=${midroogRatingID}&midroogOutlookID=${midroogOutlookID}`,
  APPlY_USER_VOTING_FOR_COMMITTEE_SERIES: (
    ratingProcessStepCommitteeID: number,
    userID: string,
    midroogRatingID: number,
    midroogOutlookID: number,
  ) =>
    `${API}/ApplyUserVotingForCommitteeSeries?ratingProcessStepCommitteeID=${ratingProcessStepCommitteeID}&userID=${userID}&midroogRatingID=${midroogRatingID}&midroogOutlookID=${midroogOutlookID}`,
  APPlY_ALL_USERS_VOTING_FOR_COMMITTEE_SERIES: (
    ratingProcessStepCommitteeID: number,
    midroogRatingID: number,
    midroogOutlookID: number,
  ) =>
    `${API}/ApplyAllUsersVotingForCommitteeSeries?ratingProcessStepCommitteeID=${ratingProcessStepCommitteeID}&midroogRatingID=${midroogRatingID}&midroogOutlookID=${midroogOutlookID}`,
  FINISH_RATING_PROCESS: (ratingProcessID: number) =>
    `${API}/FinishRatingProcess?ratingProcessID=${ratingProcessID}`,
  FINISH_RATING_PROCESS_FOR_UPDATE: (ratingProcessID: number) =>
    `${API}/FinishRatingProcessForUpdate?ratingProcessID=${ratingProcessID}`,
  UPDATE_RATING_PROCESS_TYPE: (ratingProcessID: number, ratingProcessTypeID: number) =>
    `${API}/UpdateRatingProcessType?ratingProcessID=${ratingProcessID}&ratingProcessTypeID=${ratingProcessTypeID}`,
};

export interface iCreateRatingProcessRequest {
  ratedEntityID: number;
  ratingProcessTypeID: number;
  committeeTypeID: number;
  linkedRatingProcessID: number | null;
  orderID: number | null;
  ratingProcessStepTypeIDs: number[];
}

export interface iRejectRatingProcessRequest {
  ratingProcessID: number;
  stepID: number;
  completeRatingProcessDate: string | Date;
}

export interface iApproveRatingProcessStepRequest {
  ratingProcessID: number;
  ratingProcessStepID: number;
  clientDecisionID: number | null;
  headOfFieldSignatureDate: string | Date;
}
