import { ButtonBase } from '@mui/material';
import { apiStatic, Loading, requestError, useAuth, useI18n, useRouter } from 'AurionCR/components';
import { createFilterContains } from 'utils/dynamic-filters';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import axios from 'axios';
import clsx from 'clsx';
import { format, isValid } from 'date-fns';
import { memo, useCallback, useEffect, useState, MouseEvent } from 'react';
import { API_RATING_PROCESS, tRatingProcess } from 'services/rating-process';
import { useAppDispatch } from 'store';
import style from './index.module.scss';

interface Item {
  id: number;
  ratedEntityName: string;
  ratingProcessTypeTitle: string;
  ratingProcessStatusTitle: string;
  discussionDate: string;
}

export const RatingProcesses = memo(
  ({ search, onClose }: { search: string; onClose: () => void }) => {
    const { t } = useI18n();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const router = useRouter();
    // state
    const [isAllowed] = useState(auth.permission('isAllowToViewRatingProcesses'));
    const [data, setData] = useState<Item[]>([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // handlers
    const onClick = useCallback(
      (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, id: number) => {
        e.stopPropagation();
        e.preventDefault();
        router.push(`/professional-processes/rating-processes?RP=${id}`);
        onClose();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClose],
    );
    useEffect(() => {
      const CancelToken = axios.CancelToken;
      let cancel: any;
      // 'search'
      if (isAllowed) {
        setIsLoading(true);
        apiStatic
          .get(`${API_RATING_PROCESS.GET_ALL_DYNAMIC}`, {
            params: {
              select: [
                'id',
                'ratedEntity.name as ratedEntityName',
                'ratingProcessType.title as ratingProcessTypeTitle',
                'ratingProcessStatus.title as ratingProcessStatusTitle',
                'discussionDate',
              ].join(),
              filter: search
                ? createFilterContains<tRatingProcess & { 'id.toString()': string }>(
                    [
                      'id.toString()',
                      'title',
                      'ratedEntity.name',
                      'ratingProcessType.title',
                      'ratingProcessStatus.title',
                    ],
                    search,
                  )
                : undefined,
              orderBy: 'id desc',
              take: 25,
              count: true,
            },
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          })
          .then(({ data }) => {
            const result = data?.value || [];
            setData(
              result.map((item: Item) => {
                const discussionDate = item.discussionDate ? new Date(item.discussionDate) : '';
                return {
                  ...item,
                  discussionDate:
                    discussionDate && isValid(discussionDate)
                      ? format(discussionDate, 'dd/MM/yyyy')
                      : '',
                };
              }),
            );
            setTotal(data?.count || 0);
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              dispatch(notifyRequestResult(requestError(e, e.message), 'error'));
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      return () => {
        if (cancel) cancel();
      };
    }, [isAllowed, setData, setTotal, setIsLoading, dispatch, search]);
    // render
    return isAllowed ? (
      <div className={style.result}>
        <div className={style.title}>
          <div>{t('rating-processes')}</div>
          <div className={style.pagination}>
            <strong>{data.length}</strong> {t('of')} <strong>{total}</strong>
          </div>
        </div>
        <div className={'native-scroll scroll-size-s'}>
          <div className={clsx('native-scroll__helper')}>
            {data.map(
              ({
                id,
                ratedEntityName,
                ratingProcessTypeTitle,
                discussionDate,
                ratingProcessStatusTitle,
              }) => (
                <ButtonBase key={id} onClick={(e) => onClick(e, id)} className={style.btn}>
                  #{id}: {ratedEntityName} {ratingProcessTypeTitle} ({discussionDate}-
                  {ratingProcessStatusTitle})
                </ButtonBase>
              ),
            )}
          </div>
          {isLoading && <Loading />}
        </div>
      </div>
    ) : null;
  },
);
