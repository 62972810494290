import { FC, memo, ReactNode } from 'react';
import { Tabs } from '@mui/material';

interface Props {
  value: number;
  onChange: (val: number) => void;
  children?: ReactNode;
}

export const FormTabs: FC<Props> = memo(({ value, onChange, children }) => {
  return (
    <Tabs
      value={value}
      onChange={(_, newValue: number) => onChange(newValue)}
      indicatorColor={'primary'}
      textColor={'primary'}
    >
      {children}
    </Tabs>
  );
});

export default FormTabs;
