import { useI18n } from 'AurionCR/components';
import clsx from 'clsx';
import { Orders } from './orders';
import { Issuers } from './issuers';
import { Methodologies } from './methodologies';
import { RatingProcesses } from './rating-processes';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import style from './index.module.scss';
import { ClickAwayListener, Popper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchReport = () => {
  const { t } = useI18n();
  // state
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  // handlers
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(debounce(setSearch, 400), [setSearch]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
      debounceOnChange(e.currentTarget.value);
    },
    [setValue, debounceOnChange],
  );
  const onClick = useCallback(() => {
    if (inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  }, [inputRef]);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const onClose = useCallback(() => {
    setIsOpen(false);
    setValue('');
    setSearch('');
  }, [setIsOpen, setSearch, setValue]);
  // render
  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div
          ref={wrapperRef}
          className={clsx(style.wrapper, isOpen && style.open)}
          onClick={onClick}
        >
          <div className={style.search}>
            <input
              ref={inputRef}
              value={value}
              onChange={onChange}
              className={style.input}
              placeholder={t('search')}
              onFocus={onOpen}
            />
            <SearchIcon className={style.icon} />
          </div>
          {isOpen && (
            <Popper
              open={true}
              anchorEl={wrapperRef?.current}
              placement="bottom-end"
              className={style.popper}
            >
              <div className={style.results}>
                <Issuers search={search} onClose={onClose} />
                <RatingProcesses search={search} onClose={onClose} />
                <Methodologies search={search} onClose={onClose} />
                <Orders search={search} onClose={onClose} />
              </div>
            </Popper>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};
