import main from './main';
import stepType2 from './step-type-2';
import stepType3 from './step-type-3';
import stepType4 from './step-type-4';
import stepType5 from './step-type-5';
import stepDone from './step-done';
import stepDoneForm from './step-done-form';
import magnaReport from './magna-report';
import privateMagnaReport from './private-magna-report';

export const RPSagas = [
  ...main,
  ...stepType2,
  ...stepType3,
  ...stepType4,
  ...stepType5,
  ...stepDone,
  ...stepDoneForm,
  ...magnaReport,
  ...privateMagnaReport,
];
export * from './main';
