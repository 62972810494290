import { MidroogRating } from 'services/midroog-rating';
import { tRatingProcessStep } from 'services/rating-process-step';

const API = 'RatingProcessStepReview';

export const API_RATING_PROCESS_STEP_REVIEW = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepReview, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepReview, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepReview, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepReview = {
  id: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;

  ratingProcessStepID?: number;
  ratingProcessStep?: tRatingProcessStep;
  midroogRatingID?: number | null;
  midroogRating?: MidroogRating;
  minRangeMidroogRatingID?: number | null;
  minRangeMidroogRating?: MidroogRating;
  maxRangeMidroogRatingID?: number | null;
  maxRangeMidroogRating?: MidroogRating;
  review?: string;
  reviewDate?: string | Date | null;
};
