import {
  iRPStepType3ActionTypes,
  iRPStepType3SeriesRatingAddExistItemAction,
  iRPStepType3SeriesRatingDeleteItemAction,
  iRPStepType3SeriesRatingDeleteItemDataAction,
  iRPStepType3SeriesRatingInitAction,
  iRPStepType3SeriesRatingMergeAction,
  iRPStepType3SeriesRatingMergeItemAction,
  iRPStepType3SeriesRatingMergeItemDataAction,
  iRPStepType3SeriesRatingNewItemAction,
  iRPStepType3SeriesRatingQuickSetAction,
  iRPStepType3SeriesRatingRefreshAction,
  iRPStepType3SeriesRatingSaveItemAction,
} from '../../@type';

export const iRPStepType3SeriesRatingMerge = (
  payload: iRPStepType3SeriesRatingMergeAction['payload'],
): iRPStepType3SeriesRatingMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE, payload };
};
export const iRPStepType3SeriesRatingMergeItem = (
  payload: iRPStepType3SeriesRatingMergeItemAction['payload'],
): iRPStepType3SeriesRatingMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM, payload };
};
export const iRPStepType3SeriesRatingMergeItemData = (
  payload: iRPStepType3SeriesRatingMergeItemDataAction['payload'],
): iRPStepType3SeriesRatingMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_MERGE_ITEM_DATA, payload };
};
export const iRPStepType3SeriesRatingDeleteItemData = (
  payload: iRPStepType3SeriesRatingDeleteItemDataAction['payload'],
): iRPStepType3SeriesRatingDeleteItemDataAction => {
  return {
    type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM_DATA,
    payload,
  };
};
// saga
export const iRPStepType3SeriesRatingInit = (
  payload: iRPStepType3SeriesRatingInitAction['payload'],
): iRPStepType3SeriesRatingInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_INIT, payload };
};
export const iRPStepType3SeriesRatingRefresh = (
  payload: iRPStepType3SeriesRatingRefreshAction['payload'],
): iRPStepType3SeriesRatingRefreshAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_REFRESH, payload };
};
export const iRPStepType3SeriesRatingSaveItem = (
  payload: iRPStepType3SeriesRatingSaveItemAction['payload'],
): iRPStepType3SeriesRatingSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_SAVE_ITEM, payload };
};
export const iRPStepType3SeriesRatingDeleteItem = (
  payload: iRPStepType3SeriesRatingDeleteItemAction['payload'],
): iRPStepType3SeriesRatingDeleteItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM, payload };
};
export const iRPStepType3SeriesRatingQuickSet = (
  payload: iRPStepType3SeriesRatingQuickSetAction['payload'],
): iRPStepType3SeriesRatingQuickSetAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_QUICK_SET, payload };
};

export const iRPStepType3SeriesRatingNewItem = (
  payload: iRPStepType3SeriesRatingNewItemAction['payload'],
): iRPStepType3SeriesRatingNewItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_NEW_ITEM, payload };
};

export const iRPStepType3SeriesRatingAddExistItem = (
  payload: iRPStepType3SeriesRatingAddExistItemAction['payload'],
): iRPStepType3SeriesRatingAddExistItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_ADD_EXIST_ITEM, payload };
};
