import { useI18n } from 'AurionCR/components';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS_STEP_PUBLICATION } from 'services/rating-process-step-publication';
import { iRatingProcessStepPreview, iStepDetail } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { StepDetailsList } from './step-details-list';
import { useData } from './useData';
import { Tooltip } from '@mui/material';

export const pathStepPublication = (id: number) => {
  return `
      ${API_RATING_PROCESS_STEP_PUBLICATION.GET_ALL_DYNAMIC}
      ?select=${[
        'headOfFieldSignatureDate',
        'websitePublicationDate',
        'report!=null?report.filePath:null as reportFile',
        'report!=null?report.reportDate:null as reportDate',
        'confirmationFilePath',
        'reportPublicationOption.title as reportPublicationOption',
        'publicationApprovalType.title as publicationApprovalType',
      ].join()}
    &filter=ratingProcessStepID==${id}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepPublication = (
  stepData: any,
  t: (label: string) => string,
): iStepDetail[] | null => {
  if (stepData) {
    const headOfFieldSignatureDate = stepData?.headOfFieldSignatureDate
      ? new Date(stepData?.headOfFieldSignatureDate)
      : null;
    const websitePublicationDate = stepData?.websitePublicationDate
      ? new Date(stepData?.websitePublicationDate)
      : null;
    const reportDate = stepData?.reportDate ? new Date(stepData?.reportDate) : null;
    return [
      {
        label: t('final-report-publication-date'),
        value:
          headOfFieldSignatureDate && isValid(headOfFieldSignatureDate)
            ? format(headOfFieldSignatureDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('website-publication-date'),
        value:
          websitePublicationDate && isValid(websitePublicationDate)
            ? format(websitePublicationDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('final-report'),
        value: stepData?.reportFile || '',
        type: 'link',
      },
      {
        label: t('report-date'),
        value: reportDate && isValid(reportDate) ? format(reportDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('confirmation-file'),
        value: stepData?.confirmationFilePath || '',
        type: 'link',
      },
      {
        label: t('report-publication-option'),
        value: stepData?.reportPublicationOption || '',
      },
      {
        label: t('publication-approval-type'),
        value: stepData?.publicationApprovalType || '',
      },
    ];
  } else {
    return null;
  }
};

export const StepPublication = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const [path] = useState(pathStepPublication(data.id));
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<iStepDetail[] | null>(
    () => prepareDataStepPublication(stepData, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData, labels],
  );
  const title = useMemo(() => data?.ratingProcessStepTypeTitle || '', [data]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<StepDetailsList data={prepareData} loading={loading} title={title} />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton data={data} date={date} title={title} />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <StepDetailsList data={prepareData} loading={loading} />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton data={data} date={date} title={title} disabled={true} />
    </div>
  );
});
