import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader';
import { addMonths, subMonths, format } from 'date-fns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsRTL } from 'store';
import { enUS, he } from 'date-fns/locale';

export const CustomDatePickerHeader = (props: PickersCalendarHeaderProps<Date>) => {
  const { currentMonth, onMonthChange } = props;

  const selectNextMonth = () => onMonthChange(addMonths(currentMonth, 1), 'left');
  const selectPreviousMonth = () => onMonthChange(subMonths(currentMonth, 1), 'right');

  const isRtl = useSelector(selectIsRTL);
  const locale = isRtl ? he : enUS;
  return (
    <Box display="flex" justifyContent="space-between" p={'0.5rem'} alignItems="center">
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectPreviousMonth} title="Previous month">
          {isRtl ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Stack>
      <Typography>{format(currentMonth, 'MMMM yyyy', { locale })}</Typography>
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectNextMonth} title="Next month">
          {isRtl ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Stack>
    </Box>
  );
};
