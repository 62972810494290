import { FC } from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import {
  AuthContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
} from './AurionCR/components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AxiosInterceptors } from './components/axios-interceptors';
import 'AurionCR/styles/_main.scss';
import { AppThemeProvider } from 'contexts/theme';
import 'styles/app.global.scss';
import { I18NContextProvider } from './components/language';
import { AppConfigProvider } from './components/app-config';
import { SnackbarProvider } from 'notistack';
import { DatePickerProvider } from './contexts/date-picker';
import Page404 from 'AurionCR/pages/Page404';
import Layout from 'components/layout';

import routesConfig from 'router/routes.tsx';

const App: FC = () => {
  window.authCookieName_ = 'wiz__auth_token';
  window.authRefreshCookieName_ = 'wiz__auth_refresh_token';
  window.authDateCookieName_ = 'wiz__auth_expires_token';

  return (
    <AppConfigProvider>
      <Provider store={store}>
        <AxiosInterceptors>
          <AuthContextProvider>
            <DndProvider backend={HTML5Backend}>
              <I18NContextProvider>
                <AppThemeProvider>
                  <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Notifier />
                    <DatePickerProvider>
                      <LoadingOverlayProvider>
                        <Routes layout={Layout} routes={routesConfig} page404={Page404} />
                      </LoadingOverlayProvider>
                    </DatePickerProvider>
                  </SnackbarProvider>
                </AppThemeProvider>
              </I18NContextProvider>
            </DndProvider>
          </AuthContextProvider>
        </AxiosInterceptors>
      </Provider>
    </AppConfigProvider>
  );
};

export default App;
