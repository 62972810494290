import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import React, { memo } from 'react';
import { selectIsRTL } from 'store';
import { useSelector } from 'react-redux';
import { enUS, he } from 'date-fns/locale';

interface Props {
  children: React.ReactNode;
}
export const DatePickerProvider = memo<Props>(({ children }) => {
  const isRtl = useSelector(selectIsRTL);
  const locale = isRtl ? he : enUS;

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      {children}
    </LocalizationProvider>
  );
});
