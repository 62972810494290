import { put, call, takeLatest } from 'redux-saga/effects';
import { api, requestError } from 'AurionCR/components';
import { updateTokenAll } from 'components/axios-interceptors';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import * as actions from 'AurionCR/store/modules/auth';

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* errorHandler(error: any) {
  const errorMessage = requestError(error);
  yield put(notifyRequestResult(errorMessage, 'error'));
  return errorMessage;
}

const endPoint = 'Account/';

function* getUser(): any {
  yield put(actions.authStart());
  try {
    let { data } = yield call(api.get, `${endPoint}GetCurrentAppUser`);
    // rename permission
    data.userPosition = { ...data.currentUserPermission };
    delete data.currentUserPermission;

    yield put(actions.authSuccess(data));
  } catch (error) {
    // TODO: if Error Axios try update Token
    yield put(actions.checkLoggedFail());
    return error;
  }
}

function* authUser(action: any): any {
  yield put(actions.authStart());
  try {
    const { cookie, ...data } = action.data;
    let response = yield call(api.post, `${endPoint}LoginWithCode`, data);

    if (response.status === 200) {
      const {
        jwt: { token, refreshToken },
        expires,
      } = response.data;
      try {
        updateTokenAll({ token, refreshToken, expires });
        yield call(getUser);
      } catch (error) {
        const errorMessage = yield call(errorHandler, error);
        yield put(actions.authFail(errorMessage));
      }
    } else {
      const errorMessage = yield call(errorHandler, response);
      yield put(actions.authFail(errorMessage));
    }
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

// @ts-ignore
function* authCode(action: any): any {
  yield put(actions.authStart());
  try {
    yield call(api.post, `${endPoint}/GeneratePassword`, action.data);
    yield put(actions.authCodeSuccess());
  } catch (error) {
    if (requestError(error) === ERROR_AS_SUCCESS_GENERATE) {
      yield put(actions.authFail(requestError(error)));
    } else {
      const errorMessage = yield call(errorHandler, error);
      yield put(actions.authFail(errorMessage));
    }
  }
}

function* logout() {
  yield put(actions.authStart());
  try {
    yield call(api.post, `${endPoint}Logout`);
    yield put(actions.logoutSucceed());
    updateTokenAll();
  } catch (error) {
    const errorMessage = requestError(error);
    yield put(notifyRequestResult(errorMessage, 'error'));
    yield put(actions.authFail(errorMessage));
  }
}

export const authAppSaga = [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
