import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';
import { tSeries } from 'services/series';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessStepCommitteeUserSeriesVoting';

export const API_RATING_PROCESS_STEP_COMMITTEE_USER_SERIES_VOTING = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeUserSeriesVoting, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeUserSeriesVoting, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeUserSeriesVoting, 'id'>) =>
    `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommitteeUserSeriesVoting = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  userID: string | null;
  user?: tUserDetail;
  seriesID: number;
  series?: tSeries;
  midroogRatingID: string | null;
  midroogRating?: MidroogRating;
  midroogOutlookID: string | null;
  midroogOutlook?: MidroogOutlook;
  ratingRemarks: string | null;
};
