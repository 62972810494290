import { memo } from 'react';
import { useI18n } from 'AurionCR/components';
import clsx from 'clsx';
import style from './index.module.scss';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase } from '@mui/material';
import { iRatingProcessStepPreview } from './@type';

export const StepButton = memo(
  ({
    data,
    date,
    title,
    titleDate = 'head-of-field-signature-date',
    disabled = false,
  }: {
    data: iRatingProcessStepPreview;
    date: string;
    title: string;
    titleDate?: string;
    disabled?: boolean;
  }) => {
    const { t } = useI18n();
    //render
    return (
      <ButtonBase
        disabled={disabled}
        className={clsx(
          style.RPStep,
          style.stepInformationRequest,
          data?.isCompleted
            ? style.complete
            : data?.isRPCompleted
              ? style.reject
              : data?.isEdit
                ? style.edit
                : undefined,
        )}
      >
        {data?.isCompleted ? (
          <DoneIcon className={style.iconDone} />
        ) : data?.isRPCompleted ? (
          <CloseIcon className={style.iconReject} />
        ) : data?.isEdit ? (
          <EditIcon className={style.iconEdit} />
        ) : null}
        <div className={style.title}>{title}</div>
        {data?.isCompleted && (
          <>
            <div className={style.subTitle}>{t(titleDate)}</div>
            <div className={style.date}>{date}</div>
          </>
        )}
      </ButtonBase>
    );
  },
);
