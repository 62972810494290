import {
  iRPStepType3ActionTypes,
  iRPStepType3SCInitAction,
  iRPStepType3SCMergeAction,
  iRPStepType3SCMergeItemAction,
  iRPStepType3SCMergeItemDataAction,
  iRPStepType3SCSaveItemAction,
} from '../../@type';

export const iRPStepType3SCMerge = (
  payload: iRPStepType3SCMergeAction['payload'],
): iRPStepType3SCMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE, payload };
};
export const iRPStepType3SCMergeItem = (
  payload: iRPStepType3SCMergeItemAction['payload'],
): iRPStepType3SCMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE_ITEM, payload };
};
export const iRPStepType3SCMergeItemData = (
  payload: iRPStepType3SCMergeItemDataAction['payload'],
): iRPStepType3SCMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_MERGE_ITEM_DATA, payload };
};
// saga
export const iRPStepType3SCInit = (
  payload: iRPStepType3SCInitAction['payload'],
): iRPStepType3SCInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_INIT, payload };
};

export const iRPStepType3SCSaveItem = (
  payload: iRPStepType3SCSaveItemAction['payload'],
): iRPStepType3SCSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SC_SAVE_ITEM, payload };
};
