const API = 'MediaUpload';

export const API_MEDIA_UPLOADS = {
  GET_HTML: (filePath: string) => `${API}/GetHtmlFromCloud?fileName=${filePath}`,
  UPLOAD_FILE: `${API}/UploadFileToCloud`,
  UPLOAD_HTML: `${API}/UploadHtmlToCloud`,
  REMOVE_FILE: (data: { filePath: string }) =>
    `${API}/RemoveFileFromCloud?filePath=${encodeURIComponent(data.filePath)}`,
};
export interface MediaUpload {
  fileName: string;
  content: string;
  filePath?: string;
}
