import { apiStatic, getRandomString, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { parseResponseDate, prepareRequest } from 'components/helpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import {
  API_RATING_PROCESS_STEP_REVIEW,
  tRatingProcessStepReview,
} from 'services/rating-process-step-review';
import {
  iRPState,
  iRPStepType2ActionTypes,
  iRPStepType2AddNewAction,
  iRPStepType2ApproveAction,
  iRPStepType2Data,
  iRPStepType2DeleteAction,
  iRPStepType2RejectAction,
  iRPStepType2SaveAction,
} from '../@type';
import {
  iRPStepType2DeleteListItem,
  iRPStepType2MergeList,
  iRPStepType2MergeListData,
  RPMerge,
  RPStepType2Merge,
} from '../helpers';
import { handleError, stepApprove, stepReject } from '../saga';
import { onUpdateHtmlField } from 'utils/html-upload-helpers';

export const HTML_FIELDS_STEP_2 = ['review'];

function* getData() {
  const { main, currentStep }: iRPState = yield select((state) => state.RP);
  try {
    const {
      data: { value },
    }: { data: { value: tRatingProcessStepReview[] } } = yield call(
      apiStatic.get,
      API_RATING_PROCESS_STEP_REVIEW.GET_ALL_DYNAMIC,
      {
        params: {
          select: [
            'id',
            'midroogRatingID',
            'minRangeMidroogRatingID',
            'maxRangeMidroogRatingID',
            'review',
            'reviewDate',
          ].join(),
          filter: `ratingProcessStepID==${main?.ratingProcessSteps[currentStep].id}`,
        },
      },
    );
    yield put(
      RPStepType2Merge({
        loading: false,
        init: true,
        list: value.map((item, index) => ({
          id: item.id,
          index,
          isOpen: index === value.length - 1,
          isEdit: index === value.length - 1,
          data: parseResponseDate(item, ['reviewDate']),
        })),
      }),
    );
  } catch (e) {
    yield put(RPStepType2Merge({ loading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* stepType2Init() {
  const {
    stepType2: { init, loading },
  }: iRPState = yield select((state) => state.RP);
  if (!init && !loading) {
    yield put(RPStepType2Merge({ loading: true }));
    yield call(getData);
    yield put(RPStepType2Merge({ loading: false }));
  }
}

function* saveReviewData(Data: Partial<iRPStepType2Data>): any {
  let { data, mixins } = parseMixins(Data);
  // @ts-ignore
  data = yield call(saveMixins, data, mixins);
  const htmlFieldsData = yield onUpdateHtmlField({
    formData: Data,
    field: HTML_FIELDS_STEP_2,
  });

  const updatedData = data;
  HTML_FIELDS_STEP_2.forEach((item) => {
    updatedData[item] = htmlFieldsData[item];
  });

  yield call(
    apiStatic.patch,
    API_RATING_PROCESS_STEP_REVIEW.PATCH(data),
    prepareRequest(updatedData),
  );
  return updatedData;
}

function* saveReview({ payload: { triggerClose, ...Data } }: iRPStepType2SaveAction) {
  yield put(RPMerge({ loading: true }));
  try {
    // @ts-ignore
    const data = yield call(saveReviewData, Data);
    yield put(notifyRequestResult(requestSuccess('')));
    if (!triggerClose) {
      yield put(iRPStepType2MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    } else {
      yield put(RPMerge({ triggerClose }));
    }
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* addNew({ payload }: iRPStepType2AddNewAction) {
  const { main }: iRPState = yield select((state) => state.RP);
  if (main?.id) {
    yield put(RPMerge({ loading: true }));
    yield call(saveReviewData, payload);
    yield call(apiStatic.get, API_RATING_PROCESS_HELPER.ADD_NEW_REVIEW(main.id));
    yield call(getData);
    yield put(RPMerge({ loading: false }));
  }
}

function* deleteItem({ payload: { id } }: iRPStepType2DeleteAction) {
  const {
    stepType2: { list },
  }: iRPState = yield select((state) => state.RP);
  try {
    yield put(RPMerge({ loading: true }));
    yield call(apiStatic.delete, API_RATING_PROCESS_STEP_REVIEW.DELETE({ id }));
    yield put(iRPStepType2DeleteListItem({ id }));
    yield put(iRPStepType2MergeList({ id: list[list.length - 2].id, isOpen: true, isEdit: true }));
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* reject({ payload }: iRPStepType2RejectAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveReviewData, payload);
    yield put(iRPStepType2MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(stepReject);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* approve({ payload }: iRPStepType2ApproveAction) {
  try {
    yield put(RPMerge({ loading: true }));
    // @ts-ignore
    const data = yield call(saveReviewData, payload);
    yield put(iRPStepType2MergeListData({ ...data, triggerRefreshMixins: getRandomString() }));
    yield call(stepApprove);
    yield put(RPMerge({ loading: false }));
  } catch (e) {
    yield call(handleError, e);
  }
}

export default [
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_INIT, stepType2Init),
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_SAVE, saveReview),
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_NEW, addNew),
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE, deleteItem),
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_REJECT, reject),
  takeLatest(iRPStepType2ActionTypes.RP_STEP_TYPE_2_APPROVE, approve),
];
