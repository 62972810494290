import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
// stores
import auth from 'AurionCR/store/modules/auth';
import notify from 'AurionCR/store/modules/notify';
import routes from 'AurionCR/store/modules/routes';
import sources from 'AurionCR/store/modules/sources';
import language from 'AurionCR/store/modules/language';
// sagas
import { authAppSaga } from './auth';
import sourceSaga from 'AurionCR/store/sagas/source';
// custom
import { QM, QMSagas } from 'pages/CreditRatings/QuarterlyMonitoring/components/store';
import { RP, RPSagas } from 'pages/CreditRatings/RatingProcess/components/editor/store';
import { dashboard, dashboardSagas } from 'pages/DashboardReports/components/store';

const config = {
  store: {
    auth,
    notify,
    routes,
    sources,
    language,
    QM,
    RP,
    dashboard,
  },
  sagas: [...authAppSaga, ...sourceSaga, ...QMSagas, ...RPSagas, ...dashboardSagas],
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const rootReducer = combineReducers(config.store);
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

function* rootSaga() {
  yield all(config.sagas);
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
