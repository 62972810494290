import {
  iRPStepType3ActionTypes,
  iRPStepType3AddListItemAction,
  iRPStepType3AddNewAction,
  iRPStepType3ApproveAction,
  iRPStepType3DeleteAction,
  iRPStepType3DeleteListItemAction,
  iRPStepType3InitAction,
  iRPStepType3InitCommitteeAction,
  iRPStepType3MergeAction,
  iRPStepType3MergeListAction,
  iRPStepType3MergeListDataAction,
  iRPStepType3RejectAction,
  iRPStepType3SaveAction,
  iRPStepType3ValidateCommitteeMembersAction,
  iRPStepType3ValidateCommitteeMembersVotesAction,
} from '../../@type';

export const RPStepType3Init = (): iRPStepType3InitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_INIT };
};

export const RPStepType3Merge = (
  payload: iRPStepType3MergeAction['payload'],
): iRPStepType3MergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE, payload };
};

export const RPStepType3MergeList = (
  payload: iRPStepType3MergeListAction['payload'],
): iRPStepType3MergeListAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE_LIST, payload };
};

export const RPStepType3MergeListData = (
  payload: iRPStepType3MergeListDataAction['payload'],
): iRPStepType3MergeListDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_MERGE_LIST_DATA, payload };
};

export const RPStepType3Save = (
  payload: iRPStepType3SaveAction['payload'],
): iRPStepType3SaveAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SAVE, payload };
};

export const RPStepType3AddNew = (
  payload: iRPStepType3AddNewAction['payload'],
): iRPStepType3AddNewAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_ADD_NEW, payload };
};

export const RPStepType3AddListItem = (
  payload: iRPStepType3AddListItemAction['payload'],
): iRPStepType3AddListItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_ADD_LIST_ITEM, payload };
};

export const RPStepType3DeleteListItem = (
  payload: iRPStepType3DeleteListItemAction['payload'],
): iRPStepType3DeleteListItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_DELETE_LIST_ITEM, payload };
};

export const RPStepType3Delete = (
  payload: iRPStepType3DeleteAction['payload'],
): iRPStepType3DeleteAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_DELETE, payload };
};

export const RPStepType3Approve = (
  payload: iRPStepType3ApproveAction['payload'],
): iRPStepType3ApproveAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_APPROVE, payload };
};

export const RPStepType3Reject = (
  payload: iRPStepType3RejectAction['payload'],
): iRPStepType3RejectAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_REJECT, payload };
};

export const iRPStepType3InitCommittee = (
  payload: iRPStepType3InitCommitteeAction['payload'],
): iRPStepType3InitCommitteeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_INIT_COMMITTEE, payload };
};

export const iRPStepType3ValidateCommitteeMembersVotes = (
  payload: iRPStepType3ValidateCommitteeMembersVotesAction['payload'],
): iRPStepType3ValidateCommitteeMembersVotesAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS_VOTES, payload };
};

export const iRPStepType3ValidateCommitteeMembers = (
  payload: iRPStepType3ValidateCommitteeMembersAction['payload'],
): iRPStepType3ValidateCommitteeMembersAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_VALIDATE_COMMITTEE_MEMBERS, payload };
};
