import {
  iRPStepType3ActionTypes,
  iRPStepType3UserRatEntVotDeleteItemAction,
  iRPStepType3UserRatEntVotDeleteItemDataAction,
  iRPStepType3UserRatEntVotImportAction,
  iRPStepType3UserRatEntVotInitAction,
  iRPStepType3UserRatEntVotMergeAction,
  iRPStepType3UserRatEntVotMergeItemAction,
  iRPStepType3UserRatEntVotMergeItemDataAction,
  iRPStepType3UserRatEntVotRefreshAction,
  iRPStepType3UserRatEntVotSaveItemAction,
} from '../../@type';

export const iRPStepType3UserRatEntVotMerge = (
  payload: iRPStepType3UserRatEntVotMergeAction['payload'],
): iRPStepType3UserRatEntVotMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE, payload };
};
export const iRPStepType3UserRatEntVotMergeItem = (
  payload: iRPStepType3UserRatEntVotMergeItemAction['payload'],
): iRPStepType3UserRatEntVotMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM, payload };
};
export const iRPStepType3UserRatEntVotMergeItemData = (
  payload: iRPStepType3UserRatEntVotMergeItemDataAction['payload'],
): iRPStepType3UserRatEntVotMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_MERGE_ITEM_DATA, payload };
};
export const iRPStepType3UserRatEntVotDeleteItemData = (
  payload: iRPStepType3UserRatEntVotDeleteItemDataAction['payload'],
): iRPStepType3UserRatEntVotDeleteItemDataAction => {
  return {
    type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM_DATA,
    payload,
  };
};
// saga
export const iRPStepType3UserRatEntVotInit = (
  payload: iRPStepType3UserRatEntVotInitAction['payload'],
): iRPStepType3UserRatEntVotInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_INIT, payload };
};
export const iRPStepType3UserRatEntVotRefresh = (
  payload: iRPStepType3UserRatEntVotRefreshAction['payload'],
): iRPStepType3UserRatEntVotRefreshAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_REFRESH, payload };
};
export const iRPStepType3UserRatEntVotSaveItem = (
  payload: iRPStepType3UserRatEntVotSaveItemAction['payload'],
): iRPStepType3UserRatEntVotSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_SAVE_ITEM, payload };
};
export const iRPStepType3UserRatEntVotDeleteItem = (
  payload: iRPStepType3UserRatEntVotDeleteItemAction['payload'],
): iRPStepType3UserRatEntVotDeleteItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM, payload };
};
export const iRPStepType3UserRatEntVotImport = (
  payload: iRPStepType3UserRatEntVotImportAction['payload'],
): iRPStepType3UserRatEntVotImportAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_IMPORT, payload };
};
