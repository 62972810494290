import { ClientDecision } from 'services/client-decision';
import { tRatingProcessStep } from 'services/rating-process-step';
import { tRatingProcessStepUpdateStatus } from 'services/rating-process-step-update-status';

const API = 'RatingProcessStepUpdate';

export const API_RATING_PROCESS_STEP_UPDATE = {
  API,
  GET: ({ id }: { id: number }) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: { id: number }) => `${API}/Patch/${id}`,
  DELETE: ({ id }: { id: number }) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepUpdate = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;
  ratingProcessStepID: number;
  ratingProcessStep?: tRatingProcessStep | null;
  ratingProcessStepUpdateStatusID: number;
  ratingProcessStepUpdateStatus?: tRatingProcessStepUpdateStatus | null;
  headOfFieldSignatureCode: string | null;
  headOfFieldSignatureDate: string | Date | null;
  clientUpdateDate: string | Date | null;
  clientDecisionID: number | Date;
  clientDecision?: ClientDecision | null;
  updateDescription: string | null;
  isCompleted: boolean;
};
