import {
  iRPActionTypes,
  iRPBackToStepIndexAction,
  iRPInitAction,
  iRPMainDisplaySeriesAction,
  iRPMainPushStepLogAction,
  iRPMainUpdateStepAction,
  iRPMainUpdateStepLogAction,
  iRPMergeAction,
  iRPMergeMainAction,
  iRPResetAction,
  iRPSaveMainAction,
  iRPStepApproveAction,
  iRPStepRejectAction,
  iRPStepUpdateAction,
  iRPStepUpdateIgnoreAction,
  iRPUpdateRatingProcess,
} from '../@type';

export const RPReset = (): iRPResetAction => {
  return { type: iRPActionTypes.RP_RESET };
};
export const RPMerge = (payload: iRPMergeAction['payload']): iRPMergeAction => {
  return { type: iRPActionTypes.RP_MERGE, payload };
};
export const RPMergeMain = (payload: iRPMergeMainAction['payload']): iRPMergeMainAction => {
  return { type: iRPActionTypes.RP_MERGE_MAIN, payload };
};
export const RPMainUpdateStep = (
  payload: iRPMainUpdateStepAction['payload'],
): iRPMainUpdateStepAction => {
  return { type: iRPActionTypes.RP_MAIN_UPDATE_STEP, payload };
};
export const RPStepUpdate = (payload: iRPStepUpdateAction['payload']): iRPStepUpdateAction => {
  return { type: iRPActionTypes.RP_STEP_UPDATE, payload };
};
export const RPStepUpdateIgnore = (): iRPStepUpdateIgnoreAction => {
  return { type: iRPActionTypes.RP_STEP_UPDATE_IGNORE };
};
export const RPMainPushStepLog = (
  payload: iRPMainPushStepLogAction['payload'],
): iRPMainPushStepLogAction => {
  return { type: iRPActionTypes.RP_MAIN_PUSH_STEP_LOG, payload };
};
export const RPMainUpdateStepLog = (
  payload: iRPMainUpdateStepLogAction['payload'],
): iRPMainUpdateStepLogAction => {
  return { type: iRPActionTypes.RP_MAIN_UPDATE_STEP_LOG, payload };
};
// saga
export const RPInit = (payload: iRPInitAction['payload']): iRPInitAction => {
  return { type: iRPActionTypes.RP_INIT, payload };
};
export const RPSaveMain = (payload: iRPSaveMainAction['payload']): iRPSaveMainAction => {
  return { type: iRPActionTypes.RP_SAVE_MAIN, payload };
};
export const RPUpdateRatingProcess = (
  payload: iRPUpdateRatingProcess['payload'],
): iRPUpdateRatingProcess => {
  return { type: iRPActionTypes.RP_UPDATE_RATING_PROCESS, payload };
};
export const RPStepReject = (payload: iRPStepRejectAction['payload']): iRPStepRejectAction => {
  return { type: iRPActionTypes.RP_STEP_REJECT, payload };
};
export const RPStepApprove = (payload: iRPStepApproveAction['payload']): iRPStepApproveAction => {
  return { type: iRPActionTypes.RP_STEP_APPROVE, payload };
};

export const iRPMainDisplaySeries = (): iRPMainDisplaySeriesAction => {
  return { type: iRPActionTypes.RP_MAIN_DISPLAY_SERIES };
};

export const RPBackToStepIndexAction = (
  payload: iRPBackToStepIndexAction['payload'],
): iRPBackToStepIndexAction => {
  return { type: iRPActionTypes.RP_BACK_TO_STEP_INDEX, payload };
};
