import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING } from 'services/rating-process-step-committee-series-rating';
import { API_SERIES } from 'services/series';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3SeriesRatingAddExistItemAction,
  iRPStepType3SeriesRatingDeleteItemAction,
  iRPStepType3SeriesRatingInitAction,
  iRPStepType3SeriesRatingItem,
  iRPStepType3SeriesRatingNewItemAction,
  iRPStepType3SeriesRatingQuickSetAction,
  iRPStepType3SeriesRatingRefreshAction,
  iRPStepType3SeriesRatingSaveItemAction,
} from '../../@type';
import {
  iRPStepType3SeriesRatingDeleteItemData,
  iRPStepType3SeriesRatingMerge,
  iRPStepType3SeriesRatingMergeItem,
  iRPStepType3SeriesRatingMergeItemData,
  iRPStepType3SeriesRatingRefresh,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: [
        'id',
        'ratingProcessStepCommitteeID',
        'seriesID',
        'series.title as seriesTitle',

        'midroogRatingID',
        'midroogOutlookID',
        'midroogRatingSymbolID',
        'provisional',
        'ratingRemarks',
      ].join(),
    },
  });
};

export function* refresh({ payload: { committeeIndex } }: iRPStepType3SeriesRatingRefreshAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          seriesRating: { isLoading },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isLoading) {
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: iRPStepType3SeriesRatingItem['data'][] } } = yield call(getData, id);
      // set data
      yield put(
        iRPStepType3SeriesRatingMerge({
          committeeIndex,
          isLoading: false,
          isInit: true,
          isInValid: value.length === 0,
          list: value.map((item) => ({
            isLoading: false,
            data: item,
          })),
        }),
      );
    } catch (e) {
      yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

export function* init({ payload: { committeeIndex } }: iRPStepType3SeriesRatingInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          seriesRating: { isInit },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit) {
    yield call(refresh, {
      payload: { committeeIndex },
      type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_REFRESH,
    });
  }
}

function* saveItem({
  payload: { committeeIndex, isReloadGrid, ...formData },
}: iRPStepType3SeriesRatingSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id: ratingProcessStepCommitteeID,
          seriesRating: { list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const itemIndex = list.findIndex((item) => item.data.id === formData.id);
  const current = itemIndex !== -1 ? list[itemIndex] : undefined;
  // status true
  if (isReloadGrid) {
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: true }));
  }
  if (current) {
    yield put(
      iRPStepType3SeriesRatingMergeItem({
        committeeIndex,
        itemIndex,
        isLoading: true,
      }),
    );
  }
  try {
    let { data, mixins } = parseMixins(formData);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    if (current) {
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.PATCH(data),
        prepareRequest(data),
      );
    } else {
      delete data.id;
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.POST,
        prepareRequest({ ...data, ratingProcessStepCommitteeID }),
      );
    }
    if (current) {
      yield put(
        iRPStepType3SeriesRatingMergeItemData({
          committeeIndex,
          itemIndex,
          ...data,
        }),
      );
    }
    yield put(notifyRequestResult(requestSuccess('')));
  } catch (e) {
    if (current) {
      yield put(
        iRPStepType3SeriesRatingMergeItemData({
          committeeIndex,
          itemIndex,
          ...current.data,
        }),
      );
    }
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  // status false
  if (current) {
    yield put(
      iRPStepType3SeriesRatingMergeItem({
        committeeIndex,
        itemIndex,
        isLoading: false,
      }),
    );
  }
  if (isReloadGrid) {
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isEdit: null, isLoading: false }));
    yield put(iRPStepType3SeriesRatingRefresh({ committeeIndex }));
  } else if (!current) {
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: false }));
  }
}

function* deleteItem({ payload: { committeeIndex } }: iRPStepType3SeriesRatingDeleteItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          seriesRating: { isDelete, list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const itemIndex = list.findIndex((item) => item.data.id === isDelete);
  if (itemIndex !== -1) {
    yield put(
      iRPStepType3SeriesRatingMerge({
        committeeIndex,
        isDelete: null,
        isEdit: null,
        isLoading: true,
      }),
    );
    try {
      yield call(
        apiStatic.delete,
        API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.DELETE({ id: Number(isDelete) }),
      );
      yield put(iRPStepType3SeriesRatingDeleteItemData({ committeeIndex, itemIndex }));
      yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isInValid: list.length === 1 }));
      yield put(notifyRequestResult(requestSuccess('')));
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: false }));
  }
}

function* quickSet({
  payload: { committeeIndex, midroogRatingID, midroogOutlookID },
}: iRPStepType3SeriesRatingQuickSetAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: { id },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: true }));
  try {
    yield call(
      apiStatic.get,
      API_RATING_PROCESS_HELPER.APPlY_VOTING_FOR_COMMITTEE_SERIES(
        id,
        midroogRatingID,
        midroogOutlookID,
      ),
    );
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestSuccess('')));
    yield put(iRPStepType3SeriesRatingRefresh({ committeeIndex }));
  } catch (e) {
    yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: false }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* newItem({
  payload: { committeeIndex, ...forData },
}: iRPStepType3SeriesRatingNewItemAction) {
  const {
    main,
    stepType3: {
      list: {
        [committeeIndex]: { id },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: true }));
  try {
    const {
      data: { id: seriesID },
    } = yield call(apiStatic.post, API_SERIES.POST, {
      createdDate: new Date(),
      updatedDate: new Date(),
      ratedEntityID: main?.ratedEntityID,
      isDraft: false,
      isActive: true,
      ...forData,
    });
    yield call(apiStatic.post, API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.POST, {
      seriesID,
      ratingProcessStepCommitteeID: id,
      isActive: true,
      createdDate: new Date(),
      updatedDate: new Date(),
    });
    yield put(
      iRPStepType3SeriesRatingMerge({
        committeeIndex,
        isLoading: false,
        isEdit: null,
        openNewDialogForm: null,
      }),
    );
    yield put(notifyRequestResult(requestSuccess('')));
    yield put(iRPStepType3SeriesRatingRefresh({ committeeIndex }));
  } catch (e) {
    yield put(
      iRPStepType3SeriesRatingMerge({
        committeeIndex,
        isLoading: false,
        isEdit: null,
        openNewDialogForm: null,
      }),
    );
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* addExistItem({
  payload: { committeeIndex, ...forData },
}: iRPStepType3SeriesRatingAddExistItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: { id },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3SeriesRatingMerge({ committeeIndex, isLoading: true }));
  try {
    yield call(apiStatic.post, API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING.POST, {
      seriesID: forData.seriesID,
      ratingProcessStepCommitteeID: id,
      isActive: true,
      createdDate: new Date(),
      updatedDate: new Date(),
    });
    yield put(
      iRPStepType3SeriesRatingMerge({
        committeeIndex,
        isLoading: false,
        isEdit: null,
        openNewDialogForm: null,
      }),
    );
    yield put(notifyRequestResult(requestSuccess('')));
    yield put(iRPStepType3SeriesRatingRefresh({ committeeIndex }));
  } catch (e) {
    yield put(
      iRPStepType3SeriesRatingMerge({
        committeeIndex,
        isLoading: false,
        isEdit: null,
        openNewDialogForm: null,
      }),
    );
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}
export const SeriesRating = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_INIT, init),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_REFRESH, refresh),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_SAVE_ITEM, saveItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_DELETE_ITEM, deleteItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_QUICK_SET, quickSet),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_NEW_ITEM, newItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_SERIES_RATING_ADD_EXIST_ITEM, addExistItem),
];
