import { useI18n, useSource } from 'AurionCR/components';
import { format, isValid } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { API_RATING_PROCESS } from 'services/rating-process';
import { iRatingProcessStepPreview, iStepDetail } from './@type';
import style from './index.module.scss';
import { StepButton } from './step-button';
import StepDetailsDialog from './step-details-dialog';
import { StepDetailsList } from './step-details-list';
import { useData } from './useData';
import { IUserDetailsOption, makeUserDetailsField } from 'components/hooks/use-user-detail-field';
import { API_USER_DETAIL } from 'services/user-detail';
import { Tooltip } from '@mui/material';

export const pathStepInformationRequest = (ratingProcessID: number) => {
  return `
      ${API_RATING_PROCESS.GET_ALL_DYNAMIC}
      ?select=${[
        'discussionDate',
        'requestMaterialsDate',
        'responseMaterialsDate',
        'methodology.title as primaryMethodologyTitle',
        'subMethodology.title as secondaryMethodologyTitle',
        'headOfField.fullName as headOfFieldName',
        'teamLeader.fullName as teamLeaderName',
        'analyst.fullName as analystName',
        'string.join(",",ratingProcessUsers.Select(s=>s.user.fullName)) as members',
      ].join()}
    &filter=id==${ratingProcessID}
  `.replace(/  +|\r\n|\n|\r/gm, '');
};

export const prepareDataStepInformationRequest = (
  stepData: any,
  t: (label: string) => string,
  userDetailsOptions: IUserDetailsOption[],
): iStepDetail[] | null => {
  if (stepData) {
    const updatedMembersName = makeUserDetailsField(stepData?.members, userDetailsOptions, t, true);
    const updatedAnalystName = makeUserDetailsField(stepData?.analystName, userDetailsOptions, t);
    const updatedTeamLeaderName = makeUserDetailsField(
      stepData?.teamLeaderName,
      userDetailsOptions,
      t,
    );
    const updatedHeadOfFieldName = makeUserDetailsField(
      stepData?.headOfFieldName,
      userDetailsOptions,
      t,
    );
    const discussionDate = stepData?.discussionDate ? new Date(stepData?.discussionDate) : null;
    const requestMaterialsDate = stepData?.requestMaterialsDate
      ? new Date(stepData?.requestMaterialsDate)
      : null;
    const responseMaterialsDate = stepData?.responseMaterialsDate
      ? new Date(stepData?.responseMaterialsDate)
      : null;
    return [
      {
        label: t('discussion-date'),
        value:
          discussionDate && isValid(discussionDate) ? format(discussionDate, 'dd/MM/yyyy') : '',
      },
      {
        label: t('request-materials-date'),
        value:
          requestMaterialsDate && isValid(requestMaterialsDate)
            ? format(requestMaterialsDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('response-materials-date'),
        value:
          responseMaterialsDate && isValid(responseMaterialsDate)
            ? format(responseMaterialsDate, 'dd/MM/yyyy')
            : '',
      },
      {
        label: t('methodology'),
        value: stepData?.primaryMethodologyTitle || '',
      },
      {
        label: t('sub-methodology'),
        value: stepData?.secondaryMethodologyTitle || '',
      },
      {
        label: t('head-of-field'),
        value: updatedHeadOfFieldName,
      },
      {
        label: t('team-leader'),
        value: updatedTeamLeaderName,
      },
      {
        label: t('additional-analyst'),
        value: updatedAnalystName,
      },
      {
        label: t('more-members'),
        value: updatedMembersName,
      },
    ];
  } else {
    return null;
  }
};

export const StepInformationRequest = memo(({ data }: { data: iRatingProcessStepPreview }) => {
  const { t, labels } = useI18n();
  const [path] = useState(pathStepInformationRequest(data.ratingProcessID));
  const userDetailSource = useSource<IUserDetailsOption>(
    `${API_USER_DETAIL.GET_ALL_DYNAMIC}?Select=fullName,isActive`,
  );
  const {
    active,
    date,
    loading,
    stepData,
    openTooltip,
    onOpenTooltip,
    onCloseTooltip,
    openDialog,
    onOpenDialog,
    onCloseDialog,
  } = useData(data, path);
  const prepareData = useMemo<iStepDetail[] | null>(
    () => prepareDataStepInformationRequest(stepData, t, userDetailSource.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepData, labels, userDetailSource.data],
  );
  const title = useMemo(() => data?.ratingProcessStepTypeTitle || '', [data]);
  //render
  return active ? (
    <>
      <Tooltip
        open={openTooltip}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        placement="top-start"
        classes={{ tooltip: style.stepTooltip }}
        title={<StepDetailsList data={prepareData} loading={loading} title={title} />}
      >
        <div className={style.RPStepWrapper} onClick={onOpenDialog}>
          <StepButton data={data} date={date} title={title} />
        </div>
      </Tooltip>
      {openDialog && (
        <StepDetailsDialog onCloseDialog={onCloseDialog} loading={loading} title={title}>
          <StepDetailsList data={prepareData} loading={loading} />
        </StepDetailsDialog>
      )}
    </>
  ) : (
    <div className={style.RPStepWrapper}>
      <StepButton data={data} date={date} title={title} disabled={true} />
    </div>
  );
});
