import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { sortBy } from 'lodash';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_HELPER } from 'services/rating-process-helper';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING } from 'services/rating-process-step-committee-user-rates-entity-voting';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3UserRatEntVotDeleteItemAction,
  iRPStepType3UserRatEntVotInitAction,
  iRPStepType3UserRatEntVotItem,
  iRPStepType3UserRatEntVotRefreshAction,
  iRPStepType3UserRatEntVotSaveItemAction,
} from '../../@type';
import {
  iRPStepType3SeriesRatingRefresh,
  iRPStepType3UserRatEntVotDeleteItemData,
  iRPStepType3UserRatEntVotMerge,
  iRPStepType3UserRatEntVotMergeItem,
  iRPStepType3UserRatEntVotMergeItemData,
  iRPStepType3UserRatEntVotRefresh,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: [
        'id',
        'ratingProcessStepCommitteeID',
        'userID',
        'user.fullName as userFullName',
        'committeePositionID',
        'committeePosition.title as committeePositionTitle',

        'longTermMidroogRatingID',
        // 'shortTermMidroogRatingID',
        // 'baselineCreditAssesmentRatingID',
        'provisional',
        'midroogOutlookID',
        'midroogRatingSymbolID',
        'longTermCapital',
        'shortTermCapital',
        // BankRatings
        // 'bankTier1CapitalRatingID',
        // 'bankLawerTier2CapitalRatingID',
        // 'bankUpperTier2CapitalRatingID',
        // 'bankCocosRatingID',
        // InsuranceRatings
        // 'insuranceFinancialStrengthRatingID',
        // 'insuranceTier2CapitalRatingID',
        // 'insuranceLawerTier2CapitalRatingID',
        // 'insuranceComplexTier2CapitalRatingID',
        // 'insuranceComplexTier3CapitalRatingID',
        // remarks
        'ratingDecisionRemarks',
      ].join(),
    },
  });
};

export function* refresh({ payload: { committeeIndex } }: iRPStepType3UserRatEntVotRefreshAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          userRatEntVot: { isLoading },
          user: { list: usersList },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isLoading) {
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: iRPStepType3UserRatEntVotItem['data'][] } } = yield call(getData, id);
      // rank matrix
      const rankMap: { [key: string]: number } = usersList.reduce((acc, item, index) => {
        if (item.data.userID) {
          // @ts-ignore
          acc[String(item.data.userID)] = index;
        }
        return acc;
      }, {});
      // set data
      yield put(
        iRPStepType3UserRatEntVotMerge({
          committeeIndex,
          isLoading: false,
          isInit: true,
          isInValid: value.length === 0,
          list: sortBy(
            value.map((item) => ({
              isLoading: false,
              rank:
                rankMap[String(item.userID)] !== undefined ? rankMap[String(item.userID)] : 10000,
              data: {
                ...item,
                longTermCapital: item.longTermCapital || undefined,
                shortTermCapital: item.shortTermCapital || undefined,
              },
            })),
            ['rank'],
          ),
        }),
      );
    } catch (e) {
      yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

export function* init({ payload: { committeeIndex } }: iRPStepType3UserRatEntVotInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          userRatEntVot: { isInit },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit) {
    yield call(refresh, {
      payload: { committeeIndex },
      type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_REFRESH,
    });
  }
}

function* saveItem({
  payload: { committeeIndex, isReloadGrid, ...formData },
}: iRPStepType3UserRatEntVotSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id: ratingProcessStepCommitteeID,
          userRatEntVot: { list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const itemIndex = list.findIndex((item) => item.data.id === formData.id);
  const current = itemIndex !== -1 ? list[itemIndex] : undefined;
  // status true
  if (isReloadGrid) {
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: true }));
  }
  if (current) {
    yield put(
      iRPStepType3UserRatEntVotMergeItem({
        committeeIndex,
        itemIndex,
        isLoading: true,
      }),
    );
  }
  try {
    let { data, mixins } = parseMixins(formData);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    if (current) {
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING.PATCH(data),
        prepareRequest(data),
      );
    } else {
      delete data.id;
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING.POST,
        prepareRequest({ ...data, ratingProcessStepCommitteeID }),
      );
    }
    if (current) {
      yield put(
        iRPStepType3UserRatEntVotMergeItemData({
          committeeIndex,
          itemIndex,
          ...data,
        }),
      );
    }
    yield put(notifyRequestResult(requestSuccess('')));
  } catch (e) {
    if (current) {
      yield put(
        iRPStepType3UserRatEntVotMergeItemData({
          committeeIndex,
          itemIndex,
          ...current.data,
        }),
      );
    }
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  // status false
  if (current) {
    yield put(
      iRPStepType3UserRatEntVotMergeItem({
        committeeIndex,
        itemIndex,
        isLoading: false,
      }),
    );
  }
  if (isReloadGrid) {
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isEdit: null, isLoading: false }));
    yield put(iRPStepType3UserRatEntVotRefresh({ committeeIndex }));
  } else if (!current) {
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: false }));
  }
}

function* deleteItem({ payload: { committeeIndex } }: iRPStepType3UserRatEntVotDeleteItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          userRatEntVot: { isDelete, list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const itemIndex = list.findIndex((item) => item.data.id === isDelete);
  if (itemIndex !== -1) {
    yield put(
      iRPStepType3UserRatEntVotMerge({
        committeeIndex,
        isDelete: null,
        isEdit: null,
        isLoading: true,
      }),
    );
    try {
      yield call(
        apiStatic.delete,
        API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING.DELETE({ id: Number(isDelete) }),
      );
      yield put(iRPStepType3UserRatEntVotDeleteItemData({ committeeIndex, itemIndex }));
      yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isInValid: list.length === 1 }));
      yield put(notifyRequestResult(requestSuccess('')));
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: false }));
  }
}

function* importUsers({ payload: { committeeIndex } }: iRPStepType3UserRatEntVotDeleteItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          userRatEntVot: { isLoading },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isLoading) {
    yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: true }));
    try {
      yield call(
        apiStatic.get,
        API_RATING_PROCESS_HELPER.IMPORT_USERS_FOR_COMMITTEE_RATED_ENTRY_VOTING(id),
      );
      yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: false }));
      yield put(iRPStepType3UserRatEntVotRefresh({ committeeIndex }));
      yield put(iRPStepType3SeriesRatingRefresh({ committeeIndex }));
    } catch (e) {
      yield put(iRPStepType3UserRatEntVotMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

export const UserRatingEntityVoting = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_INIT, init),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_REFRESH, refresh),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_SAVE_ITEM, saveItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_DELETE_ITEM, deleteItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_RAT_ENT_VOT_IMPORT, importUsers),
];
