import CopyToClipboard from 'react-copy-to-clipboard';
import { notifyRequestResult } from '../store/modules/notify';
import { useDispatch } from 'react-redux';
import { memo } from 'react';

export const CopyPathToClipboard = memo(
  ({
    text = '',
    message = 'copied',
    children,
  }: {
    text?: string;
    message?: string;
    children?: any;
  }) => {
    const dispatch = useDispatch();
    return (
      <CopyToClipboard
        text={text}
        onCopy={() => dispatch(notifyRequestResult(message, 'success', { autoHideDuration: 750 }))}
      >
        {children}
      </CopyToClipboard>
    );
  },
);
export default CopyPathToClipboard;
