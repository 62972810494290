import { isValid } from 'date-fns';

export const validateRequired = (data: any) => {
  return !data ? 'required' : undefined;
};
export const validateEmail = (data: string) => {
  if (data && data.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi) === null) {
    return 'invalid-email';
  } else {
    return undefined;
  }
};
export const validateURL = (data: string) => {
  // eslint-disable-next-line
  if (
    data &&
    data.match(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
    ) === null
  ) {
    return 'invalid-url';
  } else {
    return undefined;
  }
};
export const validateNumber = (data: string) => {
  let value = Number(data);
  if (data && isNaN(value)) {
    return 'invalid-number';
  } else {
    return undefined;
  }
};
export const validateDate = (data: string) => {
  if (data && !isValid(data)) {
    return 'invalid-date';
  } else {
    return undefined;
  }
};

export const validatePositiveNumber = (data: string) => {
  if (!data) return undefined;

  let value = Number(data);

  if (isNaN(value)) {
    return 'invalid-number';
  }

  if (value < 0) {
    return 'number-must-be-positive';
  }

  return undefined;
};

export const validateRule = (rule = '') => {
  if (typeof rule !== 'string') {
    return undefined;
  }

  return rule.split(',').reduce((result: any, key: string) => {
    switch (key) {
      case 'required':
        result['required'] = 'required';
        break;
      case 'requiredEditor':
        result['validate'] = (data: any) => {
          if (data) {
            if (typeof data === 'object') {
              if (data?.value && data.value.length && data.value.length) {
                const div = document.createElement('div');
                div.innerHTML = data.value;
                const text = div.textContent || div.innerText || '';
                div.remove();
                if (text.length) {
                  return undefined;
                } else {
                  return 'required';
                }
              } else {
                return 'required';
              }
            } else {
              return undefined;
            }
          }
          return 'required';
        };
        break;
      case 'requiredMixin':
        result['validate'] = (data: any) => {
          if (data) {
            if (typeof data === 'object') {
              if (data?.value && data?.value?.length) return undefined;
            } else {
              return undefined;
            }
          }
          return 'required';
        };
        break;
      case 'minLength':
        result['minLength'] = {
          value: 2,
          message: 'length-min-error',
        };
        break;
      case 'email':
        result['validate'] = validateEmail;
        break;
      case 'url':
        result['validate'] = validateURL;
        break;
      case 'number':
        result['validate'] = validateNumber;
        break;
      case 'date':
        result['validate'] = validateDate;
        break;
      case 'positiveNumber':
        result['validate'] = validatePositiveNumber;
        break;
    }
    return result;
  }, {});
};
