import { apiStatic, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { iRPState, iRPStepDoneFormActionTypes } from '../@type';
import { API_RATING_PROCESS } from 'services/rating-process';
import { tRatingProcessStepReview } from 'services/rating-process-step-review';
import { RPStepDoneFormMerge } from '../helpers';

function* getData() {
  const { main }: iRPState = yield select((state) => state.RP);
  try {
    const {
      data: { value },
    }: { data: { value: tRatingProcessStepReview[] } } = yield call(
      apiStatic.get,
      API_RATING_PROCESS.GET_ALL_DYNAMIC,
      {
        params: {
          select: [
            'completeRatingProcessDate',
            'ratingProcessDocumentations.Select(s=>new {s.title,s.filePath}) as documents',
            'monitorVotingSheetFilePath',
            'monitorFinancialAnalysisFilePath',
            'monitorOtherFilePath',
            'monitorDescription',
            'monitorUserID',
            'monitorSignatureDate',
            'monitorApproval1DocumentationStatusID',
            'monitorApproval2DocumentationStatusID',
            'monitorApproval3DocumentationStatusID',
            'monitorApproval4DocumentationStatusID',
          ].join(),
          filter: `id==${main?.id}`,
        },
      },
    );
    yield put(RPStepDoneFormMerge({ isInit: true, isLoading: false, data: value[0] }));
  } catch (e) {
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
}

function* init() {
  const {
    stepDone: { isInit, isLoading },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit && !isLoading) {
    yield put(RPStepDoneFormMerge({ isLoading: true }));
    yield call(getData);
  }
}

export default [takeLatest(iRPStepDoneFormActionTypes.RP_STEP_DONE_FORM_INIT, init)];
