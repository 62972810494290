import { Company } from 'services/company';
import { tDepartment } from 'services/department';
import { tMethodology } from 'services/methodology';
import { MonitorDecisionType } from 'services/momitor-decision-type';
import { MonitorStatus } from 'services/monitor-status';
import { tOrder } from 'services/order';
import { RatingProcessStatus } from 'services/rating-process-status';
import { RatingProcessType } from 'services/rating-process-type';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcess';

export const API_RATING_PROCESS = {
  API,
  GET: ({ id }: Pick<tRatingProcess, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcess, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcess, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcess = {
  id: number;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  isActive?: boolean;
  ratingProcessStatusID?: number;
  orderID?: number;
  ratedEntityID?: number;
  departmentID?: number;
  methodologyID?: number;
  subMethodologyID?: number;
  ratingProcessTypeID?: number;
  headOfFieldUserID?: string;
  teamLeaderUserID?: string;
  analystUserID?: string;
  monitorDecisionTypeID?: number;
  monitorStatusID?: number;
  title?: string;
  titleEng?: string;
  discussionDate?: string | Date;
  requestMaterialsDate?: string | Date;
  responseMaterialsDate?: string | Date;
  monitorDescription?: string | Date;
  isCompleted?: boolean;
  isMonitorDone?: boolean;
  isUpdated?: boolean;
  completeRatingProcessDate?: string | Date;
  ratingProcessStatus?: RatingProcessStatus;
  order?: tOrder;
  ratedEntity?: Company;
  department?: tDepartment;
  methodology?: tMethodology;
  subMethodology?: tMethodology;
  ratingProcessType?: RatingProcessType;
  headOfField?: tUserDetail;
  teamLeader?: tUserDetail;
  analyst?: tUserDetail;
  monitorDecisionType?: MonitorDecisionType;
  monitorStatus?: MonitorStatus;
  reportDate?: string | Date;

  ratingProcessSteps: any[];
};
