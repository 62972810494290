import { CommitteePosition } from 'services/committee-position';
import { MaalotOutlook } from 'services/maalot-outlook';
import { MaalotRating } from 'services/maalot-rating';
import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { MoodysOutlook } from 'services/moodys-outlook';
import { MoodysRating } from 'services/moodys-rating';
import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessStepCommitteeUserRatesEntityVoting';

export const API_RATING_PROCESS_STEP_COMMITTEE_USER_ENTITY_VOTING = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeUserRatesEntityVoting, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeUserRatesEntityVoting, 'id'>) =>
    `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeUserRatesEntityVoting, 'id'>) =>
    `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommitteeUserRatesEntityVoting = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  longTermMidroogRatingID: number | null;
  longTermMidroogRating?: MidroogRating;
  shortTermMidroogRatingID: number | null;
  shortTermMidroogRating?: MidroogRating;
  baselineCreditAssesmentRatingID: number | null;
  baselineCreditAssesmentRating?: MidroogRating;
  bankLawerTier2CapitalRatingID: number | null;
  bankLawerTier2CapitalRating?: MidroogRating;
  bankUpperTier2CapitalRatingID: number | null;
  bankUpperTier2CapitalRating?: MidroogRating;
  bankTier1CapitalRatingID: number | null;
  bankTier1CapitalRating?: MidroogRating;
  bankCocosRatingID: number | null;
  bankCocosRating?: MidroogRating;
  insuranceFinancialStrengthRatingID: number | null;
  insuranceFinancialStrengthRating?: MidroogRating;
  insuranceTier2CapitalRatingID: number | null;
  insuranceTier2CapitalRating?: MidroogRating;
  insuranceLawerTier2CapitalRatingID: number | null;
  insuranceLawerTier2CapitalRating?: MidroogRating;
  insuranceComplexTier2CapitalRatingID: number | null;
  insuranceComplexTier2CapitalRating?: MidroogRating;
  insuranceComplexTier3CapitalRatingID: number | null;
  insuranceComplexTier3CapitalRating?: MidroogRating;
  midroogOutlookID: number | null;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbolID: number | null;
  midroogRatingSymbol?: MidroogRatingSymbol;
  moodysRatingID: number | null;
  moodysRating?: MoodysRating;
  moodysOutlookID: number | null;
  moodysOutlook?: MoodysOutlook;
  maalotRatingID: number | null;
  maalotRating?: MaalotRating;
  maalotOutlookID: number | null;
  maalotOutlook?: MaalotOutlook;
  provisional: boolean;
  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  userID?: string | null;
  user?: tUserDetail;
  committeePositionID: number;
  committeePosition?: CommitteePosition;
  shortTermCapital: number | null | undefined;
  longTermCapital: number | null | undefined;
  ratingDecisionRemarks: string | null;
};
