import { MidroogOutlook } from 'services/midroog-outlook';
import { MidroogRating } from 'services/midroog-rating';
import { MidroogRatingSymbol } from 'services/midroog-rating-symbol';
import { tRatingProcessStepCommittee } from 'services/rating-process-step-committee';
import { tSeries } from 'services/series';

const API = 'RatingProcessStepCommitteeSeriesRating';

export const API_RATING_PROCESS_STEP_COMMITTEE_SERIES_RATING = {
  API,
  GET: ({ id }: Pick<tRatingProcessStepCommitteeSeriesRating, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tRatingProcessStepCommitteeSeriesRating, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tRatingProcessStepCommitteeSeriesRating, 'id'>) => `${API}/Delete/${id}`,
};

export type tRatingProcessStepCommitteeSeriesRating = {
  id: number;
  createdDate?: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;

  ratingProcessStepCommitteeID: number;
  ratingProcessStepCommittee?: tRatingProcessStepCommittee;
  seriesID: number;
  series?: tSeries;
  midroogRatingID: number | null;
  midroogRating?: MidroogRating;
  midroogOutlookID: number | null;
  midroogOutlook?: MidroogOutlook;
  midroogRatingSymbolID: number | null;
  midroogRatingSymbol?: MidroogRatingSymbol;
  provisional: boolean | null;
  ratingRemarks: string | null;
};
