import { apiStatic, requestError, requestSuccess } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_RATING_PROCESS_STEP_COMMITTEE_USER } from 'services/rating-process-step-committee-user';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3UserDeleteItemAction,
  iRPStepType3UserInitAction,
  iRPStepType3UserItem,
  iRPStepType3UserRefreshAction,
  iRPStepType3UserSaveItemAction,
} from '../../@type';
import {
  iRPStepType3UserDeleteItemData,
  iRPStepType3UserMerge,
  iRPStepType3UserMergeItem,
  iRPStepType3UserMergeItemData,
  iRPStepType3UserRefresh,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_USER.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: [
        'id',
        'ratingProcessStepCommitteeID',
        'userID',
        'user.fullName as userFullName',
        'committeePositionID',
        'committeePosition.title as committeePositionTitle',
        'votingOrder',
        'isAllowedToVote',
        'isConflictOfInterests.toString() as isConflictOfInterests',
        'conflictOfInterestsInfo',
      ].join(),
      orderBy: 'votingOrder',
    },
  });
};

export function* refresh({ payload: { committeeIndex } }: iRPStepType3UserRefreshAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          materials: { isLoading },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isLoading) {
    yield put(iRPStepType3UserMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: iRPStepType3UserItem['data'][] } } = yield call(getData, id);
      // set data
      yield put(
        iRPStepType3UserMerge({
          committeeIndex,
          isLoading: false,
          isInit: true,
          isInValid: value.length === 0,
          list: value.map((item, index) => ({
            index,
            isLoading: false,
            data: item,
          })),
        }),
      );
    } catch (e) {
      yield put(iRPStepType3UserMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

export function* init({ payload: { committeeIndex } }: iRPStepType3UserInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          user: { isInit },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit) {
    yield call(refresh, {
      payload: { committeeIndex },
      type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_REFRESH,
    });
  }
}

function* saveItem({
  payload: { committeeIndex, isReloadGrid, ...formData },
}: iRPStepType3UserSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id: ratingProcessStepCommitteeID,
          user: { list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const current = list.find((item) => item.data.id === formData.id);
  // status true
  if (isReloadGrid) {
    yield put(iRPStepType3UserMerge({ committeeIndex, isLoading: true }));
  }
  if (current) {
    yield put(
      iRPStepType3UserMergeItem({ committeeIndex, itemIndex: current.index, isLoading: true }),
    );
  }
  try {
    let { data, mixins } = parseMixins(formData);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    if (current) {
      yield call(
        apiStatic.patch,
        API_RATING_PROCESS_STEP_COMMITTEE_USER.PATCH(data),
        prepareRequest(data),
      );
    } else {
      delete data.id;
      yield call(
        apiStatic.post,
        API_RATING_PROCESS_STEP_COMMITTEE_USER.POST,
        prepareRequest({ ...data, ratingProcessStepCommitteeID }),
      );
    }
    if (current) {
      yield put(
        iRPStepType3UserMergeItemData({ committeeIndex, itemIndex: current.index, ...data }),
      );
    }
    yield put(notifyRequestResult(requestSuccess('')));
  } catch (e) {
    if (current) {
      yield put(
        iRPStepType3UserMergeItemData({
          committeeIndex,
          itemIndex: current.index,
          ...current.data,
        }),
      );
    }
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  // status false
  if (current) {
    yield put(
      iRPStepType3UserMergeItem({ committeeIndex, itemIndex: current.index, isLoading: false }),
    );
  }
  if (isReloadGrid) {
    yield put(iRPStepType3UserMerge({ committeeIndex, isEdit: null, isLoading: false }));
    yield put(iRPStepType3UserRefresh({ committeeIndex }));
  } else if (!current) {
    yield put(iRPStepType3UserMerge({ committeeIndex, isLoading: false }));
  }
}

function* deleteItem({ payload: { committeeIndex } }: iRPStepType3UserDeleteItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          user: { isDelete, list },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  const itemIndex = list.findIndex((item) => item.data.id === isDelete);
  if (itemIndex !== -1) {
    yield put(
      iRPStepType3UserMerge({ committeeIndex, isDelete: null, isEdit: null, isLoading: true }),
    );
    try {
      yield call(
        apiStatic.delete,
        API_RATING_PROCESS_STEP_COMMITTEE_USER.DELETE({ id: Number(isDelete) }),
      );
      yield put(iRPStepType3UserDeleteItemData({ committeeIndex, itemIndex }));
      yield put(iRPStepType3UserMerge({ committeeIndex, isInValid: list.length === 1 }));
      yield put(notifyRequestResult(requestSuccess('')));
    } catch (e) {
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
    yield put(iRPStepType3UserMerge({ committeeIndex, isLoading: false }));
  }
}

export const User = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_INIT, init),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_REFRESH, refresh),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_SAVE_ITEM, saveItem),
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_USER_DELETE_ITEM, deleteItem),
];
