import { memo } from 'react';
import { useI18n } from 'AurionCR/components';
import { Button } from '@mui/material';
import { tControls } from './@type';
import style from './index.module.scss';

export const FormFooterControls = memo(
  ({
    state = 'save',
    disabled = false,
    ButtonDeleteName = 'delete',
    ButtonCreateAndContinueName = 'create-and-continue',
    ButtonCancelName = 'cancel',
    onCancel,
    onSubmit,
    onSubmitAndContinue,
    onDelete,
    footerLeftChildren = null,
  }: Partial<tControls> & { cancelName?: string }) => {
    const { t } = useI18n();
    // render
    return (
      <div className={style.footerControls}>
        <div className={style.leftSide}>
          {state === 'save' && onDelete && (
            <Button
              color={'inherit'}
              disabled={disabled}
              onClick={onDelete}
              className={style.error}
            >
              {t(ButtonDeleteName)}
            </Button>
          )}
          {footerLeftChildren}
        </div>
        <div className={style.rightSide}>
          {onCancel && (
            <Button color="primary" disabled={disabled} onClick={onCancel}>
              {t(ButtonCancelName)}
            </Button>
          )}
          {state === 'create' && onSubmitAndContinue && (
            <Button color={'primary'} variant={'contained'} disabled={disabled} onClick={onSubmit}>
              {t(ButtonCreateAndContinueName)}
            </Button>
          )}
          {onSubmit && (
            <Button color={'primary'} variant={'contained'} disabled={disabled} onClick={onSubmit}>
              {t(state)}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

export default FormFooterControls;
