import { apiStatic, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { format, isValid } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import { iRatingProcessStepPreview } from './@type';

export const useData = (
  { isCompleted, isEdit, headOfFieldSignatureDate }: iRatingProcessStepPreview,
  path: string,
) => {
  const dispatch = useAppDispatch();
  const active = useMemo(() => isCompleted || isEdit, [isCompleted, isEdit]);
  const [stepData, setStepData] = useState<any>(null);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  //state
  const date = useMemo(() => {
    if (headOfFieldSignatureDate) {
      const date_ = new Date(headOfFieldSignatureDate);
      return isValid(date_) ? format(date_, 'dd/MM/yyyy, hh:mm') : '-';
    } else {
      return '-';
    }
  }, [headOfFieldSignatureDate]);
  // handlers
  const getData = useCallback(() => {
    setInit(true);
    setLoading(true);
    apiStatic
      .get(path)
      .then(({ data: { value } }) => {
        setStepData(value[0] || null);
      })
      .catch((e) => {
        dispatch(notifyRequestResult(requestError(e, e.message), 'error'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, dispatch, setStepData, path]);
  const onLoadData = useCallback(() => {
    if (!init) {
      setInit(true);
      getData();
    }
  }, [init, setInit, getData]);
  // tooltip
  const onOpenTooltip = useCallback(() => {
    setOpenTooltip(true);
    onLoadData();
  }, [setOpenTooltip, onLoadData]);
  const onCloseTooltip = useCallback(() => {
    setOpenTooltip(false);
  }, [setOpenTooltip]);
  const onOpenDialog = useCallback(() => {
    setOpenDialog(true);
    onLoadData();
  }, [setOpenDialog, onLoadData]);
  const onCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);
  // result
  return {
    date,
    active,
    stepData,
    loading,
    openDialog,
    setOpenDialog,
    openTooltip,
    setOpenTooltip,

    onLoadData,
    onOpenTooltip,
    onCloseTooltip,
    onOpenDialog,
    onCloseDialog,
  };
};
