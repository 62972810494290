import {
  iRPStepType3ActionTypes,
  iRPStepType3RSInitAction,
  iRPStepType3RSMergeAction,
  iRPStepType3RSMergeItemAction,
  iRPStepType3RSMergeItemDataAction,
  iRPStepType3RSSaveItemAction,
} from '../../@type';

export const iRPStepType3RSMerge = (
  payload: iRPStepType3RSMergeAction['payload'],
): iRPStepType3RSMergeAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE, payload };
};
export const iRPStepType3RSMergeItem = (
  payload: iRPStepType3RSMergeItemAction['payload'],
): iRPStepType3RSMergeItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE_ITEM, payload };
};
export const iRPStepType3RSMergeItemData = (
  payload: iRPStepType3RSMergeItemDataAction['payload'],
): iRPStepType3RSMergeItemDataAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_MERGE_ITEM_DATA, payload };
};
// saga
export const iRPStepType3RSInit = (
  payload: iRPStepType3RSInitAction['payload'],
): iRPStepType3RSInitAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_INIT, payload };
};

export const iRPStepType3RSSaveItem = (
  payload: iRPStepType3RSSaveItemAction['payload'],
): iRPStepType3RSSaveItemAction => {
  return { type: iRPStepType3ActionTypes.RP_STEP_TYPE_3_RS_SAVE_ITEM, payload };
};
