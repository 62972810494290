import {
  iRPStepType5ActionTypes,
  iRPStepType5ApproveAction,
  iRPStepType5InitAction,
  iRPStepType5MergeAction,
  iRPStepType5MergeCheckupAction,
  iRPStepType5MergeCheckupDataAction,
  iRPStepType5MergeDataAction,
  iRPStepType5RejectAction,
  iRPStepType5SaveAction,
  iRPStepType5SaveCheckupAction,
  iRPStepType5SaveReportAction,
  iRPStepType5ValidationAction,
} from '../@type';

export const iRPStepType5Init = (): iRPStepType5InitAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_INIT };
};

export const iRPStepType5Merge = (
  payload: iRPStepType5MergeAction['payload'],
): iRPStepType5MergeAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE, payload };
};

export const iRPStepType5MergeData = (
  payload: iRPStepType5MergeDataAction['payload'],
): iRPStepType5MergeDataAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_DATA, payload };
};

export const iRPStepType5MergeCheckup = (
  payload: iRPStepType5MergeCheckupAction['payload'],
): iRPStepType5MergeCheckupAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP, payload };
};

export const iRPStepType5MergeCheckupData = (
  payload: iRPStepType5MergeCheckupDataAction['payload'],
): iRPStepType5MergeCheckupDataAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_MERGE_CHECKUP_DATA, payload };
};

export const iRPStepType5SaveCheckup = (
  payload: iRPStepType5SaveCheckupAction['payload'],
): iRPStepType5SaveCheckupAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_CHECKUP, payload };
};

export const iRPStepType5Save = (
  payload: iRPStepType5SaveAction['payload'],
): iRPStepType5SaveAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE, payload };
};

export const iRPStepType5Approve = (
  payload: iRPStepType5ApproveAction['payload'],
): iRPStepType5ApproveAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_APPROVE, payload };
};

export const iRPStepType5Reject = (
  payload: iRPStepType5RejectAction['payload'],
): iRPStepType5RejectAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_REJECT, payload };
};

export const iRPStepType5Validation = (
  payload?: iRPStepType5ValidationAction['payload'],
): iRPStepType5ValidationAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_VALIDATION, payload };
};

export const iRPStepType5SaveReport = (
  payload: iRPStepType5SaveReportAction['payload'],
): iRPStepType5SaveReportAction => {
  return { type: iRPStepType5ActionTypes.RP_STEP_TYPE_5_SAVE_REPORT, payload };
};
