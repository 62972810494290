export const INITIAL_STATE = (): any => ({
  init: false,
  user: null,
  error: null,
  //TODO Changed loading from false to true. Need to check
  loading: true,
  code: false,
});

export default (state = INITIAL_STATE(), action: { type: string; payload?: object }) => {
  switch (action.type) {
    case 'AUTH_RESET':
      return { ...state, ...INITIAL_STATE(), init: true };
    case 'AUTH_START':
      return { ...state, loading: true, error: null };
    case 'AUTH_SUCCESS':
      return { ...state, user: action.payload, loading: false, init: true };
    case 'AUTH_FAIL':
      return { ...state, loading: false, user: null, error: action.payload, init: true };
    case 'AUTH_LOGOUT':
      return { ...state, user: null, loading: false, error: null, code: false };
    case 'AUTH_CODE_SUCCESS':
      return { ...state, loading: false, code: true };
    case 'AUTH_GET_USER_FAIL':
      return { ...state, loading: false, init: true };
    default:
      return state;
  }
};
// public
export const checkLogged = () => ({
  type: 'AUTH_GET_USER',
});
export const checkLoggedFail = () => ({
  type: 'AUTH_GET_USER_FAIL',
});
export const auth = (data: object) => ({
  type: 'AUTH_USER',
  data,
});
export const code = (data: object) => ({
  type: 'AUTH_CODE',
  data,
});
export const reset = () => ({
  type: 'AUTH_RESET',
});
export const logout = () => ({
  type: 'AUTH_INITIATE_LOGOUT',
});
// inner
export const authStart = () => ({
  type: 'AUTH_START',
});
export const authSuccess = (user: object) => ({
  type: 'AUTH_SUCCESS',
  payload: user,
});
export const authFail = (error: object) => ({
  type: 'AUTH_FAIL',
  payload: error,
});
export const authCodeSuccess = () => ({
  type: 'AUTH_CODE_SUCCESS',
});
export const logoutSucceed = () => ({
  type: 'AUTH_LOGOUT',
});
