import { ButtonBase } from '@mui/material';
import { apiStatic, Loading, requestError, useAuth, useI18n, useRouter } from 'AurionCR/components';
import { createFilterContains } from 'utils/dynamic-filters';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import axios from 'axios';
import clsx from 'clsx';
import { format, isValid } from 'date-fns';
import { memo, useCallback, useEffect, useState } from 'react';
import { API_ORDER } from 'services/order';
import { tRatingProcess } from 'services/rating-process';
import { useAppDispatch } from 'store';
import style from './index.module.scss';

interface Item {
  id: number;
  priorityOrderID: string;
  orderDate: string;
  ratedEntityName: string;
  productTitle: string;
}

export const Orders = memo(({ search, onClose }: { search: string; onClose: () => void }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const router = useRouter();
  // state
  const [isAllowed] = useState(auth.permission('isAllowToViewOrders'));
  const [data, setData] = useState<Item[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // handlers
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
      e.stopPropagation();
      e.preventDefault();
      router.push(`/commercial-processes/orders?order=${id}`);
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose],
  );
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel: any;
    // 'search'
    if (isAllowed) {
      setIsLoading(true);
      apiStatic
        .get(`${API_ORDER.GET_ALL_DYNAMIC}`, {
          params: {
            select: [
              'id',
              'orderDate',
              'priorityOrderID',
              'ratedEntity.name as ratedEntityName',
              '(orderDetails.count==0)?"":orderDetails.first().product.title as productTitle',
            ].join(),
            filter: `isCompleted==true&&isActive==true${
              search
                ? `&&${createFilterContains<tRatingProcess & any>(
                    ['priorityOrderID', 'ratedEntity.name'],
                    search,
                  )}`
                : ''
            }`,
            orderBy: 'priorityOrderID desc',
            take: 25,
            count: true,
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then(({ data }) => {
          const result = data?.value || [];
          setData(
            result.map((item: Item) => {
              const orderDate = item.orderDate ? new Date(item.orderDate) : '';
              return {
                ...item,
                orderDate: orderDate && isValid(orderDate) ? format(orderDate, 'dd/MM/yyyy') : '',
              };
            }),
          );
          setTotal(data?.count || 0);
        })
        .catch((e) => {
          setData([]);
          setTotal(0);
          if (!axios.isCancel(e)) {
            dispatch(notifyRequestResult(requestError(e, e.message), 'error'));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      if (cancel) cancel();
    };
  }, [isAllowed, setData, setTotal, setIsLoading, dispatch, search]);
  // render
  return isAllowed ? (
    <div className={style.result}>
      <div className={style.title}>
        <div>{t('orders')}</div>
        <div className={style.pagination}>
          <strong>{data.length}</strong> {t('of')} <strong>{total}</strong>
        </div>
      </div>
      <div className={'native-scroll scroll-size-s'}>
        <div className={clsx('native-scroll__helper')}>
          {data.map(({ id, priorityOrderID, ratedEntityName, productTitle, orderDate }) => (
            <ButtonBase key={id} onClick={(e) => onClick(e, id)} className={style.btn}>
              #{priorityOrderID} - {ratedEntityName} {productTitle} - {orderDate}
            </ButtonBase>
          ))}
        </div>
        {isLoading && <Loading />}
      </div>
    </div>
  ) : null;
});
