import { iRPState } from '../@type';
import { tRatingProcessStepReview } from 'services/rating-process-step-review';

export interface iRPStepType2Data {
  id: number;
  index: number;
  isOpen: boolean;
  isEdit: boolean;
  data: tRatingProcessStepReview & { triggerRefreshMixins?: string };
}

export interface iRPStepType2 {
  init: boolean;
  loading: boolean;
  list: iRPStepType2Data[];
}

export enum iRPStepType2ActionTypes {
  RP_STEP_TYPE_2_MERGE = 'RP_STEP_TYPE_2_MERGE',
  RP_STEP_TYPE_2_MERGE_LIST = 'RP_STEP_TYPE_2_MERGE_LIST',
  RP_STEP_TYPE_2_MERGE_LIST_DATA = 'RP_STEP_TYPE_2_MERGE_LIST_DATA',
  RP_STEP_TYPE_2_ADD_LIST_ITEM = 'RP_STEP_TYPE_2_ADD_LIST_ITEM',
  RP_STEP_TYPE_2_DELETE_LIST_ITEM = 'RP_STEP_TYPE_2_DELETE_LIST_ITEM',
  // saga
  RP_STEP_TYPE_2_INIT = 'RP_STEP_TYPE_2_INIT',
  RP_STEP_TYPE_2_SAVE = 'RP_STEP_TYPE_2_SAVE',
  RP_STEP_TYPE_2_ADD_NEW = 'RP_STEP_TYPE_2_ADD_NEW',
  RP_STEP_TYPE_2_DELETE = 'RP_STEP_TYPE_2_DELETE',
  RP_STEP_TYPE_2_APPROVE = 'RP_STEP_TYPE_2_APPROVE',
  RP_STEP_TYPE_2_REJECT = 'RP_STEP_TYPE_2_REJECT',
}

export interface iRPStepType2InitAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_INIT;
}

export interface iRPStepType2MergeAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE;
  payload: Partial<iRPStepType2>;
}

export interface iRPStepType2MergeListAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST;
  payload: Partial<iRPStepType2Data> & { id: number };
}

export interface iRPStepType2MergeListDataAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_MERGE_LIST_DATA;
  payload: Partial<iRPStepType2Data['data']> & { id: number };
}

export interface iRPStepType2SaveAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_SAVE;
  payload: Partial<iRPStepType2Data['data']> & {
    id: number;
    triggerClose?: iRPState['triggerClose'];
  };
}

export interface iRPStepType2AddNewAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_NEW;
  payload: Partial<iRPStepType2Data['data']> & { id: number };
}

export interface iRPStepType2AddListItemAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_ADD_LIST_ITEM;
  payload: iRPStepType2Data;
}

export interface iRPStepType2DeleteListItemAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE_LIST_ITEM;
  payload: { id: iRPStepType2Data['id'] };
}

export interface iRPStepType2DeleteAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_DELETE;
  payload: { id: iRPStepType2Data['id'] };
}

export interface iRPStepType2ApproveAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_APPROVE;
  payload: Partial<iRPStepType2Data['data']> & { id: number };
}

export interface iRPStepType2RejectAction {
  type: iRPStepType2ActionTypes.RP_STEP_TYPE_2_REJECT;
  payload: Partial<iRPStepType2Data['data']> & { id: number };
}
