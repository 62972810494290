export interface IUserDetailsOption {
  fullName: string;
  isActive: boolean;
}
export const makeUserDetailsField = (
  field: string | undefined,
  options: IUserDetailsOption[],
  t: (value: string) => string,
  isMultiple?: boolean,
) => {
  if (!field) {
    return '';
  }

  if (isMultiple) {
    const updatedOptions = field.split(',');
    return updatedOptions
      .map((item) => {
        const isActiveItem = options.find((itemOption) => item === itemOption.fullName)?.isActive;
        return `${item}${isActiveItem ? '' : ` [${t('inactive')}]`}`;
      })
      .join(', ');
  }

  const isActiveField = options.find((item) => item.fullName === field)?.isActive;
  return `${field}${isActiveField ? '' : ` [${t('inactive')}]`}`;
};
