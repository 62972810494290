import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const TabsNav = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="M13 4L6 4L6 20H18V9L13 9V4ZM16 18H8V16L16 16V18ZM16 12V14L8 14L8 12H16Z" />
      <path d="M8 16L16 16V18H8V16ZM8 12L16 12V14L8 14L8 12ZM14 2L6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20L20 8L14 2ZM18 20H6L6 4L13 4V9L18 9V20Z" />
    </SvgIcon>
  );
};
