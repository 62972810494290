import { apiStatic } from 'AurionCR/components';
import { pathStepForecast } from 'components/rating-process-preview/step-forecast';
import { call } from 'redux-saga/effects';
import { iRPMainDataSteps } from '../../@type';
import { dateHelper, getHtml, tTranslate } from './index';

export function* stepType2(t: tTranslate, step: iRPMainDataSteps): any {
  const {
    data: { value },
  } = yield call(apiStatic.get, pathStepForecast(step.id));
  // parse data
  for (let i = 0; i < value.length; i++) {
    value[i].reviewDate = dateHelper(value[i].reviewDate);
    value[i].midroogRatingTitle = value[i]?.midroogRatingTitle
      ? value[i]?.midroogRatingTitle
      : `${value[i]?.minRangeMidroogRatingTitle || '...'} - ${
          value[i]?.maxRangeMidroogRatingTitle || '...'
        }`;
    value[i].review = yield call(getHtml, value[i].review);
  }
  // create html
  return `<div style='page-break-before: always;'>
    <h1 class='pageTitle'>${step.ratingProcessStepTypeTitle}</h1>
    <ol>
    ${value
      .map(
        (item: any) => `<li>
        <table class='tablePreview'>
          <tbody>
            <tr>
              <th>${t('review-date')}</th>
              <td>${item.reviewDate}</td>    
            </tr>
             <tr>
              <th>${t('members')}</th>
              <td>${item?.members || ''}</td>    
            </tr>
             <tr>
              <th>${t('forecast-rating')}</th>
              <td>${item.midroogRatingTitle || ''}</td>    
            </tr>
             <tr>
              <th>${t('review-remarks')}</th>
              <td class='html'>${item?.review || ''}</td>    
            </tr>
          </tbody>
        </table>
      </li>`,
      )
      .join('')}
  </ol>
  </div>`;
}
