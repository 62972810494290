import { tCategory } from './../category/models';
import { tSector } from 'services/sector';

const API = 'Methodology';

export const API_METHODOLOGY = {
  API,
  GET: ({ id }: Pick<tMethodology, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<tMethodology, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<tMethodology, 'id'>) => `${API}/Delete/${id}`,
};

export type tMethodology = {
  id: string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  externalSystemKey?: string;
  sectorID?: number;
  categoryID?: number;
  isActive?: boolean;
  title?: string;
  titleEng?: string;
  description?: string;
  descriptionEng?: string;
  isMainMethodologyForCategory?: boolean;
  category?: tCategory;
  sector?: tSector;
};
