import { memo, useState } from 'react';
import { Box } from '@mui/material';

import style from './index.module.scss';
import clsx from 'clsx';

interface tTab {
  content: JSX.Element | string;
  onChange?: () => void;
  style?: string;
  id: number;
  disabled?: boolean;
}
type tDashboardFilters = {
  tabs: tTab[];
  defaultIndex?: number;
};

const TabsWrapper = memo(({ tabs, defaultIndex = 0 }: tDashboardFilters) => {
  const initialActiveTab = defaultIndex || tabs[0].id;
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTab);

  return (
    <Box className={style.tabWrapper}>
      {tabs.map((item) => (
        <Box
          key={item.id}
          className={clsx(style.tab, item.style, item.disabled && style.tabDisabled)}
          style={{
            background: `${activeTabIndex === item.id ? '#0028A0' : '#F5F6F7'}`,
            color: `${activeTabIndex === item.id ? '#fff' : '#364145'}`,
          }}
          onClick={() => {
            if (!item.disabled) {
              setActiveTabIndex(item.id);
              item.onChange && item.onChange();
            }
          }}
        >
          {item.content}
        </Box>
      ))}
    </Box>
  );
});

export default TabsWrapper;
