const API = 'DashboardReports';

export const API_DASHBOARD_REPORTS = {
  API,
  GET_KPIs: `${API}/GetKPIs`,
  GET_DEPARTMENTS: `${API}/GetDepartments`,
  GET_DEPARTMENTS_FOR_PROFESSIONAL: `${API}/GetDepartmentsForProfessional`,
  GET_MONITORS_TO_OPEN_ITEMS: `${API}/GetMonitorsToOpenItems`,
  GET_LEAD_ANALYST_PERFORMANCE_ITEMS: `${API}/GetLeadAnalystPerformanceItems`,
  GET_OPEN_RPS_BY_CURRENT_STEP_ITEMS: `${API}/GetOpenRPsByCurrentStepItems`,
  GET_OPEN_RPS_BY_CURRENT_STEP_TOTALS: `${API}/GetOpenRPsByCurrentStepTotals`,
  GET_OPEN_RPS_BY_TYPE_ITEMS: `${API}/GetOpenRPsByTypeItems`,
  GET_OPEN_RPS_BY_TYPE_TOTALS: `${API}/GetOpenRPsByTypeTotals`,
  GET_WEBSITE_PUBLICATION_ITEMS: `${API}/GetWebsitePublicationItems`,
  GET_COMPLIANCE_ITEMS: `${API}/GetComplianceItems`,
  GET_REQUESTED_ORDER_ITEMS: `${API}/GetRequestedOrderItems`,
  GET_RATING_PROCESS_COMMERCIAL_ITEMS: `${API}/GetRPCommercialItems`,
  GET_RAISED_CAPITAL_ORDER_ITEMS: `${API}/GetRaisedCapitalOrderItems`,
  GET_CREDIT_REVIEW_REPORT_ITEMS: `${API}/GetCreditReviewReportItems`,
  GET_WITHDRAWAL_RATING_PROCESSES: `${API}/GetWithdrawalRatingProcesses`,
};

export type tAnalystPerformance = {
  teamLeaderUserID: string;
  teamLeaderUserName: string;
  departmentID: number;
  departmentTitle: string;
  totalOpenRPs: number;
  totalCompanies: number;
};

export type tAnalystPerformancePreview = {
  value: tAnalystPerformance[];
  count: number;
};

export type tMonitors = {
  companyID: number;
  companyName: string;
  departmentID: number;
  departmentTitle: string;
  lastCommitteeDate: string;
  nextRatingProcessDays: number;
  lastReportDate: string;
};

export type tMonitorsPreview = {
  value: tMonitors[];
  count: number;
};

export type tKPI = {
  totalOpenRatingProcesses: number;
  totalRatingProcesses: number;
};
export type tOpenRPsByCurrentStepItem = {
  ratingProcessID: number;
  createdDate: string;
  companyID: number;
  companyName: string;
  departmentID: number;
  departmentTitle: string;
  ratingProcessTypeID: number;
  ratingProcessTypeTitle: string;
};

export type tOpenRPsByTypeItem = {
  ratingProcessID: number;
  createdDate: string;
  companyID: number;
  companyName: string;
  departmentID: number;
  departmentTitle: string;
  ratingProcessStepID: number;
  ratingProcessStepTitle: string;
};

export type tItemOpenRPsByItem = {
  ratingProcessID: number;
  createdDate: string;
  companyID: number;
  companyName: string;
  departmentID: number;
  departmentTitle: string;
  ratingProcessDefaultID: number;
  ratingProcessTitle: string;
};

export type tOpenRPsByCurrentStepTotal = {
  stepID: number;
  stepTitle: string;
  total: number;
};

export type tOpenRPsByTypeTotal = {
  total: number;
  typeID: number;
  typeTitle: string;
};

export enum DASHBOARD_TYPE {
  MANAGEMENT = 1,
  PROFESSIONAL,
  COMMERCIAL,
  OPERATIONAL,
  CREDIT,
  COMPLIANCE,
}

export type tWebsitePublications = {
  id: number;
  departmentID: number;
  departmentTitle: string;
  ratedEntityID: number;
  ratedEntityName: string;
  magnaPublicationDate: string;
  reportTitle: string;
  reportPublicationOptionTitle: string;
};

export type tWebsitePublicationsPreview = {
  value: tWebsitePublications[];
  count: number;
};

export type tCompliance = {
  id: number;
  departmentID: number;
  departmentTitle: string;
  magnaPublicationDate: string;
  ratedEntityID: number;
  ratedEntityName: string;
  ratingProcessTypeName: string;
  reportDate: string;
  reportMessage: string;
  websitePublicationDate: string;
  monitorUserName: string;
  monitorSignatureDate: string;
};

export type tCompliancePreview = {
  value: tCompliance[];
  count: number;
};

export type tRequestedOrders = {
  orderID: number;
  departmentID: number;
  departmentTitle: string;
  ratedEntityID: number;
  ratedEntityName: string;
  orderDate: string;
  product: string;
  ratingProcessID: string;
  ratingProcessStatusTitle: string;
};

export type tRequestedOrdersPreview = {
  value: tRequestedOrders[];
  count: number;
};

export type tWithdrawalRatingProcesses = {
  id: number;
  ratingProcessNumber: number;
  departmentID: number;
  departmentName: string;
  ratingProcessTypeID: number;
  ratingProcessTypeName: string;
  completeRatingProcessDate: string;
  ratedEntityID: number;
  ratedEntityName: string;
  priorityKey: string;
  orderID: number;
  priorityOrderID: string;
  currentProcessStep: string;
};

export type tWithdrawalRatingProcessesPreview = {
  value: tWithdrawalRatingProcesses[];
  count: number;
};

export type tRatingProcessInstractions = {
  id: number;
  orderID: number;
  departmentID: number;
  departmentTitle: string;
  ratedEntityID: number;
  ratedEntityName: string;
  date: string;
  dateType: string;
  title: string;
  priorityOrderID: string;
  status: string;
  instructions: string;
};

export type tRatingProcessInstractionsPreview = {
  value: tRatingProcessInstractions[];
  count: number;
};

export type tCompleteProcessesesWithBp = {
  orderID: number;
  departmentID: number;
  departmentTitle: string;
  ratedEntityID: number;
  ratedEntityName: string;
  reportIssueDate: string;
  title: string;
  priorityOrderID: string;
  capitalRaised: number;
  capitalRaiseDate: string;
};

export type tCompleteProcessesesWithBpPreview = {
  value: tCompleteProcessesesWithBp[];
  count: number;
};

export type tCreditReviewPerformance = {
  id: number;
  ratingProcessNumber: number;
  ratedEntityID: number;
  ratedEntityName: string;
  totalDaysFromLastRatingProcess: number;
  totalDaysInCreditReview: number;
  flag: boolean;
  message: string;
};

export type tCreditReviewPerformancePreview = {
  value: tCreditReviewPerformance[];
  count: number;
};
