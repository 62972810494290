import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import style from './index.module.scss';
import { TabsNav } from 'components/svg-icons/TabsNav.tsx';

export type tMainTabsNav = {
  id: string | number;
  title: string | React.ReactNode;
};

export type tMainTabsNavProps = {
  value: string[];
  items: tMainTabsNav[];
  onChange: (value: string[]) => void;
  multiple?: boolean;
  allowEmpty?: boolean;
};

type tValues = { [id: tMainTabsNav['id']]: any };

export const MainTabsNav = memo(
  ({ value, items, onChange, multiple = true, allowEmpty = true }: tMainTabsNavProps) => {
    // values
    const values: tValues = useMemo(() => {
      if (value?.length) {
        return value.reduce((acc: tValues, item, i) => {
          if (multiple || i === 0) acc[item] = 1;
          return acc;
        }, {});
      } else {
        return {};
      }
    }, [value, multiple]);
    // handlers
    const onClick = (id: tMainTabsNav['id']) => {
      let values_ = { ...values };

      if (values_[id]) {
        if (!(!allowEmpty && Object.keys(values_).length < 2)) delete values_[id];
      } else {
        if (multiple) {
          values_[id] = 1;
        } else {
          values_ = { [id]: 1 };
        }
      }

      onChange(Object.keys(values_));
    };
    // render
    return (
      <div className={style.tabs}>
        {items.map(({ id, title }) => (
          <Button
            key={id}
            className={clsx(style.tab, values[id] && style.active)}
            onClick={() => onClick(id)}
            disabled={!allowEmpty && value.length === 1 && Boolean(values[id])}
          >
            <div className={style.icon}>
              <TabsNav />
            </div>
            <div className={style.title}>{title}</div>
          </Button>
        ))}
      </div>
    );
  },
);

export default MainTabsNav;
