import { memo, useCallback, useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import { useI18n, useRouter } from 'AurionCR/components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import { APP_ROUTES } from 'configs/app-routes.ts';
import { SearchReport } from 'components/search-report';
import { selectIsRTL } from 'store/selects';

import clsx from 'clsx';
import style from './index.module.scss';

export const ContentWrapper = memo(
  ({
    title,
    label,
    children,
    classes,
    className,
  }: {
    title?: string;
    label?: string;
    children: any;
    className?: string;
    classes?: {
      wrapper?: string;
      header?: string;
      leftSide?: string;
      rightSide?: string;
      content?: string;
    };
  }) => {
    const { t } = useI18n();
    const router = useRouter();
    // state
    const routesMap = useSelector((state) => (state as any).routes.map);
    const pageTitle = useMemo(() => {
      if (title) {
        return title;
      } else if (label) {
        return t(label);
      } else {
        const title_ = routesMap[router.pathname]?.title;
        return title_ ? t(title_) : '';
      }
    }, [router.pathname, routesMap, title, label, t]);
    const isShowBtnBackToDashboard = useMemo(
      () => router.pathname !== APP_ROUTES.HOME.path,
      [router.pathname],
    );
    // handlers
    const onClickBackDashboard = useCallback(() => {
      router.push(APP_ROUTES.HOME().config);
    }, [router]);
    const isRtl = useSelector(selectIsRTL);
    // render
    return (
      <div className={clsx(style.wrapper, classes?.wrapper, className)}>
        <div className={clsx(style.header, classes?.header)}>
          <div className={clsx(style.leftSide, classes?.leftSide)}>
            <Typography variant={'h1'}>{pageTitle}</Typography>
          </div>
          <div className={clsx(style.rightSide, classes?.rightSide)}>
            {isShowBtnBackToDashboard && (
              <Button
                className={style.btnBackToDashboard}
                color={'primary'}
                variant={'outlined'}
                onClick={onClickBackDashboard}
              >
                {isRtl ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                {t('back-to-dashboard')}
              </Button>
            )}
            <SearchReport />
          </div>
        </div>
        <div className={clsx(style.content, classes?.content)}>{children}</div>
      </div>
    );
  },
);

export default ContentWrapper;
