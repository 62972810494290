import { ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import variables from 'styles/config.module.scss';

const FontSize = '1.4rem';

const appBreakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
    xxl: Number(variables.xxl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,
  // shape: {
  //   borderRadius: 8,
  // },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontFamily: variables.fontMain,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      color: variables.colorPrimary,
    },
    h2: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: variables.colorPrimary,
    },
    h3: {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      paddingBottom: '1.6rem',
      fontWeight: 600,
      color: variables.colorPrimary,
    },
    h4: {
      fontSize: '1.4rem',
      textTransform: 'uppercase',
      paddingBottom: '1.6rem',
      fontWeight: 600,
      color: variables.colorPrimary,
    },
    h5: {
      fontWeight: 600,
      color: variables.colorPrimary,
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      lineHeight: 1.43,
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      lineHeight: 1.43,
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.2rem',
    },
  },

  palette: {
    text: {
      primary: variables.colorDefault,
    },
    primary: {
      main: variables.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: variables.colorSecondary,
      contrastText: '#fff',
    },
  },

  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderColor: variables.colorBorder,
          borderWidth: 2,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '1',
          borderRadius: '0.4rem',
          textAlign: 'center',
          fontWeight: 'bold',
          minHeight: '3.6rem',
          padding: '0.5rem 2.5rem',
        },
        sizeSmall: {
          fontSize: '1.2rem',
          minHeight: '2.8rem',
          minWidth: 'inherit',
        },
        sizeLarge: {
          minHeight: '4.4rem',
          fontSize: '1.4rem',
        },
        text: {
          padding: '0.6rem 1.6rem',
        },
        textSizeSmall: {
          padding: '0.5rem 1rem',
        },
        textSizeLarge: {
          padding: '0.8rem 2.1rem',
        },
        startIcon: {
          '&.MuiButton-iconSizeSmall': {
            marginRight: '0.4rem',
            fontSize: '4rem',
          },
          '&.MuiButton-iconSizeMedium': {
            marginRight: '0.6rem',
          },
          '&.MuiButton-iconSizeLarge': {
            marginLeft: '-0.8rem',
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0.6rem',
          color: variables.colorGrey,
        },
      },
    },

    // DIALOG
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '1rem 2rem',
          '&+.MuiDialogContent-root': {
            paddingTop: '2rem !important',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem',
          borderTop: '3px solid ' + variables.colorGreyLight + '',
          borderBottom: '3px solid ' + variables.colorGreyLight + '',
          '& > *:last-child': {
            marginBottom: '0 !important',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: 'inherit',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem',
        },
      },
    },

    // TextField
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        color: 'primary',
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: FontSize,
          color: variables.colorGrey,
          '&$focused': {
            color: variables.colorGrey,
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0 0 1.5rem',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: variables.colorBorder,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: variables.colorBorder,
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          color: variables.colorGrey,
        },
        positionEnd: {
          color: variables.colorGrey,
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: FontSize,
        },
      },
    },

    // @ts-ignore
    MuiPickerDTTabs: {
      styleOverrides: {
        tabs: {
          color: '#fff',
        },
      },
    },

    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          borderRadius: '0.4rem',
          overflow: 'hidden',
        },
        dialog: {
          borderTop: 0,
        },
        withAdditionalAction: {},
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '0.3rem',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          minWidth: '8.5rem !important',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: variables.colorGrey,
          '& + .MuiFormControlLabel-label': {
            lineHeight: 1.1,
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: variables.colorGreyLight,
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: variables.colorSecondary,

          color: '#fff',
          '& .MuiIconButton-root': {
            color: '#fff',
          },
          '& .MuiButtonBase-root': {
            minHeight: '3.8rem',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
            fontSize: 12,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#fff',
          },
          '&.Mui-expanded .MuiButtonBase-root': {
            minHeight: '3.8rem',
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: variables.boxShadow,
        },
      },
    },
  },
};
