import { memo, useCallback, useEffect, useMemo } from 'react';
import TabsWrapper from '../tabs-wrapper';
import { useI18n } from 'AurionCR/components';
import { format } from 'date-fns';
import { Select } from 'AurionCR/components/form';
import { FormController } from 'AurionCR/components/formV2';
import { useAppDispatch, useAppSelector } from 'store';
// @ts-ignore
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DASHBOARD_TYPE } from 'services/dashboard-reports';
import { DASHBOARD_PERIOD_FILTER, DashboardFilterSetPeriod, DashboardFiltersMerge } from '../store';
import { Paper } from '@mui/material';

import style from './index.module.scss';
import { DateMultiple } from 'components/data-grid/components/filters/date-multiple';
import { TIME_FORMAT_DATE } from 'configs/const';
import clsx from 'clsx';

type tDashboardFilterForm = {
  departmentID: number | null;
};

export enum DASHBOARD_FILTERS_TAB {
  BEGIN_YEAR,
  ALL_MONTHS,
  CUSTOM_DATE,
}

const DashboardFilters = memo(({ className }: { className?: string }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const { departmentID, customDate, activeTabIndex } = useAppSelector(
    (state) => state.dashboard.filters,
  );
  const { departments } = useAppSelector((state) => state.dashboard.sources);
  const user = useSelector((state: any) => state.auth.user);

  const onChangePeriod = useCallback(
    (date: any) => {
      dispatch(
        DashboardFiltersMerge({
          period: {
            fromDate: format(date[0], TIME_FORMAT_DATE),
            toDate: format(date[1], TIME_FORMAT_DATE),
          },
          customDate: date,
        }),
      );
    },
    [dispatch],
  );

  const tabs = useMemo(
    () => [
      {
        style: style.tab,
        content: `${t('begin-year')} ${format(new Date(), 'yyyy')}`,
        id: DASHBOARD_FILTERS_TAB.BEGIN_YEAR,
        onChange: () =>
          dispatch(
            DashboardFiltersMerge({
              period: DASHBOARD_PERIOD_FILTER[DASHBOARD_FILTERS_TAB.BEGIN_YEAR],
              activeTabIndex: 0,
            }),
          ),
      },
      {
        style: style.tab,
        content: `12 ${t('months')}`,
        id: DASHBOARD_FILTERS_TAB.ALL_MONTHS,
        onChange: () =>
          dispatch(
            DashboardFiltersMerge({
              period: DASHBOARD_PERIOD_FILTER[DASHBOARD_FILTERS_TAB.ALL_MONTHS],
              activeTabIndex: 1,
            }),
          ),
      },
      {
        style: style.dateRangeWrapper,
        content: (
          <DateMultiple
            style={{
              width: '22rem',
              margin: 0,
            }}
            fullWidth={false}
            onChange={onChangePeriod}
            value={[new Date(customDate[0]), new Date(customDate[1])]}
            onCustomClickAway={() => dispatch(DashboardFilterSetPeriod())}
            disableClearable={true}
          />
        ),
        id: DASHBOARD_FILTERS_TAB.CUSTOM_DATE,
      },
    ],
    [t, onChangePeriod, customDate, dispatch],
  );

  const { control, setValue, watch } = useForm<tDashboardFilterForm>({
    defaultValues: {
      departmentID,
    },
  });

  const formDepartmentID = watch('departmentID');

  useEffect(() => {
    if (user.dashboardType === DASHBOARD_TYPE.PROFESSIONAL && departments?.length) {
      setValue('departmentID', departmentID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.dashboardType, departments]);

  useEffect(() => {
    if (formDepartmentID !== departmentID) {
      dispatch(DashboardFiltersMerge({ departmentID: formDepartmentID }));
    }
  }, [departmentID, dispatch, formDepartmentID]);

  // render
  return (
    <Paper elevation={8} className={clsx(style.root, className)}>
      <TabsWrapper tabs={tabs} defaultIndex={activeTabIndex} />
      <FormController<tDashboardFilterForm>
        className={style.filter}
        name="departmentID"
        label={t('department')}
        as={Select}
        control={control}
        source={departments || []}
      />
    </Paper>
  );
});

export default DashboardFilters;
