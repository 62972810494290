import { apiStatic, requestError } from 'AurionCR/components';
import { parseMixins, saveMixins } from 'AurionCR/components/formV2';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { prepareRequest } from 'components/helpers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL,
  tRatingProcessStepCommitteeMaterial,
} from 'services/rating-process-step-committee-material';
import {
  iRPState,
  iRPStepType3ActionTypes,
  iRPStepType3MSInitAction,
  iRPStepType3MSSaveItemAction,
} from '../../@type';
import {
  iRPStepType3MSMerge,
  iRPStepType3MSMergeItem,
  iRPStepType3MSMergeItemData,
} from '../../helpers';

const getData = (id: number) => {
  return apiStatic.get(API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL.GET_ALL_DYNAMIC, {
    params: {
      filter: `ratingProcessStepCommitteeID==${id}`,
      select: ['id', 'title', 'ratingProcessStepCommitteeID', 'remarks', 'isInclude'].join(),
    },
  });
};

function* init({ payload: { committeeIndex } }: iRPStepType3MSInitAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          id,
          materials: { isInit, isLoading },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  if (!isInit && !isLoading) {
    yield put(iRPStepType3MSMerge({ committeeIndex, isLoading: true }));
    try {
      const {
        data: { value },
      }: { data: { value: tRatingProcessStepCommitteeMaterial[] } } = yield call(getData, id);
      // set data
      yield put(
        iRPStepType3MSMerge({
          committeeIndex,
          isLoading: false,
          isInit: true,
          list: value.map((item, index) => ({
            index,
            isLoading: false,
            data: item,
          })),
        }),
      );
    } catch (e) {
      yield put(iRPStepType3MSMerge({ committeeIndex, isLoading: false }));
      yield put(notifyRequestResult(requestError(e), 'error'));
    }
  }
}

function* saveItem({
  payload: { committeeIndex, itemIndex, ...newData },
}: iRPStepType3MSSaveItemAction) {
  const {
    stepType3: {
      list: {
        [committeeIndex]: {
          materials: {
            list: {
              [itemIndex]: { data: prevData },
            },
          },
        },
      },
    },
  }: iRPState = yield select((state) => state.RP);
  yield put(iRPStepType3MSMergeItem({ committeeIndex, itemIndex, isLoading: true }));
  try {
    let { data, mixins } = parseMixins(newData);
    // @ts-ignore
    data = yield call(saveMixins, data, mixins);
    yield call(
      apiStatic.patch,
      API_RATING_PROCESS_STEP_COMMITTEE_MATERIAL.PATCH(prevData),
      prepareRequest(data),
    );
    yield put(iRPStepType3MSMergeItemData({ committeeIndex, itemIndex, ...data }));
  } catch (e) {
    yield put(iRPStepType3MSMergeItemData({ committeeIndex, itemIndex, ...prevData }));
    yield put(notifyRequestResult(requestError(e), 'error'));
  }
  yield put(iRPStepType3MSMergeItem({ committeeIndex, itemIndex, isLoading: false }));
}

export const MS = [
  takeLatest(iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_INIT, init),
  takeEvery(iRPStepType3ActionTypes.RP_STEP_TYPE_3_MS_SAVE_ITEM, saveItem),
];
