import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill={'#fff'}>
      <path
        opacity="0.3"
        d="M12 3.19L5 6.3V11C5 15.52 7.98 19.69 12 20.93C16.02 19.7 19 15.52 19 11V6.3L12 3.19ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
      />
      <path d="M12 3.19L19 6.3V11C19 15.52 16.02 19.69 12 20.93C7.98 19.69 5 15.52 5 11V6.3L12 3.19ZM12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z" />
    </g>
  </svg>,
  'Issuers',
);
