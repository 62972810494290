import React, { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import style from './index.module.scss';
import { DialogTitle, IconButton, Typography } from '@mui/material';

interface Props {
  title?: string;
  disabled?: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

export const DialogHeading: React.FC<Props> = ({ children, onClose, title, disabled }) => {
  return (
    <DialogTitle className={style.wrap}>
      <Typography variant={'h2'} component={'h6'} color={'primary'}>
        {title || ''}
      </Typography>
      {onClose && (
        <IconButton className={style.close} onClick={onClose} disabled={disabled}>
          <CloseIcon color={'inherit'} />
        </IconButton>
      )}
      {children}
    </DialogTitle>
  );
};
