import { memo, useCallback, useEffect, useState } from 'react';
import { useI18n } from 'AurionCR/components';
// @ts-ignore
import { useForm } from 'react-hook-form';
import {
  IRPStepDoneForm,
  RPStepSubmitForm,
} from 'pages/CreditRatings/RatingProcess/components/editor/store';
import { useAppDispatch } from 'store';
import { useStepHooks } from 'pages/CreditRatings/RatingProcess/components/editor/components/step-hooks';
import { FormController, Input, Select } from 'AurionCR/components/formV2';
import { FileUploader } from 'components/form';
import { REPORT_FILE_TYPE } from 'components/helpers';
import { API_USER_DETAIL } from 'services/user-detail';
import style from './index.module.scss';
import renderUserDetailsOption from 'AurionCR/components/form/user-details-select/render-option/render-user-details-option';
import { IUserDetailsItem } from 'AurionCR/components/form/user-details-select/types';
import getUserDetailsOptionLabel from 'AurionCR/components/form/user-details-select/get-option-label';
import { DateTimePicker } from 'components/form/date-picker-separate-time';
import { prepareData } from './step-done';
import { API_DOCUMENTATION_STATUS } from 'services/documentation-status/models';
import { Box, Button, Divider } from '@mui/material';

const DOCUMENTATION_STATUS_SOURCE = `${API_DOCUMENTATION_STATUS.GET_ALL_DYNAMIC}?Select=id,title, isActive&OrderBy=isActive desc,rank`;

export const StepDonePreviewForm = memo(
  ({ data, loading }: { data: prepareData | null; loading: boolean }) => {
    const { t } = useI18n();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
      control,
      formState: { errors },
      handleSubmit,
      trigger,
      watch,
    } = useForm<IRPStepDoneForm>({
      defaultValues: {
        monitorVotingSheetFilePath: data?.monitorVotingSheetFilePath,
        monitorFinancialAnalysisFilePath: data?.monitorVotingSheetFilePath,
        monitorOtherFilePath: data?.monitorOtherFilePath,
        monitorDescription: data?.monitorDescription,
        monitorUserID: data?.monitorUserID,
        monitorSignatureDate: data?.monitorSignatureDate,
        monitorApproval1DocumentationStatusID: data?.monitorApproval1DocumentationStatusID,
        monitorApproval2DocumentationStatusID: data?.monitorApproval2DocumentationStatusID,
        monitorApproval3DocumentationStatusID: data?.monitorApproval3DocumentationStatusID,
        monitorApproval4DocumentationStatusID: data?.monitorApproval4DocumentationStatusID,
      },
    });
    const dispatch = useAppDispatch();
    const { isAllowToEditRatingProcess } = useStepHooks();

    const handleSave = useCallback(
      (data: IRPStepDoneForm) => {
        if (isAllowToEditRatingProcess) {
          dispatch(RPStepSubmitForm(data));
        }
      },
      [dispatch, isAllowToEditRatingProcess],
    );

    const onSubmit = useCallback(() => {
      setIsSubmitted(true);
      handleSubmit(handleSave)();
    }, [handleSave, handleSubmit]);

    const monitorVotingSheetFilePath = watch('monitorVotingSheetFilePath');
    const monitorFinancialAnalysisFilePath = watch('monitorFinancialAnalysisFilePath');
    const monitorDescription = watch('monitorDescription');

    const onTrigger = useCallback(async () => {
      await trigger([
        'monitorVotingSheetFilePath',
        'monitorFinancialAnalysisFilePath',
        'monitorDescription',
      ]);
    }, [trigger]);

    useEffect(() => {
      if (isSubmitted) {
        onTrigger();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      monitorDescription,
      monitorVotingSheetFilePath,
      monitorFinancialAnalysisFilePath,
      onTrigger,
    ]);
    //render
    return (
      <>
        <tr>
          <FormController
            name="monitorVotingSheetFilePath"
            as={FileUploader}
            errors={errors}
            control={control}
            disabled={loading}
            fileAccept={REPORT_FILE_TYPE}
            renderProps={{
              label: t('monitor-voting-sheet-file'),
              disabled: loading,
            }}
            rules={{
              validate: () =>
                (typeof monitorVotingSheetFilePath === 'object'
                  ? monitorVotingSheetFilePath?.value
                  : monitorVotingSheetFilePath) || monitorDescription
                  ? true
                  : t('required-two-files-or-description'),
            }}
            className={'FormControllerStyled'}
            fileNamePrefixTemplate={t('monitor-voting-sheet-file')}
          />
        </tr>
        <tr>
          <FormController
            name="monitorFinancialAnalysisFilePath"
            as={FileUploader}
            errors={errors}
            control={control}
            disabled={loading}
            fileAccept={REPORT_FILE_TYPE}
            rules={{
              validate: () =>
                (typeof monitorFinancialAnalysisFilePath === 'object'
                  ? monitorFinancialAnalysisFilePath?.value
                  : monitorFinancialAnalysisFilePath) || monitorDescription
                  ? true
                  : t('required-two-files-or-description'),
            }}
            renderProps={{
              label: t('monitor-financial-analysis-file'),
              disabled: loading,
            }}
            className={'FormControllerStyled'}
            fileNamePrefixTemplate={t('monitor-financial-analysis-file')}
          />
        </tr>
        <tr>
          <FormController
            name="monitorOtherFilePath"
            as={FileUploader}
            errors={errors}
            control={control}
            disabled={loading}
            fileAccept={REPORT_FILE_TYPE}
            renderProps={{
              label: t('monitor-other-file-path'),
              disabled: loading,
            }}
            className={'FormControllerStyled'}
            fileNamePrefixTemplate={t('monitor-other-file-path')}
          />
        </tr>
        <tr>
          <FormController
            name="monitorDescription"
            label={t('monitor-description')}
            as={Input}
            rules={{
              validate: () =>
                monitorDescription ||
                ((typeof monitorVotingSheetFilePath === 'object'
                  ? monitorVotingSheetFilePath?.value
                  : monitorVotingSheetFilePath) &&
                  (typeof monitorFinancialAnalysisFilePath === 'object'
                    ? monitorFinancialAnalysisFilePath?.value
                    : monitorFinancialAnalysisFilePath))
                  ? true
                  : t('required-two-files-or-description'),
            }}
            errors={errors}
            disabled={loading}
            control={control}
            className={'FormControllerStyled'}
            multiline
            minRows={3}
            maxRows={5}
          />
        </tr>
        <tr>
          <FormController
            name="monitorUserID"
            placeholder={t('monitor-user')}
            label={t('monitor-user')}
            as={Select}
            rules={'required'}
            errors={errors}
            control={control}
            disabled={loading}
            source={`${API_USER_DETAIL.GET_ALL_DYNAMIC}?Select=userID as id,fullName as title, isActive&Filter=isSystemUser==true&%26%26&OrderBy=isActive desc,firstName asc,lastName asc`}
            className={style.analyst}
            renderOption={renderUserDetailsOption}
            getOptionLabel={(item: IUserDetailsItem) => getUserDetailsOptionLabel({ ...item, t })}
          />
        </tr>
        <tr>
          <FormController
            name="monitorApproval1DocumentationStatusID"
            placeholder={t('monitor-approval1-documentation-status')}
            label={t('monitor-approval1-documentation-status')}
            as={Select}
            rules={'required'}
            errors={errors}
            control={control}
            disabled={loading}
            source={DOCUMENTATION_STATUS_SOURCE}
            className={style.analyst}
            renderOption={renderUserDetailsOption}
            getOptionLabel={(item: IUserDetailsItem) => getUserDetailsOptionLabel({ ...item, t })}
          />
        </tr>
        <tr>
          <FormController
            name="monitorApproval2DocumentationStatusID"
            placeholder={t('monitor-approval2-documentation-status')}
            label={t('monitor-approval2-documentation-status')}
            as={Select}
            rules={'required'}
            errors={errors}
            control={control}
            disabled={loading}
            source={DOCUMENTATION_STATUS_SOURCE}
            className={style.analyst}
            renderOption={renderUserDetailsOption}
            getOptionLabel={(item: IUserDetailsItem) => getUserDetailsOptionLabel({ ...item, t })}
          />
        </tr>
        <tr>
          <FormController
            name="monitorApproval3DocumentationStatusID"
            placeholder={t('monitor-approval3-documentation-status')}
            label={t('monitor-approval3-documentation-status')}
            as={Select}
            rules={'required'}
            errors={errors}
            control={control}
            disabled={loading}
            source={DOCUMENTATION_STATUS_SOURCE}
            className={style.analyst}
            renderOption={renderUserDetailsOption}
            getOptionLabel={(item: IUserDetailsItem) => getUserDetailsOptionLabel({ ...item, t })}
          />
        </tr>
        <tr>
          <FormController
            name="monitorApproval4DocumentationStatusID"
            placeholder={t('monitor-approval4-documentation-status')}
            label={t('monitor-approval4-documentation-status')}
            as={Select}
            rules={'required'}
            errors={errors}
            control={control}
            disabled={loading}
            source={DOCUMENTATION_STATUS_SOURCE}
            className={style.analyst}
            renderOption={renderUserDetailsOption}
            getOptionLabel={(item: IUserDetailsItem) => getUserDetailsOptionLabel({ ...item, t })}
          />
        </tr>
        <tr>
          <FormController
            rules={'date,required'}
            name="monitorSignatureDate"
            label={t('monitor-signature-date')}
            as={DateTimePicker}
            format={'dd/MM/yyyy'}
            errors={errors}
            control={control}
            disabled={loading}
          />
        </tr>
        <Box display={'flex'} justifyContent={'end'} mb={'1rem'}>
          <Button
            className={style.btnFinish}
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={onSubmit}
          >
            {t('save')}
          </Button>
        </Box>
        <Divider orientation={'horizontal'} />
      </>
    );
  },
);
