import { apiStatic, checkEs6AndRun, Loading, requestError } from 'AurionCR/components';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import clsx from 'clsx';
import { validURL } from 'utils/validation';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import style from './index.module.scss';
import { InputLabel } from '@mui/material';

export const HtmlPreview = memo(
  ({
    apiGet = `MediaUpload/GetHtmlFromCloud?fileName=\${data}`,
    label,
    value,
    config,
  }: {
    apiGet?: string;
    label?: React.ReactNode;
    value?: string;
    config?: { height?: string | number };
  }) => {
    const dispatch = useAppDispatch();
    //state
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    // handlers
    const loadData = useCallback(
      (value: string | undefined) => {
        setLoading(true);
        apiStatic
          .get(checkEs6AndRun(apiGet, value))
          .then((response) => {
            setLoading(false);
            setContent(response.data);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            dispatch(notifyRequestResult(requestError(error), 'error'));
          });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setLoading, apiGet, setContent],
    );
    // init
    useEffect(() => {
      if (value) {
        if (validURL(value)) {
          loadData(value);
        } else {
          setContent(value);
        }
      } else {
        setContent('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, setContent]);
    //render
    return (
      <div className={clsx('editor-wrapper', label && 'label')}>
        <div>
          {label && (
            <InputLabel shrink htmlFor="code-input">
              {label}
            </InputLabel>
          )}
        </div>
        <div
          className={clsx('native-scroll', 'scroll-size-s', style.html)}
          style={{ height: config?.height || '20rem' }}
        >
          <div className={'content'} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <Loading active={loading} />
      </div>
    );
  },
);
