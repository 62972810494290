import { createConstFromEnum } from 'components/helpers';
import { tRatingProcess } from 'services/rating-process';
import { tUserDetail } from 'services/user-detail';

const API = 'RatingProcessDocumentation';

export const API_RATING_PROCESS_DOCUMENTATION = {
  API,
  GET: ({ id }: Pick<RatingProcessType, 'id'>) => `${API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<RatingProcessType, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<RatingProcessType, 'id'>) => `${API}/Delete/${id}`,
};

export enum eDocumentType {
  Auto = 'Auto',
  User = 'User',
}

export const DocumentType = createConstFromEnum(Object.entries(eDocumentType), true);

export type RatingProcessType = {
  id: number;
  createdDate: string | Date;
  updatedDate: string | Date | null;
  externalSystemKey: string | null;
  isActive: boolean;
  title: string | null;
  titleEng: string | null;
  linkToFile: string | null;
  filePath: string | null;
  remarks: string | null;
  ratingProcessID: number;
  ratingProcess?: tRatingProcess | null;
  userID: string | null;
  user?: tUserDetail | null;
  documentType: eDocumentType | string;
};
